import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import { Grid, styled, Box, Typography } from "@material-ui/core";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
// Customizable Area End

import SellerBankController, {
    AccountDetails,
    Props,
    configJSON
} from "./SellerBankController.web";


// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class SellerBankDetails extends SellerBankController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2}>
                    {
                        this.state.userRole !== "stylist" ? (
                            <SellerSidebar navigation={this.props.navigation} />
                        ): (
                            <StylishSidebar navigation={this.props.navigation} />
                        )
                    }
                    </Grid>
                    <Grid item xl={10} md={10} lg={10} sm={10} xs={10}>
                    {
                        this.state.userRole !== "stylist" ? (
                            <SellerHeader navigation={this.props.navigation} />
                        ): (
                            <StylishHeader navigation={this.props.navigation} />
                        )
                    }
                        <StoreProfile>
                            <Box className="mainContainer">
                                <Typography className="store-title">Bank Details</Typography>
                            </Box>
                            <Box style={webStyle.deatilsText}>
                                <Typography style={webStyle.Text}>Your bank information is saved to receive payments of your orders.</Typography>
                            </Box>
                                {this.state.bankDetails && this.state.bankDetails.map((item: AccountDetails, index: number)=> {
                                    return (
                                        <Box style={webStyle.deatilsInfo}>
                                        <Box className="detailsContainer">
                                        <Box className="detailsPrimary">
                                        <Box className="nameContainer">
                                            <Typography className="clientName">{item.attributes.account_holder_name}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="secondaryContainer">
                                        <Box>
                                            <Typography className="firstText">IBAN Number</Typography>
                                            <Typography className="secondText">{item.attributes.iban}</Typography>
                                        </Box>
                                        <Box >
                                            <Typography className="firstText">Account Number</Typography>
                                            <Typography className="secondText">{item.attributes.account_number}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                                    )
                                })}

                        </StoreProfile>
                    </Grid>
                </Grid>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start

const webStyle = {
    deatilsText: {
        maxWidth: "1200px",
        padding: "12px 16px 12px 16px",
        border: "1px solid #E2E8F0",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)"
    },
    deleteText: {
        fontSize: '30px',
        color: "#375280",
        fontWeight: 500,
        fontFamily: "Lato"
    },inputHeadingBox1: {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "10px 0",
        textOverflow: "ellipsis",
        fontFamily: "Lato",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    deleteContainer: {
        marginLeft: "12px", display: "flex", flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,
    deleteText1: {
        fontSize: '18px',
        color: "#375280",
        fontWeight: 400,
        fontFamily: "Lato"
    },
    confirmButton: {
        alignItems: "center",
        fontSize: "15px",
        justifyContent: "center",
        fontWeight: 500,
        display: "flex",
        color: "white",
        backgroundColor: "#CCBEB1",
        border: "1px solid #CCBEB1",
        height: "56px",
        borderRadius: "5px",
        width: "252px",
        padding: "10px",
        cursor: "pointer",
        textTransform: "capitalize"
    } as React.CSSProperties,
    cancelButton: {
        alignItems: "center",
        fontSize: "15px",
        justifyContent: "center",
        fontWeight: 500,
        display: "flex",
        color: "#375280",
        backgroundColor: "#FFFFFF",
        border: "1px solid #CCBEB1",
        height: "56px",
        borderRadius: "5px",
        width: "252px",
        padding: "10px",
        cursor: "pointer",
        textTransform: "capitalize"
    } as React.CSSProperties,
    deatilsInfo: {
        maxWidth: "1200px",
        padding: "12px 16px 12px 16px",
        border: "1px solid #E2E8F0",
    },
    threeDotBox: {
        width: "215px",
        height: "110px",
        backgroundColor: "#FFFFFF",
        position: "absolute",
        top: "38px",
        right: "6px",
        boxShadow: "8px 8px 32px 0 rgba(0, 0, 0, 0.06)",
        borderRadius: "5px",
        padding: "15px 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1
    } as React.CSSProperties,
    deleteAccount: {
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Lato",
        color: "#DC2626",
        cursor: "pointer"
    },
    Text: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        color: "#375280",
        cursor: "pointer"
    },
    saveText: {
        fontFamily: 'Lato',
        fontSize: "20px",
        fontWeight: 800,
        marginLeft: "10px"
    },
    savebuttn: {
        width: "200px",
        height: "56px",
        borderRadius: "3px",
        backgroundColor: "rgb(204, 190, 177)",
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        cursor: "pointer",
    },
};

const StoreProfile = styled(Box)({
    padding: "30px 60px",
    minHeight: "calc(100vh - 85px)",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    maxWidth: "1200px",
    "& .nameContainer":{ 
        width: "40%",
        display: "flex", gap: "12px", textTransform: "capitalize",

    "@media (min-width: 200px) and (max-width: 600px)": {
        display: "flex", gap: "12px",
        flexWrap: 'wrap',
    },
     },
    "& .store-title": {
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 700,
        color: "#375280",
        marginBottom: "20px"
    },
    "& .mainContainer": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .detailsContainer": {
        maxWidth: "1180px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px 10px"
    },
    "& .detailsPrimary": {
        maxWidth: "100%",
        display: "flex",
        justifyContent: "space-between"
    },
    "& .clientName": {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 700,
        color: "#375280"
    },
    "& .threeDotsIcon": {
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        width: "34px", height: "34px",
        borderRadius: "50%",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.08)",
        position: "relative"
    },
    "& .thirdContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "12px", 
        height: "89px"
    },
    "& .secondaryContainer": {
        display: "flex",
        gap: "30px",
        "@media (min-width: 280px) and (max-width: 400px)": {
            display: "flex",
            gap: "30px",
            flexWrap: 'wrap',
        },
    },
    "& .firstText": {
        color: "#375280",
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 500
    },
    "& .secondText": {
        color: "#94A3B8",
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: 500,
        wordBreak: "break-all"
    }
});
// Customizable Area End
