import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button
} from "@material-ui/core";
import { button_arrowrit, button_arrow } from "./assets";
import SellerSideBar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerOffersDiscountsDetailsWebController, {
    Props,
} from "./SellerOffersDiscountswebController";
// Customizable Area End

export default class SellerOffersDiscountsWebPage extends SellerOffersDiscountsDetailsWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Grid data-testid="capitalize" item xl={2} md={2} lg={2} sm={2} xs={2} >
                        <SellerSideBar navigation={this.props.navigation} />
                    </Grid>
                    <Grid item xl={10} lg={10} sm={10} md={10} xs={10}  >
                        <SellerHeader navigation={this.props.navigation} />
                        <Box style={{ margin: "30px" }}>
                            <Box style={{ display: 'flex', cursor: 'pointer' }}><img width="32px" height="32px" data-testid="sellerOffersStoreCreate" onClick={this.sellerOffersStoreCreate} src={button_arrow} />&nbsp;&nbsp;&nbsp;<Typography style={webStyle.lableText}>Offers and Discounts</Typography></Box>
                            <Box>
                                <Box style={{ backgroundColor: 'rgb(248, 248, 248)', padding: '20px', marginBottom: '30px' }}>
                                    <Box style={webStyle.haderBox}>
                                        <Typography style={webStyle.numberBox} data-testid="OffersDiscounts">Offers & Discounts</Typography>
                                        <img width="32px" height="32px" src={button_arrowrit} style={{ cursor: 'pointer' }} data-testid="sellerOffersDiscountsCreate" onClick={this.sellerOffersDiscountsCreate} />
                                    </Box>
                                    <Typography style={webStyle.createText}>Create new offers & Discount coupons</Typography>
                                    <Button style={{ ...webStyle.twoButton, textTransform: 'capitalize' }}>Explore More</Button>
                                </Box>
                                <Box style={{ padding: '20px', backgroundColor: 'rgb(248, 248, 248)' }}>
                                    <Box style={webStyle.haderBox}>
                                        <Typography id="advertisements" style={webStyle.numberBox}>Advertisements</Typography>
                                        <img width="32px" src={button_arrowrit} height="32px" style={{ cursor: 'pointer' }} onClick={this.sellerOffersDiscountsCreate} />
                                    </Box>
                                    <Typography style={webStyle.createText} id="product">More product reach, get more orders</Typography>
                                    <Button style={{ textTransform: 'capitalize', ...webStyle.twoButton }}>Create Ads</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    twoButton: { width: '180px', height: '48px', backgroundColor: '#CCBEB1', padding: '11px 27px', fontSize: '18px', fontFamily: 'Lato', fontWeight: 700, color: '#FFFFFF' },
    haderBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
    createText: { fontSize: "20px", fontFamily: "Lato", fontWeight: 500, color: "#375280", marginBottom: '30px' },
    numberBox: { fontSize: "22px", fontFamily: "Lato", fontWeight: 700, color: "#375280", marginBottom: '10px' },
    lableText: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280", marginBottom: '30px' },
    container: { display: "flex", justifyContent: "space-between" },
};
// Customizable Area End