import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Box,
  Dialog,
  styled,
} from "@material-ui/core";
import StylingRequestsController, { Props } from "./StylingRequestsController";
import { ThemeProvider } from "react-native-elements";
import {
  backBtnIcon,
  rejectIcon,
  request1,
  request2,
  request3,
  storeSuccessIcon,
} from "../../assets";
import StylishSidebar from "../../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../../components/src/Stylish/StylishHeader";
import {
  StylishClientContainer,
  StylishClientTitleContainer,
} from "./StylingClientWishlist.web";
import { CustomStyledTableUpdateInventory } from "../../Seller/InventoryManagement/UpdateInventory/UpdateInventory.web";
import {
  RequestStatusContainer,
  RequesterNGContainer,
  TableBodyCell,
  TableHeaderCell,
  TableTypographyContainer,
  ViewRequestContainer,
} from "./StylishClients.web";
import {
  CustomStyledStylishPagination,
  StylishPaginationContainer,
} from "../../../../catalogue/src/Stylish/StylishProductSourcing.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylingRequests extends StylingRequestsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            md={2}
            id="stylish-requests-sidebar-catalogue"
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={10}
            id="stylish-requests-header-catalogue"
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishClientContainer>
              <StylishClientTitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleStylishRequestsRedirect("StylishClients")
                  }
                />
                <Typography className="main-title">Styling Requests</Typography>
              </StylishClientTitleContainer>
              <CustomStyledTableUpdateInventory data-test-id="sr-table">
                <Table stickyHeader size="small" className="custom-table">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#f0f0f0" }}
                      className="sr-table-head-row"
                    >
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography data-test-id="col-req-id-head-test-id" className="table-head-title first-typo">
                          Request ID
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography data-test-id="col-req-time-head-test-id" className="table-head-title">
                          Request Time
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="sr-table-head-cell"
                        style={{ width: "330px" }}
                      >
                        <Typography data-test-id="col-ng-head-test-id" className="table-head-title">
                          Name & Gender
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography data-test-id="col-budget-head-test-id" className="table-head-title">
                          Budget
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography data-test-id="col-status-head-test-id" className="table-head-title">
                          Status
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="sr-table-head-cell"
                        style={{ width: "241px" }}
                      >
                        <Typography data-test-id="col-action-head-test-id" className="table-head-title">
                          Action
                        </Typography>
                      </TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.tableBody.map((requestData) => (
                      <TableRow key={requestData.id} className="hover">
                        <TableBodyCell data-test-id="product-name">
                          <TableTypographyContainer
                            style={{ paddingLeft: "20px" }}
                            data-test-id={`col-req-id-body-test-id-${requestData.id}`}
                          >
                            {requestData.requestId}
                          </TableTypographyContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <TableTypographyContainer data-test-id={`col-req-time-body-test-id-${requestData.id}`}>
                            {requestData.requestTime}
                          </TableTypographyContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <RequesterNGContainer data-test-id={`col-ng-body-test-id-${requestData.id}`}>
                            <Typography className="request-name">
                              {requestData.requesterName}
                            </Typography>
                            <Typography className="request-gender">
                              {requestData.requesterGender}
                            </Typography>
                          </RequesterNGContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <TableTypographyContainer data-test-id={`col-budget-id-body-test-id-${requestData.id}`}>
                            {requestData.budget}
                          </TableTypographyContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <RequestStatusContainer data-test-id={`col-status-body-test-id-${requestData.id}`}>
                            {requestData.requestStatus}
                          </RequestStatusContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <ViewRequestContainer
                            onClick={() => this.handleRequestClose()}
                            data-test-id={`col-view-req-id-body-test-id-${requestData.id}`}
                          >
                            View Request
                          </ViewRequestContainer>
                        </TableBodyCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomStyledTableUpdateInventory>
            </StylishClientContainer>
            <StylishPaginationContainer>
              <Box className="pagination-data-container">
                {1} - of 54 results
              </Box>
              <CustomStyledStylishPagination
                page={1}
                count={5}
                shape="rounded"
                color="primary"
                data-test-id="my-offer-page-test-id"
              />
            </StylishPaginationContainer>
          </Grid>
        </Grid>
        <ViewRequestDialog
          data-test-id="request-view-dialog"
          open={this.state.isRequestOpen}
          onClose={() => this.handleRequestClose()}
        >
          <ViewRequestDialogContainer>
            <Box className="main-request-title">
              <Typography className="request-number">
                #3476-9867 | 03:30 PM
              </Typography>
              <RequestStatusContainer style={{ height: "20px" }}>
                New Request
              </RequestStatusContainer>
            </Box>
            <PreviewRequestImg>
              {requestImages.map((requestFile) => (
                <Box key={requestFile.id} className="file-preview">
                  <img
                    src={requestFile.url}
                    alt={`preview ${requestFile.id}`}
                    className="preview-image"
                  />
                </Box>
              ))}
            </PreviewRequestImg>
            <RequestDetailContainer>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="requester-name">
                    Scarlet Johansson
                  </Typography>
                </Box>
                <Box className="description-data">
                  <Typography className="description-name">Plan - </Typography>
                  <Typography className="description-value">Weekly</Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-name">
                    Gender -{" "}
                  </Typography>
                  <Typography className="description-value">Female</Typography>
                </Box>
                <Box className="description-data">
                  <Typography className="description-name">
                    Budget -{" "}
                  </Typography>
                  <Typography className="description-value">
                    $50 - $250
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-name">
                    Colours -{" "}
                  </Typography>
                  <Typography className="description-value">
                    Black, Blue
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-content">
                    I want a red coloured dress with shinny embroidery over it.
                    I usually wear the ‘Medium Size’ and ‘Slim’ fit. I want to
                    wear that dress at Gala Dinner at my company’s meet up. The
                    location is Maldives so i want something according to that
                    location.
                  </Typography>
                </Box>
              </Box>
            </RequestDetailContainer>
            <RequestBtnContainer>
              <Box onClick={() => this.handleReject()} data-test-id="reject-test-id" className="reject-div">
                Reject
              </Box>
              <Box onClick={() => this.handleAccept()} data-test-id="accept-test-id" className="accept-div">
                Accept
              </Box>
            </RequestBtnContainer>
          </ViewRequestDialogContainer>
        </ViewRequestDialog>
        <DialogSuccessRejectContainer
          data-test-id="request-accept-reject-dialog"
          open={this.state.isSROpen}
        >
          <Box className="content-container">
            <img
              src={this.state.isAccepted ? storeSuccessIcon : rejectIcon}
              alt="icon"
            />
            <Typography className="text-container">
              {`You have ${
                this.state.isAccepted ? "accepted" : "rejected"
              } the styling request of Scarlet.`}
            </Typography>
          </Box>
        </DialogSuccessRejectContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const ViewRequestDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "24px 36px",
    width: 452,
  },
});

export const ViewRequestDialogContainer = styled(Box)({
  width: "100%",
  display: "flex",
  gap: 24,
  flexDirection: "column",
  "& .main-request-title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .request-number": {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontWeight: 500,
      fontSize: 14,
    },
  },
});

export const PreviewRequestImg = styled(Box)({
  display: "flex",
  gap: 12,
  flexWrap: "wrap",
  "& .file-preview": {
    position: "relative",
    "& .preview-image": {
      width: 80,
      height: 80,
      objectFit: "cover",
      borderRadius: 2,
    },
  },
});

export const RequestDetailContainer = styled(Box)({
  display: "flex",
  gap: 12,
  flexDirection: "column",
  paddingTop: 16,
  "& .row-container": {
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:540px)": {
      flexDirection: "column",
    },
    "& .description-data": {
      display: "flex",
      "& .description-name": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 16,
        color: "#375280",
      },
      "& .description-content": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 20,
        color: "#375280",
      },
      "& .description-value": {
        fontFamily: "Lato, sans-serif",
        fontSize: 16,
        fontWeight: 900,
        color: "#375280",
        paddingLeft: 4,
      },
      "& .requester-name": {
        fontFamily: "Lato, sans-serif",
        fontSize: 18,
        fontWeight: 700,
        color: "#375280",
      },
    },
  },
});

export const RequestBtnContainer = styled(Box)({
  width: "100%",
  display: "flex",
  gap: 12,
  height: 54,
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "capitalize",
  "& .reject-div": {
    color: "#F87171",
    borderColor: "#F87171",
    width: "50%",
    height: "100%",
    fontFamily: "Lato, sans-serif",
    display: "flex",
    justifyContent: "center",
    borderRadius: "2px",
    alignItems: "center",
    border: "1px solid #F87171",
  },
  "& .accept-div": {
    color: "white",
    fontFamily: "Lato, sans-serif",
    gap: "8px",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    width: "50%",
    height: "100%",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const DialogSuccessRejectContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 60px",
    width: 520,
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});

export const requestImages = [
  {
    id: 1,
    url: request1,
  },
  {
    id: 2,
    url: request2,
  },
  {
    id: 3,
    url: request3,
  },
];
// Customizable Area End
