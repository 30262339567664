import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
interface IRespData{
  id: number;
  clientName: string;
  status: string;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  respData: IRespData[];
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylishMyClientsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      respData: myClientBodyData
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  handleStylishMyClientsRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  // Customizable Area End
}
// Customizable Area Start
const myClientBodyData = [
  {
    id: 1,
    clientName: "Ralph Edwards",
    status: "Current"
  },
  {
    id: 2,
    clientName: "Annette Black",
    status: "Completed"
  },
  {
    id: 3,
    clientName: "Leslie Alexander",
    status: "Current"
  },
  {
    id: 4,
    clientName: "Courtney Henry",
    status: "Completed"
  },
  {
    id: 5,
    clientName: "Wade Warren",
    status: "Current"
  },
  {
    id: 6,
    clientName: "Jane Cooper",
    status: "Completed"
  },
  {
    id: 7,
    clientName: "Savannah Nguyen",
    status: "Current"
  },
  {
    id: 8,
    clientName: "Ralph Edwards",
    status: "Completed"
  },
  {
    id: 9,
    clientName: "Dianne Russell",
    status: "Completed"
  },
  {
    id: 10,
    clientName: "Albert Flores",
    status: "Completed"
  },
  {
    id: 11,
    clientName: "Darlene Robertson",
    status: "Completed"
  },
  {
    id: 12,
    clientName: "Cody Fisher",
    status: "Completed"
  },
  {
    id: 13,
    clientName: "Arlene McCoy",
    status: "Completed"
  },
  {
    id: 14,
    clientName: "Eleanor Pena",
    status: "Completed"
  },
  {
    id: 15,
    clientName: "Jane Cooper",
    status: "Completed"
  },
  {
    id: 16,
    clientName: "Courtney Henry",
    status: "Completed"
  },
  {
    id: 17,
    clientName: "Mini Cooper",
    status: "Completed"
  },
  {
    id: 18,
    clientName: "Floyd Miles",
    status: "Completed"
  },
]
// Customizable Area End
