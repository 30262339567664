import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  styled,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import StylishClientsController, { Props } from "./StylishClientsController";
import { ThemeProvider } from "react-native-elements";
import StylishSidebar from "../../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../../components/src/Stylish/StylishHeader";
import {
  StylishClientContainer,
  StylishClientTitleContainer,
} from "./StylingClientWishlist.web";
import ReusableSnackbar from "../../../../../components/src/AlertContainer";
import { rejectIcon, rightArrowIcon, storeSuccessIcon } from "../../assets";
import { CustomStyledTableUpdateInventory } from "../../Seller/InventoryManagement/UpdateInventory/UpdateInventory.web";
import {
  CustomStyledStylishPagination,
  StylishPaginationContainer,
} from "../../../../catalogue/src/Stylish/StylishProductSourcing.web";
import {
  DialogSuccessRejectContainer,
  PreviewRequestImg,
  RequestBtnContainer,
  RequestDetailContainer,
  ViewRequestDialog,
  ViewRequestDialogContainer,
  requestImages,
} from "./StylingRequests.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylishClients extends StylishClientsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            md={2}
            id="stylish-clients-sidebar-catalogue"
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={10}
            id="stylish-clients-header-catalogue"
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishClientContainer>
              <StylishClientTitleContainer>
                <Typography className="main-title">Clients</Typography>
              </StylishClientTitleContainer>
              <ChatClientContainer>
                <Box
                  className="chat-box"
                  data-test-id="chat-test-id"
                  onClick={() => this.handleClientChat()}
                >
                  Chats
                </Box>
                <Box className="client-box">Clients</Box>
              </ChatClientContainer>
              <Grid container spacing={3}>
                {this.state.stylishClientBtnData.map((btn) => (
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <StylishClientBtnContainer
                      data-test-id={`stylish-clients-btn-test-id-${btn.id}`}
                      onClick={() =>
                        btn.isRedirect
                          ? this.handleStylishClientsRedirect(
                              btn.redirectComponentName
                            )
                          : this.handleClientChat()
                      }
                      className={`btn-container`}
                    >
                      <Typography className={`btn-name-title`}>
                        {btn.btnTitle}
                      </Typography>
                      <img src={rightArrowIcon} alt="right-icon" />
                    </StylishClientBtnContainer>
                  </Grid>
                ))}
              </Grid>
              <TableHeaderContainer>
                <Typography className="latest-req-title">
                  Latest Requests
                </Typography>
                <Typography className="view-all">VIEW ALL</Typography>
              </TableHeaderContainer>
              <CustomStyledTableUpdateInventory data-test-id="as-table">
                <Table stickyHeader size="small" className="custom-table">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#f0f0f0" }}
                      className="as-table-head-row"
                    >
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title first-typo">
                          Request ID
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title">
                          Request Time
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="as-table-head-cell"
                        style={{ width: "330px" }}
                      >
                        <Typography className="table-head-title">
                          Name & Gender
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title">
                          Budget
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="as-table-head-cell">
                        <Typography className="table-head-title">
                          Status
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="as-table-head-cell"
                        style={{ width: "241px" }}
                      >
                        <Typography className="table-head-title">
                          Action
                        </Typography>
                      </TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.tableBody.map((requestData) => (
                      <TableRow key={requestData.id} className="hover">
                        <TableBodyCell data-test-id="product-name">
                          <TableTypographyContainer
                            style={{ paddingLeft: "20px" }}
                          >
                            {requestData.requestId}
                          </TableTypographyContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <TableTypographyContainer>
                            {requestData.requestTime}
                          </TableTypographyContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <RequesterNGContainer>
                            <Typography className="request-name">
                              {requestData.requesterName}
                            </Typography>
                            <Typography className="request-gender">
                              {requestData.requesterGender}
                            </Typography>
                          </RequesterNGContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <TableTypographyContainer>
                            {requestData.budget}
                          </TableTypographyContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <RequestStatusContainer>
                            {requestData.requestStatus}
                          </RequestStatusContainer>
                        </TableBodyCell>
                        <TableBodyCell>
                          <ViewRequestContainer
                            onClick={() => this.handleRequestClose()}
                            data-test-id={`view-req-test-id-${requestData.id}`}
                          >
                            View Request
                          </ViewRequestContainer>
                        </TableBodyCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomStyledTableUpdateInventory>
            </StylishClientContainer>
            <StylishPaginationContainer>
              <Box className="pagination-data-container">
                {1} - of 54 results
              </Box>
              <CustomStyledStylishPagination
                page={1}
                count={5}
                shape="rounded"
                color="primary"
                data-test-id="my-offer-page-test-id"
              />
            </StylishPaginationContainer>
          </Grid>
        </Grid>
        <ViewRequestDialog
          open={this.state.isRequestOpen}
          data-test-id="request-view-dialog"
          onClose={() => this.handleRequestClose()}
        >
          <ViewRequestDialogContainer>
            <Box
              data-test-id="request-view-title"
              className="main-request-title"
            >
              <Typography
                className="request-number"
                data-test-id="request-view-number"
              >
                #3476-9867 | 03:30 PM
              </Typography>
              <RequestStatusContainer
                style={{ height: "20px" }}
                data-test-id="request-view-status"
              >
                New Request
              </RequestStatusContainer>
            </Box>
            <PreviewRequestImg data-test-id="request-view-preview-img-container">
              {requestImages.map((requestFile) => (
                <Box
                  key={requestFile.id}
                  className="file-preview"
                  data-test-id="request-view-preview-img"
                >
                  <img
                    src={requestFile.url}
                    alt={`preview ${requestFile.id}`}
                    className="preview-image"
                  />
                </Box>
              ))}
            </PreviewRequestImg>
            <RequestDetailContainer data-test-id="request-view-detail-container">
              <Box
                data-test-id="request-view-row-container"
                className="row-container"
              >
                <Box className="description-data">
                  <Typography
                    className="requester-name"
                    data-test-id="request-view-req-name"
                  >
                    Scarlet Johansson
                  </Typography>
                </Box>
                <Box
                  className="description-data"
                  data-test-id="request-view-plan"
                >
                  <Typography className="description-name">Plan - </Typography>
                  <Typography className="description-value">Weekly</Typography>
                </Box>
              </Box>
              <Box className="row-container" data-test-id="request-view-gb">
                <Box
                  className="description-data"
                  data-test-id="request-view-gender"
                >
                  <Typography className="description-name">
                    Gender -{" "}
                  </Typography>
                  <Typography className="description-value">Female</Typography>
                </Box>
                <Box
                  className="description-data"
                  data-test-id="request-view-budget"
                >
                  <Typography className="description-name">
                    Budget -{" "}
                  </Typography>
                  <Typography className="description-value">
                    $50 - $250
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container" data-test-id="request-view-color">
                <Box className="description-data">
                  <Typography className="description-name">
                    Colours -{" "}
                  </Typography>
                  <Typography className="description-value">
                    Black, Blue
                  </Typography>
                </Box>
              </Box>
              <Box
                className="row-container"
                data-test-id="request-view-description"
              >
                <Box className="description-data">
                  <Typography className="description-content">
                    I want a red coloured dress with shinny embroidery over it.
                    I usually wear the ‘Medium Size’ and ‘Slim’ fit. I want to
                    wear that dress at Gala Dinner at my company’s meet up. The
                    location is Maldives so i want something according to that
                    location.
                  </Typography>
                </Box>
              </Box>
            </RequestDetailContainer>
            <RequestBtnContainer data-test-id="request-view-btn-container">
              <Box
                onClick={() => this.handleReject()}
                data-test-id="view-reject-test-id"
                className="reject-div"
              >
                Reject
              </Box>
              <Box
                onClick={() => this.handleAccept()}
                data-test-id="view-accept-test-id"
                className="accept-div"
              >
                Accept
              </Box>
            </RequestBtnContainer>
          </ViewRequestDialogContainer>
        </ViewRequestDialog>
        <DialogSuccessRejectContainer
          data-test-id="request-accept-reject-dialog"
          id="stylish-client-request-accept-reject"
          open={this.state.isSROpen}
        >
          <Box
            className="content-container"
            id="stylish-client-request-accept-reject-container"
          >
            <img
              src={this.state.isAccepted ? storeSuccessIcon : rejectIcon}
              alt="icon"
            />
            <Typography
              className="text-container"
              id="stylish-client-request-accept-reject-text"
            >
              {`You have ${
                this.state.isAccepted ? "accepted" : "rejected"
              } the styling request of Scarlet.`}
            </Typography>
          </Box>
        </DialogSuccessRejectContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertSCSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ChatClientContainer = styled(Box)({
  width: "100%",
  display: "flex",
  height: 64,
  gap: 24,
  marginBottom: 48,
  "& .chat-box": {
    borderRadius: 2,
    padding: "11px 20px 11px 20px",
    backgroundColor: "#FFF",
    color: "#E2E8F0",
    display: "flex",
    border: "1px solid #E2E8F0",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    width: "50%",
    fontSize: 18,
  },
  "& .client-box": {
    borderRadius: 2,
    width: "50%",
    padding: "11px 20px 11px 20px",
    backgroundColor: "#375280",
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 18,
  },
});

const TableHeaderContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "48px 0px 24px 0px",
  "& .latest-req-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    fontSize: 24,
    color: "#375280",
  },
  "& .view-all": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 900,
    fontSize: 16,
    color: "#375280",
  },
});

export const TableHeaderCell = styled(TableCell)({
  padding: "16px 20px 16px 0px !important",
  "& .table-head-title": {
    color: "#375280",
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 700,
    overflow: "none",
    whiteSpace: "nowrap",
  },
  "& .first-typo": {
    paddingLeft: "20px !important",
  },
});

export const TableBodyCell = styled(TableCell)({
  padding: "16px 20px 16px 0px !important",
  width: 105,
});

export const RequestStatusContainer = styled(Box)({
  backgroundColor: "#D1FAE5",
  color: "#059669",
  padding: "6px 10px 6px 10px",
  borderRadius: "2px",
  textAlign: "center",
  fontFamily: "Lato, sans-serif",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 500,
  height: "27px",
  whiteSpace: "nowrap",
});

export const ViewRequestContainer = styled(Box)({
  backgroundColor: "#CCBEB1",
  marginBottom: "4px",
  borderRadius: "2px",
  color: "#FFFFFF",
  fontSize: "14px",
  fontFamily: "Lato",
  fontWeight: 700,
  height: "36px",
  textTransform: "capitalize",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 10px",
});

export const TableTypographyContainer = styled(Typography)({
  fontFamily: "Lato, sans-serif",
  fontWeight: 400,
  fontSize: 16,
  color: "#375280",
  whiteSpace: "nowrap",
});

export const RequesterNGContainer = styled(Box)({
  fontFamily: "Lato, sans-serif",
  fontSize: 14,
  display: "flex",
  flexDirection: "column",
  gap: 2,
  color: "#375280",
  "& .request-name": {
    fontWeight: 700,
  },
  "& .request-gender": {
    fontWeight: 400,
  },
});

const StylishClientBtnContainer = styled(Box)({
  border: "1px solid #E2E8F0",
  borderRadius: 2,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "38px 26px",
  "& .btn-name-title": {
    fontFamily: "Lato, sans-serif",
    color: "#375280",
    fontSize: 22,
    fontWeight: 700,
  },
  "&:hover": {
    backgroundColor: "#F1F5F9",
    "& .btn-name-title": {
      fontSize: 24,
    },
  },
});
// Customizable Area End
