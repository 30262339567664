Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "shippingaddressvalidation2";
exports.labelBodyText = "shippingaddressvalidation2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.addAddressEndPoint = "bx_block_address/addresses"
exports.countryCodeEndPoint = "accounts/country_code_and_flags";
exports.countryApiContentType = "application/json";
exports.httpMethodType = "GET";
exports.product_cataloge = "GET";
exports.getUnique_token = "bx_block_order_management/orders/get_active_cart?unique_token";
exports.getRemove_order_items = "bx_block_order_management/orders/remove_order_items"
// Customizable Area End