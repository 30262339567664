import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
const navigation = require("react-navigation");
interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
}
export interface CountrySuccess {
    numeric_code: string;
    country_full_name: string;
    country_code: string;
    country_flag: string;
}
export interface OTPData {
    id: string;
    type: string;
    attributes: {
        full_phone_number: string;
        pin: number;
        valid_until: string;
    };
}

interface Error{
    errors: [
        {
            otp?: string;
        }
    ]
}
export interface OTPResponse {
    data: OTPData;
    meta: {
        token: string;
    };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    errorMsg:{
        errorMsgPhone?:string;
        forgotNumberError?:boolean;
        emailMessage?: string,
        errorEmail?: boolean
    };
    countrySuccess:Array<CountrySuccess>;
    dropdownOpen:boolean;
    selectedCountry:CountrySuccess | null;
    isCountryData:string;
    forgotError:boolean;
    forgotMobileNumber:string,
    emailError:boolean;
    isEmail:string;
    isForgotType:string;
    token:string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    countryCodeAPICallId:string='';
    postForgotPasswordAPICallId:string='';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            dropdownOpen:false,
            errorMsg:{
                errorMsgPhone:"",
                forgotNumberError:false,
                emailMessage: "",
                errorEmail: false,
            },
            countrySuccess:[{
                numeric_code:"",
                country_full_name:"",
                country_code:"",
                country_flag:""
            }],
            selectedCountry:null,
            isCountryData:"+965",
            forgotError:false,
            forgotMobileNumber:"",
            emailError:false,
            isEmail:"",
            isForgotType: "",
            token: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getCountryCodeApi();
        // Customizable Area End
      }

    async receive(from: string, message: Message) {
        runEngine.debugLog(configJSON.Message_Recived, message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiSucessCall(apiRequestCallId, responseJson);
           } else if (responseJson && responseJson.errors) {
             this.apiFailureCall()
           }
          }
        // Customizable Area End
    }
    // Customizable Area Start

    apiSucessCall = async (apiRequestCallId: string, responseJson: CountrySuccess[]& OTPResponse) => {
        if (apiRequestCallId === this.countryCodeAPICallId) {
            this.setState({
                countrySuccess: responseJson,
                token:responseJson?.meta?.token
            });
        }
        if(apiRequestCallId === this.postForgotPasswordAPICallId){
            if(responseJson && responseJson?.data && responseJson.meta){
                this.setState({
                token:responseJson?.meta?.token
                },()=>{this.opeForgotOTPScreen()})
            }
        }
    };

    apiFailureCall = () => {
        alert(configJSON.notFound)
    };

    handleCountrySelect = (country: CountrySuccess, code: string) => {
        this.setState({
            selectedCountry: country,
            dropdownOpen: false,
            isCountryData: code
        });
    };

    toggleDropdown = () => {
        this.setState((prevState) => ({
          dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    apiCallForgotPass = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
        };
        const forgotRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        forgotRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        forgotRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        body &&
        forgotRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        
        forgotRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        runEngine.sendMessage(forgotRequestMessage.id, forgotRequestMessage);
        return forgotRequestMessage.messageId;
    };

    handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isEmail = event.target.value
        this.setState({
            isEmail: isEmail,
            forgotError:false,
            forgotMobileNumber:"",
            emailError:false,
            isForgotType: configJSON.firstInputAutoCompleteType,
            errorMsg: {
                emailMessage: "",
                errorEmail: false
            }
        })
    };

    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const forgotMobileNumber = event.target.value;
        const mobileNumberRegx=configJSON.neightDigRegex;
        if (mobileNumberRegx.test(forgotMobileNumber)&& forgotMobileNumber.length<=7) {
            this.setState({
                forgotMobileNumber: forgotMobileNumber,
                forgotError:false,
                emailError:false,
                errorMsg:{
                    forgotNumberError:true,
                    errorMsgPhone:""
                }
            });
          } else {
            this.setState({
              forgotMobileNumber: forgotMobileNumber,
              forgotError:false,
              emailError:false,
              isEmail:"",
             isForgotType: configJSON.phoneMsg,
              errorMsg:{
                forgotNumberError:false,
                errorMsgPhone:configJSON.alphnewmertic
              }
            });
          }
    };

    handleSubmit = (submitEvent: React.FormEvent) => {
        submitEvent.preventDefault();
        const { isEmail, forgotMobileNumber } = this.state;
        const emailRegex = configJSON.emailRegex;
        const numericRegex = /^\d+$/;
        if (!isEmail && !forgotMobileNumber) {
            this.setState({
                emailError: true,
                forgotError: true,
                errorMsg: {
                    emailMessage: configJSON.emailValidationMsg,
                    errorMsgPhone: configJSON.phoneValidationMsg,
                    errorEmail: true,
                    forgotNumberError: true
                }
            });
        } else if (isEmail && !emailRegex.test(isEmail)) {
            this.setState({
                emailError: true,
                errorMsg: {
                    emailMessage: configJSON.emailValidMsg,
                    errorEmail: true
                }
            });
        } else if (forgotMobileNumber && (forgotMobileNumber.trim() === "" || !numericRegex.test(forgotMobileNumber) || forgotMobileNumber.length <= 7)) {
            this.setState({
                forgotError: true,
                errorMsg: {
                    errorMsgPhone: forgotMobileNumber.trim() === "" ? configJSON.phoneValidationMsg : configJSON.alphnewmertic,
                    forgotNumberError: true
                }
            });
        } else {
            this.setState({
                emailError: false,
                forgotError: false,
                errorMsg: {
                    emailMessage: "",
                    errorMsgPhone: "",
                    errorEmail: false,
                    forgotNumberError: false
                }
            });
            this.postForgotPasswordApi();
        }
    };

    postForgotPasswordApi = async () => {
        const emailOrPhoneNumber = this.state.isEmail
            ? this.state.isEmail
            : `${this.state.isCountryData}${this.state.forgotMobileNumber}`
        const body = {
            data: {
                attributes: {
                    email_or_full_phone_number: emailOrPhoneNumber,
                },
            }
        }

        this.postForgotPasswordAPICallId = await this.apiCallForgotPass({
            contentType: configJSON.postForgotPasswordContentType,
            method: configJSON.postApimethod,
            endPoint: configJSON.postForgotPasswordEndPoint,
            body: body
        });
    }

    getCountryCodeApi = async () => {
        this.countryCodeAPICallId = await this.apiCallForgotPass({
            contentType: configJSON.countryApiContentType,
            method: configJSON.httpMethodType,
            endPoint: configJSON.countryCodeEndPoint
        });
    };

    opeForgotOTPScreen = () => {
        const emailOrPhone = this.state.isEmail
        ? this.state.isEmail
        : `${this.state.isCountryData}${this.state.forgotMobileNumber}`
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),"ResetOTPPassword"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {forgotType:this.state.isForgotType,isEmail:emailOrPhone,isToken:this.state.token});
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
      };
    // Customizable Area End
}
