import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start

export interface AccountDetails  {
  id: string;
  type: string,
  attributes: {
      bank_name: null | string,
      account_holder_name: string,
      bank_code: null,
      account_number: string,
      iban: string,
      is_default: boolean
  }
}
interface ErrorsMessage {
  accountNameError?: string;
  IBANNumberError?: string;
  accountNumberError?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  error: string;
  expanded: string | number | boolean;
  threeDotDialog: string;
  addAccount: boolean;
  deleteAccount: boolean;
  errorsMessage: ErrorsMessage;
  accountName: string,
  IbanNumber: string,
  accountNumber: string,
  bankDetails: AccountDetails[];
  userRole: string;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SellerBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchBankDeatilCallID: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      error: "",
      expanded: false,
      threeDotDialog: '',
      addAccount: false,
      deleteAccount: false,
      errorsMessage: {
        accountNameError: "",
        IBANNumberError: '',
        accountNumberError: '',
      },
      accountName: '',
      IbanNumber: '',
      accountNumber: '',
     bankDetails: [], 
      userRole: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      if (apiRequestCallId === this.fetchBankDeatilCallID) {
        if (responseJson?.errors) {
          this.setState({
            error: "An error occurred, please try again"
          });
        }
        this.setState({
          bankDetails: responseJson?.data,
        })
      }
    }else{
      this.setState({
        error: "An error occurred, please try again"
      });
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentDidMount = async () => {
    this.getBankDetailsCallApi();
    const userData = await getStorageData('userRole', true);
    this.setState({
      userRole: userData?.userType
    });
  }

  getBankDetailsCallApi = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.countryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchBankDeatilCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankDetailsGetApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
