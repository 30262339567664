import React from "react";
import {Box,Button,Typography,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { Vector, scroll } from "../src/assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LoyaltysystemController, {
  Props,
  configJSON,
} from "./LoyaltysystemController";

export default class Loyaltysystem extends LoyaltysystemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} />
        <Grid style={{ backgroundColor: "rgb(242, 239, 239)" }} container>
          <Grid item >
            <div
              style={webStyle.innerContainer11}>
              <Typography style={webStyle.innerContainer12}>
                Home
              </Typography>
              <img src={Vector} />
              <Typography style={webStyle.innerContainer23}>
                My Account
              </Typography>
            </div>
            <div >
              <Typography style={webStyle.innerContainer3}>
                <img src={scroll} />
                Damodar Nagina
              </Typography>
            </div>
            <Typography style={webStyle.innerContainer4}>
              Welcome to your Account
            </Typography>
            <Sidebar navigation={this.props.navigation}/>
          </Grid>
          <Grid item xs={12} md={6} style={{
            marginTop: '100px', paddingInline: 20,
            justifyContent: "flex-end"
          }}>
            <Box>
              <Typography style={webStyle.headerStyle}>
                Loyalty Points
              </Typography>
              <Box bgcolor="white" style={webStyle.boxStyle}>
                <Box style={webStyle.alignStart}>
                  <Typography style={webStyle.boxNumber}>
                    253 Points
                  </Typography>
                  <Typography style={{ fontWeight: 500, fontSize: "16px", color: "#375280", fontFamily: "Lato" }}>
                    Redeem your points effortlessly when you purchase
                  </Typography>
                  <Typography style={{ fontWeight: 500, fontSize: "16px", color: "#375280", fontFamily: "Lato" }}>
                   new products from us.
                  </Typography>
                </Box>
                <Box style={{paddingTop:'40px'}}>
                  <ShoppingBtn data-test-id="continueShoppingPage" onClick={this.openLandingPage}>
                    Continue Shopping
                  </ShoppingBtn>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Footer navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ShoppingBtn = styled(Button)({
  textTransform: "capitalize",
  backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
  gap: "8px",
  margin: "10px 0",
  width: "100% !important",
  borderRadius: "3px",
  height: "56px !important",
  "& .MuiButton-label": {
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato, sans-serif",
    color: "white",
    width:'307px'
  }
})
const webStyle = {
  addAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  boxStyle:{display:"flex",justifyContent:"space-around",alignItems:"center",height:"188px",borderRadius:"4px",margin:"30px 0px"},
   headerStyle:{fontWeight:800,fontSize:"20px",color:"#375280",fontFamily:"Lato"},
  dropdown: {
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 0px #00000014',
    backgroundColor: 'white',
    zIndex: 1,
    left: 0,
    width: '120px',
    top: '100%',
    position: 'absolute',
  },
  boxNumber:{fontWeight:800,fontSize:"36px",color:"#CCBEB1",fontFamily:"Lato" , marginBottom:'10px'},
  dropdownP: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingBottom: '10px',
    borderBottom: '3px solid #D5D5D5',
  },
  dropdownP1: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  alignStart:{display:"flex",flexDirection:"column" as 'column',justifyContent:"space-between",alignItems:"start"},
  innerContainer11: {
    gap: "10px",
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
    lineHeight: "22px",
    marginLeft: "40px",
    marginTop: "27px",
    color: "#475569",
  },
  innerContainer12: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
  },
  innerContainer23: {
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569"
  },
  innerContainer3: {
    alignItems: "center",
    display: "flex",
    marginTop: "31px",
    lineHeight: "33.5px",
    marginLeft: "40px",
    color: "#375280",
    fontWeight: 800,
    gap: "15px",
    fontStyle: "normal",
    fontSize: "28px",
    fontFamily: "Lato , sans-serif",
  },
  innerContainer4: {
    marginBottom: "37px",
    marginLeft: "40px",
    color: "#807D7E",
    fontWeight: 400,
    lineHeight: "33.5px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",

  },
};
// Customizable Area End
