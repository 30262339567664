export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backgroundImage = require("../assets/backgroundImage.png")
export const appleBlue = require("../assets/apple-Blue.png")
export const callBlue = require("../assets/call-Blue.png")
export const facebookBlue = require("../assets/facebook-Blue.png")
export const googleBlue = require("../assets/google-Blue.png")
export const msgBlue = require("../assets/msgBlue.png")
export const sideImage = require("../assets/sideImage.png")
export const flag = require("../assets/flag.png");
export const otpSideImage=require("../assets/otpVerification.png");
export const resetOTP = require("../assets/resetOTP.png");
export const backImg = require("../assets/backImage.svg");
export const view1 = require("../assets/view.svg");
export const locationn = require("../assets/locationn.svg")
export const document = require("../assets/document.svg");
export const documentBack = require("../assets/documentback.svg");;;
export const addressback = require("../assets/addressback.svg");;;
export const buttonDelete = require("../assets/button_delete.svg");
export const googlepay = require("../assets/googlepay.svg");
export const applepay = require("../assets/applepay.svg");
export const group_doc = require("../assets/group_doc.svg");
export const group_pdf = require("../assets/group_pdf.svg");
export const view_Icon = require("../assets/view_Icon.svg");
