import React from "react";

// Customizable Area Start
import { Grid, withStyles,CircularProgress,Button,Radio,Modal,Divider,Fade,FormControlLabel,WithStyles,Backdrop, Box, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { backBtnIcon, downloadIcon, filterIcon } from "../../customform/src/assets";
import TwoLineChart from "../../../components/src/Chart/LineChart";
import SellerProductOverviewController, {
  Props,
} from "./SellerProductOverviewController.web";
import clsx from "clsx";
import { button_close } from "./assets";
import { Noimageavailablepng } from "../../dashboard/src/assets";

export const productOverviewAnalyticsSaleCount = [
  { label: "Wishlisted", value: 0 },
  { label: "Impression", value: 0 },
  { label: "Total Orders", value: 0 },
  { label: "Visitors", value: 0 }
]

const analyticsLineChartData = [{
  label: "Impression",
  color: "#F59E0B",
},
{
  label: "Visitors",
  color: "#34D399",
}
]

const analyticsMonthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
// Customizable Area End

export default class SellerProductOverview extends SellerProductOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    if (this.state.isLoadingProductOverviewsales) {
      return (
        <Box style={{ ...webStyle.tabsBoxAnalytics }}>
        <CircularProgress style={webStyle.CircularProgressColor} />
      </Box>
      );
  }
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xl={2} md={3} lg={2} sm={3} xs={3} >
            <SellerSidebar
              navigation={this.props.navigation} />
          </Grid>
          <Grid item xl={10} md={9} lg={10} sm={9} xs={9} >
            <SellerHeader navigation={this.props.navigation} />
           <SellerStoreAnalyticsContainer>
              <SellerStoreHeader>
                <Box className="analytics-title-container">
                <img
                    src={backBtnIcon}
                    alt="backIcon"
                    onClick={this.proceedToAnalyticsInsightsPage}
                  />
                  <Typography className="title">
                    Products Overview
                  </Typography>
                </Box>
                <Box className="btn-container">
                  <img src={downloadIcon} alt="down-load-icon" onClick={this.handleOpenPdf} />
                  <img src={filterIcon} alt="filter-icon" />
                </Box>
              </SellerStoreHeader>
              <Box className="popular-product-container">
              <Box className="products-container">
                    <Box className="product-container">
                      <Box className="product-detail-container">
                        <img width={180} height={120} src={this.state.allStoreProductOverview.image_url ? this.state.allStoreProductOverview.image_url : Noimageavailablepng} alt="" />
                        <Box className="product-detail">
                          <Typography className="product-name">
                          Black Leather Boots
                          </Typography>
                        <Box className="product-content-container">
                          <Typography className="product-title">Price</Typography>
                          <Typography className="product-value">${this.state.allStoreProductOverview.product.unit_price}</Typography>
                        </Box>
                        <Box className="product-content-container">
                          <Typography className="product-title">Total Earnings</Typography>
                          <Typography className="product-value">${this.state.allStoreProductOverview.total_earnings}</Typography>
                        </Box>
                        </Box>
                      </Box>
                      <Box className="product-detail-btn-container">
                          <Box className="product-btn-sold">{this.state.allStoreProductOverview.sold_units} Sold Units</Box>
                          <Box className="product-btn-unit">{this.state.allStoreProductOverview.returned_units} Returned Units</Box>
                          </Box>
                    </Box>
                </Box>
                </Box>
              <ChartContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                    <SellerOverCountContainer>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography className="seller-count-title">Sales</Typography>
                        </Grid>
                        {this.state.productOverviewsalesCountValue.map((item: { label: string; value: number }) => (
                          <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                            <SalesOverCountContainer>
                              <Typography className="seller-count-show-div-label">{item.label}</Typography>
                              <Typography className="seller-count-show-div-value">{item.value}</Typography>
                            </SalesOverCountContainer>
                          </Grid>
                        ))}
                      </Grid>
                    </SellerOverCountContainer>
                  </Grid>
                  <Grid item xs={12} md={12} 
                      sm={12} lg={6} xl={6}> 
                    <Box  className="chart-outer-container" > 
                      <Typography className="chart-title">
                        Impression vs Visitors
                      </Typography> 
                      <Box className="line-chart-labels-container" > 
                         {analyticsLineChartData.map((item: { label: string; color: string }) => (
                          <Box className="line-chart-label" > 
                            <Box style={{ backgroundColor: item.color }} 
                                className="color-picker"  /> 
                            <Typography>
                              {item.label}
                            </Typography> 
                          </Box> 
                        ))} 
                      </Box> 
                      <Box className="line-chart-container" > 
                        <TwoLineChart  labels={analyticsMonthLabel} 
                          datasets={[ 
                             { 
                              data: [this.state.allStoreProductOverview.graph_data.impressions.January, this.state.allStoreProductOverview.graph_data.impressions.February, this.state.allStoreProductOverview.graph_data.impressions.March, this.state.allStoreProductOverview.graph_data.impressions.April, this.state.allStoreProductOverview.graph_data.impressions.May, this.state.allStoreProductOverview.graph_data.impressions.June, this.state.allStoreProductOverview.graph_data.impressions.July, this.state.allStoreProductOverview.graph_data.impressions.August, this.state.allStoreProductOverview.graph_data.impressions.September,this.state.allStoreProductOverview.graph_data.impressions.October, this.state.allStoreProductOverview.graph_data.impressions.November, this.state.allStoreProductOverview.graph_data.impressions.December],
                              borderColor: "#F59E0B", 
                              backgroundColor: "#F59E0B", 
                              borderWidth: 2, 
                              fill: false, 
                            }, 
                            { 
                              data: [this.state.allStoreProductOverview.graph_data.visitors_count.January, this.state.allStoreProductOverview.graph_data.visitors_count.February, this.state.allStoreProductOverview.graph_data.visitors_count.March, this.state.allStoreProductOverview.graph_data.visitors_count.April, this.state.allStoreProductOverview.graph_data.visitors_count.May, this.state.allStoreProductOverview.graph_data.visitors_count.June, this.state.allStoreProductOverview.graph_data.visitors_count.July, this.state.allStoreProductOverview.graph_data.visitors_count.August, this.state.allStoreProductOverview.graph_data.visitors_count.September,this.state.allStoreProductOverview.graph_data.visitors_count.October, this.state.allStoreProductOverview.graph_data.visitors_count.November, this.state.allStoreProductOverview.graph_data.visitors_count.December],
                              borderColor: "#34D399", 
                              backgroundColor: "#34D399", 
                              borderWidth: 2, 
                              fill: false, 
                            }, 
                          ]}  
                          xTickColor="#375280"  
                          yTickColor="#375280"  
                          highlightLastTick  
                        />  
                      </Box>  
                    </Box> 
                  </Grid> 
                </Grid> 
              </ChartContainer> 
            </SellerStoreAnalyticsContainer> 
          </Grid> 
        </Grid> 
         <StyledOverDrawerOrder aria-labelledby="transition-modal-title" 
          onClose={this.handleClosePdf} 
          BackdropProps={{ 
            timeout: 500, 
          }} 
          open={this.state.pdfOpen}  aria-describedby="transition-modal-description" 
          closeAfterTransition
          BackdropComponent={Backdrop} 
        > 
          <Fade in={this.state.pdfOpen} style={webStyle.febBox} > 
            <Box > 
              <Box style={webStyle.pdfCloseBtn}>
                <img src={button_close} onClick={this.handleClosePdf} />
              </Box> 
              <Box style={webStyle.exportReject as React.CSSProperties}>
                Export Report
              </Box> 
              <Box style={webStyle.exportData as React.CSSProperties}>
                Export Analytics report as:
              </Box> 
              <Box> 
                {this.state.downloadOptions.map((option, index) => ( 
                  <> 
                    <Box key={index} 
                      style={webStyle.pdfDwonBox}> 
                      <Box style={webStyle.contentContainer} > 
                        <img width="60px" height="60px" src={option.src}/> 
                        <Box style={webStyle.pdfData}> {option.label} </Box> 
                      </Box> 
                      <Box> 
                        <FormControlLabel 
                          control={<StyledRadioOverWithStyles /> } 
                          checked={this.state.accountPdf === option.type} 
                          onChange={this.handlePdfChange} 
                          label="" 
                          value={option.type} 
                          style={webStyle.radioName} 
                        /> 
                      </Box> 
                    </Box> 
                    { this.state.downloadOptions.length - 1 !== index && <Divider style={webStyle.divayder} /> } 
                  </> 
                ))} 
              </Box> 
              <Box style={webStyle.btnModelContainer} > 
                <StyledButtonOverModelNewOrder onClick={this.handleClosePdf}>
                  Close
                </StyledButtonOverModelNewOrder> 
                <StyledButton1OverModelNewOrder >
                  Export
                </StyledButton1OverModelNewOrder> 
              </Box > 
            </Box> 
          </Fade > 
        </StyledOverDrawerOrder> 
      </ThemeProvider> 
      // Customizable Area End
    ); 
  } 
} 

// Customizable Area Start
const theme = createTheme({ 
  palette: { 
    primary: { 
      contrastText: "#fff",
      main: "#0000ff", 
    } 
  } 
}); 

const webStyle = {
  febBox: { 
    borderRadius: '10px', 
    width: "500px", 
    backgroundColor: "white",
    padding: "10px 30px 30px 30px"
  },
  CircularProgressColor: {
    color: "#375280"
  },
  tabsBoxAnalytics: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  pdfCloseBtn: {
    width: '100%', 
    justifyContent: 'end',
    display: 'flex',
  },
  btnModelContainer: { 
    display: "flex", 
    justifyContent: "space-between", 
    marginTop: '30px',
    gap: "20px",
  },
  pdfData: { 
    color: "#375280",
    marginLeft: '20px', 
    fontSize: "22px", 
    fontWeight: 700, 
    fontFamily: 'Lato' 
  },
  exportData: { 
    fontSize: '18px', 
    color: '#375280',
    fontFamily: 'Lato', 
    fontWeight: 700, 
    textAlign: "center",
    marginBottom: '20px' 
  },
  contentContainer: { 
    alignItems: 'center' ,
    display: 'flex',
  },
  exportReject: { 
    fontSize: '30px', 
    fontFamily: 'Lato', 
    textAlign: "center",
    marginBottom: '20px',
    fontWeight: 500, 
    color: '#375280',
   },
  pdfDwonBox: { 
    display: 'flex', 
    justifyContent: 'space-between' ,
    alignItems: 'center', 
  },
  divayder: { 
    color: '#e2e8f0', 
    marginBottom: '24px',
    marginTop: '24px', 
  },
  radioName: { 
    color: "rgba(55, 82, 128, 1)", 
    fontFamily: "Lato",
    fontSize: "16px", 
    fontWeight: 700,  
  },
}

const SellerStoreAnalyticsContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .popular-product-container": {
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 40,
    maxHeight: 480,
    overflowY: "auto",
    "& .popular-product-title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      "& .popular-product-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 20,
        color: "#375280",
      },
    },
    "& .products-container": {
      "& .product-container": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "16px 0px",
        "& .product-detail-container": {
          display: "flex",
          gap: 20,
          "& .product-detail": {
            display: "flex",
            flexDirection: "column",
            gap: 15,
            fontFamily: "Lato, sans-serif",
            fontSize: 18,
            width: "350px",
            color: "#375280",
            "& .product-name": {
              fontSize: "24px",
              fontWeight: 500,
            },
            "& .product-order": {
              fontWeight: 700,
            },
            "& .product-content-container": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& .product-title": {
                fontSize: "18px",
                fontWeight: 500
              },
              "& .product-value": {
                fontSize: "18px",
                fontWeight: 700
              }
            }
          },
        },
        "& .product-detail-btn-container":{
          display: "flex",
          "& .product-btn-sold": {
            padding: "0px 25px",
            height: "40px",
            borderRadius: "2px 0px 0px 0px",
            backgroundColor: "#E2E8F0",
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            display: "flex",
            color: "#375280",
            alignItems: "center",
            justifyContent: "center",
          },
          "& .product-btn-unit": {
            padding: "0px 25px",
            height: "40px",
            borderRadius: "2px 0px 0px 0px",
            backgroundColor: "#FEE2E2",
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            color: "#DC2626",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "15px"
          },
        },
        "& .product-price": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 18,
          color: "#059669",
        },
      },
    },
  },
}); 
 
const styles = { 
  icon: {
    width: 20,
    borderRadius: '50%',
    height: 20,
    backgroundColor: '#f5f8fa',
    border: '1px solid #CCBEB1',
    'input:disabled ~ &': {
        background: 'rgba(206,217,224,.5)',
    },
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
},
checkedIcon: {
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  backgroundColor: '#cbbeb2',
  '&:before': {
      display: 'block',
      height: 20,
      width: 20,
      content: '""',
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
  },
},
  root: {
      '&:hover': {
          backgroundColor: 'transparent',
      },
  },
};

const StyledRadioOverWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
  <Radio 
  disableRipple
      className={props.classes.root}
      checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
      icon={<span className={props.classes.icon} />}
      color="default"
      {...props}
  />
));

const SellerStoreHeader = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  height: 44,
  width: "100%",
  "& .btn-container": {
    gap: 24,
    display: "flex",
    height: "100%",
  },
  "@media(max-width:600px)": {
    height: "max-content",
    "& .analytics-title-container": {
      "& .title": {
        fontSize: "18px !important",
        fontWeight: 600,
      },
    },
    "& .btn-container": {
      flexDirection: "column",
      height: "max-content",
      gap: 4,
    },
  },
  "& .analytics-title-container": {
    justifyContent: "flex-start",
    display: "flex",
    gap: 20,
    alignItems: "center",
    height: "100%",
    "& .title": {
      fontWeight: 700,
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 24,
    }, 
  }, 
}); 

const StyledButtonOverModelNewOrder = styled(Button)(() => ({
  fontWeight: 500,
  textTransform: "none",
  border: "1px solid #CCBEB1",
  borderRadius: "2px",
  color: "#375280",
  fontSize: "18px", height: '54px',
  '&:hover': { 
    backgroundColor: "white", 
}, 
  width: "252px !important",
}))
const StyledButton1OverModelNewOrder = styled(Button)(() => ({
  fontSize: "18px", height: '54px',
  backgroundColor: '#CCBEB1',
  fontWeight: 500,
  textTransform: "none",
  color: "#fff",
  '&:hover': {
    backgroundColor: "#CCBEB1"
},
  width: "252px",
}))

const SellerOverCountContainer = styled(Box)({
  border: "1px solid #E2E8F0",
  padding: 20,
  marginTop: 30,
  "& .MuiGrid-root.MuiGrid-container": {
    "& .MuiGrid-root.MuiGrid-item": {
      "& .seller-count-title": {
        fontWeight: 700,
        fontFamily: "Lato, sans-serif",
        color: "#375280",
        fontSize: 20,
      },
    },
  },
});

const SalesOverCountContainer = styled(Box)({
  height: "170px",
  width: "100%",
  flexDirection: "column",
  display: "flex",
  gap: 15,
  alignItems: "center",
  backgroundColor: "#F8F8F8",
  justifyContent: "center",
  "& .seller-count-show-div-label": {
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    color: "#334155",
    fontSize: 22,
  },
  "& .seller-count-show-div-value": {
    fontFamily: "Lato, sans-serif",
    fontSize: 36,
    color: "#375280",
    fontWeight: 700,
  },
});

const StyledOverDrawerOrder = styled(Modal)((theme) => ({
  backgroundColor: "none",
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

const ChartContainer = styled(Box)({ 
  width: "100%", 
  "& .MuiGrid-root.MuiGrid-container": { 
    height: "max-content",
    "& .MuiGrid-root.MuiGrid-item": {
      "& .chart-outer-container": {
        border: "1px solid #E2E8F0",
        marginTop: 30,
        padding: 20,
        "& .chart-title": {
          fontWeight: 700,
          fontFamily: "Lato, sans-serif",
          color: "#375280",
          fontSize: 20,
        },
        "& .line-chart-labels-container": {
          display: "flex",
          gap: 20,
          padding: "20px 0px",
          "& .line-chart-label": {
            display: "flex",
            gap: 12,
            justifyContent: "center",
            color: "#375280",
            fontSize: 16,
            "& .color-picker": {
              width: 20,
              height: 24,
              borderRadius: 2,
            },
          },
        },
        "& .revenue-chart-container": { 
          width: "100%", 
          marginTop: 30
        }, 
        "& .pie-chart-container": { 
          display: "flex", 
          alignItems: "center", 
          justifyContent: "center", 
          height: "100%", 
          "& .pie-chart": {
            width: "60%",
          },
          "& .pie-chart-label-container": {
            display: "flex",
            flexDirection: "column",
            gap: 30,
            width: "40%",
          },
          "& .pie-chart-label": {
            gap: 12,
            display: "flex",
            width: "100%",
            fontSize: 16,
            color: "#375280",
            "& .label-name": {
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: 2,
            },
            "& .color-picker": {
              borderRadius: 2,
              width: 20,
              height: 24,
            },
          },
          "@media(max-width:800px)": {
            gap: 20,
            flexDirection: "column",
            "& .pie-chart": {
              width: "100%",
            },
            "& .pie-chart-label-container": {
              alignItems: "center",
              width: 320,
            },
          },
        },
        "& .line-chart-container": {
          width: "100%",
        },
        "@media(max-width:600px)": {
          "& .line-chart-container": {
            overflowX: "auto",
            width: "100%",
          },
        },
      },
    },
  },
});
// Customizable Area End
