import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import {
  Grid,
  styled,
  Box,
  Typography,
  Button,
  Switch,
  CircularProgress
} from "@material-ui/core";
import { MyStoreList } from "./MySellerStoreController";
import { Add as AddIcon } from "@material-ui/icons";
import { store } from "./assets";
import ReusableSnackbar from "../../../components/src/AlertContainer";
// Customizable Area End

import SellerStoreController, {
  configJSON,
  Props,
} from "./SellerStoreController";


// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerStore extends SellerStoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xl={2} md={3} lg={2} sm={3} xs={3}>
            <SellerSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xl={10} md={9} lg={10} sm={9} xs={9}>
            <SellerHeader navigation={this.props.navigation} />
            <CreateStore>
              {this.state.isLoader ? <div className="loader-container"><CircularProgress id="seller-store-loader" style={{ color: '#375280' }} /></div> : <><Box className="store-header">
                <Typography className="store-title">
                  {this.state.myStoreResp.length !== 0 && "My"}{" "}
                  {configJSON.stores}
                </Typography>
                {this.state.myStoreResp.length !== 0 ? (
                  <Button
                    startIcon={<AddIcon style={{ width: 24, height: 24 }} />}
                    className="create-store-button"
                    data-test-id="create-store-btn"
                    onClick={() => this.handleCreateStore("CreateSellerStore")}
                  >
                    <span style={{ marginTop: 5 }}>Add New Store</span>
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
              <>{this.state.myStoreResp.length === 0 ? (
                <Box className="store-container">
                  <img className="store-icon" src={store} alt="store" />
                  <Typography className="store-context">
                    {configJSON.notStores}
                  </Typography>
                  <Button
                    data-test-id="create-store-btn"
                    className="create-store-btn"
                    onClick={() => this.handleCreateStore("CreateSellerStore")}
                    startIcon={<AddIcon style={{ width: 24, height: 24 }} />}
                  >
                    <span style={{ marginTop: 5 }}>Add New Store</span>
                  </Button>
                </Box>
              ) : (
                <Grid container className="main-grid-container" spacing={3}>
                  {this.state?.myStoreResp?.map((store: MyStoreList) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={3}
                      key={store.id}
                    >
                      <Box>
                        <img
                          className="img-container"
                          src={store.attributes.image}
                          alt={store.attributes.store_name}
                          data-test-id={`store-id-${store.id}`}
                          onClick={() => this.navigateToPage(store.id)}
                        />
                        <Typography className="store-name-title">
                          {store.attributes.store_name}
                        </Typography>
                        <Box className="open-close-store-container">
                          <Box
                            className={`${
                              store.attributes.is_open ? "Open" : "Close"
                            }-container`}
                          >
                            {store.attributes.is_open ? "Open" : "Close"}
                          </Box>
                          <AntSwitch
                            checked={store.attributes.is_open}
                            name="checkedC"
                            data-test-id={`ant-switch-${store.id}`}
                            onChange={()=>this.handleStoreStatus(store.id,store.attributes.is_open)}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}</>
              </>
            }
            </CreateStore>
          </Grid>
        </Grid>
        <ReusableSnackbar
          onClose={this.onCloseAlert}
          open={this.state.alert.isAlert}
          severity={this.state.alert.severity}
          message={this.state.alert.message}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const CreateStore = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .loader-container":{
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .store-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "& .create-store-button": {
      height: 44,
      borderRadius: "2px",
      backgroundColor: "#CCBEB1",
      color: "#fff",
      padding: "10px 16px",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 700,
      "& .MuiButton-label": {
        gap: 6,
        display: "flex",
        alignItems: "center",
      },
    },
    "& .store-title": {
      fontFamily: "Lato, sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      color: "#375280",
    },
  },
  "& .store-container": {
    minHeight: "calc(100vh - 139px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .store-icon": {
      width: "120px",
      height: "120px",
    },
    "& .store-context": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 500,
      color: "#375280",
      fontSize: "28px",
      marginTop: 50,
    },
    "& .create-store-btn": {
      width: 250,
      height: 56,
      borderRadius: "2px 0px 0px 0px",
      marginTop: 30,
      color: "#fff",
      textTransform: "none",
      backgroundColor: "#CCBEB1",
      fontSize: 20,
      "& .MuiButton-label": {
        display: "flex",
        gap: 6,
        alignItems: "center",
      },
    },
  },
  "& .main-grid-container": {
    paddingTop: 30,
    "& .img-container": {
      borderRadius: 2,
      objectFit: "cover",
      height: 180,
      alignItems: "center",
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    "& .store-name-title": {
      width: "100%",
      color: "#375280",
      fontWeight: 400,
      paddingTop: 15,
      overflow: "hidden",
      fontSize: 18,
      textOverflow: "ellipsis",
      fontFamily: "Lato, sans-serif",
      whiteSpace: "nowrap",
    },
    "& .open-close-store-container": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingTop: 8,
      "& .Open-container": {
        width: "max-content",
        radius: 2,
        padding: "3px 14px 3px 14px",
        backgroundColor: "#E2E8F0",
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 16,
        color: "#375280",
      },
      "& .Close-container": {
        radius: 2,
        padding: "3px 14px 3px 14px",
        backgroundColor: "#FEE2E2",
        fontFamily: "Lato, sans-serif",
        width: "max-content",
        fontWeight: 500,
        fontSize: 16,
        color: "#DC2626",
      },
    },
  },
});

export const AntSwitch = styled(Switch)({
  width: 48,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(25px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "#375280",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#375280",
      border: "none",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    color:"#FFFFFF",
    margin: 2
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#E2E8F0",
    opacity: 1,
    borderRadius: 40,
    height: 24,
    width: 48
  },
});
// Customizable Area End
