import { BlockComponent } from "../../framework/src/BlockComponent";
import { Box, Grid, Typography, styled } from "@material-ui/core";
import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { facewhite, instawhite, tiwiterwhite, linkdinwhite, fashwhitelogo } from "../../blocks/email-account-registration/src/assets";
import {setStorageData } from "../../framework/src/Utilities";
interface S {

}

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class Footer extends BlockComponent<Props, S, SS>{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    headerRedirect = (pageLink: string,tabIndex?:number) => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage),{tabIndex:tabIndex} );
        toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(toNavigate);
    };
    footerNavigation = async (pageLink: string, name?: string) => {
        await setStorageData("navigationGender", name)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);      
        this.send(toNavigate);
    };
    // Customizable Area End 
    render() {
        // Customizable Area Start
        return (
            <StyledWrapper>
                <Grid className="FooterMainGrid" container>
                    <Grid
                        item
                        lg={12}
                        xl={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="footerContent"
                    >
                        <Box className="footerContentBox">
                            <Grid container>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    className="contactUS"
                                >
                                    <img src={fashwhitelogo} alt="" className="logo" onClick={() => {
                                        this.headerRedirect("LandingPage")
                                    }} />
                                    <Box className="socialMedia">
                                        <img src={facewhite} alt="facewhite" className="appLogo" />
                                        <img src={tiwiterwhite} alt="tiwiterwhite" className="appLogo" />
                                        <img src={linkdinwhite} alt="linkdinwhite" className="appLogo" />
                                        <img src={instawhite} alt="instawhite" className="appLogo" />
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={6}
                                    className="support"
                                >
                                    <Box className="shop">
                                        <Typography
                                            className="supportHeading"
                                            style={{ fontWeight: 700 }}
                                        >
                                            SHOP
                                        </Typography>
                                        <Typography className="supportText" onClick={() => { this.footerNavigation("CategoriessubcategoriesWebPage", "Women") }}>Woman</Typography>
                                        <Typography className="supportText" onClick={() => {
                                            this.footerNavigation("CategoriessubcategoriesWebPage", "Men")
                                        }}>Man</Typography>
                                        <Typography className="supportText" onClick={() => { this.footerNavigation("CategoriessubcategoriesWebPage", "Kids") }}>
                                            Kids
                                        </Typography>
                                        <Typography className="supportText">Store</Typography>
                                        <Typography className="supportText">
                                            Stylist's
                                        </Typography>
                                    </Box>
                                    <Box className="help">
                                        <Typography
                                            className="supportHeading"
                                            style={{ fontWeight: 700 }}
                                        >
                                            HELP
                                        </Typography>
                                        <Typography className="supportText"
                                            onClick={() => this.headerRedirect("Contactus")}>
                                            Customer Service
                                        </Typography>
                                        <Typography className="supportText" onClick={() => this.headerRedirect("Faqs")}>FAQ</Typography>
                                        <Typography className="supportText"
                                             onClick={() => {this.headerRedirect("TermsConditions", 0)}}>
                                            Terms Of Use
                                        </Typography>
                                        <Typography className="supportText"
                                            onClick={() => {this.headerRedirect("TermsConditions", 1)}}>
                                            Privacy Policy
                                        </Typography>
                                        <Typography className="supportText"
                                         onClick={() => {this.headerRedirect("TermsConditions", 2)}}>
                                            Shipping Policy
                                        </Typography>
                                        <Typography className="supportText"
                                         onClick={() => {this.headerRedirect("TermsConditions", 3)}}>
                                            Return Policy
                                        </Typography>
                                        <Typography className="supportText"
                                        onClick={() => this.headerRedirect("AboutFash")}>About Fash</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        xl={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="copyrightSection"
                    >
                        <Box className="copyrightBox">
                            <Typography>Fash &copy; 2024</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </StyledWrapper>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({
    width: "100%",
    scrollbarWidth: "none",
    "& .FooterMainGrid": {
        backgroundColor: "#334155",
        padding: "10px",
        bottom: 0
    },
    "& .footerContent": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "30px",
        paddingBottom: "20px",
    },
    "& .footerContentBox": {
        padding: "10px",
        paddingLeft: "0px",
        width: "90%",
    },
    "& .copyrightSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .copyrightBox": {
        padding: "30px 10px 30px 0px",
        borderTop: "2px solid #B2A69B",
        width: "90%",
        color: "var(--Grey-50---White, #FFF)",
        fontFamily: "Poppins , sans-serif;",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "160%"
    },
    "& .logo": {
        margin: "10px !important",
        marginLeft: "0px !important",
        cursor: "pointer",
        width: "118.768px",
        height: "28.073px",
    },
    "& .appLogo": {
        margin: "10px 20px 10px 0px !important",
        cursor: "pointer",
    },
    "& .support": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-end",
    },
    "& .shop": {
        padding: "10px !important",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "130px",

    },
    "& .help": {
        padding: "10px !important",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "130px",

    },
    "& .supportHeading": {
        color: "#ffff !important",
        fontSize: "24px !important",
        fontStyle: "normal !important",
        marginBottom: "5px !important",
        lineHeight: "100% !important",
    },
    "& .supportText": {
        marginTop: "3px !important",
        cursor: "pointer",
        color: "#d2d1d1 !important",
        fontFamily: "Lato , sans-serif;",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "160% !important"
    },
});

// Customizable Area End
