import React from "react";
// Customizable Area Start
import {
    Box, TableContainer, Table, TableBody, TableRow, TableCell, Snackbar, TableHead, Typography, Button, Modal, Backdrop, Fade, CircularProgress
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { styled } from "@material-ui/core/styles";
import SellersInProcessOrderController, {
    Props,
} from "../SellersInProcessOrderDetails/SellersInProcessOrderController.web";
import { button_close, Noimageavailablepng } from "../assets";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

export default class SellersInProcessOrderWeb extends SellersInProcessOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handlePageChangeInProcess = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ page: newPage });
        this.allOrderDataInProcess()
    };
    renderContent = (startIndexInProcess: number, endIndexInProcess: number) => {
        if (this.state.isLoadingInProcess) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBoxInProcess, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }
        if (this.state.tableAllOrderInProcess === undefined && this.state.isAlert) {
            return (
                <Box style={{ ...webStyle.tabeLableInProcess, position: "absolute", }}>
                    There are no orders
                </Box>
            );
        }

        return (
            <>
                <CustomStyledTableNewOrder className="container">
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHeadInProcess>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                {this.state.tableHaderName.map((header: string, index: number) => (
                                    <TableCell key={index} style={webStyle.tableHaderInProcess}>
                                        <Typography variant="h6">{header}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHeadInProcess>
                        <TableBody>
                            {this.state.tableAllOrderInProcess?.map((orderData) => (
                                <TableRow key={orderData.id} className="hover" >
                                    <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrowInProcess, verticalAlign: 'top' }}>#{orderData.attributes.order_management_order.attributes.order_number}</TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowInProcess, verticalAlign: 'top' }}>{this.dateFormetTime(orderData.attributes.order_items[0]?.attributes.placed_at)}</TableCell>
                                    <TableCell>{orderData.attributes.order_items.map((value) => {
                                        return (
                                            <Box style={{ display: 'flex' }} data-testid={`navigateTo=${orderData.attributes.order_management_order.attributes.order_number}`} onClick={() => this.navigateTo({ productId: orderData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "SellersInProcessOrderDetails" })}>
                                                <Box><img src={value.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : value.attributes.catalogue_variant_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px", marginRight: "10px" }} /></Box>
                                                <Box style={{ ...webStyle.tableHaderrowInProcess }}>{value.attributes.catalogue_name} x {value.attributes.quantity}</Box>
                                            </Box>
                                        );
                                    })}</TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowInProcess, verticalAlign: 'top' }}>{orderData.attributes.order_items[0]?.attributes.store_name}</TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowInProcess, verticalAlign: 'top' }}>$ {orderData.attributes.order_items[0]?.attributes.total_price}</TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowInProcess, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center' }}>In Process</Box></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowInProcess, verticalAlign: 'top' }}>
                                        <Button style={{ ...webStyle.acceptOrderInProcess, textTransform: "capitalize" }} onClick={() => this.handleInProcess(orderData.attributes.id)} data-testid={`handleInProcess=${orderData.attributes.id}`}>Ready to Ship</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CustomStyledTableNewOrder>
                <Box style={webStyle.pagenesonBox}>
                    <Box style={{ fontSize: "14px", fontWeight: 500, color: "#475569", fontFamily: 'Lato' }}>
                        {startIndexInProcess} - {endIndexInProcess} of {this.state.metaData?.total_record} results
                    </Box>
                    <CustomStyledPaginationInProcess
                        color="primary"
                        count={this.state.metaData?.total_pages}
                        onChange={this.handlePageChangeInProcess}
                        shape="rounded"
                        page={this.state.page}
                    />
                </Box>
            </>
        );
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 10;
        const endIndexInProcess = Math.min(this.state.metaData?.current_page * ROWS_PER_PAGE, this.state.metaData?.total_record);
        const startIndexInProcess = (this.state.metaData?.current_page - 1) * ROWS_PER_PAGE + 1;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={{ marginTop: '20px' }} data-testid="allOrder">
                    {this.renderContent(startIndexInProcess, endIndexInProcess)}
                </Box>
                <StyledDrawerInProcess
                    aria-describedby="transition-modal-description"
                    closeAfterTransition
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleCancelInProcess}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    BackdropComponent={Backdrop}
                    open={this.state.visible}
                >
                    <Fade in={this.state.visible} style={webStyle.febBoxInProcess}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'end' }}><img onClick={this.handleCancelInProcess} src={button_close} /></Box>
                            <h2 style={{ ...webStyle.rejectNameNewOrderInProcess, textAlign: "center" }}>Change Status</h2>
                            <Box style={{ ...webStyle.modelTextInProcess, textAlign: 'center' }}>
                                <Box style={{ paddingBottom: '20px' }}>Are you sure you want to change the order status to ready for collection?</Box>
                                <Box>Delivery partner will be assigned soon for the order pickup.</Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelInProcess style={{ fontSize: "14px" }} data-testid="handleCancelInProcess" onClick={this.handleCancelInProcess}>Close</StyledButtonModelInProcess>
                                <StyledButton1ModelInProcess style={{ fontSize: "14px" }} data-testid="timeDataInProcess" onClick={this.timeDataInProcess}>Confirm</StyledButton1ModelInProcess>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerInProcess>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlertInProcess}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity="success">{this.state.already}</Alert></Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    pagenesonBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "23px 0px" },
    new_orderBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    modelTextInProcess: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 400, marginBottom: "30px" },
    tabeLableInProcess: {
        color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
        top: "50%",
        left: "50%",
        transform: "translate(20%, 20%)"
    },
    acceptOrderInProcess: { backgroundColor: "#CCBEB1", width: "220px", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    tableHaderrowInProcess: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    febBoxInProcess: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    tabsBoxInProcess: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    tableHaderInProcess: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    rejectNameNewOrderInProcess: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '0px 0px 20px 0px' },
}
const TableHeadInProcess = styled(TableHead)({
    '& .MuiTableCell-stickyHeader': {
        backgroundColor: 'white !important'
    }
})
const CustomStyledPaginationInProcess = styled(Pagination)({
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .Mui-selected': {
        color: 'white',
    },
})
const StyledButtonModelInProcess = styled(Button)(() => ({
    fontWeight: 500,
    textTransform: "none",
    border: "1px solid #CCBEB1",
    color: "#375280",
    borderRadius: "2px",
    width: "252px !important",
    height: "45px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1ModelInProcess = styled(Button)(() => ({
    backgroundColor: '#CCBEB1',
    textTransform: "none",
    height: "45px",
    fontWeight: 500,
    color: "#fff",
    width: "252px",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomStyledTableNewOrder = styled(TableContainer)({
    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    },
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px"
    },
})
const StyledDrawerInProcess = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
// Customizable Area End