import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    Typography,
    Divider, TableContainer, Table, TableBody, TableRow, TableCell, TableHead,
    Modal,
    Backdrop,
    Fade, 
    FormControl,
    Select, MenuItem, withStyles
} from "@material-ui/core";
// Customizable Area End

import DashboardController, {
    Props
} from "./DashboardController";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader"; 
import { dcubesvg, shoppingBag, timerPause, timerPause1, Noimageavailablepng, buttonClose, groupImage, addCircle } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';

export default class Dashboard extends DashboardController {
    // Customizable Area Start

    orderLabelAndStyle = (value: string) => {
        let styles = {}
        let label = ''
        switch (value) {
            case 'shipped':
                styles = webStyle.shippedBox
                label = 'Out for Delivery'
                break;
            case 'delivered':
                styles = webStyle.deliveredBox
                label = 'Delivered'
                break;
            case 'processed':
                styles = webStyle.processedBox
                label = 'Ready fro Collection'
                break;
            case 'in_process':
                styles = webStyle.in_processBox
                label = 'In Process'
                break;
            case 'return_request':
                styles = webStyle.return_requestBox
                label = 'Return Request'
                break;
            case 'rejected':
                styles = webStyle.rejectedBox
                label = 'Rejected'
                break;
            case 'new_order':
                styles = webStyle.new_orderBox
                label = 'New Order'
                break;

        }
        return (<Box style={styles}>{label}</Box>)
    }

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    

    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 10;
        const startIndex = (this.state.metaDataNewOrder?.current_page - 1) * ROWS_PER_PAGE + 1;
        const endIndex = Math.min(this.state.metaDataNewOrder?.current_page * ROWS_PER_PAGE, this.state.metaDataNewOrder?.total_record);
        return (
            // Required for all blocks
            <>
               <Grid container> 
                     <Grid  item  xl={2} md={2} lg= {2}  sm={2 } xs= {2} >
                         <SellerSidebar  navigation={this.props.navigation} /> 
                     </Grid> 
                       <Grid  item  xl= {10} md={10}  lg ={10} sm={10}  xs={10}  >
                        <SellerHeader  navigation={ this.props.navigation}  /> 
                        <div style={webStyle.containerStyle}>
                                        <div style={webStyle.boxStyle}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={dcubesvg} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>Total</Box>
                                                    <Box style={webStyle.numberBox}>{this.state.totalOrderData?.total_orders}</Box>
                                                </Box>
                                            </Box>
                                        </div>
                                        <div style={webStyle.boxStyle}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={timerPause1} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>Pending</Box>
                                                    <Box style={webStyle.numberBox}>{this.state.totalOrderData?.total_new_orders}</Box>
                                                </Box>
                                            </Box>
                                        </div>
                                        <div style={webStyle.boxStyle}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={shoppingBag} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>In Progress</Box>
                                                    <Box style={webStyle.numberBox}>{this.state.totalOrderData?.total_in_process_orders}</Box>
                                                </Box>
                                            </Box>
                                        </div>
                                        <div style={webStyle.boxStyle1}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={timerPause} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>Accepted</Box>
                                                    <Box style={webStyle.numberBox}>{this.state.totalOrderData?.total_in_process_orders}</Box>
                                                </Box>
                                            </Box>
                                        </div>
                                    </div>
                        <Box sx={webStyle.secondaryContainer}>
                            <Typography style={webStyle.newOrdersText}>New Orders</Typography>
                            <Box style={webStyle.secondBox} data-test-id="viewAllClick" onClick={()=> this.headerNavigation("SellersAllOrderDetailsWebPage")}>
                            <Typography style={webStyle.viewAllText}>View All</Typography>
                            <Divider style={webStyle.dividerLine}/>
                            </Box>
                        </Box>

                        <CustomStyledTable style={{padding: "25px"}}>
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order ID</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order Time</Typography></TableCell>
                                <TableCell style={{...webStyle.tableHader, width: "30%"}}><Typography variant="h6">Products</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Shop</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Total Price</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Status</Typography></TableCell>
                                <TableCell style={{...webStyle.tableHader, width: "241px"}}><Typography variant="h6">Action</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.newOrderData?.length > 0 && (this.state.newOrderData?.map((orderData: any, index:number)=> (
                                    <TableRow key={orderData.id} className="hover" >
                                        <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#{orderData.attributes.order_management_order.attributes.order_number}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{this.dateFormetTime(orderData.attributes.order_items[0]?.attributes.placed_at)}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{orderData.attributes.order_items?.map((value:any, index: number) => {
                                            return (
                                                <Box style={{ display: 'flex' }} key={index} data-testid={`navigateTo=${orderData.attributes.order_management_order.attributes.order_number}`}>
                                                    <Box><img src={value.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : value.attributes.catalogue_variant_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px", marginRight: "10px" }} /></Box>
                                                    <Box style={{ ...webStyle.tableHaderrow }}>{value.attributes.catalogue_name} x {value.attributes.quantity}</Box>
                                                </Box>
                                            );
                                        })}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{orderData.attributes.order_items[0]?.attributes.store_name}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>$ {orderData.attributes.order_items[0]?.attributes.total_price}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{this.orderLabelAndStyle(orderData.attributes.status)}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top', width: "100%" }}>
                                            <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize",width: "35%" }} data-testid={`handleOpen1Reject=${0}`}
                                             onClick={() => this.handleRejectOpen(orderData.attributes.id)}
                                             >Reject</Button>
                                            <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize",width: "60%" }} 
                                            onClick={() => this.handleAcceptOrder(orderData.attributes.id)}
                                             data-testid={`handleOkAcceptOrder-${index}`}>Accept Order (0:29)</Button>
                                        </TableCell>
                                    </TableRow>
                            )))}

                            {this.state.newOrderData?.length == 0 && !this.state.loading && (
                                 <Box style={{...webStyle.tabeLable, position: "absolute"}}>
                                 There are no orders
                             </Box>
                            )} 

                            {this.state.noDataErrorMsg !== "" && !this.state.loading && (
                                <Box style={{...webStyle.tabeLable, position: "absolute"}}>
                                {this.state.noDataErrorMsg}
                            </Box>
                            )}

                            

                            
                        </TableBody>
                    </Table>
                </CustomStyledTable>
                            {this.state.newOrderData?.length > 0 && !this.state.loading && this.state.newOrderData && (
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px 0px" , padding: "0px 25px 0px 25px"}}>
                                    <Box style={{ color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: 'Lato' }}>
                                        {startIndex} - {endIndex} of {this.state.metaDataNewOrder?.total_record} results
                                    </Box>
                                    <CustomStyledPagination
                                        data-test-id="paginationCustom"
                                        page={this.state.page}
                                        count={Math.ceil(this.state.metaDataNewOrder?.total_record/ROWS_PER_PAGE)}
                                        shape="rounded"
                                        color="primary"
                                        onChange={this.handlePageChange}
                                    />
                                </Box>
                            )}
                            {this.state.loading && (
                                <Box data-testid="loader" style={webStyle.tabsBox}>
                                    <CircularProgress style={{ color: '#375280' }} />
                                </Box>
                            )}
                     </Grid> 
                </Grid> 

                <StyledDrawerOrder
                    aria-describedby="transition-modal-description"
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleCloseDialog}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    data-test-id="rejectDialogBox"
                    open={this.state.rejectDialogOpen}
                    BackdropComponent={Backdrop}
                    closeAfterTransition
                >
                    <Fade in={this.state.rejectDialogOpen} style={webStyle.rejectBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={buttonClose} onClick={this.handleCloseDialog} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectNameNewOrder, textAlign: "center" }}>Reject Order</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMasegNewOrder, textAlign: "center" }}>Are you sure you want to reject the order?</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>Reason of Rejection</Box>
                                <CustomSelectNewOrder variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelectNewOrder
                                        value={this.state.rejectReason}
                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                        MenuProps={{
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            getContentAnchorEl: null,
                                        }}
                                        style={webStyle.reminderName}
                                        displayEmpty
                                        onChange={this.handleRejectReasonChange}
                                        data-testid='sub-categories-selection'
                                    >
                                        <MenuItem value="" disabled>
                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>Out of Stock</Box>
                                        </MenuItem>
                                        {this.state.rejectSelectOption.map((select: string, index: number) => (
                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>
                                        ))}
                                    </MuiSelectSelectNewOrder>
                                </CustomSelectNewOrder >
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelNewOrder style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleCloseDialog}>Close</StyledButtonModelNewOrder>
                                <StyledButton1ModelNewOrder style={{ fontSize: "14px" }} data-testid="rejectData" onClick={this.rejectDataApiCall}>Reject</StyledButton1ModelNewOrder>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerOrder>

                <StyledDrawerOrder
                    closeAfterTransition
                    aria-describedby="transition-modal-description"
                    onClose={this.handleAcceptDialogClose}
                    aria-labelledby="transition-modal-title"
                    data-test-id = "AcceptDialog"
                    open={this.state.acceptOrderOpen}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    BackdropComponent={Backdrop}
                >
                    <Fade in={this.state.acceptOrderOpen} style={webStyle.rejectBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'end' }}><img onClick={this.handleAcceptDialogClose} src={buttonClose} /></Box>
                            <h2 style={{ ...webStyle.rejectNameNewOrder, textAlign: "center" }}>Accept Order</h2>
                            <h2 style={{ textAlign: "center", ...webStyle.confomMasegNewOrder }}>Are you sure you want to accept the order?</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>Set order reupload time</Box>
                                <Box style={{ backgroundColor: "#F8F8F8", padding: "10px", borderRadius: "2px" }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Button onClick={this.decrementTime} disabled={this.state.reloadTime <= 1} data-testid="decrementTime"><img width="24px" height="24px" src={groupImage} /></Button>
                                        <Box style={webStyle.timeBox}>
                                            <Typography variant="body1">{this.state.reloadTime}</Typography>
                                            <Typography variant="body1">&nbsp;mins</Typography>
                                        </Box>
                                        <Button onClick={this.incrementTime} data-testid="incrementTime" disabled={this.state.reloadTime >= 120}><img width="24px" height="24px" src={addCircle} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelNewOrder style={{ fontSize: "14px" }} data-testid="handleCancel" onClick={this.handleAcceptDialogClose}>Close</StyledButtonModelNewOrder>
                                <StyledButton1ModelNewOrder style={{ fontSize: "14px" }} data-testid="timeData" onClick={this.timeData}>Confirm</StyledButton1ModelNewOrder>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerOrder>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

const CustomStyledPagination = styled(Pagination)({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
});

const CustomSelectNewOrder = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);

const MuiSelectSelectNewOrder = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
})

const StyledDrawerOrder = styled(Modal)({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
});

const StyledButton1ModelNewOrder = styled(Button)({
    fontWeight: 500,
    backgroundColor: '#CCBEB1',
    width: "252px",
    textTransform: "none",
    height: "45px",
    color: "#fff",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
});

const StyledButtonModelNewOrder = styled(Button)({
    textTransform: "none",
    fontWeight: 500,
    borderRadius: "2px",
    border: "1px solid #CCBEB1",
    height: "45px",
    width: "252px !important",
    color: "#375280",
    '&:hover': {
        backgroundColor: "white",
    },
});

const CustomStyledTable = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px",
    },
    
    "& .MuiTable-root":{
        width: "96%"
    },

    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 20px"
    }
});

const webStyle = {
    tabeLable: {
        color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', top: "50%",
        left: "50%",
        transform: "translate(-40%, 20%)"
    },
    timeBox: { display: 'flex', alignItems: 'center', color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400 },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
    rejectBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center' },
    confomMasegNewOrder: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    rejectNameNewOrder: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    secondaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px 10px 24px',
  },
  modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
  secondBox:{
    cursor: "pointer",
    marginRight: "10px"
},
newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    new_orderBox:{ backgroundColor: '#D1FAE5', color: '#059669', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' } as React.CSSProperties,
    newOrdersText:{
        fontSize: "24px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280"
    },
    rejectedBox:{ backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    viewAllText: {
        fontSize: "14px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280",
        textTransform: "uppercase"
    } as React.CSSProperties,
    return_requestBox:{ backgroundColor: '#e8f1ff', color: '#1a8fea', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    dividerLine:{backgroundColor: "#375280", width: "65px", height: "2px"},
    in_processBox:{backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px'},
    processedBox:{backgroundColor: '#fff3c9', color: '#d97601', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px'},
    deliveredBox: { backgroundColor: '#E2E8F0', color: '#375280', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    shippedBox: { backgroundColor: '#F0E5FF', color: '#6200EA', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px", width: "10%"
    },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    acceptOrder: { backgroundColor: "#CCBEB1", marginBottom: "4px",borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    rejectButton: { color: "#375280",marginBottom: "4px", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", marginRight: "8px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    containerStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '25px',
    },
    boxStyle: {
        width: 'calc(33.33% - 10px)',
        height: '92px',
        backgroundColor: '#F8F8F8',
        marginRight: '21px',
        display: 'flex', alignItems: 'center', padding: '16px 20px'
    },
    numberBox: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280" },
    imgBakColor: { backgroundColor: "#CCBEB1", padding: '20px', borderRadius: '2px', marginRight: '16px', width: "36px", height: "36px" },
    lableText: { fontSize: "16px", fontFamily: "Lato", fontWeight: 400, color: "#334155", marginBottom: '30px' },
    boxStyle1: {
        width: 'calc(33.33% - 10px)',
        height: '92px',
        backgroundColor: '#F8F8F8',
        display: 'flex', alignItems: 'center', padding: '16px 20px'
    }

}
// Customizable Area End
