import React from "react";
// Customizable Area Start
import {
    Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Typography, Button, Modal, Backdrop, Fade, withStyles, FormControl
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { styled } from "@material-ui/core/styles";
import SellersAllOrderController, {
    Props,
} from "./SellersAllOrderController.web";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { group_image, add_circle, button_close, Noimageavailablepng } from "./assets"
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

export default class SellersAllOrderWebPage extends SellersAllOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ page: newPage }, () => this.allOrderData());
    };
    renderContent = (startIndex: number, endIndex: number) => {
        if (this.state.isLoading) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }

        if (this.state.tableAllOrder === undefined && this.state.isAlert) {
            return (
                <Box style={{ ...webStyle.tabeLable, position: "absolute", }}>
                    There are no orders
                </Box>
            );
        }

        return (
            <>
                <CustomStyledTable className="container">
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHeadAllOrder>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order ID</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order Time</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Products</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Shop</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Total Price</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Status</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Action</Typography></TableCell>
                            </TableRow>
                        </TableHeadAllOrder>
                        <TableBody>
                            {this.state.tableAllOrder && this.state.tableAllOrder?.map((orderData,index) => {
                                const orderNotGetting = orderData && orderData.attributes;
                                return (
                                    <TableRow key={index} className="hover">
                                        <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#{orderNotGetting.order_management_order?.attributes.order_number}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{this.dateFormetTime(orderNotGetting.order_items[0]?.attributes.placed_at)}</TableCell>
                                        <TableCell>{orderNotGetting.order_items.map((value) => {
                                            return (
                                                <Box style={{ display: 'flex' }}>
                                                    <Box><img src={value.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : value.attributes.catalogue_variant_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px", marginRight: "10px" }} /></Box>
                                                    <Box style={{ ...webStyle.tableHaderrow }}>{value.attributes.catalogue_name} x {value.attributes.quantity}</Box>
                                                </Box>
                                            );
                                        })}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{orderNotGetting.order_items[0]?.attributes.store_name}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>$ {orderNotGetting.order_items[0]?.attributes.total_price}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{this.orderLabel(orderNotGetting.status)}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                            {orderNotGetting.status === "new_order" ? (
                                                <>
                                                    <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} data-testid={`handleOpen1Reject=${orderNotGetting.id}`} onClick={() => this.handleOpen1(orderData.attributes.id)}>Reject</Button>
                                                    <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} onClick={() => this.handleOk(orderNotGetting.id)} data-testid="handleOkAcceptOrder">Accept Order ({this.formatTime(this.state.timerAllOrders)})</Button>
                                                </>
                                            ) : null}
                                        </TableCell>
                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </CustomStyledTable>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "23px 0px" }}>
                    <Box style={{ color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: 'Lato' }}>
                        {startIndex} - {endIndex} of {this.state.metaData?.total_record} results
                    </Box>
                    <CustomStyledPagination
                        page={this.state.page}
                        count={this.state.metaData?.total_pages}
                        shape="rounded"
                        color="primary"
                        onChange={this.handlePageChange}
                    />
                </Box>
            </>
        );
    }
    orderLabel = (value: string) => {
        let styles = {}
        let label = ''
        switch (value) {
            case 'shipped':
                styles = webStyle.shippedBox
                label = 'Out for Delivery'
                break;
            case 'delivered':
                styles = webStyle.deliveredBox
                label = 'Delivered'
                break;
            case 'processed':
                styles = webStyle.processedBox
                label = 'Ready fro Collection'
                break;
            case 'in_process':
                styles = webStyle.in_processBox
                label = 'In Process'
                break;
            case 'return_request':
                styles = webStyle.return_requestBox
                label = 'Return Request'
                break;
            case 'rejected':
                styles = webStyle.rejectedBox
                label = 'Rejected'
                break;
            case 'new_order':
                styles = webStyle.new_orderBox
                label = 'New Order'
                break;

        }
        return (<Box style={styles}>{label}</Box>)
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 10;
        const startIndex = (this.state.metaData?.current_page - 1) * ROWS_PER_PAGE + 1;
        const endIndex = Math.min(this.state.metaData?.current_page * ROWS_PER_PAGE, this.state.metaData?.total_record);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box data-testid="allOrder" style={{ marginTop: '20px' }}>
                    {this.renderContent(startIndex, endIndex)}
                </Box>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.rejectOpen}
                    onClose={this.handleClose1}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.rejectOpen} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClose1} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>Reject Order</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>Are you sure you want to reject the order?</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>Reason of Rejection</Box>
                                <CustomSelect variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelect
                                        style={webStyle.reminderName}
                                        value={this.state.numberData}
                                        onChange={this.handleSingleChangeNumber}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                        data-testid='sub-categories-selection'
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>Out of Stock</Box>
                                        </MenuItem>
                                        {this.state.short.map((select: string, index: number) => (
                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>
                                        ))}
                                    </MuiSelectSelect>
                                </CustomSelect >
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>Close</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid="rejectData" onClick={this.rejectData}>Reject</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.visible}
                    onClose={this.handleCancel}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.visible} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleCancel} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>Accept Order</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>Are you sure you want to accept the order?</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>Set order reupload time</Box>
                                <Box style={{ backgroundColor: "#F8F8F8", padding: "10px", borderRadius: "2px" }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Button data-testid="incrementTime" onClick={this.decrementTime} disabled={this.state.timerAllOrders <= 1}><img width="24px" height="24px" src={group_image} /></Button>
                                        <Box style={webStyle.timeBox}>
                                            <Typography variant="body1">{this.state.timerAllOrders}</Typography>
                                            <Typography variant="body1">&nbsp;mins</Typography>
                                        </Box>
                                        <Button data-testid="decrementTime" onClick={this.incrementTime} disabled={this.state.timerAllOrders >= 120}><img width="24px" height="24px" src={add_circle} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleCancel" onClick={this.handleCancel}>Close</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid="timeData" onClick={this.timeData}>Confirm</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    new_orderBox: { backgroundColor: '#D1FAE5', color: '#059669', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    return_requestBox: { backgroundColor: '#e8f1ff', color: '#1a8fea', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    in_processBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    processedBox: { backgroundColor: '#fff3c9', color: '#d97601', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    deliveredBox: { backgroundColor: '#E2E8F0', color: '#375280', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    shippedBox: { backgroundColor: '#F0E5FF', color: '#6200EA', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    timeBox: { display: 'flex', alignItems: 'center', color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400 },
    acceptOrder: { backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    rejectButton: { color: "#375280", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", marginRight: "8px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
    tabeLable: {
        color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', top: "50%",
        left: "50%",
        transform: "translate(-40%, 20%)"
    },
    confomMaseg: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    rejectName: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700,
    },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
}
const CustomStyledPagination = styled(Pagination)({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
})
const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "252px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "252px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomStyledTable = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px"
    },

    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    }
})
const StyledDrawer = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const TableHeadAllOrder = styled(TableHead)({
    '& .MuiTableCell-stickyHeader': {
        backgroundColor: 'white !important'
    }
})
const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
// Customizable Area End