// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { brandImage } from "./assets";
// Customizable Area End


export const configJSON = require("./config");

interface AnalyticsData {
  analyticsType: string,
  type: string
}
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  analyticsData: AnalyticsData[],
  activePage: string,
  storeActivePage: string,
  companynameData: {companyIcon: string,
    companyName: string
  }[],
  productChecked: string,
  growthSelectStore: boolean;
  growthSelectProduct: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      analyticsData: [{
        analyticsType: "Sales Revenue Report Store",
        type: "store"
      },{
        analyticsType: "Sales Revenue Report Product",
        type: "product"

      },{
        analyticsType: "Sales Volume & Growth Report",
        type: "growth"

      },{
        analyticsType: "Sales Volume Report Product",
        type: "growthProduct"

      }],
      activePage: "",
      storeActivePage: "salesStore",
      companynameData: [{
        companyIcon: brandImage,
        companyName: "Fashion Fusion Hub"
      },{
        companyIcon: brandImage,
        companyName: "Trendy"
      },{
        companyIcon: brandImage,
        companyName: "H&M"
      },{
        companyIcon: brandImage,
        companyName: "Zara"
      },{
        companyIcon: brandImage,
        companyName: "The Souled Store"
      }],
      productChecked:"",
      growthSelectStore: false,
      growthSelectProduct: false
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }


  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  handleSelectStoreClose = () => {
    this.setState({
      growthSelectStore: false
    })
  }

  handleGrowthDialogOpen = () => {
    this.setState({growthSelectStore: true})
  }

  handleStoreDialogOpen = () => {
    this.setState({growthSelectProduct: true})
  }

  handlegrowthSelectProduct = () => {
    this.setState({
      growthSelectProduct: false
    })
  }

  handlePageChange = (active: string) => {
    if(active === "product" || active === "growthProduct"){
      this.setState({storeActivePage: "salesProduct", activePage: active})
    }else{
      this.setState({
        activePage: active,
        storeActivePage: "salesStore"
      })
    }
  };

  onChangeHandler = (value: string) => {
    console.log("valued", value)
      this.setState({ productChecked: value });
  };
  
  handleStoreTab = (path: string) => {
    this.setState({
      storeActivePage: path
    });
  }
  // Customizable Area End
}
// Customizable Area Start

export const saleCount = [
    {label:"Total Sales",value:"$ 24,00"},
    {label:"Impression",value:"300"},
    {label:"Total Orders",value:"350"},
    {label:"Visitors",value:"3000"}
]

export const pieChartData = [{
    label: "Units Sold",
    value: 50,
    color: "#375280"
},
{
    label: "Returned Units",
    value: 20,
    color: "#F59E0B", 
},
]

export const lineChartData = [{
    label: "Current Period",
    color: "#059669", 
},
{
    label: "Last Period",
    color: "#F87171", 
}
]

export const monthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
// Customizable Area End
