import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import {
  ErrorMessage,
  ErrorResp,
  IImgResp,
  IMyOfferDataResp,
} from "./StylishMyOfferController";
import { logoutSellerNavigation } from "../../../../components/src/Seller/logOut";
import { ISuccessBidResp } from "./StylishProductSourcingController";
import { getStorageData } from "framework/src/Utilities";
import { apiCall } from "../../../../components/src/APICall";
import { ChangeEvent } from "react";

interface IRecordNotFound {
  errors: string[];
}

interface ErrorDetail{
  product_description?: string;
  quote_price?: string;
  images?: string;
};
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isDeletePopup: boolean;
  isSuccessDeletePopup: boolean;
  bidRequestResp: IMyOfferDataResp;
  selectedFiles: File[];
  updatedFiles: File[];
  isAlert: boolean;
  message: string;
  severity: "error" | "success" | "info";
  isLoading: boolean;
  isPlaceOfferPopup: boolean;
  errorState: {
    isProductDescError: boolean;
    productDescErrorMessage: string;
    isQuotePriceError: boolean;
    quotePriceErrorMessage: string;
    isAttachmentError: boolean;
    attachmentErrorMessage: string;
  };
  productDescription: string;
  quotePrice: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistProductSourcingRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductSourcingRequestAPICallId: string = "";
  updateProductSourcingBidAPICallId: string = "";
  deleteProductSourcingBidAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isDeletePopup: false,
      isSuccessDeletePopup: false,
      bidRequestResp: {} as IMyOfferDataResp,
      selectedFiles: [],
      isAlert: false,
      updatedFiles: [],
      message: "",
      severity: "error",
      isLoading: true,
      isPlaceOfferPopup: false,
      errorState: {
        isProductDescError: false,
        productDescErrorMessage: "",
        isQuotePriceError: false,
        quotePriceErrorMessage: "",
        isAttachmentError: false,
        attachmentErrorMessage: "",
      },
      productDescription: "",
      quotePrice: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonPSRData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJsonPSRData &&
        !responseJsonPSRData.errors &&
        !responseJsonPSRData.error
      ) {
        this.apiStylistProductSourcingRequestSuccess(
          apiRequestCallId,
          responseJsonPSRData
        );
      } else if (
        responseJsonPSRData &&
        (responseJsonPSRData.errors || responseJsonPSRData.error)
      ) {
        this.apiStylistProductSourcingRequestfailer(responseJsonPSRData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  apiStylistProductSourcingRequestSuccess = async (
    apiRequestCallId: string,
    responseJson: ISuccessBidResp
  ) => {
    if (apiRequestCallId === this.getProductSourcingRequestAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          bidRequestResp: responseJson.data,
          isLoading: !prevState.isLoading,
        }));
        if (responseJson.data.attributes.request_status === "waiting")
          await this.handleSelectedFile(
            responseJson.data.attributes.images,
            responseJson.data.attributes.product_description,
            responseJson.data.attributes.quote_price
          );
      }
    } else if (apiRequestCallId === this.updateProductSourcingBidAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          bidRequestResp: responseJson.data,
          isLoading: false,
          isAlert: true,
          isPlaceOfferPopup: false,
          message: "Your Bid Request has been updated Successfully.",
          severity: "success",
        }));
        if (responseJson.data.attributes.request_status === "waiting")
          await this.handleSelectedFile(
            responseJson.data.attributes.images,
            responseJson.data.attributes.product_description,
            responseJson.data.attributes.quote_price
          );
      }
    } else if(apiRequestCallId === this.deleteProductSourcingBidAPICallId){
      this.setState(
        (prevState) => ({
          ...prevState,
          isSuccessDeletePopup: !prevState.isSuccessDeletePopup,
          isDeletePopup: !prevState.isDeletePopup,
        }),
        () => {
          setTimeout(() => {
            this.handleStylishProductSourcingRequestRedirect("StylishMyOffer");
          }, 2000);
        }
      );
    }
  };

  handleSelectedFile = async (
    images: IImgResp[],
    description: string,
    quotePrice: number
  ) => {
    const backendFiles = await Promise.all(
      images.map(async (image) => {
        const blob = await fetch(image.url).then((res) => res.blob());  
        
        const urlParts = new URL(image.url).pathname.split(".");
        const fileExtension = urlParts.pop() || "jpg";

        let mimeType = "image/jpg";
        if (fileExtension === "png") {
          mimeType = "image/png";
        } else if (fileExtension === "jpeg") {
          mimeType = "image/jpeg";
        }

        const fileName = `backend-image-${image.id}.${fileExtension}`;

        return new File([blob], fileName, {
          type: mimeType,
        });
      })
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedFiles: backendFiles,
      updatedFiles: backendFiles,
      productDescription: description,
      quotePrice: `$${quotePrice}`,
    }));
  };

  apiStylistProductSourcingRequestfailer = async (
    responseJsonPSRError: ErrorMessage & ErrorResp & IRecordNotFound
  ) => {
    if (responseJsonPSRError.error === "you're not an authorized person!") {
      await this.handleMainPage(responseJsonPSRError.error, "Home");
    } else if (responseJsonPSRError.errors[0].token) {
      await this.handleMainPage(responseJsonPSRError.errors[0].token, "Home");
    }  else if (
      Array.isArray(responseJsonPSRError.errors) &&
      responseJsonPSRError.errors.every((error) => typeof error === "string")
    ) {
      await this.handleMainPage(
        responseJsonPSRError.errors[0] as string,
        "StylishMyOffer"
      );
    } else {
      this.setDetailedErrors(responseJsonPSRError.errors as ErrorDetail[]);
    }
  };

  setDetailedErrors = (errors: ErrorDetail[]) => {
    let errorEditQuotePrice = "",
      errorEditProductDesp = "",
      errorImages = "";
  
    let isEditQPError = false,
      isEditPDError = false,
      isEditImgError = false;
  
    if (errors.some((err) => err.product_description)) {
      isEditPDError = true;
      errorEditProductDesp = "* Please enter your product description";
    }
  
    if (errors.some((err) => err.quote_price)) {
      isEditQPError = true;
      errorEditQuotePrice = "* Please enter a quote price";
    }
  
    if (errors.some((err) => err.images)) {
      errorImages = "* Please select at least one attachment";
      isEditImgError = true;
    }
  
    this.setState((prevState) => ({
      ...prevState,
      errorState: {
        ...prevState.errorState,
        isProductDescError: isEditPDError,
        isAttachmentError: isEditImgError,
        isQuotePriceError: isEditQPError,
        productDescErrorMessage: errorEditProductDesp,
        quotePriceErrorMessage: errorEditQuotePrice,
        attachmentErrorMessage: errorImages,
      },
    }));
  };

  handleMainPage = async (message: string, redirection: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        message: message,
        severity: "error",
        isLoading: false,
      }),
      () => {
        setTimeout(() => {
          this.handleStylishProductSourcingRequestRedirect(redirection);
          if (redirection === "Home") logoutSellerNavigation();
        }, 2000);
      }
    );
  };

  handleStylishProductSourcingRequestRedirect = (redirection: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      redirection
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  async componentDidMount() {
    // Customizable Area Start
    this.getProductSourcingRequestAPICalled();
    // Customizable Area End
  }

  getProductSourcingRequestAPICalled = async () => {
    const bidRequestId = await this.getDataStorage("bid-request-id");
    if (+bidRequestId === 0)
      await this.handleMainPage(
        "Please Select Bid Request to See Data",
        "StylishMyOffer"
      );
    this.getProductSourcingRequestAPICallId = await apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getMyOfferAPIEndpoint}/${+bidRequestId}`,
      token: await this.getDataStorage("auth-token"),
    });
  };

  getDataStorage = async (value: string) => {
    return await getStorageData(value);
  };

  handleDeleteOpen = () => {
    this.setState((prevState) => ({
      ...prevState,
      isDeletePopup: !prevState.isDeletePopup,
    }));
  };

  handleDeleteClick = async () => {
    const bidDeleteRequestId = await this.getDataStorage("bid-request-id");
    if (+bidDeleteRequestId === 0)
      await this.handleMainPage(
        "Please Select Bid Request to See Data",
        "StylishMyOffer"
      );
    this.deleteProductSourcingBidAPICallId = await apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeDelete,
      endPoint: `${configJSON.getMyOfferAPIEndpoint}/${+bidDeleteRequestId}`,
      token: await this.getDataStorage("auth-token"),
    });
  };

  onAlertPSRSnackClose = () => {
    this.setState((prevState) => ({ ...prevState, isAlert: false }));
  };

  handleEditPlaceOfferPopupClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      updatedFiles: prevState.selectedFiles,
      isPlaceOfferPopup: !prevState.isPlaceOfferPopup,
      errorState: {
        ...prevState.errorState,
        isQuotePriceError: false,
        isProductDescError: false,
        productDescErrorMessage: "",
        isAttachmentError: false,
        quotePriceErrorMessage: "",
        attachmentErrorMessage: "",
      },
      productDescription:
        prevState.bidRequestResp.attributes.product_description,
      quotePrice: `$${prevState.bidRequestResp.attributes.quote_price}`,
    }));
  };

  handleEditBidOpen = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPlaceOfferPopup: true,
    }));
  };

  handleEditPriceCheckNumber = (editValue: string) => {
    let numericEditValue = editValue.replace(/[^0-9.]/g, "");

    if (numericEditValue.includes(".")) {
      let [integerEditPart, decimalEditPart] = numericEditValue.split(".");
      if (integerEditPart.length > 6) {
        integerEditPart = integerEditPart.substring(0, 6);
      }
      decimalEditPart = decimalEditPart.substring(0, 2);
      numericEditValue = `${integerEditPart}.${decimalEditPart}`;
    } else {
      if (numericEditValue.length > 6) {
        numericEditValue = numericEditValue.substring(0, 6);
      }
    }

    if (
      numericEditValue === "" ||
      numericEditValue === "." ||
      numericEditValue === "0" ||
      numericEditValue === "0."
    ) {
      return "$";
    }

    return `$${numericEditValue}`;
  };

  handleEditProductDesc = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      errorState: {
        ...prevState.errorState,
        productDescErrorMessage: "",
        isProductDescError: false,
      },
      productDescription: value,
    }));
  };

  handleEditQuotePrice = (value: string) => {
    const price = this.handleEditPriceCheckNumber(value);
    this.setState((prevState) => ({
      ...prevState,
      errorState: {
        ...prevState.errorState,
        quotePriceErrorMessage: "",
        isQuotePriceError: false,
      },
      quotePrice: price,
    }));
  };

  handleEditUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    const editFiles = event.target.files;
    if (editFiles) {
      const newEditFiles = Array.from(editFiles);
      const invalidEditFiles: File[] = [];

      newEditFiles.forEach((editFile) => {
        const isValidType = /\.(jpg|jpeg|png)$/i.test(editFile.name);
        const isValidSize = editFile.size <= 10 * 1024 * 1024;

        if (!isValidSize || !isValidType) {
          invalidEditFiles.push(editFile);
        }
      });

      if (invalidEditFiles.length > 0) {
        let errorMessage = "";

        invalidEditFiles.forEach((file) => {
          const isValidSize = file.size <= 10 * 1024 * 1024;
          const isValidType = /\.(jpg|jpeg|png)$/i.test(file.name);
          if (!isValidSize) {
            errorMessage += `${file.name} exceeds the 10MB size limit. `;
          } else if (!isValidType) {
            errorMessage += `${file.name} has an invalid file type. `;
          }
        });

        this.setState((prevState) => ({
          ...prevState,
          errorState: {
            ...prevState.errorState,
            attachmentErrorMessage: errorMessage,
            isAttachmentError: true,
          },
        }));
        return;
      }

      if (this.state.updatedFiles.length + newEditFiles.length > 5) {
        this.setState((prevState) => ({
          ...prevState,
          errorState: {
            ...prevState.errorState,
            attachmentErrorMessage: "You can only upload up to 5 files",
            isAttachmentError: true,
          },
        }));
        return;
      }
      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          attachmentErrorMessage: "",
          isAttachmentError: false,
        },
        updatedFiles: [...prevState.updatedFiles, ...newEditFiles],
      }));
    }
  };

  handleEditRemoveFile = (indexRemove: number) => {
    const updatedFiles = this.state.updatedFiles.filter(
      (_, index) => index !== indexRemove
    );
    this.setState({ updatedFiles });
  };

  validateEditSubmit = async () => {
    const {
      productDescription,
      quotePrice,
      bidRequestResp,
      updatedFiles,
    } = this.state;
    const updatedQuotePrice = +quotePrice.replace("$", "");

    if (
      productDescription === "" ||
      quotePrice === "$" ||
      quotePrice === "" ||
      updatedFiles.length === 0 ||
      updatedQuotePrice >
        +bidRequestResp.attributes.product_sourcing_request.max_price ||
      updatedQuotePrice <
        +bidRequestResp.attributes.product_sourcing_request.min_price
    ) {
      if (productDescription === "") {
        this.validateEditDescription();
      }
      if (
        quotePrice === "$" ||
        quotePrice === "" ||
        +bidRequestResp.attributes.product_sourcing_request.max_price <
          updatedQuotePrice ||
        updatedQuotePrice <
          +bidRequestResp.attributes.product_sourcing_request.min_price
      ) {
        this.validEditQuotePrice();
      }
      if (updatedFiles.length === 0) {
        this.validEditFiles();
      }
      return true;
    } else {
      return false;
    }
  };

  validateEditDescription = () => {
    this.setState((prevState) => ({
      ...prevState,
      errorState: {
        ...prevState.errorState,
        productDescErrorMessage: "* Please enter your product description",
        isProductDescError: true,
      },
    }));
  };

  validEditQuotePrice = () => {
    const { bidRequestResp, quotePrice } = this.state;
    const updatedQuotePrice = +quotePrice.replace("$", "");
    if (
      +bidRequestResp.attributes.product_sourcing_request.max_price <
      updatedQuotePrice
    ) {
      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          quotePriceErrorMessage:
            "* Quote price must be less than maximum price",
          isQuotePriceError: true,
        },
      }));
    } else if (
      updatedQuotePrice !== 0 &&
      updatedQuotePrice <
        +bidRequestResp.attributes.product_sourcing_request.min_price
    ) {
      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          quotePriceErrorMessage:
            "* Quote price must be greater than minimum price",
          isQuotePriceError: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errorState: {
          ...prevState.errorState,
          quotePriceErrorMessage: "* Please enter a quote price",
          isQuotePriceError: true,
        },
      }));
    }
  };

  validEditFiles = () => {
    this.setState((prevState) => ({
      ...prevState,
      errorState: {
        ...prevState.errorState,
        attachmentErrorMessage: "* Please select at least one attachment",
        isAttachmentError: true,
      },
    }));
  };

  handleEditPlaceOfferSubmit = async () => {
    const isEditValid = await this.validateEditSubmit();
    if (isEditValid) {
      return;
    }
    this.updateProductSourcingBidAPICalled();
  };

  updateProductSourcingBidAPICalled = async () => {
    const {
      bidRequestResp,
      quotePrice,
      productDescription,
      updatedFiles,
    } = this.state;
    const formData = new FormData();
    formData.append("product_description", productDescription.trim());
    formData.append("quote_price", quotePrice.replace(/[^0-9.]/g, ""));
    if (updatedFiles.length > 0 && updatedFiles.length <= 5) {
      updatedFiles.forEach((file) => {
        formData.append("images[]", file);
      });
    }
    this.updateProductSourcingBidAPICallId = await apiCall({
      method: configJSON.apiMethodTypePut,
      endPoint: `${
        configJSON.addProductSourcingBidAPIEndpoint
      }/${+bidRequestResp.id}`,
      token: await this.getDataStorage("auth-token"),
      body: formData,
      isJsonStringify: false,
    });
  };

  handleCustomerChat = () => {
    this.setState((prevState) => ({
      ...prevState,
      severity: "info",
      message: "This screen is under development",
      isAlert: true,
    }));
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
