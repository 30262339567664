import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  Plugin,
  ChartData,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface BarChartProps {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
  }[];
  barThickness?: number;
  xYTick?: string;
  xTickColor: string;
  yTickColor: string;
  viewingMonth: string;
}

const UpdatedBarChart: React.FC<BarChartProps> = ({
  labels,
  datasets,
  barThickness,
  xYTick,
  xTickColor,
  yTickColor,
  viewingMonth,
}) => {
  const monthIndexToHighlight = labels.indexOf(viewingMonth);

  const data: ChartData<"bar"> = React.useMemo(() => ({
    labels,
    datasets: datasets.map((dataset) => ({
      ...dataset,
      barThickness: barThickness || "flex",
      backgroundColor: dataset.data.map((_, index) =>
        index === monthIndexToHighlight ? "#375280" : "#94A3B8"
      ),
    })),
  }), [labels, datasets, barThickness, monthIndexToHighlight]);

  const customPlugin: Plugin<"bar"> = React.useMemo(() => ({
    id: "customUSDLabel",
    afterDraw: (chart) => {
        const {tooltip} = chart
        if (tooltip && tooltip.chart && tooltip?.getActiveElements().length == 0) {
            const tooltipIndex = monthIndexToHighlight;
            const datasetMeta = chart.getDatasetMeta(0);
    
            if (tooltipIndex >= 0 && tooltipIndex < datasetMeta.data.length) {
              const chartElement = datasetMeta.data[tooltipIndex];
    
              if (chartElement) {
                const eventPosition = {
                  x: chartElement.x - 20,
                  y: chartElement.y + 20,
                };
                const isTooltipActive =
                Array.isArray(tooltip.active) && 
                tooltip.active.length > 0 &&
                tooltip.active[0].datasetIndex === 0 && 
                tooltip.active[0].index === tooltipIndex;
    
              if (!isTooltipActive) {
                tooltip.setActiveElements([{ datasetIndex: 0, index: tooltipIndex }], eventPosition);
                chart.update(); 
              }
              }
            }
        }
    }
  }), [monthIndexToHighlight, xYTick]);

  const options: ChartOptions<"bar"> = React.useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Bar Chart",
      },
      tooltip: {
        enabled: true,
        position: "nearest",
        backgroundColor: "#fff",
        titleColor: "#375280",
        titleFont: { size: 14, weight: "bold" },
        bodyColor: "#375280",
        bodyFont: { size: 13, weight: "normal" },
        borderColor: "#375280",
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += `$${context.raw}`;
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: false },
        ticks: {
          color: xTickColor,
          font: { size: 13, weight: "bold" },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: window.innerWidth < 768,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: yTickColor,
          font: { size: 13, weight: "bold" },
          precision: 0,
          stepSize: 10000,
          callback: (value) => (Number(value) >= 1000 ? `$${Number(value) / 1000}k` : `$${value}`),
        },
      },
    },
  }), [xTickColor, yTickColor]);

  return (
    <div style={{ overflowX: "auto", minWidth: "320px", minHeight: "270px" }}>
      <Bar data={data} options={options} plugins={[customPlugin]} />
    </div>
  );
};

export default UpdatedBarChart;
