import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
interface INavigateDetailsPageTo {
  id: string | undefined;
  screenName: string;
  raiseMessage?: Message;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  trendgingData: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      catalogue_variants: {}[],
      primary_price: string;
      primary_image: string;
      is_wishlist: boolean;
    };
  }[]
  error: string;
  token: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  page: number;
  circularProgress: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
   fetchCarouselListApi: string = "";
   fetchTrendingPageApi: string = "";
   postFavouriteApiCalledId: string = '';
   deleteFavouriteApiCalledId: string = '';
   fetchTrendingPageApiPag: string = '';
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      showLoader: false,
      trendgingData:[],
      error: "",
      token: '',
      isAlert: false,
      alertType: 'success',
      alertMsg: '',
      page: 1,
      circularProgress: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);   
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
    if (apiRequestCallId === this.fetchTrendingPageApi) {
      if (responseJson.errors) {
        this.setState({
          error: "An error occurred, please try again",
          isAlert: true,
          alertType: "error",
          alertMsg: responseJson.errors.length > 0 ? responseJson.errors[0].token : responseJson.errors ,
          circularProgress: false
        });
      }else{
        this.setState({
          trendgingData: responseJson?.data,
          page: this.state.page +1,
          circularProgress: false
        })
      }
    }
     else{
         this.setState({
           error: "An error occurred, please try again"
         });
        }

        this.handleApiCallResponse(apiRequestCallId,message);

   
       
      }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => { 
    const token = await getStorageData('auth-token');
    this.setState({
      token: token,
      circularProgress: true
    });
    this.getTrendingPageListDeatils();
    const dataBox = document.getElementById('scrollableDiv') as HTMLElement;
    dataBox.addEventListener('scroll', this.handleScroll);
  };

  handleApiCallResponse = (apiRequestCallId: string,message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch(apiRequestCallId){
      case this.deleteFavouriteApiCalledId: 
      if(responseJson.error){
        this.setState({
          isAlert: true,
          alertMsg: responseJson.error,
          alertType: "error"
        })
      }else{
        this.setState({
          isAlert: true,
          alertMsg: responseJson?.message,
          alertType: "success"
        });
      }
      this.getTrendingPageListDeatils();
      break;
      case this.fetchTrendingPageApiPag: 
      if (responseJson.errors) {
            this.setState({
              error: "An error occurred, please try again",
              circularProgress: false,
            });
          };
          if(responseJson.meta.total_pages >= this.state.page ){
            const arrTrendList = [...this.state.trendgingData, ...responseJson?.data];
           this.setState(prev => {
             return {
              trendgingData: [...arrTrendList],
              page: prev.page + 1,
              circularProgress: false
          }
        });
        }else{
          this.setState({
            trendgingData: responseJson?.data,
            circularProgress: false
          })
        }break;
        case this.postFavouriteApiCalledId:
          if(responseJson?.error){
                this.setState({
                  isAlert: true,
                  alertType: "error",
                  alertMsg: responseJson.error
                });
              }else{
                this.setState({
                  isAlert: true,
                  alertType: "success",
                  alertMsg: responseJson.meta.message
                });
              }
              this.getTrendingPageListDeatils();
              break;
          default: 
          this.setState({
            error: "An error occurred, please try again"
          });
    }
  }

  handleScroll = async () => {
    const dataBox = await document.getElementById('scrollableDiv') as HTMLElement;
    if(dataBox.scrollHeight - dataBox.scrollTop === dataBox.clientHeight){
        this.loadTodos();
    };
};

loadTodos = async () => {
  this.setState({
    circularProgress: true
  });
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.fetchTrendingPageApiPag = requestMessage.messageId;
  const apiRoute = this.state.token ? `${configJSON.getLandingPageTrendAllToken}?page=${this.state.page}&per_page=12&sort=trending` : configJSON.getLandingPageTrendingListPageApi;

  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token
  };
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiRoute
  );   
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getTrendingPageListDeatils = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchTrendingPageApi = requestMessage.messageId;
    
    const apiRoute = this.state.token ? `${configJSON.getLandingPageTrendAllToken}?page=1&per_page=12&sort=trending` : configJSON.getLandingPageTrendingListPageApi;

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  navigateToDeatils = ({id,screenName,raiseMessage}: INavigateDetailsPageTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && 
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
};

handleDeleteWishlist = async (favouriteId?: string) => {

  if (!this.state.token) {
    this.setState({
      isAlert: true,
      alertType: "warning",
      alertMsg: "Please Login First"
    });
    return false;
  }
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteFavouriteApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleAddWishlistApiCall = async (favouriteId?: string) => {
  if (!this.state.token) {
    this.setState({
      isAlert: true,
      alertType: "warning",
      alertMsg: "Please Login First"
    });
    return false;
  }

  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const httpBody = {
    data: {
      favouriteable_id: favouriteId
    }
  };

  this.postFavouriteApiCalledId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.postFavouriteApi
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

handleFavouriteApi = (favouriteId: string, fav: boolean) => {
  if (fav) {
    this.handleDeleteWishlist(favouriteId);
  } else {
    this.handleAddWishlistApiCall(favouriteId);
  }
}

 
  // Customizable Area End
}
