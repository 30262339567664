import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { doc, jpg, pdf } from "./assets";
import { productOverviewAnalyticsSaleCount } from "./SellerProductOverview.web";

interface GraphData {
  impressions: {
    June: number,
    July: number,
    August: number,
    September: number,
    October: number,
    November: number,
      January: number,
      February: number,
      March: number,
      April: number,
      May: number,
      December: number
  };
  visitors_count: {
      August: number,
      September: number,
      October: number,
      November: number,
      January: number,
      February: number,
      March: number,
      April: number,
      May: number,
      June: number,
      July: number,
      December: number
  };
}
interface Product {
  manage_placed_status: boolean;
    refunded_at: string | null;
    seller_id: number;
    manage_cancelled_status: boolean;
    reason_of_rejection: string;
    seller_order_id: number;
    rejected_at: string;
    accept_order_upload_time: string | null;
    bussiness_information_id: number;
    item_return_date: string | null;
    process_at: string | null;
    item_return_time: string | null;
    shipped_at: string | null;
    return_cancel_at: string | null;
    return_at: string | null;
    return_placed_at: string | null;
    return_confirmed_at: string | null;
    return_pick_at: string | null;
    return_reject_at: string | null;
    reason_of_return: string | null;
    returned_assign_at: string | null;
    reason_refunded_cancel: string | null;
    refunded_cancel_at: string | null;
    id: number;
    quantity: number;
    order_management_order_id: number;
    total_price: string;
    unit_price: string;
    old_unit_price: string | null;
    status: string;
    updated_at: string;
    created_at: string;
    catalogue_variant_id: number;
    catalogue_id: number;
    placed_at: string;
    order_status_id: number;
    confirmed_at: string | null;
    delivered_at: string | null;
    cancelled_at: string | null;
    in_transit_at: string | null;
  }
    
    interface Data {
      graph_data: GraphData;
      product: Product;
      sold_units: number,
      returned_units: number,
      filter_range: string;
      total_sales: number;
      wishlisted: number;
      impressions: number;
      total_orders: number;
      visitors_count: number;
      total_earnings: string,
      image_url: string,
      name: string
    }
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    accountPdf: string;
    pdfOpen: boolean;
    isLoadingProductOverviewsales: boolean;
    downloadOptions: { type: string, src: string, label: string }[];
    productOverviewsalesCountValue: { label: string, value: number }[]
    allStoreProductOverview: Data;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SellerProductOverviewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProductOverviewApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        this.state = {
            accountPdf: "pdf",
            pdfOpen: false,
            downloadOptions: [
              { type: 'pdf', src: pdf, label: 'PDF' },
              { type: 'doc', src: doc, label: 'Doc File' },
              { type: 'jpg', src: jpg, label: 'JPG' },
          ],
        productOverviewsalesCountValue: productOverviewAnalyticsSaleCount,
          isLoadingProductOverviewsales: true,
          allStoreProductOverview: {
            "filter_range": "this month",
            "total_sales": 92700,
            "wishlisted": 0,
            "impressions": 86,
            "total_orders": 4,
            "graph_data": {
              "impressions": {
                "February": 0,
                "January": 0,
                "March": 0,
                "May": 0,
                "April": 0,
                "August": 0,
                "June": 0,
                "July": 82,
                "October": 0,
                "September": 0,
                "December": 0,
                "November": 0,
              },
              "visitors_count": {
                "February": 0,
                "January": 0,
                "June": 0,
                "August": 0,
                "July": 9,
                "September": 0,
                "November": 0,
                "December": 0,
                "October": 0,
                "April": 0,
                "March": 0,
                "May": 0,
              }
            },
            "visitors_count": 9,
            "product": {
              "id": 1259,
              "quantity": 1,
              "order_management_order_id": 923,
              "total_price": "100.0",
              "unit_price": "100.0",
              "old_unit_price": null,
              "status": "rejected",
              "created_at": "2024-07-13T04:18:48.552Z",
              "updated_at": "2024-07-13T04:20:38.227Z",
              "catalogue_variant_id": 470,
              "catalogue_id": 302,
              "order_status_id": 2,
              "confirmed_at": null,
              "placed_at": "2024-07-13T04:20:21.373Z",
              "delivered_at": null,
              "in_transit_at": null,
              "refunded_at": null,
              "cancelled_at": null,
              "manage_placed_status": false,
              "manage_cancelled_status": false,
              "seller_order_id": 661,
              "seller_id": 501,
              "reason_of_rejection": "Order Processing Error",
              "bussiness_information_id": 246,
              "rejected_at": "2024-07-13T04:20:38.217Z",
              "accept_order_upload_time": null,
              "item_return_time": null,
              "item_return_date": null,
              "process_at": null,
              "shipped_at": null,
              "return_at": null,
              "return_cancel_at": null,
              "return_placed_at": null,
              "return_pick_at": null,
              "return_confirmed_at": null,
              "return_reject_at": null,
              "reason_of_return": null,
              "refunded_cancel_at": null,
              "returned_assign_at": null,
              "reason_refunded_cancel": null
            },
            "sold_units": 0,
            "total_earnings": "100.0",
            "returned_units": 0,
            "image_url": "active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdVFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a24a3d5f493e7f383d926f6b5e1beb33c4173838/20181202_170304.png",
            "name": "Brand 246"
          },
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getSellerProductOverviewApi();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if(apiRequestCallId === this.getProductOverviewApiCallId){
            this.setState({ allStoreProductOverview: responseJson.data, isLoadingProductOverviewsales: false, productOverviewsalesCountValue: [
              { label: "Wishlisted", value: responseJson.data.wishlisted },
              { label: "Impression", value: responseJson.data.impressions },
              { label: "Total Orders", value: responseJson.data.total_orders },
              { label: "Visitors", value: responseJson.data.visitors_count }
            ]})
          }
        // Customizable Area End
    }

    // Customizable Area Start
    proceedToAnalyticsInsightsPage = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "AnalyticsInsights");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
      handleOpenPdf = () => {
        this.setState({ pdfOpen: true });
    };
    handleClosePdf = () => {
        this.setState({ pdfOpen: false });
    }

    getSellerProductOverviewApi = async () => {  
      const token = await getStorageData("auth-token");
      const selectProductId = await getStorageData("productOverviewId");
      const selectStoreId = await getStorageData("bussinessInformationId");
      
      const headers = {
        "token": token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getProductOverviewApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `accounts/bussiness/${selectStoreId}/product_analytical_report/${selectProductId}?filter_range=manually&start_date=03/07/2021&end_date=27/07/2026`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    
    handlePdfChange = (event: React.ChangeEvent<{}>) => {
        const value = (event.target as HTMLInputElement).value;
        this.setState({ accountPdf: value });
    }  
    // Customizable Area End
}