import React from "react";
// Customizable Area Start
import {
    Box, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Button, Modal, Typography, Backdrop, Fade, Grid, Snackbar
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import SellersInProcessOrderDetailsController, {
    Props,
} from "./SellersInProcessOrderDetailsController.web";
import { button_close, Noimageavailablepng, button_arrow } from "../assets"
import SellerSideBar from "../../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../../components/src/Seller/SellerHeader";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

export default class SellersInProcessOrderDetailsWebPage extends SellersInProcessOrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    orderDetailsInProcess = () => {
        const orderDatalsInProcess = this.state.tableAllOrder.find(
            (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
                order.attributes.order_management_order.attributes.order_number === this.state.orderId
        );
        if (!orderDatalsInProcess) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }

        const { order_items, order_management_order } = orderDatalsInProcess.attributes;
        const { delivery_addresses } = order_management_order.attributes;

        return (
            <>
                <Box style={{ margin: '30px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Box style={{ display: "flex", alignItems: 'center' }}>
                                <img src={button_arrow} style={{ cursor: "pointer" }} data-testid="contioneShopping" onClick={this.contioneShoppingInProcessDetails} /> <Box style={webStyle.newOrderIdInProcess} data-testid={`order_number`}>#{order_management_order.attributes.order_number}</Box> <Box style={{ ...webStyle.new_orderBox, textAlign: 'center' }}>In Process</Box>
                            </Box>
                            <Box style={{ color: "#9A9A9A", fontSize: "16px", fontFamily: "Lato", fontWeight: 500, marginTop: "6px" }}>
                                Total Products : {order_items.length}
                            </Box>
                        </Box>
                        <Box>
                            <Button style={{ ...webStyle.acceptOrderInProcessDetails, textTransform: "capitalize" }} data-testid={`timeOrderData=${orderDatalsInProcess.attributes.id}`} onClick={() => this.handleOpenInProcessDetails(orderDatalsInProcess.attributes.id)}>Ready To Ship</Button>
                        </Box>
                    </Box>
                    <Box>
                        <Box style={{ border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "20px" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {this.state.tableLable.map((header: string, index: number) => (
                                            <TableCell key={index} style={webStyle.tableHader}>
                                                <Typography variant="h6">{header}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order_items?.map((order, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>
                                                <Box style={{ display: "flex" }}>
                                                    <Box >
                                                        <img src={order.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : order.attributes.catalogue_variant_front_image} style={{ width: '44px', height: '44px', borderRadius: '2px', marginRight: "10px" }} />
                                                    </Box>
                                                    <Box style={{ ...webStyle.tableHaderrowInProcessDetails }}>{order.attributes.catalogue_name}</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>{order.attributes.brand_name}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_size}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box style={{ backgroundColor: order.attributes.catalogue_variant_color, width: '14px', height: '14px', borderRadius: '10px', marginRight: "8px" }} />{order.attributes.catalogue_variant_color}
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_sku}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>${order.attributes.total_price}</TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box style={webStyle.tableBox}>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameInProcessDetails}>Store Name</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{order_items[0].attributes.store_name}</Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameInProcessDetails}>Customer Name</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{order_management_order.attributes.account}</Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameInProcessDetails}>Shipping Address</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{this.getAddressInProcessDetails(delivery_addresses)}</Box>
                                </Grid>
                            </Grid>
                            <Box style={{ marginTop: '30px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Box style={webStyle.orderDetailsNameInProcessDetails}>Coupon Applied</Box>
                                        <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>Fash10(-$5)</Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box style={webStyle.orderDetailsNameInProcessDetails}>Payment Method</Box>
                                        <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{order_management_order.attributes.payment_detail.payment_type}</Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={{ display: 'flex' }}>
                    <SellerSideBar navigation={this.props.navigation} />
                    <Grid container>
                        <Grid item lg={12} md={12}>
                            <SellerHeader navigation={this.props.navigation} />
                            {this.orderDetailsInProcess()}
                        </Grid>
                    </Grid>
                </Box>
                <StyledDrawerInProcess
                    closeAfterTransition
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    BackdropProps={{
                        timeout: 500,
                    }}
                    onClose={this.handleCancelInProcessDetails}
                    open={this.state.visible}
                    BackdropComponent={Backdrop}
                >
                    <Fade in={this.state.visible} style={webStyle.febBoxInProcessInProcessDetails}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'end' }}><img onClick={this.handleCancelInProcessDetails} src={button_close} /></Box>
                            <h2 style={{ ...webStyle.changeOrderInProcessDetails, textAlign: "center" }}>Change Status</h2>
                            <Box style={{ ...webStyle.modelTextInProcessDetails, textAlign: 'center' }}>
                                <Box style={{ paddingBottom: '20px' }}>Are you sure you want to change the order status to ready for collection?</Box>
                                <Box>Delivery partner will be assigned soon for the order pickup.</Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelInProcessDetails style={{ fontSize: "14px" }} data-testid="handleCancelInProcess" onClick={this.handleCancelInProcessDetails}>Close</StyledButtonModelInProcessDetails>
                                <StyledButtonModelInProcessInProcessDetails style={{ fontSize: "14px" }} data-testid="timeDataInProcess" onClick={this.timeDataInProcessDetails}>Confirm</StyledButtonModelInProcessInProcessDetails>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerInProcess>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlert}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity="success">{this.state.already}</Alert></Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    modelTextInProcessDetails: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 400, marginBottom: "30px" },
    febBoxInProcessInProcessDetails: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    acceptOrderInProcessDetails: { backgroundColor: "#CCBEB1", width: "160px", height: "44px", borderRadius: "2px", color: "#FFFFFF", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700 },
    orderDetailsNameInProcessDetails: { color: "#375280", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px" },
    orderDetailsValueInProcessDetails: { color: "#94A3B8", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px", marginTop: "10px", textTransform: "capitalize" },
    newOrderIdInProcess: { color: "#375280", fontFamily: 'Lato', fontWeight: 700, fontSize: "24px", margin: '0px 16px' },
    tableBox: { border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "30px", padding: '20px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    new_orderBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    tableHaderrowInProcessDetails: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    changeOrderInProcessDetails: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '0px 0px 20px 0px' },
}

const StyledButtonModelInProcessDetails = styled(Button)(() => ({
    textTransform: "none",
    height: "45px",
    fontWeight: 500,
    color: "#375280",
    borderRadius: "2px",
    border: "1px solid #CCBEB1",
    width: "252px !important",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButtonModelInProcessInProcessDetails = styled(Button)(() => ({
    backgroundColor: '#CCBEB1',
    height: "45px",
    textTransform: "none",
    color: "#fff",
    width: "252px",
    fontWeight: 500,
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))

const StyledDrawerInProcess = styled(Modal)((theme) => ({
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: "none",
    alignItems: 'center',
}));
// Customizable Area End