import React from "react";
// Customizable Area Start
import AdminRequestController, { Props } from "./AdminRequestController";
import { Grid, styled, Box, Typography } from "@material-ui/core";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class AdminRequest extends AdminRequestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <ThemeProvider theme={theme}>

                <Grid container>
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2} >
                        {
                        this.state.userRole !== "stylist" ? (
                            <SellerSidebar navigation={this.props.navigation} />
                        ): (
                            <StylishSidebar navigation={this.props.navigation} />
                        )
                    }
                    </Grid>
                    <Grid item xl={10} md={10} lg={10} sm={10} xs={10}  >
                    {
                        this.state.userRole !== "stylist" ? (
                            <SellerHeader navigation={this.props.navigation} />
                        ): (
                            <StylishHeader navigation={this.props.navigation} />
                        )
                    }
                        <Snackbar 
                            open={this.state.isAlert} 
                             anchorOrigin={{ horizontal:  'right',
                              vertical: 'top' }}
                             onClose={this.oncloseAlert}
                            data-test-id="alertTestId" 
                     >
                       <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                     </Snackbar>
                        <AdminRequestContainer>
                            <Typography style={{ fontFamily: "Lato", fontSize: "24px", fontWeight: 700, color: "#375280" }}>Admin Request</Typography>
                            {
                                this.state.adminRequestData?.length > 0 && (
                            <Box style={{ width: "100%", maxHeight: "100%", overflow: "auto", border: "1px solid #E2E8F0", marginTop: "20px" }}>
                                { this.state.adminRequestData?.map((element, index) => (
                                    <>
                                        <Box style={{ height: "40px", padding: "12px", display: "flex", alignItems: "center" }}>
                                            <Box style={{ width: "40px", height: "40px" }}>
                                                <img src={element.attributes.image} alt="image" style={{ height: "100%", width: "100%", borderRadius: "50%" }} />
                                            </Box>
                                            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%", marginLeft: "15px" }}>
                                                <Typography style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 500, color: "#375280" }}>{element.attributes.store_name}</Typography>
                                                <Box style={element.attributes.status === "Pending" ? webStyles.pendingbutton : webStyles.approvedbutton}>
                                                    <Typography >{element.attributes.status}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {
                                            this.state.adminRequestData.length - 1 !== index && (
                                                <hr style={{ margin: "0px 12px", border: "1px solid #E2E8F0" }} />
                                            )
                                        }
                                    </>
                                ))}
                            </Box>
                                )
                            }
                        </AdminRequestContainer>
                    </Grid>
                </Grid>

            </ThemeProvider>
        );
    }
    // Customizable Area End



}

// Customizable Area Start
const AdminRequestContainer = styled(Box)({
    minHeight: "calc(100vh - 85px)",
    padding: "30px 60px",
    "& .mainTitle": {
        width: "40%",
        display: "flex", gap: "12px",
        textTransform: "capitalize"
    }
});
const webStyles = {
    approvedbutton: {
        backgroundColor: "#D1FAE5",
        color: "#059669",
        width: "93px", padding: "6px 16px", display: "flex", justifyContent: "center"
    } as React.CSSProperties,
    pendingbutton: {
        backgroundColor: "#FEF3C7",
        color: "#D97706",
        width: "82px", padding: "6px 16px", display: "flex", justifyContent: "center"
    } as React.CSSProperties
}
// Customizable Area End
