import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ErrorMessage {
  errors: {
    full_phone_number?: string;
    email?: string;
    full_name?: string;
    first_name?: string;
    last_name?: string;
    password?: string;
    password_confirmation?: string;
  };
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}
type AlertType = "success" | "error" | "warning" | "info";

export interface SignUpSuccess {
  data: {
    id: string;
    type: string;
    attributes: {
      activated: boolean;
      country_code: string;
      email: string;
      full_phone_number: string;
      full_name: string;
      phone_number: string;
      unique_auth_id: string;
      id_proof: {
        name: string;
        record: {
          id: number;
          full_phone_number: string;
          country_code: number;
          phone_number: number;
          email: string;
          notification: Object;
          role:string;
        };
      };
     language:number;
      currency:number;
    };
  };
  meta: {
    sms_otp_token: string;
    email_otp_token: string;
  };
}
interface ErrorsMessage {
  firstNameMessage?: string;
  lastNameMessage?: string;
  emailMessage?: string;
  passwordMessage?: string;
  rePasswordMessage?: string;
  phoneNumberMessage?: string;
  errorFirstName?: boolean;
  errorLastName?: boolean;
  errorEmail?: boolean;
  errorPassword?: boolean;
  errorRePassword?: boolean;
  errorPhoneNumber?: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  countryArray: Array<object>;
  showPassword: boolean;
  reshowPassword: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  rePassword: string;
  countryCode: string;
  phoneNumber: string;
  nameError: boolean;
  firstNameError: boolean;
  lastNameError: boolean;
  emailError: boolean;
  passError: boolean;
  reError: boolean;
  phoneError: boolean;
  errorsMessage: ErrorsMessage;
  countrySuccess: CountrySuccess[];
  smsOTP: string;
  isAlert: boolean;
  alertMsg: string | undefined;
  alertType: AlertType;
  isChecked: boolean;
  showValidationMessage: boolean;
  selectedCountry: CountrySuccess | null;
  dropdownOpen: boolean;
  isCountryData: string;
  smsToken: string;
  emailToken: string;
  fullPhoneNumber: string;
  userEmail: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryCodeAPICallId: string = "";
  registrationAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showPassword: false,
      reshowPassword: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      countryCode: "+965",
      phoneNumber: "",
      nameError: false,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: false,
      passError: false,
      reError: false,
      errorsMessage: {
        firstNameMessage: "",
        lastNameMessage: "",
        emailMessage: "",
        passwordMessage: "",
        rePasswordMessage: "",
        phoneNumberMessage: "",
        errorFirstName: false,
        errorLastName: false,
        errorEmail: false,
        errorPassword: false,
        errorRePassword: false,
        errorPhoneNumber: false
      },
      countryArray: [],
      countrySuccess: [
        {
          numeric_code: "",
          country_full_name: "",
          country_code: "",
          country_flag: ""
        }
      ],
      smsOTP: "",
      isAlert: false,
      alertMsg: "",
      alertType: "success" as AlertType,
      isChecked: false,
      showValidationMessage: false,
      selectedCountry: null,
      dropdownOpen: false,
      isCountryData: "+965",
      smsToken: "",
      emailToken: "",
      fullPhoneNumber: "",
      userEmail: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getCountryCodeApi();
    // this.handleTokenData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customidzable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.apiFailureCall(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  apiSucessCall = async (
    apiRequestCallId: string,
    responseJson: CountrySuccess[] & SignUpSuccess
  ) => {
    if (apiRequestCallId === this.countryCodeAPICallId) {
      if (responseJson) {
        this.setState({
          countrySuccess: responseJson
        });
      }
    }
    if (apiRequestCallId === this.registrationAPICallId) {
      if (responseJson.data) {
        const tempRole = {
          userType: responseJson.data?.attributes?.id_proof?.record?.role,
          language: responseJson.data.attributes.language,
          currency: responseJson.data.attributes.currency
        };
        await setStorageData("userRole", JSON.stringify(tempRole));
        this.setState(
          {
            isAlert: true,
            alertMsg:configJSON.loginSuccessMsg,
            alertType: configJSON.successMsg,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            rePassword: "",
            phoneNumber: "",
            isChecked: false,
            isCountryData: "+965",
            smsToken: responseJson.meta.sms_otp_token,
            emailToken: responseJson.meta.email_otp_token,
            fullPhoneNumber: responseJson.data.attributes.full_phone_number,
            userEmail: responseJson.data.attributes.email
          },
          () => {
            this.openEmailOTPScreen();
          }
        );
      }
    }
  };

  apiFailureCall = (responseJson: ErrorMessage) => {
if(responseJson?.errors?.email){
  this.setState({
    isAlert: true,
    alertMsg: responseJson?.errors?.email,
    alertType: "error"
  })
}else{
  this.setState({
    isAlert: true,
    alertMsg: responseJson?.errors?.full_phone_number,
    alertType: "error"
  })
}
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleTogglePassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  };

  handleToggaleRePassword = () => {
    this.setState(prevState => ({
      reshowPassword: !prevState.reshowPassword
    }));
  };

  handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    this.setState({
      firstName: firstName.replace(/\d/g, ''),
      firstNameError: false,
      emailError: false,
      errorsMessage: {
        firstNameMessage: "",
        errorFirstName: false
      }
    });
  };
  handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    this.setState({
      lastName: lastName.replace(/\d/g, ''),
      lastNameError: false,
      emailError: false,
      errorsMessage: {
        lastNameMessage: "",
        errorLastName: false
      }
    });
  };

  handleEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    this.setState({
      email: email,
      emailError: false,
      passError: false,
      errorsMessage: {
        emailMessage: "",
        errorEmail: false
      }
    });
  };

  handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    const passRegx = configJSON.passRegx;
    if (!passRegx.test(password)) {
      this.setState({
        password: password,
        passError: true,
        reError: false,
        errorsMessage: {
          passwordMessage: configJSON.min8digitValidationMsg,
          errorPassword: true
        }
      });
    } else {
      this.setState({
        password: password,
        passError: false,
        reError: false,
        errorsMessage: {
          passwordMessage: "",
          errorPassword: false
        }
      });
    }
  };

  handleRePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rePassword = event.target.value;
    const { password } = this.state;
    if (password !== rePassword) {
      this.setState({
        rePassword: rePassword,
        reError: true,
        passError: false,
        phoneError: false,
        errorsMessage: {
          rePasswordMessage: configJSON.confirmPass,
          errorRePassword: true
        }
      });
    } else {
      this.setState({
        reError: false,
        rePassword: rePassword,
        passError: false,
        phoneError: false,
        errorsMessage: {
          rePasswordMessage: "",
          errorRePassword: false
        }
      });
    }
  };

  handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneNumber = event.target.value;
    const mobileNumberRegx = configJSON.neightDigRegex;
    if (mobileNumberRegx.test(phoneNumber) && phoneNumber.length <= 7) {
      this.setState({
        phoneNumber: phoneNumber,
        phoneError: true,
        errorsMessage: {
          phoneNumberMessage: "",
          errorPhoneNumber: true
        }
      });
    } else {
      this.setState({
        phoneNumber: phoneNumber,
        phoneError: false,
        errorsMessage: {
          errorPhoneNumber: false,
          phoneNumberMessage: configJSON.alphnewmertic
        }
      });
    }
  };

  handleSubmit = (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();
    const { isChecked } = this.state;

    if (!this.validateFirstName()) {
      return;
    }
    if (!this.validateLastName()) {
      return;
    }
    if (!this.validateEmail()) {
      return;
    }
    if (!this.validatePassword()) {
      return;
    }
    if (!this.validateRePassword()) {
      return;
    }
    if (!this.validatePhoneNumber()) {
      return;
    }

    if (!isChecked) {
      this.setState({ showValidationMessage: true, phoneError: false });
      return;
    }

    this.postFormApi();
  };

  validateFirstName = () => {
    const { firstName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (firstName.trim() === "" || !nameRegex.test(firstName)) {
      this.setState({
        errorsMessage: {
          firstNameMessage: configJSON.firstNameValidationMsg,
          errorFirstName: true
        },
        firstNameError: true
      });
      return false;
    }
    return true;
  };
  validateLastName = () => {
    const { lastName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (lastName.trim() === "" || !nameRegex.test(lastName)) {
      this.setState({
        errorsMessage: {
          lastNameMessage: configJSON.lastNameValidationMsg,
          errorLastName: true
        },
        lastNameError: true
      });
      return false;
    }
    return true;
  };

  validateEmail = () => {
    const { email } = this.state;
    const emailRegex = configJSON.emailRegex;
    if (email === "" || !emailRegex.test(email)) {
      this.setState({
        emailError: true,
        errorsMessage: {
          emailMessage: email
            ? configJSON.emailValidMsg
            : configJSON.emailValidationMsg,
          errorEmail: true
        }
      });
      return false;
    }
    return true;
  };

  validatePassword = () => {
    const { password } = this.state;
    const passRegx = configJSON.passRegx;
    if (password === "" || !passRegx.test(password)) {
      this.setState({
        passError: true,
        errorsMessage: {
          passwordMessage: password
            ? configJSON.min8digitValidationMsg
            : configJSON.validationPassMsg,
          errorPassword: true
        }
      });
      return false;
    }
    return true;
  };

  validateRePassword = () => {
    const { password, rePassword } = this.state;
    if (rePassword === "" || rePassword !== password) {
      this.setState({
        reError: true,
        phoneError: false,
        errorsMessage: {
          rePasswordMessage: rePassword
            ? configJSON.confirmPass
            : configJSON.reEnterValidationMsg,
          errorRePassword: true
        }
      });
      return false;
    }
    return true;
  };

  validatePhoneNumber = () => {
    const { phoneNumber } = this.state;
    const phoneRegex = configJSON.phoneRegex;

    if (phoneNumber.trim() === "") {
      this.setState({
        phoneError: true,
        errorsMessage: {
          phoneNumberMessage: configJSON.phoneValidationMsg,
          errorPhoneNumber: true
        }
      });
      return false;
    } else if (!phoneRegex.test(phoneNumber) && phoneNumber.length <= 7) {
      this.setState({
        phoneError: true,
        errorsMessage: {
          phoneNumberMessage: configJSON.alphnewmertic,
          errorPhoneNumber: true
        }
      });
      return false;
    } else {
      this.setState({
        phoneError: false,
        errorsMessage: {
          phoneNumberMessage: "",
          errorPhoneNumber: false
        }
      });
      return true;
    }
  };

  postFormApi = async () => {
   
    const userRole = await getStorageData("userRole");
    const body = {
      data: {
        type: configJSON.emailtypeMsg,
        attributes: {
          full_phone_number: this.state.isCountryData + this.state.phoneNumber,
          email: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          password_confirmation: this.state.rePassword,
          role: Number(JSON.parse(userRole)?.userType) || 0,
          language:Number(JSON.parse(userRole)?.language)|| 0,
          currency:Number(JSON.parse(userRole)?.currency) || 0
        }
      }
    };
    this.registrationAPICallId = await this.apiCall({
      contentType: configJSON.postRegistrationContentType,
      method: configJSON.postApimethod,
      endPoint: configJSON.postRegistrationEndPoint,
      body: body
    });
  };

  getCountryCodeApi = async () => {
    this.countryCodeAPICallId = await this.apiCall({
      contentType: configJSON.countryApiContentType,
      method: configJSON.httpMethodType,
      endPoint: configJSON.countryCodeEndPoint
    });
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  openLoginScreen = () => {
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.emailAccountLoginBlockmsg
    );
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  };

  openEmailOTPScreen = async () => {
    const { emailToken, smsToken, userEmail, fullPhoneNumber } = this.state;
    await storage.set("email-token", emailToken)
    await storage.set("sms-token", smsToken);    ;
    await storage.set("user-email", userEmail);
    await storage.set("user-mobiler-number", fullPhoneNumber);
    const OTPValue = new Message(getName(MessageEnum.NavigationMessage));
    OTPValue.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    OTPValue.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.EmailOTPVarificationMsg
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      isToken: emailToken,
      isType: configJSON.email_otpMsg,
      isPhoneNumber: fullPhoneNumber,
      userEmail: userEmail
    });
    OTPValue.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(OTPValue.id, OTPValue);
    this.send(OTPValue);
  };

  handleCheckboxChange = () => {
    this.setState(prevState => ({
      isChecked: !prevState.isChecked,
      showValidationMessage: false,
      phoneError: false
    }));
  };

  handleCountrySelect = (country: CountrySuccess, code: string) => {
    this.setState({
      selectedCountry: country,
      dropdownOpen: false,
      isCountryData: code
    });
  };
  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  generateClassName = (
    error: boolean,
    baseClassName: string,
    errorClassName: string
  ) => {
    return error ? errorClassName : baseClassName;
  };

  generateErrorMessage = (
    errorKey: keyof ErrorsMessage,
    defaultMessage: string
  ): string => {
    const { errorsMessage } = this.state;
    const errorMessage = errorsMessage[errorKey];

    // Check if errorMessage is a boolean
    if (typeof errorMessage === "boolean") {
      return "";
    }
    return errorMessage || defaultMessage ||"";
  };

  headerRedirectPolicies = (tabIndex:number) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.TermsConditionsMsg);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message( getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage),{tabIndex:tabIndex} );
    toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(toNavigate);
};

  // Customizable Area End
}
