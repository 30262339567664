import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface CouponCatalogue {
    id: number;
    coupon_code_id: number;
    catalogue_variant_id: number;
    created_at: string;
    updated_at: string;
}
interface CouponAttributes {
    id: number;
    code: string;
    isActive: boolean;
    discount_type: string;
    discount: string;
    valid_from: string;
    valid_to: string;
    min_cart_value: string;
    applicable_for: string;
    discount_cap: number;
    coupon_catalogues: CouponCatalogue[];
    coupon_business_informations: [];
}
interface CouponCode {
    id: string;
    type: string;
    attributes: CouponAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profileName: string,
    value: number,
    orderStatus: string | undefined;
    userStats: { title: string, percentage: string }[],
    storeIdResp: boolean,
    anchor: null | string,
    tabeLoder: boolean,
    promoCards: CouponCode[],
    acountId: number,
    discountType: string,
    visible: boolean,
    alert: {
        message: string;
        isAlert: boolean;
        severity: "error" | "warning" | "info" | "success";
    };
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerOffersDiscountsCreatewebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getMy_OrdersCallId: string = "";
    getCancelOrderCallId: string = "";
    getTrackOfferId: string = "";
    getActiveTrackOfferId: string = "";
    getDeleteTrackOfferId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            alert: {
                isAlert: false,
                message: "",
                severity: "success",
            },
            profileName: "",
            storeIdResp: false,
            value: 0,
            orderStatus: "all_orders",
            anchor: null,
            tabeLoder: true,
            promoCards: [],
            userStats: [
                { title: 'All users', percentage: '10%' },
                { title: 'All users', percentage: '20%' },
                { title: 'All users', percentage: '30%' },
                { title: 'All users', percentage: '50%' },
            ],
            discountType: '',
            acountId: 0,
            visible: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getTrackOfferId !== null &&
            this.getTrackOfferId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonSize = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState((prevState) => ({
                ...prevState,
                promoCards: this.sortById(responseJsonSize && responseJsonSize.data),
            }));
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getActiveTrackOfferId !== null &&
            this.getActiveTrackOfferId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonSize = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJsonSize) {
                this.setState((prevState) => ({
                    ...prevState,
                    isLoader: false,
                    alert: {
                        ...prevState.alert,
                        message: "Track Offer Update Successfully",
                        isAlert: true,
                        severity: "success",
                    },
                }));
                this.generatorTrackOffer();
            }
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getDeleteTrackOfferId !== null &&
            this.getDeleteTrackOfferId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonSize = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJsonSize.message === "Coupon deleted successfully") {
                this.setState((prevState) => ({
                    ...prevState,
                    isLoader: false,
                    visible: false,
                    alert: {
                        ...prevState.alert,
                        message: "Delete Update Successfully",
                        isAlert: true,
                        severity: "success",
                    },
                }));
                this.generatorTrackOffer();
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    onCloseAlert = () => {
        this.setState((prevState) => ({
            ...prevState,
            alert: {
                ...prevState.alert,
                isAlert: !prevState?.alert.isAlert,
            },
        }));
    };
    sortById = (items: CouponCode[]): CouponCode[] => {
        if (!items) return [];
        const compareById = (item1: CouponCode, item2: CouponCode): number => { return parseInt(item2.id) - parseInt(item1.id)};
        const sortedItems = [...items];
        sortedItems.sort(compareById);
        return sortedItems;
    };
    formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };
    async componentDidMount() {
        this.generatorTrackOffer()
    }
    sellerOffersDiscounts = () => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellerOffersDiscounts");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    handleStoreStatusUpdate = async (status: boolean, acountId: number) => {
        this.setState({ storeIdResp: status })
        this.toggleActiveTrackOffer(acountId)
    };
    handleOpen = (acountId: number) => {
        this.setState({ visible: true, acountId: acountId })
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };
    handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
        event.preventDefault();
        this.setState({ value: newValue });
    };
    handleOpenMenu = async (cardId: string | null) => {
        this.setState((prevState) => ({
            anchor: prevState.anchor === cardId ? null : cardId,
        }));
    };
    sellerOffersEditeAccount = async (discountId: number) => {
        await setStorageData("discountId", discountId)
        const findData = this.state.promoCards.find((value) => { return value.attributes.id === discountId })
        const variantDateId = findData && findData.attributes.coupon_catalogues.map((value) => { return value.catalogue_variant_id })
        await setStorageData("selectedIndices", variantDateId)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellerOffersStoreFormWebPage");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    sellerOffersAccount = () => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellerOffersStoreFormWebPage");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 1 });
        toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(toNavigate);
    }
    sellerOffersAccount1 = () => {
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellerOffersStoreFormWebPage");
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 2 });
        toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(toNavigate);
    }
    generatorTrackOffer = async () => {
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTrackOfferId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.generatorTrack
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };
    toggleActiveTrackOffer = async (acountId: number) => {
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getActiveTrackOfferId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodPatchType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.toggleActiveTrack}/${acountId}/toggle_active`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };
    toggleDeleteTrackOffer = async (acountId: number) => {
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDeleteTrackOfferId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodDeleteType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.toggleDeleteTrack}/${acountId}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    };
    // Customizable Area End
}
