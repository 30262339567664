import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface INavigateDetailsTo {
  id: string | undefined;
  screenName: string;
  raiseMessage?: Message;
}

interface ImageData {
  portfolio_image_id: number;
  description: string | null;
  image_id: number;
  url: string;
}

interface DataRecord {
  id: number;
  description: string | null;
  account_id: number;
  created_at: string;
  updated_at: string;
  image: ImageData;
}

interface LandingTitleContentDataType {
  id: string,
  type: string,
  attributes: {
      id: number,
      title: string,
      subtitle: string,
      created_at: string,
      updated_at: string
  }
}

interface HandlePostAPIResponse {
  data: {
    id: string,
    type: string,
    attributes: {
      created_at: number,
      favouriteable: {
        data: {
          id: string,
          type: string,
          attributes: {
            availability: null,
            brand: null,
            brand_name: string,
            description: string,
            discount: null,
            fit: string,
            fit_discription: null,
            gender: string,
            is_wishlist: true,
            list_the_product: string,
            manufacture_date: null,
            material: string,
            name: string,
            price: null,
            primary_colour: {
              created_at: "2023-10-05T05:19:28.122Z",
              id: 7,
              name: "Grey",
              updated_at: "2023-10-05T05:19:28.122Z",
            },
            primary_image: "https://google/profile.jpg", primary_price: "45.0",
            primary_size: {
              created_at: string,
              id: number,
              name: string,
              updated_at: string
            },
            prodcut_care: string,
            product_number: null,
            sku: null,
            stock_qty: null
          },
          favouriteable_id: number,
          favouriteable_type: string,
          notes: null,
          updated_at: string,
          wishlist_id: number
        }
      },
      favouriteable_id: number,
      favouriteable_type: string, notes: null,
      updated_at: string,
      wishlist_id: number
    }
  },
  meta: {
    message: string
  }
};
interface HandlePostResponseError {
  error: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  carouselList: {
    id: string;
    type: string;
    attributes: {
      id: number;
      title: string;
      subtitle: string;
      description: string;
      link: string;
      rank: number;
      created_at: string;
      updated_at: string;
      image: string;
    };
  }[];
  trendgingDataList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      catalogue_variants: {}[],
      primary_price: string;
      primary_image: string;
      is_wishlist: boolean
    };
  }[]
  recommendationDataList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      catalogue_variants: {}[],
      primary_price: string;
      primary_image: string;
      is_wishlist: boolean;
    };
  }[]
  stylistServiceDataList: DataRecord[],
  landingPageTitleList: LandingTitleContentDataType,
  newLaunchDataList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      catalogue_variants: {}[],
      primary_price: string;
      primary_image: string;
      is_wishlist: boolean;
    };
  }[]
  error: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  token: string;
  modalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchCarouselPageListApi: string = "";
  fetchStylistServicePageListApi: string = "";
  fetchLandingPageTitleListApi: string = "";
  fetchTrendingListPageApi: string = "";
  fetchNewLaunchListPageApi: string = "";
  fetchRecommendationListPageApi: string = "";
  postFavouriteApiCalledId: string = "";
  deleteFavouriteApiCalledId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      showLoader: false,
      carouselList: [],
      trendgingDataList: [],
      newLaunchDataList: [],
      recommendationDataList: [],
      stylistServiceDataList: [],
      landingPageTitleList: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          title: "",
          subtitle: "",
          created_at: "",
          updated_at: ""
        }
      },
      error: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: '',
      token: '',
      modalOpen: false

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      switch (apiRequestCallId) {
        case this.fetchCarouselPageListApi:
          if (responseJson.errors) {
            this.setState({
              error: "An error occurred, please try again"
            });
          }
          this.setState({
            carouselList: [
              ...responseJson.data,
              responseJson?.data[responseJson.data.length - 1]
            ]
          });
          break;
        case this.fetchNewLaunchListPageApi:
          if (responseJson.errors) {
            this.setState({
              error: "An error occurred, please try again"
            });
          }
          this.setState({
            newLaunchDataList: responseJson.data,
          })
          break;
        case this.fetchTrendingListPageApi:
          if (responseJson.errors) {
            this.setState({
              error: "An error occurred, please try again"
            });
          }
          this.setState({
            trendgingDataList: responseJson.data,
          })
          break;
        case this.fetchRecommendationListPageApi:
          this.setState({
            recommendationDataList: responseJson?.data,
          })
          break;
        case this.fetchStylistServicePageListApi:
            this.setState({
              stylistServiceDataList: responseJson?.data,
            })   
          break;  
        case this.fetchLandingPageTitleListApi:
            this.setState({
              landingPageTitleList: responseJson?.data,
            }) 
          break;
        case this.postFavouriteApiCalledId:
          this.handleFavouriteResponse(responseJson);
          break;
        case this.deleteFavouriteApiCalledId:
          this.getTrendingListPage();
          this.getNewLaunchPageList();
          this.getCarouselPageList();
          this.getRecommandtionPageList();
          this.setState({
            isAlert: true,
            alertMsg: responseJson?.message,
            alertType: "success"
          })
          break;
      }
    } else {
      this.setState({
        error: "An error occurred, please try again"
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleFavouriteResponse = (response: HandlePostAPIResponse & HandlePostResponseError) => {
    if (response.error) {
      this.setState({
        error: response.error
      });
    } else {
      this.getTrendingListPage();
      this.getNewLaunchPageList();
      this.getCarouselPageList();
      this.setState({
        isAlert: true,
        alertMsg: response?.meta?.message,
        alertType: 'success'
      });


    }
  }

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  componentDidMount = async () => {
    const token = await getStorageData("auth-token");
    this.setState({
      token: token
    })
    this.getCarouselPageList();
    this.getTrendingListPage();
    this.getNewLaunchPageList();
    this.getStylistServiceData();
    this.getLandingPageTitleData();
    token && this.getRecommandtionPageList();
  };

  handleDeleteApiCall = async (favouriteId?: string) => {

    if (!this.state.token) {
      this.setState({
        modalOpen: true
      });
      return false;
    }

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFavouriteApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlePostApiCall = async (favouriteId?: string) => {
    if (!this.state.token) {
      this.setState({
        modalOpen: true
      });
      return false;
    }

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const httpBody = {
      data: {
        favouriteable_id: favouriteId
      }
    };

    this.postFavouriteApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postFavouriteApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFavouriteApi = async (favouriteId: string, fav: boolean) => {
    if (fav) {
      this.handleDeleteApiCall(favouriteId);
    } else {
      this.handlePostApiCall(favouriteId);
    }
  };

  handleTrendingList = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'TrendingListPage');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  getCarouselPageList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchCarouselPageListApi = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingCarouselApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStylistServiceData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStylistServicePageListApi = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStylistServiceApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLandingPageTitleData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchLandingPageTitleListApi = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageTitleContentApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getNewLaunchPageList = async () => {
    const apiRoute = this.state.token ? configJSON.getLandingPageNewLaunchApi : configJSON.getLandingPageNewLauncheApi;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    this.fetchNewLaunchListPageApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  navigateToDeatilPage = ({ id, screenName, raiseMessage }: INavigateDetailsTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id &&
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
  }

  getTrendingListPage = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const apiRoute = this.state.token ? configJSON.getLandingPageTrendingApi : configJSON.getLandingPageTrendingPageApi;
    this.fetchTrendingListPageApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRecommandtionPageList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchRecommendationListPageApi = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageRecommendationsApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
