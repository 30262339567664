import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react"
import AuthenticateUser from "../../../components/src/AuthenticateUser";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { RefObject, createRef } from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { v4 as uuidv4 } from 'uuid'
import { format } from 'date-fns';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Product {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    primary_image: string | undefined;
    primary_price: string;
    is_wishlist: boolean
  };
}
interface Size {
  name: string;
  label: string;
}
interface VariantSwithStore {
  id: string,
  type: string,
  attributes: {
    id: number,
    catalogue_id: number,
    catalogue_variant_color_id: number,
    catalogue_variant_color: {
      id: number,
      name: string,
      created_at: string,
      updated_at: string
    },
    catalogue_variant_size_id: number,
    catalogue_variant_size: {
      id: number,
      name: string,
      created_at: string,
      updated_at: string
    },
    is_listed: boolean,
    front_image: string,
    pair_it_with: [
      {
        id: string,
        type: string,
        attributes: {
          id: number;
          catalogue_id: number;
          product_name: string;
          product_description: string;
          sku: string;
          stock_qty: number;
          low_stock_threshold: number;
          is_listed: boolean;
          price: string;
          size: string;
          colour: string;
          gender: string;
          front_image: string;
          brand_name: string;
        }
      }
    ]
  },
  store_info: {
    id: string,
    type: string,
    attributes: {
      store_name: string,
      description: string,
      address: string,
      area: string,
      block: string,
      mall_name?: string,
      floor: string,
      unit_number: number,
      city: string,
      zipcode: number | string,
      driver_instruction: string,
      average_shipping_time: string
      expected_delivery_time: string
    },
  }
}
interface ProductData {
  id: string;
  type: string;
  attributes: {
    id: string;
    catalogue_variants_with_store: VariantSwithStore[]
    name: string;
    description: string;
    primary_image: string | undefined;
    primary_price: string;
    is_wishlist: boolean;
    fit: string;
    prodcut_care: string;
    material: string;
    expected_delivery_time: string;
    brand_name:string;
  };
}
interface SummaryData {
  name: string;
  title: string;
  description: string;
}
interface INavigateTo {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedItem: number;
  arrowDisable: boolean;
  selectedSize: string,
  selectedSizeLabel: string | null;
  selectedColor: number | null;
  products: Product[],
  productsData: ProductData | null,
  categoryData: string,
  sub_categoryData: string,
  productsloding: boolean,
  initialSize: Size[],
  colors: string[],
  filterSize: string[],
  filterColor: string[],
  summuryData: SummaryData[],
  lodingProduct: boolean,
  selectColore: string,
  cataegreyId: number | string,
  isAlert: boolean,
  already: string,
  setProps: React.ReactNode,
  modalOpen:boolean,
  tokens:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Productdescription3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  elementRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  getcategoriesCallId: string = "";
  getaddCartCallId: string = "";
  getcategoriesId: string = "";
  getproductCallId: string = "";
  getproductLickCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      modalOpen: false,
      setProps: "",
      isAlert: false,
      cataegreyId: "",
      colors: ['red', 'blue', 'green', 'yellow', 'purple'],
      sub_categoryData: "",
      productsloding: false,
      categoryData: "",
      selectedColor: 0,
      arrowDisable: false,
      selectedItem: 0,
      selectedSize: '',
      selectedSizeLabel: '',
      productsData: null,
      products: [],
      initialSize: [
        { name: 'XS', label: 'Extra Small' },
        { name: 'S', label: 'Small' },
        { name: 'M', label: 'Medium' },
        { name: 'L', label: 'Large' },
        { name: 'XL', label: 'Extra Large' }
      ],
      filterSize: [],
      filterColor: [],
      summuryData: [{ name: 'description', title: "Product description", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: 'fit', title: "Size & Fit", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: 'prodcut_care', title: "Product care & Material", description: "Machine Wash 55% Cotton 45% Polyster" },
      { name: '', title: "Expected delivery & total cost ", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: '', title: "Expected Cost", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      { name: '', title: "Store information", description: "Black other checked opaque Casual shirt, has a spread collar, button placket, 1 patch pocket, long roll-up sleeves, curved hem." },
      ],
      lodingProduct: true,
      selectColore: "",
      already: "",
      tokens:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getcategoriesCallId !== null &&
      this.getcategoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonColor = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const fleet = responseJsonColor.data.attributes.catalogue_variants_with_store?.map((value: { attributes: { catalogue_variant_size: { name: string } } }) => {
        return value.attributes?.catalogue_variant_size.name
      })
      const filtData: SummaryData[] = this.state.summuryData.map((value: SummaryData) => {
        return responseJsonColor.data.attributes[value.name]
          ? { name: value.name ? value.name : '', title: value.title, description: responseJsonColor.data.attributes[value.name] }
          : value;
      });
      const uniqueSizes = fleet.filter((value: string, index: number, self: string[]) => { return self.indexOf(value) === index });
      const fleetColore = responseJsonColor.data.attributes.catalogue_variants_with_store?.map((value: { attributes: { catalogue_variant_color: { name: string } } }) => value.attributes?.catalogue_variant_color.name)
      const uniqueColore = fleetColore.filter((value: string, index: number, self: string[]) => { return self.indexOf(value) === index });
      const categoryName = responseJsonColor.data.attributes.category.attributes.name;
      const match = categoryName.match(/^[^\s']+/);
      const firstWord = match ? match[0] : '';
      this.setState({ cataegreyId: responseJsonColor.data.attributes.category.attributes.id, productsData: responseJsonColor.data, lodingProduct: false, productsloding: true, summuryData: filtData, filterColor: uniqueColore, filterSize: uniqueSizes, selectColore: responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.catalogue_variant_color.name, selectedSize: responseJsonColor.data.attributes.catalogue_variants_with_store[0].attributes.catalogue_variant_size.name, selectedSizeLabel: responseJsonColor.data.attributes?.catalogue_variants_with_store[0].attributes.catalogue_variant_size.name, categoryData: firstWord, sub_categoryData: responseJsonColor.data.attributes?.sub_category.attributes.name }, () => {
        this.categoreySimilar(responseJsonColor.data.attributes.category.attributes.id)
      })

    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getcategoriesId !== null &&
      this.getcategoriesId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ products: responseJson.data })
    }
    this.getaddCart(message)
    this.getaddCardData(message)
    this.getaddCardLinkData(message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }
  // Customizable Area End
  // Customizable Area Start
  getaddCardData = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getproductCallId !== null &&
      this.getproductCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message === "Destroy successfully") {
        this.categorey()
        this.categoreySimilar(this.state.cataegreyId)
      }else if (responseJson.error[0].token === "Invalid token") {
        this.renderDialog()
      }
    }
  }
  getaddCardLinkData = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getproductLickCallId !== null &&
      this.getproductLickCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.meta.message === "Added to wishlist.") {
        this.categorey()
        this.categoreySimilar(this.state.cataegreyId)
      }
    }
  }

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  handleSelect = (index: number) => {
    this.setState({ selectedItem: index });
  };
  handleHorizantalScroll = (element: RefObject<HTMLDivElement>, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (element.current) {
        element.current.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        if (element.current.scrollLeft === 0) {
          this.setState({ arrowDisable: true });
        } else {
          this.setState({ arrowDisable: false });
        }
      }
    }, speed);
  };

  clothingPageNavigation = (props: React.ReactNode) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriessubcategoriesWebPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    this.send(message);
  }
  getSizeValue = (size: string, type: 'label' | 'value') => {
    switch (size) {
      case 'XS':
        return type === 'label' ? 'Extra Small' : 'XS';
      case 'S':
        return type === 'label' ? 'Small' : 'S';
      case 'M':
        return type === 'label' ? 'Medium' : 'M';
      case 'L':
        return type === 'label' ? 'Large' : 'L';
      case 'XL':
        return type === 'label' ? 'Extra Large' : 'XL';
      default:
        return type === 'label' ? size.toUpperCase() : null;
    }
  };
  // Customizable Area End
  // Customizable Area Start
  formattedDate = () => {
    const newDate = this.state.productsData?.attributes?.catalogue_variants_with_store[0].store_info.attributes?.expected_delivery_time || "";
    if (!newDate) return ""
    const date = new Date(newDate);
    let dateString = date.toDateString().split(' ')
    return `${dateString[0]}, ${dateString[2]} ${dateString[1]} - ${dateString[3]}`
  }
  getAddress = () => {
    const { mall_name, floor, area, block, city, zipcode } = this.state.productsData?.attributes.catalogue_variants_with_store[0].store_info.attributes || {
      store_name: "",
      description: "",
      address: "",
      area: "",
      block: "",
      mall_name: "",
      floor: "",
      unit_number: "",
      city: "",
      zipcode: "",
      driver_instruction: "",
      average_shipping_time: "",
      expected_delivery_time: ""
    } 
    const addressParts = [mall_name, floor, area, block, city, zipcode].filter(part => part).join(', ');
    return addressParts
  }
  isSelectedSize = (isSelectedSize: boolean, size: { name: string }) => {
    return isSelectedSize ? size.name.toUpperCase() : this.getSizeValue(size.name, "value")
  }
  backgroundColor = (isSelectedSize: boolean) => {
    return isSelectedSize ? '#CCBEB1' : 'white'
  }
  sizeColor = (isSelectedSize: boolean) => {
    return isSelectedSize ? 'white' : '#375280'
  }
  filterColore = (index: number) => {
    return this.state.selectedColor === index ? '1px solid #375280' : "1px solid rgba(55, 82, 128, 0)"
  }
  handleColorClick = (color: string, index: number) => {
    this.setState({ selectedColor: index, selectColore: color });
  };

  getNavigationMessage = (props?: React.ReactNode) => {
    this.setState({ setProps: props })
    this.addtoCart()
  }
  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("auth-token")
    this.categorey()
    const unique_token = await getStorageData('unique_token')
    !unique_token && await setStorageData("unique_token", uuidv4())
    this.setState({tokens:token})
    // Customizable Area End
  }

  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
    this.categorey(productId)
  }
  categorey = async (productId?: string) => {
    const idData = productId ? productId : this.props.navigation.getParam("navigationBarTitleText");
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcategoriesCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      token === null ? `${configJSON.cataloge}/${idData}` : `${configJSON.catalogeToken}=${idData}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  addtoCart = async () => {
    const idData = this.state.productsData?.attributes?.catalogue_variants_with_store[0].attributes.catalogue_id;
    const catalogue_variantID = this.state.productsData?.attributes?.catalogue_variants_with_store[0].id;
    const catalogue_informationID = this.state.productsData?.attributes?.catalogue_variants_with_store[0].store_info.id;
    const unique_token = await getStorageData("unique_token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      unique_token: unique_token,
    };
    const body = {
      "quantity": 1,
      "catalogue_id": Number(idData),
      "catalogue_variant_id": Number(catalogue_variantID),
      "bussiness_information_id": Number(catalogue_informationID)
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getaddCartCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_addCart
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.product_addCartUrl}=${unique_token}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  categoreySimilar = async (cataegreyId?: number | string) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcategoriesId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      token === null ? `${configJSON.getCatalogues}=${cataegreyId}&page=1&per_page=5` : `${configJSON.getCataloguesToken}=${cataegreyId}&page=1&per_page=5`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  navigateToData = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
  }
  showeErrorParir = () => {
    this.setState({ isAlert: true, already: "No catalogue pairs available!" })
  }

  wishlistLike = async (productId: number | string) => {
    if (!this.state.tokens) {
      this.setState({
        modalOpen: true
      });
      return false;
    }
    const token = await getStorageData("auth-token");
    const bodyDelete = { "data": { "favouriteable_id": Number(productId) } }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getproductLickCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouritesLink
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyDelete)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  wishlistLikeRemoved = async (productId: number | string) => {
    if (!this.state.tokens) {
      this.setState({
        modalOpen: true
      });
      return false;
    }
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getproductCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiRemoved
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouritesremoved}=${Number(productId)}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  is_wishlistData = (product: Product, event: React.MouseEvent) => {
    event.stopPropagation()
    if (product.attributes.is_wishlist) {
      this.wishlistLikeRemoved(product.id);
    } else {
      this.wishlistLike(product.id);
    }
  }
  handleWishlistToggle = (filteredValue: VariantSwithStore) => {
    if (this.state.productsData?.attributes?.is_wishlist) {
      this.wishlistLikeRemoved(filteredValue.attributes?.catalogue_id);
    } else {
      this.wishlistLike(filteredValue.attributes?.catalogue_id);
    }
  };
  getaddCart = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getaddCartCallId !== null &&
      this.getaddCartCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (!responseJson.errors) {
        setStorageData("addToCart", true)
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductdetailsWeb");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.state.setProps);
        this.send(message);
      } else {
        this.setState({ already: responseJson.errors, isAlert: true })
      }
    }
  }
  renderDialog = () => {
    return (
      <AuthenticateUser
        auth={this.state.tokens}
        title="Log in to view your Wishlist."
        description="Shop them anything you like."
        btnText="Log In or Sign up"
        navigation={this.props.navigation}
        onClose={this.closeModal}
      />
    )
  }
  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  handleClick = () => {
    this.setState(prevState => ({ selectedItem: prevState.selectedItem - 1 }));
    this.handleHorizantalScroll(this.elementRef, 25, 100, -10);
  };
  handleNextClick = () => {
    this.setState(prevState => ({ selectedItem: prevState.selectedItem + 1 }));
    this.handleHorizantalScroll(this.elementRef, 25, 100, 10);
  };
  handleSizeClick = (size: Size) => {
    const newSelectedSizeLabel = this.getSizeValue(size.name, "label");
    this.setState({
      selectedSize: size.label,
      selectedSizeLabel: newSelectedSizeLabel
    });
  };
  homePageNavigation = (props: React.ReactNode) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    this.send(message);
  }
  // Customizable Area End
}
