import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface OrderSeller {
    id: string;
    type: 'order_seller';
    attributes: {
      order_number: string;
      account: string;
      order_item_count: number;
      sub_total: string;
      total: string;
      status: string;
      placed_at: string;
      confirmed_at: string;
      in_transit_at: string | null;
      delivered_at: string | null;
      process_at: string;
      shipped_at: string | null;
      return_at: string | null;
      return_cancel_at: string | null;
      return_pick_at: string | null;
      cancelled_at: string | null;
      cancellation_reason: string | null;
      rejected_at: string | null;
      refunded_at: string | null;
      returned_at: string | null;
      deliver_by: string | null;
      order_status_id: number;
      created_at: string;
      updated_at: string;
      order_deliver_date: string | null;
      order_deliver_time: string | null;
      delivery_addresses: {
        id: string;
        type: 'delivery_address';
        attributes: {
          name: string;
          country_code: string;
          phone_number: string;
          contact_number: string;
          street: string;
          zip_code: string;
          area: string;
          block: string;
          city: string;
          house_or_building_number: string;
          address_name: string;
          is_default: boolean;
          latitude: number;
          longitude: number;
        };
      };
      order_return_date: string | null;
      order_return_time: string | null;
      return_placed_at: string | null;
      return_confirmed_at: string | null;
      return_reject_at: string | null;
      returned_assign_at: string | null;
      refunded_cancel_at: string | null;
      status_humanize: string;
      order_items: {
        id: string;
        type: 'order_item_seller';
        attributes: {
          status: string;
          placed_at: string;
          confirmed_at: string;
          in_transit_at: string | null;
          delivered_at: string | null;
          cancelled_at: string | null;
          rejected_at: string | null;
          process_at: string;
          shipped_at: string | null;
          return_at: string | null;
          return_cancel_at: string | null;
          return_pick_at: string | null;
          return_placed_at: string | null;
          return_confirmed_at: string | null;
          return_reject_at: string | null;
          returned_assign_at: string | null;
          quantity: number;
          unit_price: string;
          total_price: string;
          reason_of_rejection: string | null;
          reason_of_return: string | null;
          refunded_cancel_at: string | null;
          reason_refunded_cancel: string | null;
          refunded_at: string | null;
          status_humanize: string;
          catalogue_name: string;
          brand_name: string;
          catalogue_variant_color: string;
          catalogue_variant_sku: string;
          store_name: string;
          catalogue_variant_size: string;
          catalogue_variant_front_image: string;
          catalogue_variant_back_image: string;
          catalogue_variant_side_image: string;
          driver_name: string | null;
          driver_latitude: number;
          driver_longitude: number;
          driver_phone_number: string | null;
          otp: string | null;
        };
      }[];
      payment_detail: {
        id: number;
        status: string;
        created_at: string;
        updated_at: string;
        charge_id: string;
        merchant_id: string | null;
        order_id: string;
        amount: string;
        currency: string;
        customer_id: string;
        reason: string;
        account_id: number;
        order_management_order_id: number;
        refund_id: string | null;
        refund_amount: string;
        refund_reason: string | null;
        seller_order_id: string | null;
        last_four_card_digit: string;
        payment_type: string;
        deleted: boolean;
      };
      buyer_latitude: number;
      estimated_delivery_time: string | null;
      buyer_longitude: number;
      expected_time: string;
    };
  };
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileName: string;
  TrackOrderDetailsList: OrderSeller,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TrackOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTRackOrderApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profileName: '',
      TrackOrderDetailsList: {
          "id": "1227",
          "type": "order_seller",
          "attributes": {
            "order_number": "OD00000349",
            "account": "Arnab Fash",
            "order_item_count": 1,
            "sub_total": "1000.0",
            "total": "1180.0",
            "status": "confirmed",
            "placed_at": "2024-08-01T12:17:00.912Z",
            "confirmed_at": "2024-08-01T12:17:36.248Z",
            "in_transit_at": null,
            "delivered_at": null,
            "process_at": "2024-08-05T13:29:51.725Z",
            "shipped_at": null,
            "return_at": null,
            "return_cancel_at": null,
            "return_pick_at": null,
            "cancelled_at": null,
            "cancellation_reason": null,
            "rejected_at": null,
            "refunded_at": null,
            "returned_at": null,
            "deliver_by": null,
            "order_status_id": 2,
            "created_at": "2024-08-01T12:15:33.853Z",
            "updated_at": "2024-08-05T13:29:51.732Z",
            "order_deliver_date": null,
            "order_deliver_time": null,
            "delivery_addresses": {
              "id": "724",
              "type": "delivery_address",
              "attributes": {
                "name": "ggg",
                "country_code": "+91",
                "phone_number": "9752340590",
                "contact_number": "+919752340590",
                "street": "Dunkirk, France",
                "zip_code": "395006",
                "area": "rt",
                "block": "5",
                "city": "surat ",
                "house_or_building_number": "34",
                "address_name": "ffffrrrr",
                "is_default": false,
                "latitude": 51.0343684,
                "longitude": 2.3767763
              }
            },
            "order_return_date": null,
            "order_return_time": null,
            "return_placed_at": null,
            "return_confirmed_at": null,
            "return_reject_at": null,
            "returned_assign_at": null,
            "refunded_cancel_at": null,
            "status_humanize": "Confirmed",
            "order_items": [
              {
                "id": "1605",
                "type": "order_item_seller",
                "attributes": {
                  "status": "ready_to_ship",
                  "placed_at": "2024-08-01T12:17:01.461Z",
                  "confirmed_at": "2024-08-01T12:17:36.209Z",
                  "in_transit_at": null,
                  "delivered_at": null,
                  "cancelled_at": null,
                  "rejected_at": null,
                  "process_at": "2024-08-05T13:29:51.638Z",
                  "shipped_at": null,
                  "return_at": null,
                  "return_cancel_at": null,
                  "return_pick_at": null,
                  "return_placed_at": null,
                  "return_confirmed_at": null,
                  "return_reject_at": null,
                  "returned_assign_at": null,
                  "quantity": 1,
                  "unit_price": "1000.0",
                  "total_price": "1000.0",
                  "reason_of_rejection": null,
                  "reason_of_return": null,
                  "refunded_cancel_at": null,
                  "reason_refunded_cancel": null,
                  "refunded_at": null,
                  "status_humanize": "Ready to ship",
                  "catalogue_name": "Nykaa",
                  "brand_name": "H&M",
                  "catalogue_variant_color": "Blue",
                  "catalogue_variant_sku": "ABCfD22237",
                  "store_name": "fashionbrand",
                  "catalogue_variant_size": "L",
                  "catalogue_variant_front_image": "/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ3NRIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f8d6f473e9e98b2ba042f08c949b103eac3e32f6/men1.jpg",
                  "catalogue_variant_back_image": "/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ3dRIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--bc1b99fb001b457053f036c8804c97e9285cb8e0/men1.jpg",
                  "catalogue_variant_side_image": "/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzBRIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--29c05312d172f65e1bb6bdafa660ecb6fa092d80/mens.jpg",
                  "driver_name": null,
                  "driver_latitude": 0,
                  "driver_longitude": 0,
                  "driver_phone_number": null,
                  "otp": null
                }
              }
            ],
            "payment_detail": {
              "id": 370,
              "status": "CAPTURED",
              "created_at": "2024-08-01T12:17:00.908Z",
              "updated_at": "2024-08-01T12:17:01.539Z",
              "charge_id": "chg_TS03A3820241515Qx030108223",
              "merchant_id": null,
              "order_id": "OD00000349",
              "amount": "1180.0",
              "currency": "KWD",
              "customer_id": "cus_TS06A3420241333u6Z31505392",
              "reason": "",
              "account_id": 685,
              "order_management_order_id": 1227,
              "refund_id": null,
              "refund_amount": "0.0",
              "refund_reason": null,
              "seller_order_id": null,
              "last_four_card_digit": "4242",
              "payment_type": "VISA",
              "deleted": false
            },
            "buyer_latitude": 0,
            "estimated_delivery_time": null,
            "expected_time": "09:36 AM",
            "buyer_longitude": 0
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getTRackOrderApiCallId) {      
      this.setState({ TrackOrderDetailsList: responseJson.data });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getTrackOrderDetailApi();
    const profileHolder = await getStorageData("user_data")
    this.setState({ profileName: JSON.parse(profileHolder).first_name + " " + JSON.parse(profileHolder).last_name })
  }

  proceedToTrackPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "OrderManagementPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToOrderDetail = async ({props,screenName}: {props: unknown,screenName: string}) => {
    let trackId= await getStorageData("trackID")
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    trackId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), trackId);
    runEngine.sendMessage(message.id, message);
  }

  proceedToTrackHomePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getTrackOrderDetailApi = async () => {
    const getToken = await getStorageData("auth-token")
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    
    const headers = {
      redirect: "follow",
      "token": getToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTRackOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/${idData}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
