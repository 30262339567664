import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  ThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  styled,
  Dialog,
  InputBase,
  IconButton,
  Button,
} from "@material-ui/core";
import DeleteProductsController, { Props } from "./DeleteProductsController";
import { SellerStoreBodyContainer } from "../../Revenue/SellerRevenue.web";
import SellerHeader from "../../../../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../../../../components/src/Seller/SellerSideBar";
import { backBtnIcon, crossIcon } from "../../../assets";
import {
  CustomStyledPagination,
  CustomStyledTableUpdateInventory,
  PaginationContainer,
  TableHeaderCell,
} from "../UpdateInventory/UpdateInventory.web";
import { CheckboxContainer } from "../SellerInventoryManagement.web";
import {
  ASCheckTableHeadBox,
  BtnContainer,
  NoRecordTypoContainer,
  WarningDialogContainer,
} from "../AssignStore/InventoryAssignStore.web";
import Loader from "../../../../../../components/src/LoaderContainer";
import { search } from "../../../../../email-account-registration/src/assets";
import { ButtonContainer } from "./BulkAction.web";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#CCBEB1",
    },
  },
});
// Customizable Area End

export default class DeleteProducts extends DeleteProductsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDeleteProductsBody = () => (
    <>
      {this.state.tableBodyData.length === 0 ? (
        <TableRow>
          <TableCell colSpan={3}>
            <NoRecordTypoContainer>No Records Found</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {this.state.tableBodyData.map((deleteInventoryData) => (
            <TableRow key={deleteInventoryData.id} className="hover">
              <TableCell data-test-id="product-name">
                <Box className="product-name-container">
                  <img
                    src={deleteInventoryData.front_image}
                    className="img-container"
                    alt={deleteInventoryData.product_name}
                  />
                  <Typography className="product-title">
                    {deleteInventoryData.product_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography className="product-sku">
                  {deleteInventoryData.sku}
                </Typography>
              </TableCell>
              <TableCell>
                <CheckboxContainer
                  color="secondary"
                  data-test-id={`delete-product-checked-${deleteInventoryData.id}`}
                  checked={deleteInventoryData.isSelected}
                  onChange={() =>
                    this.handleProductCheckboxChange(
                      deleteInventoryData.id,
                      !deleteInventoryData.isSelected
                    )
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );

  renderDeleteProductsPagination = () => (
    <>
      {this.state.deleteInventoryMeta !== undefined ? (
        <PaginationContainer>
          <Box id="dp-page-container" className="pagination-data-container">
            {`${(this.state.deleteInventoryMeta?.current_page - 1) * 10 + 1} - 
              ${Math.min(
                this.state.deleteInventoryMeta?.current_page * 10,
                this.state.deleteInventoryMeta?.total_record
              )}
            of ${this.state.deleteInventoryMeta?.total_record} results`}
          </Box>
          <CustomStyledPagination
            data-test-id="dp-pagination-test-id"
            page={this.state.page}
            count={this.state.deleteInventoryMeta?.total_pages}
            shape="rounded"
            color="primary"
            onChange={this.handleDeleteInventoryPageChange}
          />
        </PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container className="grid-seller-store-delete-products">
          <Grid
            item
            xl={2}
            md={3}
            className="sidebar-seller-store-delete-products"
            lg={2}
            sm={3}
            xs={3}
          >
            <SellerSidebar
              navigation={this.props.navigation}
              className="sidebar-seller-store-inventory-delete-products"
            />
          </Grid>
          <Grid
            item
            xl={10}
            md={9}
            className="header-seller-store--delete-products"
            lg={10}
            sm={9}
            xs={9}
          >
            <SellerHeader navigation={this.props.navigation} />
            <SellerStoreBodyContainer>
            <DeleteProductHeader>
              <Box className="left-container">
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleRedirect("SellerInventoryManagement")
                  }
                />
                <Typography className="main-title" >Select Products </Typography>
                <Typography className="secondary-title">[Select the products from the below table to delete in bulk]</Typography>
                </Box>
                <Box className="right-container">
                <InputBase
                  placeholder="Search Product"
                  className="input-field"
                  startAdornment={
                    <IconButton style={{ padding: "10px" }} aria-label="search">
                      <img src={search} width="20px" height="20px" />
                    </IconButton>
                  }
                  value={this.state.search}
                  onChange={this.handleSearchInventory}
                  data-test-id="search-box-test-id"
                  name="searchValue"
                />
                <Button
                  variant={
                    this.state.selectedDeleteProductIds.length !== 0
                      ? "contained"
                      : "outlined"
                  }
                  className={
                    this.state.selectedDeleteProductIds.length !== 0
                      ? "contained-btn-confirm"
                      : "outline-btn-confirm"
                  }
                  disableElevation
                  data-test-id="delete-btn-test-id"
                  onClick={
                    this.state.selectedDeleteProductIds.length !== 0
                      ? () => this.handleDeletePopUpInventory()
                      : undefined
                  }
                  disabled={this.state.selectedDeleteProductIds.length === 0}
                >
                  Delete Products
                </Button>
                </Box>
              </DeleteProductHeader>
              <CustomStyledTableUpdateInventory data-test-id="delete-product-table">
                <Table stickyHeader size="small" className="custom-table">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#f0f0f0" }}
                      className="as-table-head-row"
                    >
                      {this.state.tableHeadName.map(
                        (header: string, index: number) => (
                          <TableHeaderCell
                            key={index}
                            className="delete-product-table-head-cell"
                          >
                            {header === "Select Products" ? (
                              <ASCheckTableHeadBox>
                                <CheckboxContainer
                                  color="secondary"
                                  data-test-id={`select-all-product-delete`}
                                  checked={
                                    this.state.isAllSelectedDeleteProduct
                                  }
                                  onChange={() =>
                                    this.handleAllProductDeleteCheckboxChange()
                                  }
                                />
                                <Typography className="table-head-title">
                                  {header}
                                </Typography>
                              </ASCheckTableHeadBox>
                            ) : (
                              <Typography className="table-head-title">
                                {header}
                              </Typography>
                            )}
                          </TableHeaderCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.isLoading ? (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Loader
                            id="delete-product-loader"
                            dataTestId="delete-product-loader-test"
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      this.renderDeleteProductsBody()
                    )}
                  </TableBody>
                </Table>
              </CustomStyledTableUpdateInventory>
              {this.state.deleteInventoryMeta.total_record > 10 &&
                this.renderDeleteProductsPagination()}
            </SellerStoreBodyContainer>
          </Grid>
        </Grid>
        <DialogDeleteContainer
          data-test-id="delete-pop-up-dialog"
          open={this.state.isDeleteOpen}
        >
          <Box className="cross-icon-container">
            <img
              src={crossIcon}
              onClick={() => this.handleDeletePopUpInventory()}
              alt="cross-icon"
              data-test-id="cross-icon-test"
            />
          </Box>
          <Box className="content-container">
            <Typography className="content-title">Delete Products</Typography>
            <Typography className="content-description">
              All the selected products will be deleted by using bulk actions.
            </Typography>
            <Typography className="content-description">
              Are you sure you want to delete all the products?
            </Typography>
            <ButtonContainer>
              <Button
                variant="contained"
                className={"delete-btn"}
                disableElevation
                onClick={() => this.handleDeleteProduct()}
                data-test-id="delete-test-id"
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                className={"cancel-btn"}
                disableElevation
                data-test-id="cancel-test-id"
                onClick={() => this.handleDeletePopUpInventory()}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </Box>
        </DialogDeleteContainer>
        <WarningDialogContainer
          data-test-id="delete-warning-dialog"
          open={this.state.warningOpen}
        >
          <Box className="content-container">
            <Typography id="delete-title-1" className="text-container">
              You have unsaved changes.
            </Typography>
            <Typography id="delete-title-2" className="text-container">
              Are you sure you want to leave this page?
            </Typography>
            <Typography id="delete-title-3" className="text-container">
              If <span className="success-color">No</span> Please click on
              Delete Products to Delete Products Otherwise{" "}
              <span className="error-color">Yes</span>
            </Typography>
          </Box>
          <BtnContainer className="btn-container">
            <Button
              variant="outlined"
              onClick={() => this.handleDeleteNo()}
              className={"no-btn"}
              disableElevation
              data-test-id="delete-warning-no-test-id"
            >
              No
            </Button>
            <Button
              variant="contained"
              className={"yes-btn"}
              disableElevation
              onClick={() => this.handleDeleteYes()}
              data-test-id="delete-warning-yes-test-id"
            >
              Yes
            </Button>
          </BtnContainer>
        </WarningDialogContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertDeleteSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const DeleteProductHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: 44,
  paddingBottom: 30,
  "@media(max-width:900px)": {
    flexDirection: "column",
    gap: 20,
    height: "max-content"
  },
  "& .left-container":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .main-title":{
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 24,
      fontWeight: 700,
      paddingLeft: 15
    },
    "& .secondary-title":{
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 16,
      fontWeight: 400,
      paddingLeft: 5,
      paddingTop: 3
    }
  },
  "& .right-container":{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
    "& .input-field":{
      border: "1px solid #CBD5E1",
      fontFamily: "Lato, sans-serif",
      background: "#FFFFFF",
      color: "#94A3B8",
      fontSize: "16px",
      fontWeight: 400,
      borderRadius: "2px",
      padding: "10px, 8px, 10px, 12px",
      width: "210px",
      height: 50,
    },
    "& .outline-btn-confirm": {
      textTransform: "capitalize",
      color: "#F87171",
      height: "100%",
      borderColor: "#F87171",
      padding: "8px 18.5px",
      width: "fit-content",
      "& .MuiButton-label": {
        fontSize: "18px",
        fontWeight: 400,
      },
    },
    "& .contained-btn-confirm": {
      textTransform: "capitalize",
      padding: "8px 18.5px",
      gap: "8px",
      height: "100%",
      color: "#fff",
      backgroundColor: "#F87171",
      width: "fit-content",
      borderRadius: "2px",
      "& .MuiButton-label": {
        fontSize: "18px",
        fontWeight: 600,
      },
    },
  }
});

const DialogDeleteContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: 620,
    "& .cross-icon-container": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .content-container": {
      padding: "0px 50px 50px 50px",
      "& .content-title": {
        fontFamily: "Lato, sans-serif",
        color: "#375280",
        fontSize: 30,
        fontWeight: 500,
        textAlign: "center",
        paddingBottom: 30,
      },
      "& .content-description": {
        fontFamily: "Lato, sans-serif",
        color: "#375280",
        fontSize: 18,
        fontWeight: 400,
        textAlign: "center",
        paddingBottom: 10,
      },
    },
  },
});
// Customizable Area End
