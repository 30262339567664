import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import moment from 'moment';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface CouponCatalogue {
  id: number;
  coupon_code_id: number;
  catalogue_variant_id: number;
  created_at: string;
  updated_at: string;
}
interface CouponData {
  id: string;
  type: string;
  attributes: {
    id: number;
    code: string;
    isActive: boolean;
    discount_type: string;
    discount: number;
    valid_from: string;
    valid_to: string;
    min_cart_value: number;
    applicable_for: string;
    discount_cap: number;
    coupon_catalogues: CouponCatalogue[];
    coupon_business_informations: [];
  };
}
export interface FormError {
  [key: string]: string;
}
export interface FormErrorTouched {
  [key: string]: boolean;
}
type NullableDate = Date | string | null;
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: string;
  profileName: string;
  discountType: string;
  discountPercentage: string | number;
  maxCap: string | number;
  createPromoCode: string;
  minimumOrderValue: string | number;
  startDate: NullableDate;
  endDate: NullableDate;
  errors: FormError;
  touched: FormErrorTouched;
  isPickerOpen: boolean;
  isPickerOpen1: boolean;
  startNewDate: string | undefined;
  endNewDate: string | undefined;
  value: number;
  selectedIndices: string,
  indicateError: boolean,
  visible: boolean,
  editeIdData: number,
  editeFromData: CouponData | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerOffersStoreFormWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCancelOrderCallId: string = "";
  getStoreFromCallId: string = "";
  getEditeFromCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      discountType: '',
      editeFromData: null,
      activeTab: 'new_users',
      visible: false,
      profileName: "",
      discountPercentage: "",
      maxCap: "",
      createPromoCode: "",
      selectedIndices: '',
      minimumOrderValue: "",
      startDate: null,
      endDate: null,
      errors: {},
      touched: {},
      isPickerOpen: false,
      isPickerOpen1: false,
      startNewDate: "",
      endNewDate: "",
      value: 1,
      indicateError: false,
      editeIdData: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const navigationParams = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    if (typeof navigationParams?.tabIndex === configJSON.numberMsg) {
      this.setState({ value: navigationParams.tabIndex })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStoreFromCallId !== null &&
      this.getStoreFromCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSize = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonSize.data) {
        this.setState({ visible: true })
        await Promise.all(
          ['discountId', 'selectedIndices'].map(async (value: string) => {
            await removeStorageData(value);
          })
        );
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getEditeFromCallId !== null &&
      this.getEditeFromCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSize = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonSize && responseJsonSize.data) {
        const editeFromData: CouponData = responseJsonSize.data;
        this.setState({
          editeFromData,
          discountPercentage: Math.floor(Number(editeFromData.attributes.discount.toString()) || 0),
          maxCap: Math.floor(Number(editeFromData.attributes.discount_cap.toString()) || 0),
          createPromoCode: editeFromData.attributes.code,
          minimumOrderValue: Math.floor(Number(editeFromData.attributes.min_cart_value.toString()) || 0),
          startDate: moment(editeFromData.attributes.valid_from).format('ddd, DD MMM YYYY'),
          endDate: moment(editeFromData.attributes.valid_to).format('ddd, DD MMM YYYY'),
          activeTab: editeFromData.attributes.applicable_for,
          startNewDate: moment(editeFromData.attributes.valid_from).format('DD MMM YYYY'),
          endNewDate: moment(editeFromData.attributes.valid_to).format('DD MMM YYYY'),
          discountType: editeFromData.attributes.discount_type
        });
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    const selectedId = await getStorageData("discountId")
    const selectedIndices = await getStorageData("selectedIndices")
    const startNewDate = new Date()
    const momentDate = startNewDate ? moment(startNewDate) : null;
    const dateNew = momentDate?.format('DD MMM YYYY')
    this.getEditDateFrom()
    this.setState({ startNewDate: dateNew, endNewDate: dateNew, selectedIndices: selectedIndices, editeIdData: selectedId })
  }
  handlePickerOpen1 = () => {
    this.setState({ isPickerOpen1: true });
  };
  handlePickerClose1 = () => {
    this.setState({ isPickerOpen1: false });
  };
  handlePickerOpen = () => {
    this.setState({ isPickerOpen: true });
  };
  handlePickerClose = () => {
    this.setState({ isPickerOpen: false });
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let updatedValue: string | number = value;
      if (['discountPercentage', 'maxCap', 'minimumOrderValue'].includes(name)) {
        const numberValue = parseFloat(value);
        updatedValue = !isNaN(numberValue) ? numberValue : '';
      }
      return {
        ...prevState,
        [name]: updatedValue,
        errors: {
          ...prevState.errors,
          [name]: ''
        },
        touched: {
          ...prevState.touched,
          [name]: true
        }
      };
    });
  };
  sellerOffersDiscountsCreate = async () => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellerOffersDiscountsCreateWebPage");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    await Promise.all(['discountId', 'selectedIndices'].map(async (value: string) => { await removeStorageData(value); }));
  }
  sellerTermsConditions = () => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  handleCancel = () => {
    this.setState({ visible: false });
    this.sellerOffersDiscountsCreate()
  };
  sellerSelectProductsCreate = () => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellerSelectProductsWebPage");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const storeId = await getStorageData("store-id")
    const { discountPercentage, maxCap, createPromoCode, minimumOrderValue, startDate, endDate } = this.state;
    const errors: FormError = {};
    const touched: FormErrorTouched = {};
    if (!discountPercentage) {
      errors.discountPercentage = '*Please enter discount percentage';
      touched.discountPercentage = true;
    }
    if (!maxCap) {
      errors.maxCap = '*Please enter max cap';
      touched.maxCap = true;
    }
    if (!createPromoCode) {
      errors.createPromoCode = ' ';
      touched.createPromoCode = true;
    }
    if (!minimumOrderValue) {
      errors.minimumOrderValue = '*Please enter min order value';
      touched.minimumOrderValue = true;
    }
    if (!startDate) {
      errors.startDate = '*Please select start date';
      touched.startDate = true;
    }
    if (!endDate) {
      errors.endDate = '*Please select end date';
      touched.endDate = true;
    }
    this.setState({ errors, touched, indicateError: this.state.selectedIndices === null ? true : false });
    const httpBody = {
      "data": {
        "code": createPromoCode,
        "discount_type": this.state.value === 2 ? "Products" : "Store",
        "discount": discountPercentage,
        "valid_from": startDate,
        "valid_to": endDate,
        "min_cart_value": minimumOrderValue,
        "discount_cap": maxCap,
        "applicable_for": this.state.activeTab,
        "catalogue_variants_ids": this.state.selectedIndices === null ? "" : this.state.selectedIndices.split(',').map(Number),
        "bussiness_information_id": Number(storeId)
      }
    }

    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStoreFromCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.editeIdData ? configJSON.validationApiMethodPatchType :
        configJSON.exampleAPiMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.editeIdData
        ? `${configJSON.getStoreIdDate}/${this.state.editeIdData}`
        : `${configJSON.getStoreIdDate}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  handleTabChange = (tabs: string) => {
    this.setState({ activeTab: tabs });
  }

  handlesatrtDateChange = (date: Date | null) => {
    const momentDate = date ? moment(date) : moment();
    const dateNew = momentDate.format('ddd, DD MMM YYYY')
    const newDateSelect = momentDate.format('DD MMM YYYY')
    this.setState({ startDate: dateNew, startNewDate: newDateSelect, isPickerOpen1: false });
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        startDate: ''
      },
      touched: {
        ...prevState.touched,
        startDate: true
      }
    }));
  };
  handleEndDateChange = (date: Date | null) => {
    const momentDate = date ? moment(date) : moment();
    const dateNew = momentDate && momentDate.format('ddd, DD MMM YYYY')
    const newEndDate = momentDate && momentDate.format('DD MMM YYYY')
    this.setState({ endDate: dateNew, endNewDate: newEndDate, isPickerOpen: false });
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        endDate: ''
      },
      touched: {
        ...prevState.touched,
        endDate: true
      }
    }));
  };
  getEditDateFrom = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEditeFromCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStoreIdDate}/${this.state.editeIdData}`

    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  // Customizable Area End
}
