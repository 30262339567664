import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { MetaResponse } from "../../Catalogues/SellerCataloguesController";
import {
  IInventoryPaginationResponse,
  InventoryState,
} from "../UpdateInventory/UpdateInventoryController";
import {
  ExtractedInventoryItem,
  inventoryAssignStoreTableHead,
} from "../AssignStore/InventoryAssignStoreController";
import { apiCall } from "../../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { logoutSellerNavigation } from "../../../../../../components/src/Seller/logOut";
import { ErrorMessageResponse } from "../../../SellerStoreController";
import {
  BulkActionErrorResp,
  BulkActionSuccessResp,
} from "./BulkActionController";
// Customizable Area End

export const configJSON = require("../../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableHeadName: string[];
  selectedDeleteProductIds: number[];
  isAllSelectedDeleteProduct: boolean;
  responseInventoryData: InventoryState[];
  tableBodyData: ExtractedInventoryItem[];
  page: number;
  deleteInventoryMeta: MetaResponse;
  responseBodyData: ExtractedInventoryItem[];
  search: string;
  warningOpen: boolean;
  newPage: number;
  newSearch: string;
  isAlert: boolean;
  severity: "error" | "success";
  message: string;
  isLoading: boolean;
  isDeleteOpen: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DeleteProductsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private debounceSearchTimeout: NodeJS.Timeout | null = null;
  getListInventoryAPICallId: string = "";
  deleteProductsAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableHeadName: inventoryAssignStoreTableHead,
      selectedDeleteProductIds: [],
      isAllSelectedDeleteProduct: false,
      responseInventoryData: [],
      search: "",
      tableBodyData: [],
      page: 1,
      deleteInventoryMeta: {} as MetaResponse,
      responseBodyData: [],
      warningOpen: false,
      newSearch: "",
      newPage: 1,
      isAlert: false,
      message: "",
      severity: "success",
      isLoading: true,
      isDeleteOpen: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleSearchInventory = this.handleSearchInventory.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseDeleteProductData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseDeleteProductData &&
        !responseDeleteProductData.errors &&
        !responseDeleteProductData.error
      ) {
        this.apiDeleteInventorySuccess(
          apiRequestCallId,
          responseDeleteProductData
        );
      } else if (
        responseDeleteProductData &&
        (responseDeleteProductData.error || responseDeleteProductData.errors)
      ) {
        this.apiDeleteInventoryFailer(responseDeleteProductData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  debounceTimeSearch(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceSearchTimeout) {
        clearTimeout(this.debounceSearchTimeout);
      }
      this.debounceSearchTimeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchDeleteDataBySearchValue("", 1);
    // Customizable Area End
  }

  apiDeleteInventorySuccess = async (
    apiRequestCallId: string,
    responseDeleteJson: IInventoryPaginationResponse & BulkActionSuccessResp
  ) => {
    if (apiRequestCallId === this.getListInventoryAPICallId) {
      if (responseDeleteJson)
        this.handleDeleteInventoryResp(responseDeleteJson);
    } else if (apiRequestCallId === this.deleteProductsAPICallId) {
      await this.handleDeleteSuccessResp(responseDeleteJson)
    }
  };

  apiDeleteInventoryFailer = (
    responseDelErrJson: BulkActionErrorResp & ErrorMessageResponse
  ) => {
    if (responseDelErrJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        responseBodyData: [],
        isLoading: false,
        tableBodyData: [],
        assignInventoryMeta: {} as MetaResponse,
      }));
    } else if (responseDelErrJson.errors[0].token) {
      this.setState(
        (prevState) => ({
          ...prevState,
          isAlert: true,
          message: responseDelErrJson.errors[0].token as string,
          severity: "error",
          isLoading: false,
        }),
        () => {
          setTimeout(() => {
            this.handleRedirect("Home");
            logoutSellerNavigation();
          }, 2000);
        }
      );
    } else if (responseDelErrJson.errors[0].message) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        message: responseDelErrJson.errors[0].message as string,
        severity: "error",
        isLoading: false,
      }));
    }
  };

  handleRedirect = (redirect: string) => {
    const NavigateMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    NavigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    if (redirect === "SellerInventoryManagement") {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        sellerInventoryManagePageTabValue: 4,
      });
      NavigateMsg.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
    }
    this.send(NavigateMsg);
  };
  
  handleDeleteSuccessResp = async (response: BulkActionSuccessResp) =>{
    if (response.success) {
      this.setState((prevState) => {
        const { page } = prevState;
        const { total_pages } =
          prevState.deleteInventoryMeta || ({} as MetaResponse);
  
        let nextPage = page;
  
        if (total_pages) {
          if (page === total_pages) {
            nextPage = page - 1;
          }
  
          if (nextPage < 1) {
            nextPage = 1;
          }
        }
  
        return {
          ...prevState,
          isAlert: true,
          severity: "success",
          message: response.success[0].message as string,
          isDeleteOpen: !prevState.isDeleteOpen,
          selectedDeleteProductIds: [],
          page: nextPage,
        };
      });
      await this.fetchDeleteDataBySearchValue(
        this.state.search,
        this.state.page
      );
    }
  }  

  onAlertDeleteSnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  handleDeleteInventoryResp = (response: IInventoryPaginationResponse) => {
    this.setState((prevState) => ({
      ...prevState,
      responseInventoryData: response.data,
      responseBodyData: this.extractDeleteInventoryData(response.data),
      isLoading: false,
      tableBodyData: this.extractDeleteInventoryData(response.data),
      deleteInventoryMeta: response.meta,
    }));
  };

  fetchDeleteDataBySearchValue = async (search: string, page: number) => {
    this.setState((prevState) => ({ ...prevState, page, isLoading: true, search: search }));

    const storeId = +(await this.getDataStorage("store-id"));
    this.getListInventoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: `${configJSON.getInventoryListEndPoint}search=${search}&store_id=${storeId}&per_page=10&page=${page}`,
      method: configJSON.httpGetMethod,
      token: await this.getDataStorage("auth-token"),
    });
  };

  getDataStorage = async (value: string) => {
    return await getStorageData(value);
  };

  handleDeleteInventoryPageChange = async (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    if (this.state.selectedDeleteProductIds.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        newPage: newPage,
        warningOpen: !prevState.warningOpen,
      }));
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      page: newPage,
      newPage: newPage
    }));
    this.fetchDeleteDataBySearchValue(this.state.newSearch, newPage)
  };

  handleProductCheckboxChange = (id: number, isSelected: boolean) => {
    this.setState((prevState) => {
      const updatedTableBodyData = prevState.tableBodyData.map((product) =>
        product.id === id ? { ...product, isSelected } : product
      );

      const updatedSelectedProductIds = isSelected
        ? [...prevState.selectedDeleteProductIds, id]
        : prevState.selectedDeleteProductIds.filter(
            (productId) => productId !== id
          );

      return {
        tableBodyData: updatedTableBodyData,
        selectedDeleteProductIds: updatedSelectedProductIds,
        isAllSelectedDeleteProduct:
          updatedSelectedProductIds.length === prevState.tableBodyData.length &&
          updatedSelectedProductIds.length !== 0,
      };
    });
  };

  handleAllProductDeleteCheckboxChange = () => {
    this.setState((prevState) => {
      const isAllSelected = !prevState.isAllSelectedDeleteProduct;
      const selectedProductIds = isAllSelected
        ? this.getAllDeleteProductIds()
        : [];

      const updatedTableBodyData = prevState.tableBodyData.map((item) => ({
        ...item,
        isSelected: isAllSelected,
      }));
      return {
        ...prevState,
        isAllSelectedDeleteProduct: isAllSelected,
        selectedDeleteProductIds: selectedProductIds,
        tableBodyData: updatedTableBodyData,
      };
    });
  };

  getAllDeleteProductIds = () => {
    return this.state.tableBodyData.map((product) => product.id);
  };

  extractDeleteInventoryData = (
    deleteItems: InventoryState[]
  ): ExtractedInventoryItem[] => {
    return deleteItems.map((item) => {
      const { id, is_listed, sku, product_name, front_image } = item.attributes;
      return {
        id,
        product_name,
        front_image,
        is_listed,
        sku,
        isSelected: false,
      };
    });
  };

  handleDeletePopUpInventory = () => {
    this.setState((prevState) => ({
      ...prevState,
      isDeleteOpen: !prevState.isDeleteOpen,
    }));
  };

  handleSearchInventory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    const { selectedDeleteProductIds, page } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      search: query,
      newSearch: query
    }));
    if (selectedDeleteProductIds.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        warningOpen: !prevState.warningOpen,
        search: "",
        newSearch: query
      }));
      return;
    }
    this.debounceTimeSearch(
      () => this.fetchDeleteDataBySearchValue(query, 1),
      800
    )();
  };

  handleDeleteProduct = async () => {
    const body = {
      catalogue_variant_ids: this.state.selectedDeleteProductIds,
    };
    this.deleteProductsAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpDeleteMethod,
      endPoint: configJSON.bulkDeleteProductEndPoint,
      token: await this.getDataStorage("auth-token"),
      body: body,
      isJsonStringify: true,
    });
  };

  handleDeleteYes = async () => {
    this.setState((prevState) => ({
      ...prevState,
      page: prevState.newPage,
      warningOpen: !prevState.warningOpen,
      selectedDeleteProductIds: [],
      isAllSelectedDeleteProduct: false,
      search: prevState.newSearch
    }));
    if(this.state.search !== this.state.newSearch){
      await this.fetchDeleteDataBySearchValue(
        this.state.newSearch,
        1
      );
      this.setState((prevState) => ({
        ...prevState,
        page: 1,
        newPage: 1,
      }));
    }else{
    await this.fetchDeleteDataBySearchValue(
      this.state.newSearch,
      this.state.newPage
    );
  }
  };

  handleDeleteNo = () => {
    this.setState((prevState) => ({
      ...prevState,
      warningOpen: !prevState.warningOpen,
    }));
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
