import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
interface IStylishClientBtn{
  id: number;
  btnTitle: string;
  isRedirect: boolean;
  redirectComponentName: string;
}

export interface IStylistRequestResponse{
    id: number;
    requestId: string;
    requestTime: string;
    requesterName: string;
    requesterGender: string;
    budget: string;
    requestStatus: string;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isAlert: boolean;
  message: string;
  severity: "error" | "success" | "info";
  stylishClientBtnData: IStylishClientBtn[];
  tableHeadName: string[];
  tableBody: IStylistRequestResponse[];
  isSROpen: boolean;
  isAccepted: boolean;
  isRequestOpen: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylishClientsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      isAlert: false,
      message: "",
      severity: "info",
      stylishClientBtnData: StylishClientBtnData,
      tableHeadName: latestRequestTableHead,
      isSROpen: false,
      isAccepted: true,
      isRequestOpen: false,
      tableBody: latestRequestTableBody
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  handleClientChat = () => {
    this.setState((prevState) => ({
      ...prevState,
      message: "This screen is under development",
      severity: "info",
      isAlert: true,
    }));
  };

  handleStylishClientsRedirect = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  onAlertSCSnackClose = () => {
    this.setState((prevState) => ({ ...prevState, isAlert: false }));
  };

  handleRequestClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isRequestOpen: !prevState.isRequestOpen,
    }));
  };

  handleReject = () => {
    this.setState(
      (prevState) => ({ ...prevState, isAccepted: false, isSROpen: true}),
      () => {
        setTimeout(() => this.handleClose(), 2000);
      }
    );
  };

  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isRequestOpen: false,
      isSROpen: false,
    }));
  };

  handleAccept = () => {
    this.setState(
      (prevState) => ({ ...prevState, isSROpen: true, isAccepted: true }),
      () => {
        setTimeout(() => this.handleClose(), 2000);
      }
    );
  };
  // Customizable Area End
}
// Customizable Area Start
const StylishClientBtnData = [
  {
    id: 1,
    btnTitle: "My Clients",
    isRedirect: true,
    redirectComponentName: "StylishMyClients",
  },
  {
    id: 2,
    btnTitle: "Styling Requests",
    isRedirect: true,
    redirectComponentName: "StylingRequests",
  },
  {
    id: 3,
    btnTitle: "Call Back Requests",
    isRedirect: false,
    redirectComponentName: "",
  },
  {
    id: 4,
    btnTitle: "Create Client Specific Wishlist",
    isRedirect: false,
    redirectComponentName: "",
  },
];

const latestRequestTableHead = [
  "Request ID",
  "Request Time",
  "Name & Gender",
  "Budget",
  "Status",
  "Action"
];

const latestRequestTableBody = [
  {
    id: 1,
    requestId: "#4564-4511",
    requestTime: "3:30 PM",
    requesterName: "Emilia Clark",
    requesterGender: "Female",
    budget: "$50 - $150",
    requestStatus: "New Request"
  },
  {
    id: 2,
    requestId: "#4564-4512",
    requestTime: "3:32 PM",
    requesterName: "Armonion",
    requesterGender: "Male",
    budget: "$30 - $90",
    requestStatus: "New Request"
  },
  {
    id: 3,
    requestId: "#4564-4513",
    requestTime: "3:45 PM",
    requesterName: "Armando",
    requesterGender: "Male",
    budget: "$80 - $120",
    requestStatus: "New Request"
  }
]
// Customizable Area End
