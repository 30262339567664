import React from "react";
// Customizable Area Start
import {Typography, Grid, Box, Card } from "@material-ui/core";
// Customizable Area End

import TrendingPageController, { Props,configJSON } from "./TrendingPageController";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { noImage } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CircularProgress from '@material-ui/core/CircularProgress';


export default class TrendingListPage extends TrendingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            <>
                <Header navigation={this.props.navigation} />
                <Snackbar
                    open={this.state.isAlert} 
                     autoHideDuration={3000}
                    anchorOrigin={{ horizontal:  'right', vertical: 'top' }}       
                     onClose={this.oncloseAlert} 
                    data-test-id="alertTestId" 
                    >
                      <Alert severity={this.state.alertType}>
                        {this.state.alertMsg}</Alert> 
                    </Snackbar>
                    <Box>
                    <Box style={{
                        maxHeight: "680px",
                        overflowY: "scroll"
                    }} data-test-id="handleScroll" id="scrollableDiv">
                    <Grid container
                    spacing={0}
                        style={webStyles.heraderText}>
                        <Typography style={webStyles.contentText}>
                            <Box style={webStyles.homeBox}>
                                <h2 style={webStyles.text2}>{configJSON.trendingHeadingText}</h2>
                            </Box>
                        </Typography>
                        <Grid container spacing={1} style={webStyles.gridConter}>
                            {this.state.trendgingData &&
                                this.state.trendgingData.map((cardDetail, index) => (
                                    <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={index} style={webStyles.trendingGrid}>
                                        <Card data-testId="trendingCart" style={webStyles.cardContainer}>
                                            <Box style={{ position: 'relative' }}>
                                                <img
                                                    src={cardDetail?.attributes?.primary_image === null ? noImage : cardDetail?.attributes?.primary_image}
                                                    alt={`Image ${index + 1}`}
                                                    style={webStyles.imgTag}
                                                    data-test-id = {`navigateToDetail-${index}`}
                                                    onClick={this.navigateToDeatils.bind(this,{ id: cardDetail.id, screenName: "Productdescription3" })}
                                                />
                                                <Box style={webStyles.productIcon1 }>
                                                    <Box style={webStyles.productDiscount1}>{configJSON.offerPercentage}</Box>
                                                    <Box style={webStyles.productFavorite1} data-test-id={`handleLikeClick-${index}`} onClick={()=>this.handleFavouriteApi(cardDetail?.id, cardDetail.attributes?.is_wishlist)} >
                                                        {cardDetail.attributes?.is_wishlist ? (
                                                   <FavoriteIcon width="18px" height="18px" style={{ fill: 'blue' }}/>
                                                   ):(<FavoriteBorderIcon color="secondary"  style={{ fill: 'blue' }}/>)
                                                       }</Box>

                                                </Box>
                                            </Box>
                                            <Box style={{ padding: '24px' }}>
                                                <Box style={webStyles.productData1}>
                                                    <Box style={webStyles.productValue1}>
                                                        <Typography style={webStyles.productName1} >{cardDetail?.attributes?.name}</Typography>
                                                        <Typography style={webStyles.productDescription1} >{cardDetail?.attributes?.description}</Typography>
                                                    </Box>
                                                    <Box style={webStyles.acountMrp1}>{`$ ${cardDetail?.attributes?.primary_price}`}</Box>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                                        {this.state.circularProgress && <Box style={{ width: '100%', textAlign: 'center', paddingBottom: '20px' }}><CircularProgress style={{ color: '#375280' }} /></Box>}

                        </Grid>
                    </Grid>
                        
                        </Box> 
                    <Box>
                           <Footer navigation={this.props.navigation}/>
                        </Box>
                    </Box>

            </>
        );
    }
    
}

// Customizable Area Start
const webStyles = {
    homeBox: { display: 'flex', alignItems: 'center', flexDirection: "column" as "column", fontFamily: "Lato", padding: '17px 0px' },
    productIcon1: { top: '20px', left: '20px', display: "flex", justifyContent: 'space-between', right: '0',position: 'absolute' as 'absolute' },
    productFavorite1: { color: "#375280", marginRight: '30px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
    productDiscount1: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
    productData1: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', 
    textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' ,whiteSpace: 'nowrap' as 'nowrap', flexDirection: 'row' as 'row' },
    productValue1: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto',whiteSpace: 'nowrap' as 'nowrap', },
    productDescription1: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500,whiteSpace: 'nowrap' as 'nowrap', textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
    productName1: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", whiteSpace: 'nowrap' as 'nowrap',overflow: "hidden", width: '90%', fontFamily: "Lato" },
    acountMrp1: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', border: "1px solid #D5D5D5", padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
    contentText: {
        color: "#375280",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "30px",
        textAlign: "center" as "center",
        padding: "20px 0 0",
        fontFamily: "Lato"
    },
    text2: {
        textAlign: "center" as "center", fontFamily: "Lato", color: "#375280", marginTop: "20px"
    },
    heraderText:{  background: "#FFF", justifyContent: "center", alignItems: "center" },
    gridConter:{ padding: "30px", alignItems: "center",width:"85%"},
    imgTag:{
        width: "340px",
        height: "370px",
        objectFit: "cover" as "cover",
        position: 'relative' as 'relative', display: 'block', margin: 'auto'
    },
    cardContainer: {boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "340px", height: "455px",backgroundColor:"#f2efef" },
    trendingGrid:{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }


};
// Customizable Area End
