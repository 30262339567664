import React from "react";
// Customizable Area Start
import {
  Typography,
  Box,
  Button,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  TextField,
  Modal,
} from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import {
  createTheme,
  ThemeProvider,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaymentDetailsController, {
  Props,
} from "./PaymentDetailsController.web";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import CheckoutSteps from "./CheckoutSteps.web";
import RadioButton from "../../../components/src/RadioButton";
import {
  expressDeliveryIcon,
  standardDeliveryIcon,
  mastercardIcon,
  googlePayIcon,
  applePayIcon,
  debitCardIcon,
  modalSuccessImg,
  loyaltyBadgeIcon,
  Noimageavailable
} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
    },
  },
});

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface CustomSwitchProps extends SwitchProps {
  classes: Styles;
}
// Customizable Area End

export default class PaymentDetails extends PaymentDetailsController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  shippingOptions = [
    {
      value: "same",
      label: (
        <Typography style={webStyle.shippingLabel}>
          Same as Billing address
        </Typography>
      ),
    },
    {
      value: "change",
      label: (
        <Typography style={webStyle.shippingLabel}>
          Use a different shipping address
        </Typography>
      ),
    },
  ];

  shippingMethodOptions = [
    {
      value: "standart",
      label: (
        <Box style={webStyle.flex}>
          <img src={standardDeliveryIcon} height={28} />
          <Typography style={webStyle.shippingLabel}>
            Standard Delivery
          </Typography>
        </Box>
      ),
      price: "$10.00",
      time: "90 min",
    },
    {
      value: "express",
      label: (
        <Box style={webStyle.flex}>
          <img src={expressDeliveryIcon} height={28} />
          <Typography style={webStyle.shippingLabel}>
            Express Delivery
          </Typography>
        </Box>
      ),
      price: "$20.00",
      time: "30 min",
    },
  ];

  savedPayment = [
    {
      value: "mc-745",
      label: (
        <Box style={{ display: "flex", gap: "1rem " }}>
          <img src={mastercardIcon} height={24} />
          <Typography style={webStyle.savedMethodData}>
            **** **** **** *745
          </Typography>
          ,
        </Box>
      ),
      type: "mastercard",
    },
  ];

  addCardForm = (activeCard: string) => (
    <Box style={webStyle.cardInfoBlock}>
      <Box>
        <Typography style={webStyle.inputLabel}>Name on card</Typography>
        <TextField
          variant="filled"
          InputProps={{ disableUnderline: true, style: webStyle.input }}
          inputProps={{ style: { ...webStyle.input, margin: 0 } }}
          fullWidth
        />
      </Box>
      <Box>
        <Typography style={webStyle.inputLabel}>Card number</Typography>
        <TextField
          variant="filled"
          InputProps={{ disableUnderline: true, style: webStyle.input }}
          inputProps={{ style: { ...webStyle.input, margin: 0 } }}
          fullWidth
          value={this.state.cardNumber}
          onChange={(event) => this.creditCardInputOnChange(event.target.value)}
          data-test-id="card-input"
        />
      </Box>
      <Box>
        <Typography style={webStyle.inputLabel}>Expire Date</Typography>
        <TextField
          variant="filled"
          InputProps={{ disableUnderline: true, style: webStyle.input }}
          inputProps={{ style: { ...webStyle.input, margin: 0 } }}
          fullWidth
        />
      </Box>
      <Box>
        <Typography style={webStyle.inputLabel}>CVV</Typography>
        <TextField
          variant="filled"
          InputProps={{ disableUnderline: true, style: webStyle.input }}
          inputProps={{ style: { ...webStyle.input, margin: 0 } }}
          fullWidth
          value={this.state.cvv}
          onChange={(event) => this.digitsValidation(event.target.value)}
          data-test-id="cvv-input"
        />
      </Box>
      <FormControl>
        <FormControlLabelNoMargin
          control={
            <IOSSwitch
              name="saveCard"
              defaultChecked={this.state.saveCard}
              onChange={this.saveCardChange}
            />
          }
          label={
            <Typography style={{ fontWeight: 500 }}>
              Save this card for future payment
            </Typography>
          }
          style={webStyle.switchControl}
        />
      </FormControl>
      <Button style={webStyle.addCardBtn}>Add Card</Button>
    </Box>
  );

  paymentMethods = [
    {
      value: "creditCard",
      label: (
        <Box>
          <Typography style={webStyle.paymentLabel}>Credit Card</Typography>
          <Typography style={{ color: "#3C4242" }}>
            We accept all major credit cards.
          </Typography>
        </Box>
      ),
      subText: (
        <Box style={{ ...webStyle.savedPaymentItem, ...webStyle.debitCardAdd }}>
        <Box style={{ ...webStyle.flex, gap: "1rem" }}>
          <img src={debitCardIcon} height={24} />
          <Typography style={webStyle.savedMethodData}>
            Add credit card
          </Typography>
        </Box>
        <Typography style={webStyle.addDebitBtn} onClick={() => this.changeActiveAddCardForm("creditCard")}>ADD</Typography>
      </Box>
      ),
    },
    {
      value: "debitCard",
      label: (
        <Box>
          <Typography style={webStyle.paymentLabel}>Debit Card</Typography>
          <Typography style={{ color: "#3C4242" }}>
            We accept all major Debit Cards.
          </Typography>
        </Box>
      ),
      subText: (
        <Box style={{ ...webStyle.savedPaymentItem, ...webStyle.debitCardAdd }}>
          <Box style={{ ...webStyle.flex, gap: "1rem" }}>
            <img src={debitCardIcon} height={24} />
            <Typography style={webStyle.savedMethodData}>
              Add debit card
            </Typography>
          </Box>
          <Typography style={webStyle.addDebitBtn} onClick={() => this.changeActiveAddCardForm("debitCard")}>ADD</Typography>
        </Box>
      ),
    },
    {
      value: "googlePay",
      label: (
        <Box style={webStyle.spaceBetween}>
          <Typography style={webStyle.paymentLabel}>Google Pay</Typography>
          <img src={googlePayIcon} height={20} />
        </Box>
      ),
    },
    {
      value: "applePay",
      label: (
        <Box style={webStyle.spaceBetween}>
          <Typography style={webStyle.paymentLabel}>Apple Pay</Typography>
          <img src={applePayIcon} height={23} />
        </Box>
      ),
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Header navigation={this.props.navigation} />
        <CheckoutSteps activeBread="payment" />
        <Box style={webStyle.mainDiv}>
          <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
            <Typography style={{ ...webStyle.subTitle, fontSize: "1.5rem" }}>
              Shipping Information
            </Typography>
            <Box style={webStyle.billingBlock}>
              <Box style={webStyle.addressMain}>
                <Box>
                  <Typography
                    style={{
                      ...webStyle.addressHead,
                      ...webStyle.addressData,
                      textAlign: "center",
                    }}
                  >
                    {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ ...webStyle.addressHead, ...webStyle.addressData }}
                  >
                    {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.contact_number}
                  </Typography>
                </Box>
                <Typography data-test-id="proceedToShippingAddressPage" onClick={this.proceedToShippingAddressPage} style={webStyle.addressContextMenu}>Edit</Typography>
              </Box>
              <Box>
                <Typography style={webStyle.fullAddress}>
                {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.house_or_building_number}, {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.street}, {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.area}
                </Typography>
                <Typography style={webStyle.fullAddress}>
                {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.city} - {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.zip_code}
                </Typography>
              </Box>
            </Box>
            <Typography style={webStyle.subTitle}>Shipping Method</Typography>
            <Box style={webStyle.shippingBlock}>
              <FormControl fullWidth>
                <RadioGroup
                  name="shipping-selection"
                  value={this.state.shippingChoice}
                  data-test-id="group-shipping"
                >
                  {this.shippingMethodOptions.map((item, number) => (
                    <React.Fragment key={item.value}>
                      <FormControlLabelNoMargin
                        key={item.value}
                        name={item.value}
                        control={
                          <StyledCheckbox
                            checked={this.state.shippingMethod === item.value}
                          />
                        }
                        label={item.label}
                        data-test-id={`radio-${item.value}`}
                        onChange={() =>
                          this.onChangeHandler("shippingMethod", item.value)
                        }
                        style={webStyle.methodForm}
                      />
                      <Divider
                        style={{
                          backgroundColor: "#D5D5D5",
                          margin: "1.25rem 0",
                        }}
                      />
                      <Box style={webStyle.spaceBetween}>
                        <Box>
                          <Typography
                            style={{
                              ...webStyle.shippingLabel,
                              paddingLeft: 0,
                            }}
                          >
                            Delivery Charges
                          </Typography>
                          <Typography style={webStyle.fullAddress}>
                            Estimated Delivery time
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              ...webStyle.shippingLabel,
                              paddingLeft: 0,
                              textAlign: "end",
                            }}
                          >
                            {item.price}
                          </Typography>
                          <Typography
                            style={{
                              ...webStyle.fullAddress,
                              textAlign: "end",
                            }}
                          >
                            {item.time}
                          </Typography>
                        </Box>
                      </Box>
                      {number !== this.shippingMethodOptions.length - 1 && (
                        <Divider
                          style={{
                            backgroundColor: "#BEBCBD",
                            margin: "2rem 0 1rem",
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Divider
              style={{ backgroundColor: "#DEDEDE", marginBottom: "1.5rem" }}
            />
            <Typography style={webStyle.subTitle}>Payment Method</Typography>
            <Typography style={{ color: "#94A3B8" }}>
              Select the address that matches your card or payment method.
            </Typography>
            <Box style={webStyle.shippingBlock}>
              <FormControl fullWidth>
                <RadioGroup
                  name="saved-payment"
                  value={this.state.paymentMethod}
                  data-test-id="group-saved-payment"
                  onChange={(_, value) =>
                    this.onChangeHandler("paymentMethod", value)
                  }
                >
                  {this.savedPayment.map((item) => (
                    <Box style={webStyle.savedPaymentItem} key={item.value}>
                      <FormControlLabelNoMargin
                        key={item.value}
                        value={item.value}
                        control={<RadioButton variant="solid" />}
                        label={item.label}
                        data-test-id={`radio-${item.value}`}
                      />
                      <Typography style={webStyle.removeButton}>
                        Remove
                      </Typography>
                    </Box>
                  ))}
                  {this.paymentMethods.map((item, number) => (
                    <>
                      <FormControlLabelFullWidth
                        key={item.value}
                        value={item.value}
                        control={<RadioButton variant="solid" />}
                        label={item.label}
                        data-test-id={`radio-${item.value}`}
                      />
                      {this.state.activeAddCardForm === item.value ? this.addCardForm(item.value) : item.subText}
                      {number !== this.paymentMethods.length - 1 && (
                        <Divider
                          style={{
                            backgroundColor: "#BEBCBD",
                            margin: "2rem 0 1rem",
                          }}
                        />
                      )}
                    </>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={12}
            xs={12}
            style={webStyle.summary}
          >
            <Box mt="0.5rem" mb="1rem">
              <Typography style={webStyle.summaryTitle}>
                Order Summary
              </Typography>
            </Box>
            <Divider style={{ borderColor: "#EDEEF2" }} />
            {this.state.ActiveCartView?.attributes?.order_items?.map((item, number) => (
            <Box style={{ ...webStyle.flex, gap: "1rem" }} my="1rem">
              <Box>
                <img
                  src={item.attributes.catalogue_variant_front_image ? item.attributes.catalogue_variant_front_image : Noimageavailable}
                  width={63}
                  height={63}
                  style={{ borderRadius: "4px" }}
                />
              </Box>
              <Box my="auto" width="100%">
                <Typography style={webStyle.itemName}>
                  {item.attributes.catalogue_name}{" "}
                  <span style={webStyle.fullAddress}>x {item.attributes.quantity}</span>
                </Typography>
                <Box style={webStyle.spaceBetween}>
                  <Typography style={webStyle.itemName}>
                    Color : <span style={webStyle.fullAddress}>{item.attributes.catalogue_variant_color}</span>
                  </Typography>
                  <Typography style={webStyle.fullAddress}>${item.attributes.total_price}</Typography>
                </Box>
              </Box>
            </Box>
              ))}
            <Divider style={{ borderColor: "#EDEEF2" }} />
            <Box mt="1rem" mb="2rem">
              <Box style={webStyle.spaceBetween} mb="0.75rem">
                <Typography style={webStyle.savedMethodData}>
                  Item total <span style={webStyle.SubtotalColor}>( {this.state.ActiveCartView?.attributes?.order_item_count} items )</span>
                </Typography>
                <Typography style={webStyle.savedMethodData}>${this.state.ActiveCartView?.attributes?.sub_total}</Typography>
              </Box>
              <Box style={webStyle.spaceBetween} mb="0.75rem">
                <Typography style={webStyle.savedMethodData}>
                  Shipping fee
                </Typography>
                <Typography style={webStyle.savedMethodData}>
                  ${this.state.ActiveCartView?.attributes?.shipping_charge}
                </Typography>
              </Box>
              <Box style={webStyle.spaceBetween} my="0.75rem">
                <Typography style={webStyle.savedMethodData}>
                  Total Tax
                </Typography>
                <Typography style={webStyle.savedMethodData}>${this.state.ActiveCartView?.attributes?.total_tax}</Typography>
              </Box>
              <Box style={webStyle.spaceBetween} my="0.75rem">
                <Typography style={{...webStyle.savedMethodData,color:'#01835c'}}>
                  Voucher
                </Typography>
                <Typography style={webStyle.savedMethodData}>-</Typography>
              </Box>
              <Divider style={{ borderColor: "#EDEEF2" }} />
              <Box style={webStyle.spaceBetween} my="0.75rem">
                <Typography style={webStyle.totalText}>Total Cost</Typography>
                <Typography style={webStyle.totalText}>${this.state.ActiveCartView?.attributes?.total}</Typography>
              </Box>
            </Box>
            <Button style={webStyle.placeOrderBtn} data-test-id="placeOrderApi" onClick={this.placeOrderApi}>Place Order</Button>
          </Grid>
        </Box>
        <Modal open={this.state.isModalOpen} style={webStyle.flex}>
          <Box style={webStyle.modalBox}>
            <img src={modalSuccessImg} />
            <Typography style={webStyle.modalHead}>Great!</Typography>
            <Typography style={{ fontSize: "1.5rem" }}>
              Your order has been placed successfully.
            </Typography>
            <Box style={webStyle.loyaltyPointsString}>
              <img src={loyaltyBadgeIcon} />
              <Typography style={{ fontSize: "1.375rem" }}>
                80 Loyalty points on the way.
              </Typography>
            </Box>
            <Button style={{ ...webStyle.placeOrderBtn2 }} data-test-id="proceedToTrackOrderPage" onClick={this.proceedToTrackOrderPage}>
              All Orders
            </Button>
          </Box>
        </Modal>
        <Footer navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  mainDiv: {
    display: "flex",
    padding: "0 2.25rem 5.25rem",
    backgroundColor: "#F8F8F8",
    gap: "2.5rem",
  },
  flex: { display: "flex" },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subTitle: { fontSize: "1.375rem", fontWeight: 700, marginBottom: "0.5rem" },
  billingBlock: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "1rem 1rem 1.5rem",
    margin: "1rem 0 2.5rem",
    borderRadius: "2px",
  },
  addressMain: { display: "flex", gap: "1.25rem" },
  addressName: { backgroundColor: "#F6F6F6", textAlign: "center" as "center" },
  addressHead: { color: "#94A3B8", fontWeight: 700, padding: "0.5rem 0" },
  addressData: { color: "#375280" },
  addressContextMenu: {
    color: "#375280",
    marginRight: "0",
    marginLeft: "auto",
    cursor: "pointer"
  },
  fullAddress: { color: "#94A3B8", fontSize: "0.875rem" },
  shippingBlock: {
    padding: "2.25rem 1.75rem",
    borderRadius: "2px",
    backgroundColor: "#FFF",
    margin: "1.5rem 0 2.5rem",
  },
  shippingLabel: {
    fontSize: "1.25rem",
    color: "#334155",
    fontWeight: 700,
    paddingLeft: "1rem",
  },
  methodForm: {
    flexDirection: "row-reverse" as "row-reverse",
    justifyContent: "space-between",
    marginLeft: 0,
    marginRight: 0,
  },
  methodLabel: {
    fontSize: "1.25rem",
    color: "#334155",
    fontWeight: 700,
  },
  savedPaymentItem: {
    margin: "2rem 1.5rem",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "2px",
    width: "-webkit-fill-available",
    padding: "1rem",
  },
  savedMethodData: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  removeButton: {
    color: "#DC2626",
    fontSize: "1.125rem",
    fontWeight: 500,
    marginTop: "0.5rem",
    cursor: "pointer",
  },
  paymentLabel: {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: "#3C4242",
  },
  SubtotalColor: {
    color: "94A3B8"
  },
  cardInfoBlock: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "2rem",
    margin: "2rem 0 1.5rem",
  },
  inputLabel: {
    fontWeight: 700,
  },
  input: {
    backgroundColor: "#F8F8F8",
    marginTop: "0.5rem",
    padding: "0.5rem",
    fontSize: "1.125rem",
    color: "#375280",
  },
  switchControl: {
    gap: "0.5rem",
    marginTop: "-2rem",
  },
  addCardBtn: {
    backgroundColor: "#C7B9AD",
    marginTop: "2rem",
    padding: "1rem 0",
    fontSize: "1.125rem",
    fontWeight: 800,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    textTransform: "none" as "none",
  },
  debitCardAdd: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 0",
    padding: "1.5rem 1rem",
  },
  addDebitBtn: {
    fontWeight: 600,
    textDecoration: "underline",
    textUnderlinePosition: "under",
    cursor: "pointer",
  },
  summary: {
    backgroundColor: "#FFF",
    borderRadius: "2px",
    padding: "1.5rem 1rem",
    height: "fit-content",
    boxShadow: "2px 2px 4px 0px #0000000D,-2px -2px 4px 0px #0000000D",
  },
  summaryTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
  },
  itemName: {
    fontSize: "0.875rem",
  },
  totalText: {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
  placeOrderBtn: {
    width: "100%",
    backgroundColor: "#C7B9AD",
    marginTop: "1rem",
    padding: "1rem 0",
    fontSize: "1.125rem",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    textTransform: "none" as "none",
  },
  placeOrderBtn2: {
    width: "75%",
    backgroundColor: "#C7B9AD",
    marginTop: "1rem",
    padding: "1rem 0",
    fontSize: "1.125rem",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    textTransform: "none" as "none",
  },
  modalBox: {
    background: "#fff",
    width: "30vw",
    margin: "auto",
    borderRadius: "2px",
    padding: "3rem",
    textAlign: "center" as "center",
  },
  modalHead: {
    fontSize: "1.875rem",
    fontWeight: 700,
    marginTop: "2.5rem",
    marginBottom: "0.5rem",
  },
  loyaltyPointsString: {
    display: "flex",
    alignItems: "center",
    margin: "0.5rem 0 1.5rem",
    justifyContent: "center",
    gap: "0.25rem",
  },
};

const StyledCheckbox = withStyles({
  root: {
    color: "#B2A69B",
  },
  checked: {
    color: "#CCBEB1 !important",
  },
})(Checkbox);

const FormControlLabelFullWidth = withStyles({
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    width: "100%",
  },
})(FormControlLabel);

const FormControlLabelNoMargin = withStyles({
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
})(FormControlLabel);

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "36px",
      height: "20px",
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: "1px",
      top: "1px",
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#C7B9AD",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#C7B9AD",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: "16px",
      height: "16px",
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: CustomSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
// Customizable Area End
