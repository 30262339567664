import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { pdf, jpg, doc } from "./assets"
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
import moment from 'moment';
type NullableDate = Date | string | null | undefined;
interface Order {
    id: number;
    account_id: number;
    amount: string | null;
    driver_order_id: number | null;
    status: string;
    created_at: string;
    updated_at: string;
    seller_order_id: number;
    order_number: string;
    catalogue_name: string | null;
}
interface EarningsData {
    [key: string]: {
        total_earnings: number;
    };
}
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    dataChart: EarningsData;
    nextpage: string,
    numberData: string,
    endDate: NullableDate;
    startDate: NullableDate;
    isPickerOpen: boolean;
    anchorEl: HTMLElement | null,
    downloadOptionsActive: { type: string, label: string }[];
    open: boolean;
    loading: boolean;
    withdrawMoneyOpen: boolean;
    account: string;
    pdfOpen: boolean;
    downloadOptions: { type: string, src: string, label: string }[];
    accountPdf: string;
    accountFilter: string;
    earningDetails: Order[];
    showDatePicker: boolean;
    walletAmount: number;
    selectLable: { label: string }[];
    tempFilter: string;
    lastValidFilter: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EarningsAnalyticsPageWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getproductGetCallId: string = "";
    getsellerEarningsCallId: string = "";
    getfiltersellerEarningsCallId: string = "";
    getearningsDownload: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.RestAPIRequestBodyMessage),
        ];
        this.state = {
            dataChart: {},
            nextpage: "",
            numberData: "all",
            showDatePicker: false,
            open: false,
            anchorEl: null,
            startDate: null,
            isPickerOpen: false,
            endDate: null,
            withdrawMoneyOpen: false,
            account: "",
            loading: true,
            pdfOpen: false,
            downloadOptions: [
                { type: 'pdf', src: pdf, label: 'PDF' },
                { type: 'docx', src: doc, label: 'Doc File' },
                { type: 'jpg', src: jpg, label: 'JPG' },
            ],
            accountPdf: "pdf",
            accountFilter: "this_month",
            earningDetails: [],
            walletAmount: 0,
            downloadOptionsActive: [
                { type: 'this_week', label: 'This Week' },
                { type: 'this_month', label: 'This Month' },
                { type: 'this_year', label: 'This Year' },
                { type: 'select_date_range', label: 'Select Date Range' },
            ],
            selectLable: [{ label: 'Week' }, { label: 'Month' }, { label: 'Year' }],
            tempFilter: 'this_month',
            lastValidFilter: 'this_month'
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.orderSellerEarnings(this.state.numberData)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getsellerEarningsCallId !== null &&
            this.getsellerEarningsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonsellerEarnings = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ earningDetails: responseJsonsellerEarnings && responseJsonsellerEarnings.earning_details, loading: false }, () => this.filterCartSellerEarnings())
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getfiltersellerEarningsCallId !== null &&
            this.getfiltersellerEarningsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonsellerEarnings = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ dataChart: responseJsonsellerEarnings && responseJsonsellerEarnings.data, loading: false })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleOpenPdf = () => {
        this.setState({ pdfOpen: true });
    };

    handleClosePdf = () => {
        this.setState({ pdfOpen: false });
    }
    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };
    handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handlePdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState({ accountPdf: value });
    }
    handlefilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        this.setState({
            accountFilter: value,
        });
    }

    filterSellerEarnings = () => {
        this.setState({ tempFilter: this.state.accountFilter, anchorEl: null }, () => {
            this.filterCartSellerEarnings()
        });
    };
    orderSellerEarnings = async (orderLable: string) => {
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getsellerEarningsCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.sellerEarnings}=${orderLable}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }

    filterCartSellerEarnings = async () => {
        const startDateNew = moment(this.state.startDate).format('DD-MM-YYYY');
        const startDateNewEnd = moment(this.state.endDate).format('DD-MM-YYYY');
        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getfiltersellerEarningsCallId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.accountFilter === "select_date_range" ?
            `${configJSON.startEndDate}?&start_date=${startDateNew}&end_date=${startDateNewEnd}` :
            `${configJSON.filterDate}=${this.state.accountFilter}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    handleSingleChangeNumber(event: React.ChangeEvent<{ value: unknown }>) {
        const newValue = event.target.value as string;
        this.setState({ numberData: newValue });
        this.orderSellerEarnings(newValue);
    }
    handlesatrtDateChange = (date: Date | null) => {
        this.setState({ startDate: date, isPickerOpen: false });
    };
    handleEndDateChange = (date: Date | null) => {
        this.setState({ endDate: date, isPickerOpen: false });
    };

    sellerEarningsDownload = async () => {
        const pageHtml = document.documentElement.outerHTML;
        const httpBody = {
            "text": `<!DOCTYPE html>${pageHtml}`
        };

        const token = await getStorageData("auth-token");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getearningsDownload = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.earningsDownload}=${this.state.accountPdf}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    // Customizable Area End
}