import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import {
  inventoryTotalProductIcon,
  inventoryOutOfStockIcon,
  inventoryLowOnStockIcon,
  inventoryUnlistedIcon,
} from "../../assets";
import { MyStoreAPIResponse, MyStoreList } from "../../MySellerStoreController";
import { ErrorMessage } from "../../CreateSellerStoreController";
import { apiCall } from "../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { StoreOperatingHours } from "../../SellerStoreProfileController";
import { CatalogueItems, IInventoryResponse } from "./UpdateInventory/UpdateInventoryController";
import { ErrorMessageResponse } from "../../SellerStoreController";
import { logoutSellerNavigation } from "../../../../../components/src/Seller/logOut";
interface IAssignStoreResponse {
  id: number;
  path: string;
  name: string;
  isSelected: boolean;
}

interface FilterState {
  lowOnStock: boolean;
  unlisted: boolean;
  listed: boolean;
  outOfStock: boolean;
}

interface CatalogueVariants {
  id: string;
  type: string;
  attributes: {
    id: number;
    catalogue_id: number;
    product_name: string;
    product_description: string;
    sku: string;
    stock_qty: number;
    low_stock_threshold: number;
    is_listed: boolean;
    price: string;
    size: string;
    colour: string;
    gender: string;
    front_image: string;
    brand_name: string;
    pair_it_with: string[];
  };
}

interface IAssignStoreData {
  id: string;
  type: string;
  attributes: {
    store_name: string;
    description: string;
    address: string;
    area: string;
    block: string;
    mall_name: string;
    floor: string;
    unit_number: number;
    city: string;
    zipcode: string;
    driver_instruction: string;
    average_shipping_time: string;
    payment_mode: string[];
    store_operating_hours: StoreOperatingHours;
    status: string;
    latitude: number;
    longitude: number;
    is_open: boolean;
    available_variants: CatalogueVariants[];
    image: string;
    email: string;
    contact_number: {
      country_code: string;
      phone_number: string;
    };
    expected_delivery_time: string;
  };
}

export interface IUpdateAssignStoreResponse{
  data: IAssignStoreData[],
  meta: {
    message: string
  }
}

interface IinventoryCount {
  total_products: number;
  out_of_stock: number;
  low_stock: number;
  unlisted: number;
}

interface IProductCountData {
  path: string;
  name: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  filterPopup: {
    openInventoryUpdate: boolean;
    openAssignStore: boolean;
  };
  filterState: {
    updateInventory: FilterState;
    setPrice: {
      name: string;
      filter: { type: string; value: boolean }[];
    }[];
    selectedSetPrice: string;
  };
  variantIds: number[];
  isAssignStoreDialogOpen: boolean;
  activeTab: number;
  searchAssignStoreQuery: string;
  assignStoreDataResp: IAssignStoreResponse[];
  selectedAssignData: IAssignStoreResponse[];
  selectedStoreIds: number[];
  isAllSelectedStore: boolean;
  filter: {
    stock_status: string;
    listing?: boolean;
  };
  assignStoreError: {
    error: boolean;
    errorMessage: string;
  };
  inventoryFilter: {
    error: boolean;
    errorMessage: string;
  };
  updatedInventory: CatalogueItems[];
  search: string;
  searchValue: string;
  isInParentAPICalled: boolean;
  severity: "error" | "success",
        message: string,
        isAlert: boolean,
  storeId: number;
  inventoryCount: IProductCountData[];
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerInventoryManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private debounceTimeout: NodeJS.Timeout | null = null;
  assignStoreAPICallId: string = "";
  assignStoreUpdateAPICallId: string = "";
  getInventoryCountAPICallId: string = "";
  updateInventoryAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      filterPopup: {
        openInventoryUpdate: false,
        openAssignStore: false,
      },
      filterState: {
        updateInventory: initialFilter12,
        setPrice: assignStoreFilters,
        selectedSetPrice: "Color",
      },
      variantIds: [],
      activeTab: 0,
      isAssignStoreDialogOpen: false,
      searchAssignStoreQuery: "",
      assignStoreDataResp: [],
      selectedAssignData: [],
      selectedStoreIds: [],
      isAllSelectedStore: false,
      filter: initialFilter,
      assignStoreError: {
        error: false,
        errorMessage: "",
      },
      inventoryFilter: {
        error: false,
        errorMessage: "",
      },
      updatedInventory: [],
      search: "",
      searchValue: "",
      isInParentAPICalled: false,
      severity: "success",
      message: "",
      isAlert: false,
      storeId: 0,
      inventoryCount: inventoryTotalData,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleAssignStoreSearch = this.handleAssignStoreSearch.bind(this);
    this.filterAssignSearchData = this.filterAssignSearchData.bind(this);
    this.propsHandleSearch = this.propsHandleSearch.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const tabValue = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState((prevState) => ({
        ...prevState,
        activeTab: tabValue.sellerInventoryManagePageTabValue,
      }));
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.apiSuccessCalled(apiRequestCallId, responseJson);
      } else if (responseJson && (responseJson.error || responseJson.errors)) {
        this.apiFailureCalled(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiSuccessCalled = async (
    apiRequestCallId: string,
    responseJson: MyStoreAPIResponse &
      IUpdateAssignStoreResponse &
      IinventoryCount &
      IInventoryResponse
  ) => {
    if (apiRequestCallId === this.assignStoreAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          assignStoreDataResp: this.extractAssignStoreData(responseJson.data),
          selectedAssignData: this.extractAssignStoreData(responseJson.data),
          isLoader: false,
        }));
      }
    } else if (apiRequestCallId === this.assignStoreUpdateAPICallId) {
      this.setState((prevState) => ({
        ...prevState,
        isAllSelectedStore: false,
        selectedStoreIds: [],
        variantIds: [],
        isAlert: true,
        message: responseJson.meta.message,
        isInParentAPICalled: !prevState.isInParentAPICalled,
        selectedAssignData: prevState.assignStoreDataResp,
      }));
      this.getInventoryProductCount(this.state.storeId);
    } else if (apiRequestCallId === this.getInventoryCountAPICallId) {
      if (responseJson) this.handleInventoryCountResp(responseJson);
    } else if (apiRequestCallId === this.updateInventoryAPICallId) {
      if (responseJson.data) {
        this.setState((prevState) => ({
          ...prevState,
          isAlert: true,
          message: "Your entries has Been Updated",
          updatedInventory: [],
          isInParentAPICalled: !prevState.isInParentAPICalled,
        }));
        this.getInventoryProductCount(this.state.storeId);
      }
    }
  };

  apiFailureCalled = (responseJson: ErrorMessage & ErrorMessageResponse) => {
    if (responseJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJson.error,
      }));
    } else if (responseJson.errors[0].token) {
      this.setState((prevState) => ({
        ...prevState,
        isAlert: true,
        severity: "error",
        message: responseJson.errors[0].token as string,
      }), () => {
        setTimeout(() => {
          this.handleRedirectBack("Home");
          logoutSellerNavigation();
        }, 2000);
      });
    } else if (responseJson.errors) {
      this.setState((prevState) => ({
        ...prevState,
        message: responseJson.errors as string,
      }));
    }
  };

  onAlertIsMSnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: false,
    }));
  };

  async componentDidMount() {
    // Customizable Area Start
    this.getMyStoreApi();
    const storeId = +(await this.getDataFromStorage("store-id"));
    this.setState((prevState) => ({
      ...prevState,
      storeId: storeId,
    }));
    this.getInventoryProductCount(storeId);
    // Customizable Area End
  }

  getMyStoreApi = async () => {
    this.assignStoreAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.sellerMyStoreEndPoint}?approved=true`,
      token: await this.getLocalTokenData(),
    });
  };

  apiHandleAssignStore = async () => {
    const { selectedStoreIds, variantIds } = this.state;
    const body = {
      catalogue_variant_ids: variantIds,
      store_ids: selectedStoreIds,
    };
    this.assignStoreUpdateAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.assignStoreEndPoint,
      token: await this.getLocalTokenData(),
      body,
      isJsonStringify: true,
    });
  };

  getLocalTokenData = async () => {
    return await getStorageData("auth-token");
  };

  handleInventoryCountResp = (responseData: IinventoryCount) => {
    const updateInventoryData = [
      {
        path: inventoryTotalProductIcon,
        name: "Total Products",
        value: responseData.total_products.toString(),
      },
      {
        path: inventoryOutOfStockIcon,
        name: "Out of Stock",
        value: responseData.out_of_stock.toString(),
      },
      {
        path: inventoryLowOnStockIcon,
        name: "Low Stock",
        value: responseData.low_stock.toString(),
      },
      {
        path: inventoryUnlistedIcon,
        name: "Unlisted",
        value: responseData.unlisted.toString(),
      },
    ];

    this.setState((prevState) => ({
      ...prevState,
      inventoryCount: updateInventoryData,
    }));
  };

  getDataFromStorage = async (value: string) => {
    return await getStorageData(value);
  };

  handleUpdateInventoryFilterClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        openInventoryUpdate: true,
      },
    }));
  };

  handleCloseUpdateInventoryFilter = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        anchorElInventoryUpdate: null,
        openInventoryUpdate: false,
      },
    }));
  };

  handleAssignStoreFilterClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        openAssignStore: true,
      },
    }));
  };

  handleCloseAssignStoreFilter = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterPopup: {
        ...prevState.filterPopup,
        openAssignStore: false,
      },
    }));
  };

  handleCheckboxFilterInventory = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      inventoryFilter: {
        ...prevState.inventoryFilter,
        error: false,
        errorMessage: "",
      },
    }));
    switch (value) {
      case "lowOnStock":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              lowOnStock: !prevState.filterState.updateInventory.lowOnStock,
            },
          },
        }));
        break;
      case "unlisted":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              unlisted: !prevState.filterState.updateInventory.unlisted,
            },
          },
        }));
        break;
      case "listed":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              listed: !prevState.filterState.updateInventory.listed,
            },
          },
        }));
        break;
      case "outOfStock":
        this.setState((prevState) => ({
          filterState: {
            ...prevState.filterState,
            updateInventory: {
              ...prevState.filterState.updateInventory,
              outOfStock: !prevState.filterState.updateInventory.outOfStock,
            },
          },
        }));
        break;
      default:
        break;
    }
  };

  handleClearAll = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterState: {
        ...prevState.filterState,
        updateInventory: initialFilter12,
      },
      filterPopup: {
        ...prevState.filterPopup,
        openInventoryUpdate: !prevState.filterPopup.openInventoryUpdate,
      },
      inventoryFilter: {
        error: false,
        errorMessage: "",
      },
      filter: {
        stock_status: "",
      },
    }));
  };

  areAllFiltersFalse = (filterState: FilterState): boolean => {
    return (
      !filterState.lowOnStock &&
      !filterState.unlisted &&
      !filterState.listed &&
      !filterState.outOfStock
    );
  };

  handleFilterApply = () => {
    const FilterData = this.state.filterState.updateInventory;
    if (this.areAllFiltersFalse(FilterData)) {
      this.setState((prevState) => ({
        ...prevState,
        inventoryFilter: {
          ...prevState.inventoryFilter,
          error: true,
          errorMessage: "Please Select any one store. This is required",
        },
      }));
      return;
    }
    const filterStatus = this.getFilterValue(FilterData);
    const filterListing = this.getListingFilterValue(FilterData);
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        stock_status: filterStatus,
        listing: filterListing,
      },
      filterPopup: {
        ...prevState.filterPopup,
        openInventoryUpdate: !prevState.filterPopup.openInventoryUpdate,
      },
    }));
  };

  getFilterValue = (filter: FilterState): string => {
    switch (true) {
      case filter.lowOnStock && filter.outOfStock:
        return "both";
      case filter.lowOnStock && !filter.outOfStock:
        return "low_on_stock";
      case filter.outOfStock && !filter.lowOnStock:
        return "out_of_stock";
      default:
        return "";
    }
  };

  getListingFilterValue = (filter: FilterState): boolean | undefined => {
    if (filter.unlisted && !filter.listed) {
      return false;
    } else if (filter.listed && !filter.unlisted) {
      return true;
    } else {
      return undefined;
    }
  };

  handleInventoryTabChange = async (newValue: number) => {
    this.setState((prevState) => ({
      ...prevState,
      activeTab: newValue,
      search: "",
      searchValue: "",
      filter: initialFilter,
      filterState: {
        ...prevState.filterState,
        updateInventory: initialFilter12,
      },
      updatedInventory: [],
      selectedAssignData: prevState.assignStoreDataResp,
      selectedStoreIds: [],
      variantIds:[]
    }));
  };

  handleRedirectBack = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    this.send(userNavMsg);
  };

  handleUpdateStore = (updatedInventory: CatalogueItems[]) => {
    this.setState((prevState) => ({
      ...prevState,
      updatedInventory: updatedInventory,
    }));
  };

  handleConfirmUpdateInventory = async () => {
    const { updatedInventory } = this.state;
    const body = { catalogue_variants: updatedInventory };
    this.updateInventoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPatchMethod,
      endPoint: configJSON.updateInventoryEndPoint,
      token: await this.getDataFromStorage("auth-token"),
      body: body,
      isJsonStringify: true,
    });
  };

  getInventoryProductCount = async (storeId: number) => {
    this.getInventoryCountAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getInventoryCountEndPoint}${storeId}/store_variant_count`,
      token: await this.getDataFromStorage("auth-token"),
    });
  };

  handleSelectedSetPricesFilter = (item: string) => {
    this.setState((prevState) => ({
      filterState: {
        ...prevState.filterState,
        selectedSetPrice: item,
      },
    }));
  };

  handleCheckboxChange = (filterName: string, type: string) => {
    this.setState((prevState) => ({
      filterState: {
        ...prevState.filterState,
        setPrice: prevState.filterState.setPrice.map((filter) => {
          if (filter.name === filterName) {
            return {
              ...filter,
              filter: filter.filter.map((f) => {
                if (f.type === type) {
                  return { ...f, value: !f.value };
                }
                return f;
              }),
            };
          }
          return filter;
        }),
      },
    }));
  };

  handleAssignStore = (variantIds: number[]) => {
    this.setState((prevState) => ({
      ...prevState,
      variantIds,
    }));
  };

  handleAssignStoreDialogClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAssignStoreDialogOpen: !prevState.isAssignStoreDialogOpen,
      assignStoreError: {
        ...prevState.assignStoreError,
        error: false,
        errorMessage: "",
      },
    }));
  };

  handleAssignSubmitStore = async () => {
    if (this.state.selectedStoreIds.length === 0) {
      this.setState((prevState) => ({
        ...prevState,
        assignStoreError: {
          ...prevState.assignStoreError,
          error: true,
          errorMessage: "Please Select any one store. This is required",
        },
      }));
      return;
    }
    await this.apiHandleAssignStore();
    this.handleAssignStoreDialogClose();
  };

  debounceTime(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleAssignStoreSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    this.setState((prevState) => ({
      ...prevState,
      searchAssignStoreQuery: query,
      selectedStoreIds: [],
      isAllSelectedStore: false,
    }));
    this.debounceTime(this.filterAssignSearchData, 300)();
  };

  handleSearchInventory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    this.setState((prevState) => ({
      ...prevState,
      search: query,
    }));
    this.debounceTime(() => this.propsHandleSearch(query), 800)();
  };

  propsHandleSearch = (value: string) => {
    this.setState((prevState) => ({
      ...prevState,
      searchValue: value,
    }));
  };

  filterAssignSearchData = () => {
    const {
      assignStoreDataResp,
      searchAssignStoreQuery,
      isAllSelectedStore,
      selectedStoreIds,
    } = this.state;
    if (!searchAssignStoreQuery) {
      const updatedFilteredData = assignStoreDataResp.map((assignStore) => ({
        ...assignStore,
        isSelected: selectedStoreIds.includes(assignStore.id),
      }));

      const selectedCount = updatedFilteredData.filter(
        (assignStore) => assignStore.isSelected
      ).length;

      this.setState((prevState) => ({
        ...prevState,
        selectedAssignData: updatedFilteredData,
        isAllSelectedStore:
          assignStoreDataResp.length === selectedCount && selectedCount !== 0,
      }));
      return;
    }

    const filteredData = assignStoreDataResp.filter((assignStore) =>
      assignStore.name.toLowerCase().includes(searchAssignStoreQuery)
    );

    const updatedFilteredData = filteredData.map((assignStore) => ({
      ...assignStore,
      isSelected: selectedStoreIds.includes(assignStore.id),
    }));

    const selectedCount = updatedFilteredData.filter(
      (assignStore) => assignStore.isSelected
    ).length;

    this.setState((prevState) => ({
      ...prevState,
      selectedAssignData: updatedFilteredData,
      selectedStoreIds: filteredData.map((item) => item.id),
      isAllSelectedStore:
        updatedFilteredData.length === selectedCount
          ? true
          : isAllSelectedStore,
    }));
  };

  handleCheckboxSelectStore = (id: number, isSelected: boolean) => {
    this.setState((prevState) => {
      const updatedStoreStatusData = prevState.selectedAssignData.map((store) =>
        store.id === id ? { ...store, isSelected } : store
      );

      const updatedSelectedStoreIds = isSelected
        ? [...prevState.selectedStoreIds, id]
        : prevState.selectedStoreIds.filter((storeId) => storeId !== id);

      return {
        selectedAssignData: updatedStoreStatusData,
        selectedStoreIds: updatedSelectedStoreIds,
        isAllSelectedStore:
          updatedStoreStatusData.length === updatedSelectedStoreIds.length,
        assignStoreError: {
          ...prevState.assignStoreError,
          error: false,
          errorMessage: "",
        },
      };
    });
  };

  handleCheckboxAllSelectStore = () => {
    this.setState((prevState) => {
      const isAllSelected = !prevState.isAllSelectedStore;
      const updatedSelectedStoreIds = isAllSelected
        ? this.getAllStoreIds()
        : [];

      const updatedStoreStatusData = prevState.selectedAssignData.map(
        (item) => ({
          ...item,
          isSelected: isAllSelected,
        })
      );

      return {
        ...prevState,
        isAllSelectedStore: isAllSelected,
        selectedStoreIds: updatedSelectedStoreIds,
        selectedAssignData: updatedStoreStatusData,
        assignStoreError: {
          ...prevState.assignStoreError,
          error: false,
          errorMessage: "",
        },
      };
    });
  };

  getAllStoreIds = () => {
    return this.state.selectedAssignData.map((store) => store.id);
  };

  extractAssignStoreData = (items: MyStoreList[]): IAssignStoreResponse[] => {
    return items.filter((item) => +item.id !== this.state.storeId).map((item) => {
      const { id, attributes } = item;
      return {
        id: +id,
        path: attributes.image,
        name: attributes.store_name,
        isSelected: false,
      };
    });
  };

  // Customizable Area End
}
// Customizable Area Start

const initialFilter = {
    stock_status: "",
}

const initialFilter12 = {
  lowOnStock: false,
  unlisted: false,
  listed: false,
  outOfStock: false
};

export const inventoryTotalData = [
  { path: inventoryTotalProductIcon, name: "Total Products", value: "" },
  { path: inventoryOutOfStockIcon, name: "Out of Stock", value: "" },
  { path: inventoryLowOnStockIcon, name: "Low Stock", value: "" },
  { path: inventoryUnlistedIcon, name: "Unlisted", value: "" },
];

export const inventoryTab = [
  { label: "Update Inventory", testId: "updateInventory" },
  { label: "Set Prices", testId: "setPrices" },
  { label: "Assign Store", testId: "assignStore" },
  { label: "Pair the Products", testId: "pairTheProducts" },
  { label: "Bulk Actions", testId: "bulkActions" },
  { label: "Download Templates", testId: "downloadTemplates" },
];

const assignStoreFilters = [
  {
    name: "Color",
    filter: [
      {
        type: "Black",
        value: false,
      },
      {
        type: "Blue",
        value: false,
      },
      {
        type: "Purple",
        value: false,
      },
      {
        type: "Orange",
        value: false,
      },
      {
        type: "Red",
        value: false,
      },
      {
        type: "Pink",
        value: false,
      },
      {
        type: "White",
        value: false,
      },
      {
        type: "Brown",
        value: false,
      },
    ],
  },
  {
    name: "Price",
    filter: [],
  },
  {
    name: "Gender",
    filter: [],
  },
  {
    name: "Dresses",
    filter: [],
  },
  {
    name: "Tops",
    filter: [],
  },
  {
    name: "Bottoms",
    filter: [],
  },
  {
    name: "OutWear",
    filter: [],
  },
  {
    name: "Activewear",
    filter: [],
  },
  {
    name: "Swimwear",
    filter: [],
  },
  {
    name: "Shorts",
    filter: [],
  },
];
// Customizable Area End
