import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  styled,
  Box,
  Typography,
  Tooltip,
} from "@material-ui/core";
import StylishMyOfferController, { Props } from "./StylishMyOfferController";
import StylishSidebar from "../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../components/src/Stylish/StylishHeader";
import { ThemeProvider } from "react-native-elements";
import { backBtnIcon } from "../../../customform/src/assets";
import { CustomStyledStylishPagination, StylishPaginationContainer, TitleContainer } from "./StylishProductSourcing.web";
import Loader from "../../../../components/src/LoaderContainer";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylishMyOffer extends StylishMyOfferController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderMyOfferPagination = () => (
    <>
      {this.state.metaResp !== undefined ? (
        <StylishPaginationContainer>
        <Box className="pagination-data-container">
            {this.state.metaResp.current_page !== null
              ? (this.state.metaResp.current_page - 1) * 10 + 1
              : ""}{" "}
            -{" "}
            {this.state.metaResp.current_page !== null &&
            this.state.metaResp.total_record
              ? Math.min(
                  this.state.metaResp.current_page * 10,
                  this.state.metaResp?.total_record
                )
              : ""}{" "}
            of {this.state.metaResp.total_record} results
          </Box>
          <CustomStyledStylishPagination
            page={
              this.state.metaResp.current_page !== null
                ? this.state.metaResp.current_page
                : 1
            }
            count={
              this.state.metaResp.total_pages !== null
                ? this.state.metaResp.total_pages
                : 1
            }
            shape="rounded"
            color="primary"
            data-test-id="my-offer-page-test-id"
            onChange={this.handleMyOfferPageChange}
          />
        </StylishPaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  listMyOfferBody = () => (
  this.state.listData.length === 0 ? <Box className="loader-container"><NoTypography>No Bids Available</NoTypography></Box> : <Grid container spacing={3}>
    {this.state.listData.map((item) => (
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <ListContainer data-test-id={`list-${item.id}`}>
          <div className="img-container">
          <img
            className="image"
            src={item.attributes.product_sourcing_request.images[0].url}
            alt={`img-${item.id}`}
          />
          </div>
          <Box className="list-right-part">
            <Box className="top-container">
              <Box>
                <Tooltip title={item.attributes.product_sourcing_request.product_name}>
                  <Typography className="text-title">
                    {item.attributes.product_sourcing_request.product_name}
                  </Typography>
                </Tooltip>
                <Typography className="price-title">
                  {`$${+item.attributes.product_sourcing_request.min_price} - $${+item.attributes.product_sourcing_request.max_price}`}
                </Typography>
              </Box>
              <Box className={`container-${item.attributes.request_status}`}>
                <Typography className={`status-${item.attributes.request_status}`}>
                  {item.attributes.request_status}
                </Typography>
              </Box>
            </Box>
            <Box className="bottom-container">
              <Typography className="text-title">
                Quoted Price
              </Typography>
              <Typography
                className="text-title"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {`$${item.attributes.quote_price}`}
              </Typography>
            </Box>
          </Box>
        </ListContainer>
        <ViewBtnContainer onClick={()=>this.handleProductRequestPage(+item.id)}>View</ViewBtnContainer>
      </Grid>
    ))}
  </Grid>)
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            id="stylish-sidebar-my-offer"
            md={2}
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            id="stylish-header-my-offer"
            md={10}
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishMyOfferContainer>
              <TitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleStylishMyOfferRedirect("StylishProductSourcing")
                  }
                />
                <Typography className="title">My Bids</Typography>
              </TitleContainer>
              {this.state.isLoading ? <Box className="loader-container"><Loader
                      id="my-offer-loader"
                      dataTestId="my-offer-loader-test"
                    /></Box> :this.listMyOfferBody()}
            </StylishMyOfferContainer>
            {this.state.metaResp.total_record > 10 && this.renderMyOfferPagination()}
          </Grid>
        </Grid>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertMOSnackClose}
          severity="error"
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StylishMyOfferContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .loader-container":{
    width: "100%",
    minHeight: "420px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

export const NoTypography = styled(Typography)({
  fontFamily: "Lato, sans-serif",
  color: "#375280",
  fontSize: 28,
  fontWeight: 500,
});

export const ListContainer = styled(Box)({
  gap: 20,
  display: "flex",
  "& .img-container": {
    height: 140,
    borderRadius: 2,
    width: 140,
    objectFit: "cover",
    "& .image": {
      borderRadius: 2,
      width: "inherit",
      height: "inherit"
    }
  },
  "& .img-pro-sourcing-request-container": {
    width: 98,
    height: 104,
    objectFit: "cover",
    borderRadius: 2,
    "& .image": {
      width: "inherit",
      borderRadius: 2,
      height: "inherit",
    },
  },
  "& .list-right-part": {
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .text-title": {
      WebkitBoxOrient: "vertical",
      textOverflow: "ellipsis",
      fontFamily: "Lato, sans-serif",
      width: "100%",
      color: "#375280",
      fontSize: 18,
      fontWeight: 700,
      whiteSpace: "normal",
      paddingBottom: 4,
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,   
    },
    "& .bottom-container": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    "& .top-container": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .container-accepted": {
        width: 79,
        height: 27,
        borderRadius: 2,
        padding: "6px 10px 6px 10px",
        backgroundColor: "#E1EFE1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .status-accepted": {
          fontFamily: "Lato, sans-serif",
          color: "#059669",
          textTransform: "capitalize",
          fontWeight: 500,
          fontSize: 12,
        },
      },
      "& .price-title": {
        fontFamily: "Lato, sans-serif",
        color: "#375280",
        fontSize: 18,
        fontWeight: 400,
        width: "100%",
        paddingBottom: 4,
      },
      "& .container-rejected": {
        width: 79,
        height: 27,
        borderRadius: 2,
        padding: "6px 10px 6px 10px",
        backgroundColor: "#FEE2E2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .status-rejected": {
          fontFamily: "Lato, sans-serif",
          textTransform: "capitalize",
          color: "DC2626",
          fontWeight: 500,
          fontSize: 12,
        },
      },
      "& .container-waiting": {
        width: 79,
        height: 27,
        borderRadius: 2,
        padding: "6px 10px 6px 10px",
        backgroundColor: "#FFE7D0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .status-waiting": {
          textTransform: "capitalize",
          fontFamily: "Lato, sans-serif",
          color: "#BE5B00",
          fontWeight: 500,
          fontSize: 12,
        },
      },
    },
  },
});

const ViewBtnContainer = styled(Box)({
  textTransform: "capitalize",
  marginTop: 24,
  color: "white",
  gap: "8px",
  backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
  height: 42,
  borderRadius: "1px",
  fontFamily: "Lato, sans-serif",
  width: "100%",
  fontSize: "18px",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// Customizable Area End
