export const supportHelp = require("./assets/24-support-help.svg");
export const analytics = require("./assets/analytics.svg");
export const catalogue = require("./assets/catalogue.svg");
export const sale = require("./assets/sale.svg");
export const earnings = require("./assets/earnings.svg");
export const boardDashboard = require("./assets/menu-boardDashboard.svg");
export const order = require("./assets/orders.svg");
export const userActivity = require("./assets/user-activity.svg");
export const language = require("./assets/language.svg");
export const storeLogo = require("./assets/store-logo.svg");
export const notification = require("./assets/notification.svg");
export const clients = require("./assets/clientss.svg");
export const portfolio = require("./assets/portfolio.svg");
export const inventory = require("./assets/inventory.svg");
export const notSelectedStore = require("./assets/not-selected-store.svg");
export const expandArrowIcon = require("./assets/expand-arrow-icon.svg");
export const defaultProfileIcon = require("./assets/default-profile-img.svg");