import React from "react";
// Customizable Area Start
import SalesReportController, { Props, orderInsights } from "./SalesReportController";
import { Grid, styled, Box, Typography, Dialog, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel } from "@material-ui/core";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { downloadIcon, filterIcon } from "../../customform/src/assets";
import PieChart from "../../../components/src/Chart/PieChart";
import { close } from "../../customisableuserprofiles2/src/assets";
import RadioButton from "../../../components/src/RadioButton";
import { doc, jpg, pdf } from "./assets";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class SalesReport extends SalesReportController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    rearrangedPieChartData = [...orderInsights.slice(1), orderInsights[0]];

    exportOptions = [
        {
            value: "PDF", image: pdf, label: (
                <Typography style={{ fontFamily: "Lato, sans-serif", fontSize: "22px", fontWeight: 700 }}>
                    PDF
                </Typography>
            ), selectedValue: "PDF"
        },
        {
            value: "Doc File", image: doc, label: (
                <Typography style={{ fontFamily: "Lato, sans-serif", fontSize: "22px", fontWeight: 700 }}>
                    Doc File
                </Typography>
            ), selectedValue: "Doc File"
        },
        {
            value: "CSV", image: jpg, label: (
                <Typography style={{ fontFamily: "Lato, sans-serif", fontSize: "22px", fontWeight: 700 }}>
                    CSV
                </Typography>
            ), selectedValue: "CSV"
        },
    ];
    render() {
        return (
            <ThemeProvider theme={theme}>

                <Grid container>
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2} >
                        <SellerSidebar navigation={this.props.navigation} />
                    </Grid>
                    <Grid item xl={10} md={10} lg={10} sm={10} xs={10}  >
                        <SellerHeader navigation={this.props.navigation} />
                        <SellerSalesReport>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box className="sales-report-title">
                                    <Typography className="title">
                                        Sales Report
                                    </Typography>
                                    <Typography className="span-title">
                                        [Past 1 Month]
                                    </Typography>
                                </Box>
                                <Box className="btn-container">
                                    <img src={downloadIcon} alt="down-load-icon" data-test-id="downloadIcon" onClick={this.handleExportDialogOpen} />
                                    <img src={filterIcon} alt="filter-icon" />
                                </Box>

                            </Box>
                            <ChartContainer>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} sm={12} lg={6} xl={6} style={{ paddingRight: "0px" }}>
                                        <Box className="primaryContainer">
                                            <Typography className="salesText">Sales</Typography>
                                            <Box style={{ display: "flex", gap: 10, }}>
                                                <Box style={webStyles.boxStyle}>
                                                    <Typography style={webStyles.upperHeading}>Earning</Typography>
                                                    <Typography style={webStyles.valueText}>$ 100K</Typography>
                                                </Box>

                                                <Box style={webStyles.boxStyle}>
                                                    <Typography style={webStyles.upperHeading}>Sold Units</Typography>
                                                    <Typography style={webStyles.valueText}>300</Typography>
                                                </Box>

                                                <Box style={webStyles.boxStyle}>
                                                    <Typography style={webStyles.upperHeading}>Returned</Typography>
                                                    <Typography style={webStyles.valueText}>350</Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12} sm={12} lg={6} xl={6} style={{ paddingRight: "0px" }}>
                                        <Box className="chart-outer-container">
                                            <Typography className="chart-title">
                                                Order Insights
                                            </Typography>
                                            <div className="pie-chart-container">
                                                <div className="pie-chart">
                                                    <PieChart
                                                        labels={this.rearrangedPieChartData.map((item) => item.label)}
                                                        dataSetsVal={this.rearrangedPieChartData.map((item) => item.value)}
                                                        backgroundColor={this.rearrangedPieChartData.map((item) => item.color)}
                                                    />
                                                </div>
                                                <div className="pie-chart-label-container">
                                                    {orderInsights.map(
                                                        (item: {
                                                            label: string;
                                                            color: string;
                                                            value: number;
                                                        }) => (
                                                            <Box className="pie-chart-label">
                                                                <Box
                                                                    style={{ backgroundColor: item.color }}
                                                                    className="color-picker"
                                                                />
                                                                <Box className="label-name">
                                                                    <Typography>{item.label}</Typography>
                                                                    <Typography>{`${item.value} %`}</Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </ChartContainer>

                            <Box className="tableContainer">
                                <Typography className="salesText">Products Overview</Typography>
                                {
                                    this.state.productOverview && this.state.productOverview.map((item) => (
                                        <>
                                            <Box style={{ height: "80px", display: "flex", gap: 24, margin: "18px 0px" }} key={item.id}>
                                                <Box style={{ minWidth: "80px", minHeight: "80px", borderRadius: "2px" }}>
                                                    <img src={item.image} style={{ width: "100%", height: "100%", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} alt="prduct image" />
                                                </Box>
                                                <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    <Box>
                                                        <Typography style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 500, color: "#375280" }}>{item.productName}</Typography>
                                                        <Box style={{ display: "flex", gap: "15px" }}>
                                                            <Box style={{ width: "135px", height: "40px", padding: "6px 20px", borderRadius: "6px", backgroundColor: "#E2E8F0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 500, color: "#375280" }}>20 Sold Units</Typography>
                                                            </Box>
                                                            <Box style={{ width: "160px", height: "40px", padding: "6px 20px", borderRadius: "6px", backgroundColor: "#FEE2E2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 500, color: "#DC2626" }}>5 Returned Units</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Typography style={{ fontFamily: "Lato", fontWeight: 700, fontSize: "18px", color: "#059669" }}>{item.price}</Typography>
                                                </Box>
                                            </Box>
                                            <hr style={{ border: "1px solid #E2E8F0" }} />
                                        </>
                                    ))
                                }
                            </Box>

                            <ContactSupport
                                open={this.state.exportDailog}
                                data-test-id="handleContactSupport"
                                onClose={this.handleExportDialogClose}
                            >
                                <DiologcontentStyled >
                                    <Box style={{
                                        padding: "25px 25px 0px 25px"
                                    }}>
                                        <Box style={{
                                            display: "flex",
                                            justifyContent: "end"
                                        }} onClick={this.handleExportDialogClose}>
                                            <img src={close} alt="close" />
                                        </Box>
                                        <Box style={webStyles.headingContainer}>
                                            <Typography style={webStyles.primaryText}>
                                                Export Report
                                            </Typography >
                                            <Typography style={webStyles.secondaryText}>
                                                Export Analytics report as:
                                            </Typography >
                                        </Box>
                                        <Box>
                                            <RadioGroup
                                                row
                                                aria-labelledby="language-selection"
                                                name="language-selection"
                                                style={{ justifyContent: "space-between", margin: '25px', display: "flex", flexDirection: "column" }}
                                                value={this.state.exportType}
                                                data-test-id="group-language"
                                                onChange={(event, value) =>
                                                    this.onChangeLanguage(value)
                                                }
                                            >
                                                {this.exportOptions.map((lang, index) => (
                                                    <Box>
                                                        <Box style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            color: "#375280", marginLeft: "0px", marginRight: "0px"
                                                        }}>
                                                            <Box style={{ height: "60px", width: "60px" }}>
                                                                <img src={lang.image} style={{ height: "100%", width: "100%" }} alt="image" />
                                                            </Box>
                                                            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%", marginLeft: "10px" }}>
                                                                <FormControlLabel
                                                                    value={lang.selectedValue}
                                                                    control={<RadioButton variant="outlined" />}
                                                                    label={lang.label}
                                                                    className={`radioButton ${lang.selectedValue === this.state.exportType
                                                                        ? "activeRadioButton"
                                                                        : ""
                                                                        }`}
                                                                    style={{
                                                                        justifyContent: 'space-between',
                                                                        display: 'flex',
                                                                        flexDirection: "row-reverse",
                                                                        alignItems: 'center',
                                                                        color: "#375280", marginLeft: "0px", marginRight: "0px", width: "100%"
                                                                    }}
                                                                />
                                                            </Box>

                                                        </Box>
                                                        {index !== 2 && <hr style={{
                                                            color: "#D9D9D9"
                                                        }} />}
                                                    </Box>
                                                ))}
                                            </RadioGroup>
                                        </Box>

                                    </Box>
                                </DiologcontentStyled>
                                <DialogActions style={{ justifyContent: "center", padding: "0px 40px 40px 40px " }}>
                                    <Button data-test-id="handleConfirmClose" style={webStyles.cancelButton} onClick={this.handleExportDialogClose}>
                                        Close
                                    </Button>
                                    <Button data-test-id="handleConfirmAdd" style={webStyles.confirmButton}
                                    // onClick={this.postContactUsApi}
                                    >
                                        Export
                                    </Button>
                                </DialogActions>
                            </ContactSupport>
                        </SellerSalesReport>
                    </Grid>
                </Grid>

            </ThemeProvider>
        );
    }
    // Customizable Area End



}

// Customizable Area Start

const ContactSupport = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        minWidth: '620px',
        minHeight: "500px"
    },
    '& .MuiPaper-rounded': {
        borderRadius: '12px',
    }, "& .inputFeild2Error2": {
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            border: "none"
        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
    },
    "& .inputFeild2": {
        "& .MuiTextField-root": {
            "&:hover": {
                border: "1px solid #F0F0F0 !important",
            },
        },
        "& .MuiOutlinedInput-root:hover": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",

        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },
    "& .MuiDialogContent-root:first-child": {
        paddingTop: "0px"
    }
});

const DiologcontentStyled = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    gap: "24px",
    flex: "none"
});

const ChartContainer = styled(Box)({
    width: "100%",
    "& .MuiGrid-root.MuiGrid-container": {
        height: "max-content",
        "& .MuiGrid-root.MuiGrid-item": {
            "& .chart-outer-container": {
                padding: 20,
                border: "1px solid #E2E8F0",
                marginTop: 30,
                height: "75%",
                "& .chart-title": {
                    fontFamily: "Lato, sans-serif",
                    fontWeight: 700,
                    fontSize: 20,
                    color: "#375280",
                },
                "& .pie-chart-container": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    gap: 20,
                    "& .pie-chart": {
                        height: "150px",
                        width: "40%",
                        transform: 'rotate(130deg)'
                    },
                    "& .pie-chart-label-container": {
                        display: "flex",
                        flexDirection: "column",
                        gap: 30,
                        width: "50%",
                    },
                    "& .pie-chart-label": {
                        display: "flex",
                        gap: 12,
                        width: "100%",
                        color: "#375280",
                        fontSize: 16,
                        "& .label-name": {
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: 2,
                        },
                        "& .color-picker": {
                            width: 20,
                            borderRadius: 2,
                            height: 24,
                        },
                    },
                    "@media(max-width:800px)": {
                        flexDirection: "column",
                        gap: 20,
                        "& .pie-chart": {
                            width: "100%",
                        },
                        "& .pie-chart-label-container": {
                            width: 320,
                            alignItems: "center",
                        },
                    },
                },
            },
        },
    },
});
const SellerSalesReport = styled(Box)({
    minHeight: "calc(100vh - 85px)",
    padding: "30px 60px",
    "& .sales-report-title": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 12,
        "& .title": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 700,
            fontSize: 24,
            color: "#375280",
        },
        "& .span-title": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 400,
            fontSize: 16,
            marginTop: 3,
            color: "#375280",
        },
    },
    "& .btn-container": {
        display: "flex",
        gap: 24,
        cursor: "pointer"
    },
    "& .primaryContainer": { height: "75%", border: "1px solid #E2E8F0", marginTop: "30px", padding: "20px", display: "flex", flexDirection: "column", gap: 20 },
    "& .salesText": { fontSize: "20px", fontWeight: 700, fontFamily: "Lato", color: "#375280" },
    "& .tableContainer": { width: "98%", maxHeight: "550px", marginTop: "30px", border: '1px solid #E2E8F0', padding: "20px", overflow: "scroll" },
    "@media (min-width: 660px) and (max-width : 1280px) ": {
        "& .tableContainer": {
            width: "97%"
        }
    },
    "@media(max-width:600px)": {
        height: "max-content",
        "& .sales-report-title": {
            "& .title": {
                fontWeight: 600,
                fontSize: "18px !important",
            },
        },



        "& .btn-container": {
            flexDirection: "column",
            gap: 4,
            height: "max-content",
        },
    },
});
const webStyles = {
    boxStyle: { width: "151.68px", height: "150px", backgroundColor: "#F8F8F8", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" } as React.CSSProperties,
    upperHeading: { fontSize: "20px", fontWeight: 400, fontFamily: "Lato", color: "#334155" },
    valueText: { fontSize: "32px", fontWeight: 700, fontFamily: "Lato", color: "#375280" },
    headingContainer: {
        marginLeft: "12px", display: "flex", flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties,
    primaryText: {
        fontSize: '30px',
        color: "#375280",
        fontWeight: 500,
        fontFamily: "Lato"
    },
    secondaryText: {
        fontSize: '18px',
        color: "#375280",
        fontWeight: 400,
        fontFamily: "Lato"
    },
    confirmButton: {
        alignItems: "center",
        fontSize: "15px",
        justifyContent: "center",
        fontWeight: 500,
        display: "flex",
        color: "white",
        backgroundColor: "#CCBEB1",
        border: "1px solid #CCBEB1",
        height: "56px",
        borderRadius: "5px",
        width: "252px",
        padding: "10px",
        cursor: "pointer",
        textTransform: "capitalize"
    } as React.CSSProperties,
    cancelButton: {
        alignItems: "center",
        fontSize: "15px",
        justifyContent: "center",
        fontWeight: 500,
        display: "flex",
        color: "#375280",
        backgroundColor: "#FFFFFF",
        border: "1px solid #CCBEB1",
        height: "56px",
        borderRadius: "5px",
        width: "252px",
        padding: "10px",
        cursor: "pointer",
        textTransform: "capitalize"
    } as React.CSSProperties,
}
// Customizable Area End
