import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Snackbar
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { createTheme, styled } from "@material-ui/core/styles";
import Footer from "../../../components/src/Footer";
import CustomizedSteppers from "../../../components/src/Stylish/StylistStepper";
import StylistAccountActiveProfileController, {
  Props
} from "./StylistAccountActiveProfileController.web";
import { view1, document, buttonDelete, documentBack } from "./assets";
import SellerHeaderActive from "../../../components/src/Seller/SellerHeaderActive";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylistPortfolioAcitve extends StylistAccountActiveProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <>
        <SellerHeaderActive navigation={this.props.navigation} />
        <MainGrid data-test-id="mainGridContainer" container>
          <Grid item lg={6} md={6} xl={6} sm={12} xs={12} className="imageGridData">
            <Box className="sideImageBoxData">
              <img src={documentBack} alt="" className="sideImageData" />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12} xl={6} lg={6} className={"formGridData"}>
            <Box className={"formBox"} data-testid="portfolioActive">
              <form data-test-id="formSubmitted" style={{ width: "90%" }}>
                <Box width={"100%"}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "25px", marginBottom: "30px" }}>
                    <img src={view1} alt="" className="view" data-testid="getNavigationProfile" onClick={() => this.getNavigationActiveAddress()} />
                    <Typography className={"welcomeText"} data-testid="CreateProfile">Create Profile</Typography>
                  </Box>
                  <Box style={{ color: "#375280", fontFamily: "Lato" }}>
                    <CustomizedSteppers
                      activeStep={2}
                      steps={['Profile Setup', 'Contact & Payment', 'Portfolio']}
                      stepCount={3}
                    />
                  </Box>
                </Box>
              </form>
              <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px' }}>
                <Box style={{ color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 700 }}>Upload Portfolio</Box>
                <Box style={{ color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 700, cursor: 'pointer' }} data-testid="uplodeDocuments" onClick={this.uplodeDocuments}>Skip</Box>
              </Box>
              <Box style={webStyle.documentBox as React.CSSProperties}>
                <Box>
                  <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                    <img className="fieldImg" alt='add image' src={document} data-testid="thodUploadImageFile" onClick={() => { this.thodUploadImageFile() }} />
                  </Box>
                  <Box style={{ textAlign: 'center' }}>
                    <Typography className="fieldAreaLabel portableNewOne" style={{ color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 700 }}>Upload Multiple Photos</Typography>
                    <Typography className="fieldAreaLabel portableNewOne" style={{ color: "#94A3B8", fontFamily: 'Lato', fontSize: '14px', fontWeight: 400 }}>Only png or Jpg files are accepted</Typography>
                  </Box>
                </Box>
              </Box>
              {this.state.presentImage.length !== 0 && <Box style={{ border: '1px solid #E2E8F0', width: '95%', padding: '16px', marginTop: '40px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Typography style={{ color: "#375280", fontFamily: 'Lato', fontSize: '16px', fontWeight: 900, marginBottom: '20px' }}>Photos</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography style={{ color: "#375280", fontFamily: 'Lato', fontSize: '16px', fontWeight: 900 }}>Description</Typography>
                  </Grid>
                  {this.state.presentImage?.map((value, index) => (
                    <>
                      <Grid container>
                        <Grid item xs={12} sm={3}>
                          <Box style={{
                            position: 'relative',
                            color: 'white',
                            width: 'max-content',
                            marginLeft: '13px'
                          }}>
                            <img
                              key={index}
                              src={value.updateProImages[0] || undefined}
                              alt={`Product ${index + 1}`}
                              style={{ width: '104px', height: '104px', marginBottom: '20px' }}
                            />
                            <Box style={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                            }}><img src={buttonDelete} width="24px" height="24px" data-testid="thodUploadImageFileRemoved" onClick={() => { this.handleRemoveThad(index) }} /></Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Box style={{ width: '100%' }}>
                            <InputField
                              id="outlined-multiline-static"
                              multiline
                              inputProps={{ 'data-testid': 'changeDisc' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                'aria-label'?: string;
                                'data-testid'?: string;
                              }}
                              type="text"
                              rows={3}
                              variant="outlined"
                              value={value.description} onChange={(event) => {
                                this.changeDisc(index, event.target.value)
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>}
              <Button disabled={this.state.presentImage.length === 0} style={{ ...webStyle.createButton, backgroundColor: this.state.presentImage.length === 0 ? "#CBD5E1" : '#CCBEB1', textTransform: 'capitalize', textAlign: 'center' }} data-testid="uplodeApi" onClick={async () => { await this.uplodeApi() }}>Create & Verify</Button>
            </Box>
          </Grid>
          <Snackbar
            open={this.state.isAlert}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            onClose={this.oncloseAlert}
            data-testid="alertTestingId"
          >
            <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
          </Snackbar>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  createButton: { color: '#FFFFFF', height: '54px', marginTop: '48px', fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', padding: '10px 16px' },
  documentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '14.5rem',
    width: '100%',
    backgroundColor: '#F0F0F0',
    position: "relative",
  },
}
const InputField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "#375280 !important" // Change text color here
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
      borderWidth: "1px",
    }
  },
  "& .MuiInputBase-root": {
    fontSize: "16px",
    borderRadius: "2px",
    margin: "auto",
    height: '104px',
    width: '26vw',
    border: '1px solid #E2E8F0',
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-input": {
    color: "#1E293B",
    "&::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      "@media only screen and (max-width: 414px)": {
        fontSize: "14px"
      }
    },
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    }
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  }
});
const MainGrid = styled(Grid)({
  justifyContent: "center",
  display: "flex",
  "& .sideImageBoxData": {
    height: "100%",
    width: "100%",
  },
  "& .sideImageData": {
    width: "100%",
    height: "100%",
  },
  "& .imageGridData": {
    justifyContent: "center",
    alignItems: "flex-start",
    display: "flex",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    alignSelf: "flex-start",
    margin: "10px 0px 0px 0px",
    lineHeight: "26px",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Avenir, sans-serif",
  },
  "& .inputFeild": {
    gap: "4px",
    border: "none",
    borderRadius: "0px"
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formGridData": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "7%"
  },
  "& .formBox": {
    marginTop: "30px",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: 'Lato , sans-serif',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start",

  },
  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    fontWeight: 200,
    textOverflow: "ellipsis",
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    cursor: "pointer",
  },
  "& .backButton": {
    borderRadius: "0px !important",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    gap: "8px",
    border: "1px solid #CCBEB1",
    backgroundColor: "white",
    width: "50% !important",
    textTransform: "capitalize",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .nextButton": {
    color: "white !important",
    borderRadius: "0px !important",
    width: "50% !important",
    textTransform: "capitalize",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    height: "56px !important",
    "& .MuiButton-label": {
      fontSize: "16px",
      fontWeight: 600,
    }
  },
  "& .terms": {

    fontFamily: "Lato ,sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    margin: "10px 10px 10px 10px !important",
    color: "#979797 !important",
    width: "520px !important",
    lineHeight: "24px !important",
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },

    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },

    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },

  },
  "& .Register": {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    width: "100%",
    padding: "10px",
  },
  "& .socialLogin": {
    alignItems: "center",
    gap: "35px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "20px",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",

    fontWeight: 700,
    lineHeight: "24px !important",
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
  },
  "& .bottomText": {
    fontWeight: 400,
    lineHeight: "24px !important",
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
    color: "#375280 !important",
  },
  "& .continueText": {
    width: "100%",
    lineHeight: "24px !important",
    fontStyle: "normal !important",
    display: "flex",
    marginTop: "15px",
    fontWeight: 400,
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
    flexDirection: "row",
    color: "#375280 !important",
    "&:before, &:after": {
      content: "''",
      flex: "1 1",
      margin: "auto",
      borderBottom: "1px solid",
    },
    "&:before": {
      marginRight: "25px"
    },
    "&:after": {
      marginLeft: "25px"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "350px !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .backButton": {
      width: "100% !important",
      "& .MuiButton-label": {
        fontSize: "16px",
        fontWeight: 600,
      }
    },
    "& .phoneInput": { width: "370px" },
    "& .continueText": {
      lineHeight: "24px !important",
      fontWeight: 400,
      fontStyle: "normal !important",
      display: "flex",
      fontSize: "16px !important",
      marginTop: "15px",
      fontFamily: "Lato, sans-serif",
      color: "#375280 !important",
      width: "100%",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
      },
      "&:before": {
        marginRight: "25px"
      },
      "&:after": {
        marginLeft: "25px"
      }
    },
  },
});
// Customizable Area End
