import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  Dialog
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { documentBack, document, view1 } from "./assets";
import Footer from "../../../components/src/Footer";
import {storeSuccessIcon} from "../../customform/src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// Customizable Area End

import AccountActiveProfileController, {
  Props,
  configJSON,
  DocumentType
} from "./AccountActiveProfileController.web";
import SellerHeaderActive from "../../../components/src/Seller/SellerHeaderActive";
import CustomizedSteppers from "../../../components/src/Stepper";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});



export default class DocumentAcitve extends AccountActiveProfileController {
  // Customizable Area Start
  private aadharInput: HTMLInputElement | null = null;
  private pancardInput: HTMLInputElement | null = null;
  private licenseInput: HTMLInputElement | null = null;
  private passportInput: HTMLInputElement | null = null;
  private accountInput: HTMLInputElement | null = null;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
    // Customizable Area End
  }

  handleFileClick(docType: DocumentType) {
    switch (docType) {
      case "passport":
        this.aadharInput?.click();
        break;
      case "license":
        this.pancardInput?.click();
        break;
      case "authorized_signature":
        this.licenseInput?.click();
        break;
      case "moa":
        this.passportInput?.click();
        break;
        case "business_bank_account":
        this.accountInput?.click();
        break;
      default:
        break;
    }
  }


  render() {
    // Customizable Area Start
    const { selectedFiles } = this.state;
    const { errors } = this.state;

    return (
      // Required for all blocks
      <>
        <SellerHeaderActive navigation={this.props.navigation} />
        <MainGrid data-test-id="mainGridContainer" container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              <img src={documentBack} alt="" className="sideImage" />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
            <Box className={"formBox"}>
              <form data-test-id="formSubmitted"  style={{ width: "80%" }}>
                <Box width={"100%"}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "25px", marginBottom: "30px" }}>
                    <img src={view1} alt="" className="view" data-test-id="getNavigationActiveAddress" onClick={()=>this.getNavigationActiveAddress()} />
                    <Typography className={"welcomeText"}>{configJSON.AccountActivation}</Typography>
                  </Box>
                  <Box style={{ color: "#375280", fontFamily: "Lato" }}>
                  <CustomizedSteppers
                  activeStep={2}  
                  steps={['Create Profile', 'Address', 'Documents']} 
                  stepCount={3} 
                />

                  </Box>
                  <Box className="uploadBox">
                    <Typography variant="h6" style={webStyle.docTitle}>Civil ID/Passport</Typography>
                    <Box style={webStyle.documentBox as React.CSSProperties}>
                    <img
                      src={document}
                      alt="Upload"
                      className="uploadImage"
                      data-test-id="handleFileClick"
                      onClick={() => this.handleFileClick("passport")}
                      style={{ cursor: "pointer", width: "40px", height: "40px" }}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      data-test-id="handleFileChange"
                      ref={fileInput => this.aadharInput = fileInput}
                      onChange={(event) => this.handleFileChange(event, "passport")}
                    />
                    {selectedFiles.passport && (
                      <Typography variant="body1" style={webStyle.uploadedText as React.CSSProperties}>
                       {selectedFiles.passport.name}
                      </Typography>
                    )}
                    </Box>
                    {errors.passport && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.passport}</span>}
                    <Typography variant="h6" style={webStyle.docBottom}>Passport is accepted for only "Kuwaitis". Required for all 
                    shareholders with 25% shares or more plus all authorized signatories as well.</Typography>
                  </Box>
                  
                  <Box className="uploadBox">
                    <Typography variant="h6" style={webStyle.docTitle}>Commercial License</Typography>
                    <Box style={webStyle.documentBox as React.CSSProperties}>
                    <img
                      src={document} 
                      alt="Upload"
                      className="uploadImage"
                      data-test-id="handleFileClick1"
                      onClick={() => this.handleFileClick("license")}
                      style={{ cursor: "pointer",  width: "40px", height: "40px" }}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      data-test-id="handleFileChange1"
                      ref={fileInput => this.pancardInput = fileInput}
                      onChange={(event) => this.handleFileChange(event, "license")}
                    />
                    {selectedFiles.license && (
                      <Typography variant="body1" style={webStyle.uploadedText as React.CSSProperties}>
                       {selectedFiles.license.name}
                      </Typography>
                    )}
                    </Box>
                    {errors.license && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.license}</span>}

                  </Box>
                  <Box className="uploadBox">
                    <Typography variant="h6" style={webStyle.docTitle}>Authorized Signatures</Typography>
                    <Box style={webStyle.documentBox as React.CSSProperties}>
                    <img
                      src={document}
                      alt="Upload"
                      className="uploadImage"
                      data-test-id="handleFileClick2"
                      onClick={() => this.handleFileClick("authorized_signature")}
                      style={{ cursor: "pointer",  width: "40px", height: "40px" }}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      data-test-id="handleFileChange2"
                      ref={fileInput => this.licenseInput = fileInput}
                      onChange={(event) => this.handleFileChange(event, "authorized_signature")}
                    />
                    {selectedFiles.authorized_signature && (
                      <Typography variant="body1" style={webStyle.uploadedText as React.CSSProperties}>
                         {selectedFiles.authorized_signature.name}
                      </Typography>
                    )}
                    </Box>
                    {errors.authorized_signature && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.authorized_signature}</span>}

                  </Box>
                  <Box className="uploadBox">
                    <Typography variant="h6" style={webStyle.docTitle}>MOA or AOA</Typography>
                    <Box style={webStyle.documentBox as React.CSSProperties}>
                    <img
                      src={document} 
                      alt="Upload"
                      className="uploadImage"
                      data-test-id="handleFileClick3"
                      onClick={() => this.handleFileClick("moa")}
                      style={{ cursor: "pointer",  width: "40px", height: "40px" }}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      data-test-id="handleFileChange3"
                      ref={fileInput => this.passportInput = fileInput}
                      onChange={(event) => this.handleFileChange(event, "moa")}
                    />
                    {selectedFiles.moa && (
                      <Typography variant="body1" style={webStyle.uploadedText as React.CSSProperties}>
                        {selectedFiles.moa.name}
                      </Typography>
                    )}
                    </Box>
                    {errors.moa && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.moa}</span>}

                    <Typography variant="h6" style={webStyle.docBottom}>Copy of the Memorandum of Association or Articles of association of shareholding 
                    entities with 25% shares or more is required..</Typography>
                  </Box>
                  <Box className="uploadBox">
                    <Typography variant="h6" style={webStyle.docTitle}>Business Bank Account</Typography>
                    <Box style={webStyle.documentBox as React.CSSProperties}>
                    <img
                      src={document} 
                      alt="Upload"
                      className="uploadImage"
                      data-test-id="handleFileClick4"
                      onClick={() => this.handleFileClick("business_bank_account")}
                      style={{ cursor: "pointer",  width: "40px", height: "40px" }}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      data-test-id="handleFileChange4"
                      ref={fileInput => this.accountInput = fileInput}
                      onChange={(event) => this.handleFileChange(event, "business_bank_account")}
                    />
                    {selectedFiles.business_bank_account && (
                      <Typography variant="body1" style={webStyle.uploadedText as React.CSSProperties}>
                        {selectedFiles.business_bank_account.name}
                      </Typography>
                    )}
                    </Box>
                    {errors.business_bank_account && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.business_bank_account}</span>}

                    <Typography variant="h6" style={webStyle.docBottom}>The above info must be provided with a Stamped official Bank Certificate. 
                    It cannot be a personal bank account.</Typography>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: "20px" }}>
                    <Button
                      variant="contained"
                      className={"backButton"}
                      disableElevation
                      type="submit"
                      data-test-id="getNavigationActiveAddress1"
                      onClick={() => this.getNavigationActiveAddress()}
                    >Back
                    </Button>

                    <Button
                      variant="contained"
                      className={"nextButton"}
                      
                      data-test-id="AccountActivation11"
                      onClick={()=>this.handledocumentSubmit()
                      }
                    >
                      Create
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
        <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestId"
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
        <DialogContainer data-test-id="handleRedirect1"  open={this.state.isSuccess} onClose={()=>this.handleRedirect("SellerStore")}>
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">Store successfully registered!</Typography>
          </Box>
        </DialogContainer>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});
const webStyle = {
  documentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '14.5rem',
    width: '100%',
    backgroundColor: 'rgba(215, 209, 209, 0.42)',
    position:"relative",
  },
  docTitle:{
    color:"#375280",
    fontSize:"16px",
    fontWeight:700,
    fontFamily: 'Lato , sans-serif',
    marginBottom:"30px",
    marginTop:"24px"
  },
  uploadedText:{
    color:"#375280",
    fontWeight:700,
    fontFamily: 'Lato , sans-serif',
    position:"absolute",
    bottom:"40px"
  },
  docBottom:{
    color:"#94A3B8",
    fontSize:"14px",
    fontWeight:400,
    fontFamily: 'Lato , sans-serif',

  },

};
const MainGrid = styled(Grid)({
  justifyContent: "center",
  display: "flex",
  "& .sideImageBox": {
    height: "100%",
    width: "100%",
  },
  "& .sideImage": {
    width: "100%",
    height: "100%",
  },
  "& .imageGrid": {
    justifyContent: "center",
    alignItems: "flex-start",
    display: "flex",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    alignSelf: "flex-start",
    margin: "10px 0px 0px 0px",
    lineHeight: "26px",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Avenir, sans-serif",
  },
  "& .inputFeild": {
    gap: "4px",
    border: "none",
    borderRadius: "0px"
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "7%"
  },
  "& .formBox": {
    marginTop: "30px",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    display: "flex",
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: 'Lato , sans-serif',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start",

  },
  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    fontWeight: 200,
    textOverflow: "ellipsis",
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Lato, sans-serif",
    lineHeight: "26px",
    cursor: "pointer",
  },
  "& .backButton": {
    borderRadius: "0px !important",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    gap: "8px",
    border: "1px solid #CCBEB1",
    backgroundColor: "white",
    width: "50% !important",
    textTransform: "capitalize",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .nextButton": {
    color: "white !important",
    borderRadius: "0px !important",
    width: "50% !important",
    textTransform: "capitalize",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    height: "56px !important",
    "& .MuiButton-label": {
      fontSize: "16px",
      fontWeight: 600,
    }
  },
  "& .terms": {
    
    fontFamily: "Lato ,sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    margin: "10px 10px 10px 10px !important",
    color: "#979797 !important",
    width: "520px !important",
    lineHeight: "24px !important",
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
   
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
   
  },
  "& .Register": {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    width: "100%",
    padding: "10px",
  },
  "& .socialLogin": {
    alignItems: "center",
    gap: "35px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "20px",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",

    fontWeight: 700,
    lineHeight: "24px !important",
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
  },
  "& .bottomText": {
    fontWeight: 400,
    lineHeight: "24px !important",
    fontStyle: "normal !important",
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
    color: "#375280 !important",
  },
  "& .continueText": {
    width: "100%",
    lineHeight: "24px !important",
    fontStyle: "normal !important",
    display: "flex",
    marginTop: "15px",
    fontWeight: 400,
    fontSize: "16px !important",
    fontFamily: "Lato, sans-serif",
    flexDirection: "row",
    color: "#375280 !important",
    "&:before, &:after": {
      content: "''",
      flex: "1 1",
      margin: "auto",
      borderBottom: "1px solid",
    },
    "&:before": {
      marginRight: "25px"
    },
    "&:after": {
      marginLeft: "25px"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "350px !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .backButton": {
      width: "100% !important",
      "& .MuiButton-label": {
        fontSize: "16px",
        fontWeight: 600,
      }
    },
    "& .phoneInput": { width: "370px" },
    "& .continueText": {
      lineHeight: "24px !important",
      fontWeight: 400,
      fontStyle: "normal !important",
      display: "flex",
      fontSize: "16px !important",
      marginTop: "15px",
      fontFamily: "Lato, sans-serif",
      color: "#375280 !important",
      width: "100%",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
      },
      "&:before": {
        marginRight: "25px"
      },
      "&:after": {
        marginLeft: "25px"
      }
    },
  },
});

// Customizable Area End
