import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CreateSellerStoreController, {
  averageDeliveryModes,
  configJSON,
  CountrySuccess,
  FormError,
  FormErrorTouched,
  paymentModes,
  Props,
  step2Schema,
  step3Schema,
} from "./CreateSellerStoreController";
import {
  Grid,
  styled,
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  InputAdornment,
  Select,
  MenuItem,
  ListItemText,
  FormControl,
  Dialog
} from "@material-ui/core";
import Footer from "../../../components/src/Footer";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import CustomizedSteppers from "../../../components/src/Stepper";
import {
  backBtnIcon,
  editUploadIcon,
  endLocationIcon,
  removeIcon,
  selectEndIcon,
  step1,
  step2,
  step3,
  storeSuccessIcon,
  uploadIcon,
} from "./assets";
import { Formik } from "formik";
import ReactCountryFlag from "react-country-flag";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReusableSnackbar from "../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class CreateSellerStore extends CreateSellerStoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderStepper1 = () => (
    <form data-test-id="formSubmit" onSubmit={this.handleSubmit1}>
      {!this.state.step1.previewUrl ? (
        <Button variant="contained" component="label" className="upload-btn">
          <img src={uploadIcon} alt="upload" />
          <Typography className="upload-title">
            Upload Store Profile Image
          </Typography>
          <input
            type="file"
            hidden
            data-test-id="upload-image-test"
            onChange={this.handleFileChange}
            accept=".png, .jpg, .jpeg"
          />
        </Button>
      ) : (
        <Box
          className="image-preview-box"
          style={{ position: "relative", marginTop: "16px" }}
        >
          <img
            src={this.state.step1.previewUrl}
            alt="Uploaded Profile"
            className="image-preview"
            style={{
              width: "100%",
              height: 240,
              objectFit: "cover",
            }}
          />
          <Button
            variant="contained"
            component="label"
            className="edit-btn"
            style={{
              position: "absolute",
              top: "6px",
              right: "0px",
              background: "transparent",
              boxShadow: "none",
            }}
          >
            <img src={editUploadIcon} alt="edit" />
            <input
              type="file"
              data-test-id="re-upload-image-test"
              hidden
              onChange={this.handleFileChange}
              accept=".png, .jpg, .jpeg"
            />
          </Button>
        </Box>
      )}
      <FormHelperText
        data-test-id="upload-img-error"
        style={{ color: "#f44336" }}
      >
        {this.state.errorsMessageStep1.errorStoreUpload
          ? this.state.errorsMessageStep1.errorStoreUpload
          : ""}
      </FormHelperText>
      <Typography className={"inputHeading"}>{configJSON.storeName}</Typography>
      <TextField
        data-test-id="store-name"
        fullWidth
        variant="outlined"
        className={
          this.state.errorsMessageStep1.storeNameError
            ? "inputFeild2Error2"
            : "inputFeild2"
        }
        placeholder={configJSON.storeNamePlace}
        value={this.state.step1.storeName}
        onChange={this.handleStoreName}
        inputProps={{ maxLength: 120 }}
        error={this.state.errorsMessageStep1.storeNameError}
        helperText={
          this.state.errorsMessageStep1.errorStoreName
            ? this.state.errorsMessageStep1.errorStoreName
            : ""
        }
      />
      <Typography className={"inputHeading"}>
        {configJSON.storeDescription}
      </Typography>
      <TextField
        data-test-id="store-description"
        fullWidth
        variant="outlined"
        className={
          this.state.errorsMessageStep1.storeDescriptionError
            ? "inputFeild2Error2"
            : "inputFeild2"
        }
        placeholder={configJSON.storeDescriptionPlace}
        value={this.state.step1.storeDescription}
        multiline
        inputProps={{ maxLength: 420 }}
        rows={3}
        onChange={this.handleStoreDescription}
        error={this.state.errorsMessageStep1.storeDescriptionError}
        helperText={
          this.state.errorsMessageStep1.errorStoreDescription
            ? this.state.errorsMessageStep1.errorStoreDescription
            : ""
        }
      />
      <Box className="btn-container">
        <Button variant="outlined" onClick={() => this.handleBack()} className={"back-btn"} disableElevation data-test-id="form-1-back-btn">
          {configJSON.back}
        </Button>
        <Button
          variant="contained"
          className={"next-btn"}
          disableElevation
          type="submit"
        >
          {configJSON.next}
        </Button>
      </Box>
    </form>
  );

  selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{ paddingRight: 10, position: "absolute",
        right: 0,
        zIndex: 1,
        pointerEvents: "none" }}
      data-test-id="select-drop-down-img"
    />
  );

  renderStepper2 = () => (
    <Formik
      initialValues={this.state.step2}
      validationSchema={step2Schema}
      enableReinitialize
      onSubmit={this.handleSubmit2}
      data-test-id="stepper-2-form-data"
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
      }) => {
        const handleRemovePayment = (paymentMode: string) => {
          const paymentModes = this.state.payMentMode;
          setFieldValue("paymentMode", paymentModes.filter((mode) => mode !== paymentMode));
          this.setState((prevState) => ({
            ...prevState,
            payMentMode: prevState.payMentMode.filter((mode) => mode !== paymentMode),
          }));
        };
        const handlePaymentModeChange = (
          event: React.ChangeEvent<{ value: unknown }>
        ) => {
          const selectedValues = event.target.value as string[];
          this.handlePayMentMode(selectedValues);
          setFieldValue("paymentMode", selectedValues);
        };
        return (
          <form onSubmit={handleSubmit}>
            <Typography className={"inputHeading"}>
              {configJSON.address}
            </Typography>
            <TextField
              {...getFieldProps("address")}
              fullWidth
              id="address"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {<img src={endLocationIcon} alt="location" />}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.address}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "address",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "address",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              inputProps={{ maxLength: 120 }}
              data-test-id="create-store-address"
            />
            <Typography className={"inputHeading"}>
              {configJSON.area}*
            </Typography>
            <TextField
              {...getFieldProps("area")}
              fullWidth
              id="area"
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.area}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "area",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "area",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-area"
            />
            <Typography className={"inputHeading"}>
              {configJSON.block}*
            </Typography>
            <TextField
              {...getFieldProps("block")}
              fullWidth
              id="block"
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.block}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "block",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "block",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-block"
            />
            <Typography className={"inputHeading"}>
              {configJSON.mallName} ({configJSON.optionalString})
            </Typography>
            <TextField
              {...getFieldProps("mallName")}
              fullWidth
              id="mallName"
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.mallName}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "mallName",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 50 }}
              helperText={
                this.getErrorAndHelperText(
                  "mallName",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-mall-name"
            />
            <Typography className={"inputHeading"}>
              {configJSON.floor} ({configJSON.optionalString})
            </Typography>
            <TextField
              {...getFieldProps("floor")}
              fullWidth
              id="floor"
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.floor}`}
              type="text"
              className="inputFeild2"
              inputProps={{ maxLength: 50 }}
              error={
                this.getErrorAndHelperText(
                  "floor",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "floor",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-floor"
            />
            <Typography className={"inputHeading"}>
              {configJSON.unitNumber} ({configJSON.optionalString})
            </Typography>
            <TextField
              {...getFieldProps("unitNumber")}
              fullWidth
              id="unitNumber"
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.unitNumber}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "unitNumber",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "unitNumber",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-unit-number"
            />
            <Typography className={"inputHeading"}>
              {configJSON.city}
            </Typography>
            <TextField
              {...getFieldProps("city")}
              fullWidth
              id="city"
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.city}`}
              type="text"
              className="inputFeild2"
              inputProps={{ maxLength: 50 }}
              error={
                this.getErrorAndHelperText(
                  "city",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "city",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-city"
            />
            <Typography className={"inputHeading"}>
              {configJSON.zipCode}
            </Typography>
            <TextField
              {...getFieldProps("zipCode")}
              fullWidth
              id="zipCode"
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.zipCode}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "zipCode",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              helperText={
                this.getErrorAndHelperText(
                  "zipCode",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              inputProps={{ maxLength: 6 }}
              data-test-id="create-store-zip-code"
            />
            <Typography className={"inputHeading"}>
              {configJSON.paymentMode}
            </Typography>
            <FormControl
              className="finance-status"
              fullWidth
              error={touched.paymentMode && Boolean(errors.paymentMode)}
            >
              <Select
                id="paymentMode"
                name="paymentMode"
                className="inputFeild2"
                multiple
                variant="outlined"
                fullWidth
                placeholder={`${configJSON.selectString} ${configJSON.paymentMode}`}
                value={this.state.payMentMode}
                onChange={handlePaymentModeChange}
                onBlur={handleBlur}
                style={{ borderRadius: 2, backgroundColor: "#F0F0F0" }}
                IconComponent={this.selectIconRender}
                displayEmpty
                renderValue={(selected) =>
                  (selected as string[])
                    .map((item: string) => {
                      const element = paymentModes.find(
                        ({ value }) => value === item
                      );
                      return element ? element.label : "";
                    })
                    .join(", ")
                }
                data-test-id="payment-mode-data-test-id"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                error={
                  this.getErrorAndHelperText(
                    "paymentMode",
                    errors as FormError,
                    touched as FormErrorTouched
                  ).isError
                }
              >
                <MenuItem disabled value="">
                  <span>{`${configJSON.selectString} ${configJSON.paymentMode}`}</span>
                </MenuItem>
                {paymentModes.map(({ label, value }) => (
                  <MenuItem
                    style={{
                      fontFamily: "Lato, sans-serif",
                      fontSize: 16,
                      color: "#375280",
                    }}
                    key={value}
                    value={value}
                  >
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
              <Box
                style={{
                  marginTop: 8,
                  display: "flex",
                  gap: 15,
                  flexWrap: "wrap",
                }}
              >
                {this.state.payMentMode.map((payment: string) => (
                  <Box
                    style={{
                      display: "flex",
                      border: "1px solid #CCBEB1",
                      padding: "6px 8px 6px 8px",
                      gap: 6,
                      fontFamily: "Lato, sans-serif",
                      fontWeight: 400,
                      fontSize: 14,
                      color: "#375280",
                      flexWrap: "wrap-reverse",
                    }}
                  >
                    {payment}
                    <img
                      onClick={() => handleRemovePayment(payment)}
                      src={removeIcon}
                      alt="remove"
                      data-test-id={`${payment}-remove-data-id`}
                    />
                  </Box>
                ))}
              </Box>
              {touched.paymentMode && errors.paymentMode && (
                <FormHelperText>{errors.paymentMode}</FormHelperText>
              )}
            </FormControl>
            <Typography className={"inputHeading"}>
              {configJSON.instruction}
            </Typography>
            <TextField
              {...getFieldProps("instruction")}
              fullWidth
              id="zipCode"
              variant="outlined"
              placeholder={`${configJSON.enterString} ${configJSON.instructionForDrivePlace}`}
              type="text"
              className="inputFeild2"
              error={
                this.getErrorAndHelperText(
                  "instruction",
                  errors as FormError,
                  touched as FormErrorTouched
                ).isError
              }
              inputProps={{ maxLength: 120 }}
              helperText={
                this.getErrorAndHelperText(
                  "instruction",
                  errors as FormError,
                  touched as FormErrorTouched
                ).helperText
              }
              data-test-id="create-store-instruction"
            />
            <Typography className={"inputHeading"}>
              {configJSON.phoneNumber}
            </Typography>
            <Box style={{ display: "flex", gap: 14, marginTop: 10 }}>
              <Box style={{ width: "35%" }}>
                <Select
                  id="phoneCode"
                  name="phoneCode"
                  className="inputFeild2"
                  variant="outlined"
                  fullWidth
                  placeholder={`${configJSON.selectString} ${configJSON.phoneNumber}`}
                  value={values.phoneCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ borderRadius: 2, backgroundColor: "#F0F0F0" }}
                  IconComponent={this.selectIconRender}
                  data-test-id="country-code-data-test-id"
                  renderValue={(selected) => {
                    const selectedCountry = this.state.countryCode.find(
                      (country) => country.numeric_code === selected
                    );
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ReactCountryFlag
                          countryCode={
                            selectedCountry?.country_code ||
                            this.state.step2.phoneCode
                          }
                          svg
                        />
                        <span style={{ marginLeft: 8 }}>
                          ({selectedCountry?.numeric_code})
                        </span>
                      </div>
                    );
                  }}
                  MenuProps={{
                    style: {
                      minWidth: "180px",
                      height: "320px",
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {this.state.countryCode.map((country: CountrySuccess) => (
                    <MenuItem
                      key={country.numeric_code}
                      value={country.numeric_code}
                      style={{
                        fontFamily: "Lato, sans-serif",
                        fontSize: 12,
                        color: "#375280",
                        padding: "10px",
                        display: "flex",
                      }}
                    >
                      <Typography
                        style={{
                          gap: 3,
                          color: "#375280",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={country.country_code}
                          svg
                        />{" "}
                        {country.country_full_name}
                        <br />({country.numeric_code})
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <TextField
                {...getFieldProps("phoneNumber")}
                fullWidth
                id="phoneNumber"
                variant="outlined"
                placeholder={`${configJSON.enterString} ${configJSON.phoneNumber}`}
                type="text"
                className="inputFeild2"
                error={
                  this.getErrorAndHelperText(
                    "phoneNumber",
                    errors as FormError,
                    touched as FormErrorTouched
                  ).isError
                }
                helperText={
                  this.getErrorAndHelperText(
                    "phoneNumber",
                    errors as FormError,
                    touched as FormErrorTouched
                  ).helperText
                }
                data-test-id="create-store-phone-number"
              />
            </Box>
            <Box className="btn-container">
              <Button
                variant="outlined"
                className={"back-btn"}
                onClick={() => this.handleBack()}
                disableElevation
                data-test-id="back-icon-test-id"
              >
                {configJSON.back}
              </Button>
              <Button
                variant="contained"
                className={"next-btn"}
                disableElevation
                type="submit"
              >
                {configJSON.next}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );

  renderStepper3 = () => (
    <Formik
      initialValues={this.state.step3}
      validationSchema={step3Schema}
      enableReinitialize
      onSubmit={this.handleSubmit3}
      data-test-id="stepper-3-form-data"
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
      }) => {
        
        const handleDateChange = (value: Date | null, field: string) => {
          setFieldValue(field, value ? value.toISOString() : "");
        };
        return (
          <form onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                <React.Fragment key={"mon"}>
                  <Grid item xs={2}>
                    <Step3Title>
                    {this.capitalizeFirstLetter("mon")}
                    </Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("monFrom")}
                        margin="normal"
                        variant="dialog"
                        placeholder={configJSON.placeholderFrom}
                        value={this.valueStep3(values.monFrom)}
                        error={
                          this.getErrorAndHelperText(
                            "monFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        onChange={(time) => {handleDateChange(time, "monFrom")}}
                        helperText={
                          this.getErrorAndHelperText(
                            "monFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="mon-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("monTo")}
                        margin="normal"
                        placeholder={configJSON.placeholderTo}
                        variant="dialog"
                        value={this.valueStep3(values.monTo)}
                        onChange={(time) => {handleDateChange(time, "monTo")}}
                        error={
                          this.getErrorAndHelperText(
                            "monTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "monTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="mon-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"tue"}>
                  <Grid item xs={2}>
                    <Step3Title>
                    {this.capitalizeFirstLetter("tue")}
                    </Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("tueFrom")}
                        margin="normal"
                        variant="dialog"
                        placeholder={configJSON.placeholderFrom}
                        onChange={(time) => handleDateChange(time, "tueFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "tueFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueStep3(values.tueFrom)}
                        helperText={
                          this.getErrorAndHelperText(
                            "tueFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="tue-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("tueTo")}
                        margin="normal"
                        variant="dialog"
                        placeholder={configJSON.placeholderTo}
                        value={this.valueStep3(values.tueTo)}
                        onChange={(time) => handleDateChange(time, "tueTo")}
                        error={
                          this.getErrorAndHelperText(
                            "tueTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "tueTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="tue-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"wed"}>
                  <Grid item xs={2}>
                    <Step3Title>
                    {this.capitalizeFirstLetter("wed")}
                    </Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("wedFrom")}
                        margin="normal"
                        placeholder={configJSON.placeholderFrom}
                        variant="dialog"
                        value={this.valueStep3(values.wedFrom)}
                        onChange={(time) => handleDateChange(time, "wedFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "wedFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "wedFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="wed-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("wedTo")}
                        variant="dialog"
                        placeholder={configJSON.placeholderTo}
                        margin="normal"
                        value={this.valueStep3(values.wedTo)}
                        onChange={(time) => handleDateChange(time, "wedTo")}
                        helperText={
                          this.getErrorAndHelperText(
                            "wedTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        error={
                          this.getErrorAndHelperText(
                            "wedTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="wed-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"thu"}>
                  <Grid item xs={2}>
                    <Step3Title>
                    {this.capitalizeFirstLetter("thu")}
                    </Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("thuFrom")}
                        placeholder={configJSON.placeholderFrom}
                        variant="dialog"
                        value={this.valueStep3(values.thuFrom)}
                        margin="normal"
                        onChange={(time) => handleDateChange(time, "thuFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "thuFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "thuFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="thu-from-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("thuTo")}
                        placeholder={configJSON.placeholderTo}
                        onChange={(time) => handleDateChange(time, "thuTo")}
                        variant="dialog"
                        value={this.valueStep3(values.thuTo)}
                        margin="normal"
                        error={
                          this.getErrorAndHelperText(
                            "thuTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "thuTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="thu-to-date-test"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"fri"}>
                  <Grid item xs={2}>
                    <Step3Title>
                    {this.capitalizeFirstLetter("fri")}
                    </Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("friFrom")}
                        margin="normal"
                        error={
                          this.getErrorAndHelperText(
                            "friFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="fri-from-date-test"
                        variant="dialog"
                        placeholder={configJSON.placeholderFrom}
                        helperText={
                          this.getErrorAndHelperText(
                            "friFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        value={this.valueStep3(values.friFrom)}
                        onChange={(time) => handleDateChange(time, "friFrom")}
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("friTo")}
                        margin="normal"
                        variant="dialog"
                        data-test-id="fri-to-date-test"
                        placeholder={configJSON.placeholderTo}
                        value={this.valueStep3(values.friTo)}
                        onChange={(time) => handleDateChange(time, "friTo")}
                        error={
                          this.getErrorAndHelperText(
                            "friTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        helperText={
                          this.getErrorAndHelperText(
                            "friTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"sat"}>
                  <Grid item xs={2}>
                    <Step3Title>
                    {this.capitalizeFirstLetter("sat")}
                    </Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("satFrom")}
                        margin="normal"
                        placeholder={configJSON.placeholderFrom}
                        onChange={(time) => handleDateChange(time, "satFrom")}
                        error={
                          this.getErrorAndHelperText(
                            "satFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueStep3(values.satFrom)}
                        helperText={
                          this.getErrorAndHelperText(
                            "satFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        data-test-id="sat-from-date-test"
                        variant="dialog"
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("satTo")}
                        data-test-id="sat-to-date-test"
                        helperText={
                          this.getErrorAndHelperText(
                            "satTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        margin="normal"
                        placeholder={configJSON.placeholderTo}
                        error={
                          this.getErrorAndHelperText(
                            "satTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        variant="dialog"
                        onChange={(time) => handleDateChange(time, "satTo")}
                        value={this.valueStep3(values.satTo)}
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <React.Fragment key={"sun"}>
                  <Grid item xs={2}>
                    <Step3Title>
                    {this.capitalizeFirstLetter("sun")}
                    </Step3Title>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("sunFrom")}
                        variant="dialog"
                        error={
                          this.getErrorAndHelperText(
                            "sunFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        data-test-id="sun-from-date-test"
                        placeholder={configJSON.placeholderFrom}
                        onChange={(time) => handleDateChange(time, "sunFrom")}
                        margin="normal"
                        helperText={
                          this.getErrorAndHelperText(
                            "sunFrom",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        value={this.valueStep3(values.sunFrom)}
                      />
                    </TimePickerContainer>
                  </Grid>
                  <Grid item xs={5}>
                    <TimePickerContainer>
                      <TimePicker
                        {...getFieldProps("sunTo")}
                        onChange={(time) => handleDateChange(time, "sunTo")}
                        margin="normal"
                        placeholder={configJSON.placeholderTo}
                        data-test-id="sun-to-date-test"
                        error={
                          this.getErrorAndHelperText(
                            "sunTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).isError
                        }
                        value={this.valueStep3(values.sunTo)}
                        helperText={
                          this.getErrorAndHelperText(
                            "sunTo",
                            errors as FormError,
                            touched as FormErrorTouched
                          ).helperText
                        }
                        variant="dialog"
                      />
                    </TimePickerContainer>
                  </Grid>
                </React.Fragment>
                <Typography className={"inputHeading"}>
                  Average time to ship order
                </Typography>
                <FormControl className="finance-status" fullWidth error={touched.averageTime && Boolean(errors.averageTime)}>
                  <Select
                    id="averageTime"
                    name="averageTime"
                    className="inputFeild2"
                    variant="outlined"
                    fullWidth
                    placeholder={`${configJSON.selectString} Average Delivery Time`}
                    value={values.averageTime}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    data-test-id="average-time-date-test"
                    style={{
                      marginTop: 5,
                      borderRadius: 2,
                      backgroundColor: "#F0F0F0",
                    }}
                    IconComponent={this.selectIconRender}
                    displayEmpty
                    MenuProps={{
                      style: { minWidth: "180px", height: "320px" },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem disabled value="">
                      <span>{`${configJSON.selectString} Average Delivery Time`}</span>
                    </MenuItem>
                    {averageDeliveryModes.map(({ label, value }) => (
                      <MenuItem
                        style={{
                          fontFamily: "Lato, sans-serif",
                          fontSize: 16,
                          color: "#375280",
                        }}
                        key={value}
                        value={value}
                      >
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.averageTime && errors.averageTime && (
                      <FormHelperText>{errors.averageTime}</FormHelperText>
                    )}
                </FormControl>

                <Box className="btn-container">
                  <Button
                    variant="outlined"
                    className={"back-btn"}
                    onClick={() => this.handleBack()}
                    disableElevation
                    data-test-id="back-icon-test-id"
                  >
                    {configJSON.back}
                  </Button>
                  <Button
                    variant="contained"
                    className={"next-btn"}
                    disableElevation
                    type="submit"
                  >
                    {configJSON.create}
                  </Button>
                </Box>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        );
      }}
    </Formik>
  );

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SellerHeader navigation={this.props.navigation} isSellerForm />
        <MainGrid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              {this.state.activeStep === 0 && (
                <img className="sideImage" src={step1} alt="step-1" />
              )}
              {this.state.activeStep === 1 && (
                <img className="sideImage" src={step2} alt="step-2" />
              )}
              {this.state.activeStep === 2 && (
                <img className="sideImage" src={step3} alt="step-3" />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={"formGrid"}
          >
            <Box className="title-container">
              <img src={backBtnIcon} alt="backIcon" onClick={() => this.handleBack()}/>
              <Typography className="title">Create A Store</Typography>
            </Box>
            <Box className="stepper-container">
              <CustomizedSteppers
                activeStep={this.state.activeStep}
                steps={["Store Details", "Address", "Timings"]}
                stepCount={3}
              />
            </Box>
            <Box className="step-1-container">
              {this.state.activeStep === 0 && this.renderStepper1()}
              {this.state.activeStep === 1 && this.renderStepper2()}
              {this.state.activeStep === 2 && this.renderStepper3()}
            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
        <DialogContainer data-test-id="create-store-success-dialog" open={this.state.isSuccess} onClose={()=>this.handleRedirect("MyStore")}>
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">Store successfully registered!</Typography>
          </Box>
        </DialogContainer>
        <ReusableSnackbar
          open={this.state.alertBox.isAlert}
          message={this.state.alertBox.message}
          onClose={this.onAlertBoxClose}
          severity={this.state.alertBox.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const MainGrid = styled(Grid)({
  "& .imageGrid": {
    width: "100%",
    "& .sideImageBox": {
      width: "100%",
      height: "100%",
      "& .sideImage": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
  "& .formGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 100,
    flexDirection: "column",
    width: "100%",
    "@media(max-width:520px)": {
      padding: 10,
    },
    "& .title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      height: 48,
      width: "100%",
      "& .title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 800,
        fontSize: 24,
        lineHeight: 26,
        color: "#375280",
      },
    },
    "& .stepper-container": {
      paddingLeft:"15px",
      width: "100%",
    },
    "& .step-1-container": {
      width: "100%",
      "& .upload-btn": {
        boxShadow: "none",
        backgroundColor: "#F8F8F8 !important",
        width: "100%",
        height: 240,
        "& .MuiButton-label": {
          display: "flex",
          flexDirection: "column",
          gap: 16,
        },
        "& .upload-title": {
          fontFamily: "Lato, sans-serif",
          fontSize: 18,
          fontWeight: 700,
          color: "#375280",
          textTransform: "capitalize",
        },
      },
      "& .inputHeading": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        paddingTop: 26,
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
      },
      "& .inputFeild2Error2": {
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#F0F0F0",
          borderRadius: "2px",
          border: "none",
        },
        "& .MuiFormHelperText-contained": {
          margin: "0 !important",
        },
        "& .MuiInputBase-input": {
          color: "#375280",
        },
      },
      "& .MuiFormControl-root":{
        "& .MuiInputBase-root.MuiOutlinedInput-root.inputFeild2.Mui-error":{
          border: "1px solid #f44336",
        }
      },
      "& .inputFeild2": {
        "& .MuiTextField-root": {
          "&:hover": {
            border: "1px solid #F0F0F0 !important",
          },
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error": {
          border: "1px solid #f44336",
          "& .MuiInputBase-input.MuiOutlinedInput-input": {
            color: "#f44336",
          },
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#F0F0F0",
          },
        "& .MuiOutlinedInput-root": {
          backgroundColor: "#F0F0F0",
          borderRadius: "2px",
        },
        "& .MuiFormHelperText-contained": {
          margin: "0 !important",
        },
        "& .MuiInputBase-input": {
          color: "#375280",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
        },
      },
      "& .btn-container": {
        display: "flex",
        gap: 16,
        height: "54px",
        width: "100%",
        marginTop: "52px",
        "& .back-btn": {
          textTransform: "capitalize",
          color: "#375280",
          borderColor: "#CCBEB1",
          width: "50%",
          height: "100%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        "& .next-btn": {
          textTransform: "capitalize",
          color: "white",
          gap: "8px",
          backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
          width: "50%",
          height: "100%",
          borderRadius: "2px",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
      },
    },
  },
});

export const TimePickerContainer = styled(Box)({
  width: "100%",
  "& .MuiFormControl-root.MuiTextField-root": {
    width: "100%",
    height: "100%",
    margin: 0,
    borderRadius: 8,
    "& .MuiInputBase-root.Mui-error": {
      border: "1px solid #f44336",
      "& .MuiInputBase-input": {
        color: "#f44336",
      },
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      height: 56,
      "& .MuiInputBase-input": {
        color: "#94A3B8",
        height: "100%",
        padding: "15px 16px",
        fontWeight: 400,
        fontSize: 18,
        fontFamily: "Lato, sans-serif",
      },
    },
    "& .MuiInput-underline:before": {
      border: "none !important",
    },
    "& .MuiInput-underline:after": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important",
    },
  },
});

export const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});

export const Step3Title = styled(Typography)({
        paddingTop: 12,
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        fontFamily: "Lato, sans-serif",
        textOverflow: "ellipsis",
        fontStyle: "normal",
        fontSize: 18,
        fontWeight: 700,
})
// Customizable Area End
