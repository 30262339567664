import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import { backImg, view1 } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Footer from "../../../components/src/Footer";
// Customizable Area End

import AccountActiveProfileController, {
  Props,
  configJSON
} from "./AccountActiveProfileController.web";
import SellerHeaderActive from "../../../components/src/Seller/SellerHeaderActive";
import CustomizedSteppers from "../../../components/src/Stepper";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AccountActiveProfile extends AccountActiveProfileController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleInputChange = this.handleInputChange.bind(this);
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { errors } = this.state;
    
    return (
      // Required for all blocks
      <>
        <SellerHeaderActive navigation={this.props.navigation} />
        <MainGrid data-test-id="mainGridContainer1" container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imgGrid">
            <Box className="sideImageBox">
              <img src={backImg} alt="" className="sideImage" />

            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>

            <Box className={"formBox"}>

              <form data-test-id="formSubmittedSuccess" style={{ width: "80%" }}>
                <Box width={"100%"}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "25px", marginBottom: "30px" }}>
                    <img src={view1} alt="" className="view" />
                    <Typography className={"welcomeText"}>{configJSON.AccountActivation}</Typography>
                  </Box>
                  <Box style={{ color: "#375280", fontFamily: "Lato" }}>
                  <CustomizedSteppers
                  activeStep={0} 
                  steps={['Create Profile', 'Address', 'Documents']} 
                  stepCount={3}  
                />
                  </Box>
                  <Typography className={"inputHeading"}>{configJSON.full_name}*</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={configJSON.fullnamePlace}
                    data-test-id="full_name"
                    name="full_name"
                    value={this.state.formData.full_name}
                    onChange={this.handleInputChange}
                    className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                  />
                  {errors.full_name && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.full_name}</span>}


                  <Typography className={"inputHeading"}>{configJSON.emailAddress}*</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    data-test-id="emailTextInput"
                    type="email"
                    placeholder={configJSON.emailPlace}
                    name="email"
                    value={this.state.formData.email}
                    onChange={this.handleInputChange}
                    className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                  />
                  {errors.email && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.email}</span>}


                  <Typography className={"inputHeading"}>{configJSON.socailmediaAccount}*</Typography>
                
                    <TextField
                      
                      variant="outlined"
                      data-test-id="socailmediaAccount"
                      placeholder={configJSON.socailmediaAccountPlace}
                      fullWidth
                      name="social_media_account"
                      value={this.state.formData.social_media_account}
                      onChange={this.handleInputChange}
                      className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    />
                    {errors.social_media_account && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.social_media_account}</span>}

                


                  <Typography className={"inputHeading"}>{configJSON.ibanNumber}*</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-test-id="ibanNumber"
                    placeholder={configJSON.ibanplace}
                    name="iban"
                    value={this.state.formData.iban}
                    onChange={this.handleInputChange}
                    className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                  />
                  {errors.iban && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.iban}</span>}


                  <Typography className={"inputHeading"}>{configJSON.bankAccountName}</Typography>
                  <TextField
                    variant="outlined"
                    data-test-id="bankAccountName"
                    placeholder={configJSON.accountNamePlace}
                    name="account_holder_name"
                    value={this.state.formData.account_holder_name}
                    onChange={this.handleInputChange}
                    className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    fullWidth
                  />
                  {errors.account_holder_name && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.account_holder_name}</span>}

                  <Typography className={"inputHeading"}>{configJSON.bankAccountNumber}*</Typography>
                  <TextField
                    variant="outlined"
                    data-test-id="bankAccountNumber"
                    placeholder={configJSON.accountNumberPlace}
                    name="account_number"
                    value={this.state.formData.account_number}
                    onChange={this.handleInputChange}
                    className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                    fullWidth
                  />
                  {errors.account_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{errors.account_number}</span>}

                  <Box style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: '24px' }}>
                    <Button
                      variant="contained"
                      className={"backButton"}
                      disableElevation
                      type="submit"
                    >Back
                    </Button>

                    <Button
                      variant="contained"
                      className={"nextButton"}

                      data-test-id="getNavigationActiveAddressPro"
                      onClick={() => this.handleSubmit()}

                    >
                      Next
                    </Button>
                  </Box>
                  <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    data-test-id="alertTestingId"
                  >
                    <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                  </Snackbar>
                </Box>

              </form>

            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& .sideImageBox": {
    width: "100%",
    height: "100%",
  },
  "& .sideImage": {
    width: "100%",
    height: "100%",
  },
  "& .imgGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px",
    alignSelf: "flex-start",
    margin: "10px 0px 0px 0px"
  },
  "& .inputFeild": {
    gap: "4px",
    border: "none",
    borderRadius: "0px"
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "7%"
  },
  "& .formBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginTop: "30px"
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: 'Lato , sans-serif',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start",

  },
  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px"
  },
  "& .backButton": {
    textTransform: "capitalize",
    width: "50% !important",
    borderRadius: "0px !important",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    gap: "8px",
    border: "1px solid #CCBEB1",
    backgroundColor: "white",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .nextButton": {
    textTransform: "capitalize",
    width: "50% !important",
    borderRadius: "0px !important",
    color: "white !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .terms": {
    color: "#979797 !important",
    margin: "10px 10px 10px 10px !important",
    width: "520px !important",

    fontFamily: "Lato ,sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },

  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    }
  },
  "& .Register": {
    padding: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .socialLogin": {
    padding: "20px",
    width: "100%",
    display: "flex",
    gap: "35px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "24px !important",
  },
  "& .bottomText": {
    color: "#375280 !important",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "& .continueText": {
    width: "100%",
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    display: "flex",
    marginTop: "15px",
    flexDirection: "row",
    "&:before, &:after": {
      content: "''",
      flex: "1 1",
      borderBottom: "1px solid",
      margin: "auto"
    },
    "&:before": {
      marginRight: "25px"
    },
    "&:after": {
      marginLeft: "25px"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "350px !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .backButton": {
      width: "100% !important",
      "& .MuiButton-label": {
        fontWeight: 600,
        fontSize: "16px"
      }
    },
    "& .phoneInput": { width: "370px" },
    "& .continueText": {
      width: "100%",
      color: "#375280 !important",
      fontFamily: "Lato, sans-serif",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      fontWeight: 400,
      lineHeight: "24px !important",
      display: "flex",
      marginTop: "15px",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
      },
      "&:before": {
        marginRight: "25px"
      },
      "&:after": {
        marginLeft: "25px"
      }
    },
  },
});

// Customizable Area End
