import React, { ReactNode } from "react";
import { OptionType } from "../../../blocks/customform/src/Seller/InventoryManagement/PairTheProducts/InventoryPairTheProductsController";
import { Props as SelectInputProps } from "react-select";
import { search } from "../../../blocks/email-account-registration/src/assets";
import { InputBase, IconButton } from "@material-ui/core";

interface CustomInputProps extends SelectInputProps<OptionType, false> {}
export const selectStyles = {
  control: (provided: React.CSSProperties) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
    boxShadow: "none",
    border: "none",
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    color: "#333",
  }),
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 4,
    marginTop: 8,
    zIndex: 2,
    height: 240,
    boxShadow: "none",
  }),
  menuList: (provided: React.CSSProperties) => ({
    ...provided,
    padding: 0,
    maxHeight: 130,
    border: "1px solid #E2E8F0",
    borderTop: "none",
    boxShadow: "none",
  }),
  option: (
    provided: React.CSSProperties,
    state: { isSelected: boolean; isFocused: boolean }
  ) => ({
    ...provided,
    backgroundColor: state.isSelected || state.isFocused ? "E2E8F0" : "#fff",
    margin: "0px 20px",
    width: "90%",
    padding: "16px 0px",
    "&:hover": {
      backgroundColor: "#e9ecef",
    },
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 14,
    color: "#375280",
    borderBottom: "1px solid #E2E8F0",
  }),
  placeholder: (provided: React.CSSProperties) => ({
    ...provided,
    color: "#aaa",
  }),
  singleValue: (provided: React.CSSProperties) => ({
    ...provided,
    color: "#333",
  }),
  valueContainer: (provided: React.CSSProperties) => ({
    ...provided,
    padding: "15px 15px 5px",
    border: "none",
  }),
};

export const CustomInput = (props: CustomInputProps) => {
  const { value, selectProps } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectProps.onInputChange) {
      selectProps.onInputChange(event.target.value, { action: "input-change" });
    }
  };

  return (
    <InputBase
      placeholder="Search SKU"
      style={{
        border: "1px solid #CBD5E1",
        fontFamily: "Lato, sans-serif",
        background: "#FFFFFF",
        color: "#94A3B8",
        fontSize: "16px",
        fontWeight: 400,
        borderRadius: "2px",
        padding: "10px, 8px, 10px, 12px",
        width: "100%",
        height: "44px",
      }}
      startAdornment={
        <IconButton style={{ padding: "10px" }} aria-label="search">
          <img src={search} width="20px" height="20px" alt="search icon" />
        </IconButton>
      }
      value={value}
      onChange={handleChange}
    />
  );
};

export const Blanket = (props: React.HTMLProps<HTMLDivElement>) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);

export const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
}) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export const DropdownIndicator = () => <></>;

export const Menu = (props: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
        width: "97%",
        border: "1px solid #E2E8F0",
        borderBottom: "none",
      }}
      {...props}
    />
  );
};
