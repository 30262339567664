export const imgVisa = require("../assets/visa.png");
export const imgMasterCard = require("../assets/mastercard.png");
export const imgGooglePay = require("../assets/google_pay.png");
export const imgApplePay = require("../assets/apple_pay.png");
export const imgPlusBtn = require("../assets/plus_button.png")
export const imgOrderSuccess = require("../assets/order_success.png")

export const vectorImg = require("../assets/vector.svg")
export const scrollImg = require("../assets/scroll.svg")
export const backArrowImg = require("../assets/back_arrow.png")
export const backBlueArrowImg = require("../assets/backblue_arrow.svg")
export const Search = require("../assets/Search.png");
export const calendarpng = require("../assets/calendar.png");
export const Noimageavailablepng = require("../assets/Noimageavailable.png");
export const notActivs = require("../assets/notActivs.svg")
export const complate = require("../assets/complate.svg")
export const callBtn = require("../assets/callBtn.svg")
export const msgBtn = require("../assets/msgBtn.svg")
export const isActive = require("../assets/isActive.svg")
export const button_filter = require("../assets/button_filter.svg")
export const dcubesvg = require("../assets/3dcube.svg")
export const shoppingBag = require("../assets/shopping-bag.svg")
export const timerPause = require("../assets/timer-pause.svg")
export const timerPause1 = require("../assets/timer-pause1.svg")
export const group_image = require("../assets/group_image.svg")
export const add_circle = require("../assets/add-circle.svg")
export const button_close = require("../assets/button_close.svg")
export const button_arrow = require("../assets/button_arrow.svg")
export const view_Icon = require("../assets/view_Icon.svg")
export const small = require("../assets/small.svg")
export const modeofimage = require("../assets/modeofimage.svg")
