export const group_group = require("../assets/group_group_.svg");
export const button_close = require("../assets/button_close.svg");
export const button_export = require("../assets/button_export.svg");
export const button_filter = require("../assets/button_filter.svg");
export const group = require("../assets/group_.svg");
export const pdf = require("../assets/pdf.svg");
export const jpg = require("../assets/jpg.svg");
export const doc = require("../assets/doc.svg");
export const calendar = require("../assets/calendar.svg")
export const product1 = require("../assets/product-1.svg");
export const product2 = require("../assets/product-2.svg");
export const product3 = require("../assets/product-3.svg");
export const product4 = require("../assets/product-4.svg");
export const filterIcon = require("../assets/filter-icon.svg");
export const downloadIcon = require("../assets/down-load-icon.svg");
export const backBtnIcon = require("../assets/arrow-back.svg");
export const rightArrowPng = require("../assets/image_Icon.png");
export const rightArrowSvg = require("../assets/rightARROW.svg");
export const upwardArrowSvg = require("../assets/upwardArrow.svg");
export const search = require("../assets/search.png");
export const brandImage = require("../assets/brandImage.svg");