export const tshirt = require("../assets/tshirt.svg")
export const setting = require("../assets/setting.svg")
export const search = require("../assets/search.svg")
export const zara = require("../assets/zara.png")
export const heart = require("../assets/heart.svg")
export const heartLike = require("../assets/heartLike.png")
export const cross = require("../assets/cross.svg")
export const upload = require("../assets/upload.svg")
export const arrow = require("../assets/arrow.svg")
export const arrowselect = require("../assets/arrowselect.svg")
export const crossmini = require("../assets/crossmini.svg")
export const stores = require("../assets/store.svg")
export const deleteBttn = require("../assets/deleteBttn.svg")
export const product1 = require("../assets/product-1.svg")
export const product2 = require("../assets/product-2.svg")
export const product3 = require("../assets/product-3.svg")
export const btnRemoveIcon = require("../assets/button-delete-icon.svg")
export const successCheckedIcon = require("../assets/success_checked_icon.svg")
