/*global google*/
import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer
} from "react-google-maps";

interface MapComponentProps {
  googleMapURL: string;
  loadingElement: JSX.Element;
  origin: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
}

class TrackMap extends Component<MapComponentProps> {
  state = {
    directions: undefined
  };

  componentDidMount() {
    const directionsService = new google.maps.DirectionsService();    
    const { origin, destination } = this.props;    
    
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    const { origin } = this.props;    
    const GoogleMapExample = withGoogleMap((props) => (
      <GoogleMap
        defaultCenter={origin}
        defaultZoom={13}
      >
        <DirectionsRenderer
          directions={this.state.directions}
        />
      </GoogleMap>
    ));

    return (
      <div>
        <GoogleMapExample containerElement={<div className="containerElementHeight" />}
          mapElement={<div className="MapComponentHeight" />} />
      </div>
    );
  }
}

export default TrackMap;
