import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import * as Yup from "yup";
import parse from "date-fns/parse";
import dayjs from "dayjs";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";

export interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
  isJsonStringify?: boolean;
}

export interface ErrorMessage {
  errors: [
    {
      token?: string;
      contact_number?: string;
      store_name?: string;
    }
  ];
}

interface StoreName {
  exists: boolean;
}

export interface FormError {
  [key: string]: string;
}
export interface FormErrorTouched {
  [key: string]: boolean;
}

export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}

export interface Istep2 {
  address: string;
  area: string;
  block: string;
  mallName: string;
  floor: string;
  unitNumber: string;
  city: string;
  zipCode: string;
  paymentMode: string[];
  instruction: string;
  phoneCode: string;
  phoneNumber: string;
}

export interface Step3State {
  monFrom: string | null;
  monTo: string | null;
  tueFrom: string | null;
  tueTo: string | null;
  wedFrom: string | null;
  wedTo: string | null;
  thuFrom: string | null;
  thuTo: string | null;
  friFrom: string | null;
  friTo: string | null;
  satFrom: string | null;
  satTo: string | null;
  sunFrom: string | null;
  sunTo: string | null;
  averageTime: string;
}

interface CreateStoreResponse{
  data: object;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeStep: number;
  errorsMessageStep1: {
    errorStoreName?: string;
    storeNameError?: boolean;
    errorStoreDescription?: string;
    storeDescriptionError?: boolean;
    errorStoreUpload?: string;
    storeUploadError?: boolean;
  };
  step1: {
    storeName: string;
    storeDescription: string;
    selectedFile: File | null;
    previewUrl: string;
  };
  step2: Istep2;
  step3: Step3State;
  step3Response: Step3State;
  alertBox: {
    isAlert: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  };
  payMentMode: string[];
  countryCode: CountrySuccess[];
  isSuccess: boolean
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateSellerStoreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  storeNameAPICallId: string = "";
  countryCodeAPICallId: string = "";
  createStoreAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activeStep: 0,
      errorsMessageStep1: {
        errorStoreName: "",
        storeNameError: false,
        errorStoreDescription: "",
        storeDescriptionError: false,
        errorStoreUpload: "",
        storeUploadError: false,
      },
      step1: {
        storeName: "",
        storeDescription: "",
        selectedFile: null,
        previewUrl: "",
      },
      step2: {
        address: "",
        area: "",
        block: "",
        mallName: "",
        floor: "",
        unitNumber: "0",
        city: "",
        zipCode: "",
        paymentMode: [],
        instruction: "",
        phoneCode: "+965",
        phoneNumber: "",
      },
      step3: {
        monFrom: null,
        monTo: null,
        tueFrom: null,
        tueTo: null,
        wedFrom: null,
        wedTo: null,
        thuFrom: null,
        thuTo: null,
        friFrom: null,
        friTo: null,
        satFrom: null,
        satTo: null,
        sunFrom: null,
        sunTo: null,
        averageTime: "",
      },
      step3Response: {
        monFrom: "",
        monTo: "",
        tueFrom: "",
        tueTo: "",
        wedFrom: "",
        wedTo: "",
        thuFrom: "",
        thuTo: "",
        friFrom: "",
        friTo: "",
        satFrom: "",
        satTo: "",
        sunFrom: "",
        sunTo: "",
        averageTime: "",
      },
      payMentMode: [],
      alertBox: {
        isAlert: false,
        message: "",
        severity: "error",
      },
      countryCode: [],
      isSuccess: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.apiSuccessCall(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.apiFailureCall(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getCountryCodeApi();
    // Customizable Area End
  }

  apiCallFunc = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } = data;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        isJsonStringify ? JSON.stringify(body) : body ,
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSuccessCall = async (
    apiRequestCallId: string,
    responseJson: StoreName & CountrySuccess[] & CreateStoreResponse
  ) => {
    switch (apiRequestCallId){
      case this.storeNameAPICallId: 
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          errorsMessageStep1: {
            ...prevState.errorsMessageStep1,
            errorStoreName: responseJson.exists
              ? configJSON.storeNameExistMsg
              : "",
            storeNameError: responseJson.exists,
          },
          activeStep: responseJson.exists ? 0 : 1,
        }));
      }
    break;
    case this.countryCodeAPICallId:
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          countryCode: responseJson,
        }));
      }
      break;
    case this.createStoreAPICallId:
        if(responseJson.data){
          this.setState((prevState) => ({
            ...prevState,
            isSuccess: true,
          }), () => {
            setTimeout(() => {
              this.handleRedirect("MyStore");
            }, 4000);
          });
        }
        break;
    default:
      break;
  };
}

  apiFailureCall = (responseJson: ErrorMessage) => {
    if (responseJson.errors[0].token) {
      this.setState((prevState) => ({
        ...prevState,
        alertBox: {
          ...prevState.alertBox,
          severity: "error",
          message: responseJson.errors[0].token as string,
          isAlert: true,
        },
      }), () => {
        setTimeout(() => {
          this.handleRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      });
    }
    else {
      this.setState((prevState) => ({
        ...prevState,
        alertBox: {
          ...prevState.alertBox,
          severity: "error",
          message: responseJson.errors[0].contact_number as string,
          isAlert: true,
        },
      }));
    }
  };

  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState((prevState) => ({
        ...prevState,
        step1: {
          ...prevState.step1,
          selectedFile: file,
          previewUrl: URL.createObjectURL(file),
        },
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: "",
          storeUploadError: false,
        },
      }));
    }
  };

  handleStoreName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const storeName = event.target.value;
    const alphaRegx = configJSON.alphaRegx;
    if (!alphaRegx.test(storeName)) {
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeName: storeName,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreName: `${configJSON.storeName} ${configJSON.alphaErr}`,
        storeNameError: true,
      },
    }));
  }
  else{
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeName: storeName,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreName: "",
        storeNameError: false,
      },
    }));
  }
  };
  handleStoreDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const storeDescription = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      step1: {
        ...prevState.step1,
        storeDescription: storeDescription,
      },
      errorsMessageStep1: {
        ...prevState.errorsMessageStep1,
        errorStoreDescription: "",
        storeDescriptionError: false,
      },
    }));
  };

  validateStoreName = () => {
    const { storeName } = this.state.step1;
    if (storeName.trim() === "") {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreName: `${configJSON.storeNameValidMsg}${configJSON.afterReqMessage}`,
          storeNameError: true,
        },
      }));
      return false;
    }
    else if(!configJSON.alphaRegx.test(storeName)){
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          storeNameError: true,
          errorStoreName: `${configJSON.storeName} ${configJSON.alphaErr}`,
        },
        step1: {
          ...prevState.step1,
          storeName: storeName,
        },
      }));
      return false;
    }
    return true;
  };

  validateStoreDescription = () => {
    const { storeDescription } = this.state.step1;
    if (storeDescription.trim() === "") {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreDescription: `${configJSON.storeDescriptionValidMsg}${configJSON.afterReqMessage}`,
          storeDescriptionError: true,
        },
      }));
      return false;
    }
    return true;
  };

  validateStoreUpload = () => {
    const { selectedFile } = this.state.step1;
    const maxFileSize = 5 * 1024 * 1024;
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!selectedFile) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: `${configJSON.storeUploadValidMsg}${configJSON.afterReqMessage}`,
          storeUploadError: true,
        },
      }));
      return false;
    } else if (selectedFile.size > maxFileSize) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: `File size should be less than 5 MB`,
          storeUploadError: true,
        },
      }));
      return false;
    }else if (!validFileTypes.includes(selectedFile.type)) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessageStep1: {
          ...prevState.errorsMessageStep1,
          errorStoreUpload: 'Only .png, .jpg, and .jpeg files are allowed',
          storeUploadError: true,
        },
      }));
      return false;
    }

    return true;
  };

  handleSubmit1 = (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();

    if (!this.validateStoreUpload()) {
      return;
    }
    if (!this.validateStoreName()) {
      return;
    }
    if (!this.validateStoreDescription()) {
      return;
    }

    this.getStoreNameApi();
  };

  getToken = async () => {
    return await getStorageData("auth-token")
  }

  getStoreNameApi = async () => {
    this.storeNameAPICallId = await this.apiCallFunc({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.validStoreNameEndPoint}${this.state.step1.storeName}`,
      token: await this.getToken(),
    });
  };

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText };
  };

  handleBack = () => {
    if(this.state.activeStep > 0){
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
    }));
  }
  else{
    this.handleRedirect("SellerStore");
  }
  };

  handlePayMentMode = (modes: string[]) => {
    this.setState((prevState) => ({
      ...prevState,
      payMentMode: modes,
    }));
  };

  getCountryCodeApi = async () => {
    this.countryCodeAPICallId = await this.apiCallFunc({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.countryCodeEndPoint,
    });
  };

  handleSubmit2 = (values: Istep2) => {
    this.setState((prevState) => ({
      ...prevState,
      step2: values,
      activeStep: prevState.activeStep + 1,
    }));
  };

  capitalizeFirstLetter = (day: string) =>
    day.charAt(0).toUpperCase() + day.slice(1);

  parseTime = (timeString: string) => {
    return parse(timeString, "hh:mm a", new Date());
  };

  valueStep3 = (timeValue: string | null) =>{
    return timeValue ? timeValue : null
  }

  parseValidFormate = (timeString: string | null) => {
    return dayjs(timeString).format('HH:MM')
  }


  handleSubmit3 = (values: Step3State) => {
    this.setState((prevState) => ({
      ...prevState,
      step3Response:{
        ...prevState.step3Response,
        monFrom: this.parseValidFormate(values.monFrom),
        monTo: this.parseValidFormate(values.monTo),
        tueFrom: this.parseValidFormate(values.tueFrom),
        tueTo: this.parseValidFormate(values.tueTo),
        wedFrom: this.parseValidFormate(values.wedFrom),
        wedTo: this.parseValidFormate(values.wedTo),
        thuFrom: this.parseValidFormate(values.thuFrom),
        thuTo: this.parseValidFormate(values.thuTo),
        friFrom: this.parseValidFormate(values.friFrom),
        friTo: this.parseValidFormate(values.friTo),
        satFrom: this.parseValidFormate(values.satFrom),
        satTo: this.parseValidFormate(values.satTo),
        sunFrom: this.parseValidFormate(values.sunFrom),
        sunTo: this.parseValidFormate(values.sunTo),
      },
      step3:{
        ...prevState.step3,
        averageTime: values.averageTime,
        monFrom: values.monFrom,
        monTo: values.monTo,
        tueFrom: values.tueFrom,
        tueTo: values.tueTo,
        wedFrom: values.wedFrom,
        wedTo: values.wedTo,
        thuFrom: values.thuFrom,
        thuTo: values.thuTo,
        friFrom: values.friFrom,
        friTo: values.friTo,
        satFrom: values.satFrom,
        satTo: values.satTo,
        sunFrom: values.sunFrom,
        sunTo: values.sunTo,
      }
    }));
    this.createStoreAPICallFunc();
  };

  createStoreAPICallFunc = async () => {
    const {step1, step2, step3Response, payMentMode, step3} =this.state;
    const formData = new FormData();
    formData.append("business[store_name]",step1.storeName);
    formData.append("business[description]",step1.storeDescription);
    if(step1.selectedFile){
      formData.append("business[image]", step1.selectedFile);
    }
    formData.append("business[address]", step2.address);
    formData.append("business[area]",step2.area);
    formData.append("business[block]",step2.block);
    formData.append("business[mall_name]",step2.mallName);
    formData.append("business[floor]",step2.floor);
    formData.append("business[unit_number]",step2.unitNumber);
    formData.append("business[city]",step2.city);
    formData.append("business[zipcode]",step2.zipCode);
    formData.append("business[payment_mode][]", payMentMode.toString());
    formData.append("business[driver_instruction]", step2.instruction);
    formData.append("business[contact_number]", `${step2.phoneCode}${step2.phoneNumber}`);
    if(step3Response.monFrom) formData.append("business[store_operating_hours][monday][open", step3Response.monFrom);
    
    if(step3Response.monTo) formData.append("business[store_operating_hours][monday][close]", step3Response.monTo);
    if(step3Response.tueFrom) formData.append("business[store_operating_hours][tuesday][open]", step3Response.tueFrom);
    if(step3Response.tueTo) formData.append("business[store_operating_hours][tuesday][close]", step3Response.tueTo);
    if(step3Response.wedFrom) formData.append("business[store_operating_hours][wednesday][open]", step3Response.wedFrom);
    if(step3Response.wedTo) formData.append("business[store_operating_hours][wednesday][close]", step3Response.wedTo);
    if(step3Response.thuFrom) formData.append("business[store_operating_hours][thursday][open]", step3Response.thuFrom);
    if(step3Response.thuTo) formData.append("business[store_operating_hours][thursday][close]", step3Response.thuTo);
    if(step3Response.friFrom) formData.append("business[store_operating_hours][friday][open]", step3Response.friFrom);
    if(step3Response.friTo) formData.append("business[store_operating_hours][friday][close]", step3Response.friTo);
    if(step3Response.satFrom) formData.append("business[store_operating_hours][saturday][open]", step3Response.satFrom);
    if(step3Response.satTo) formData.append("business[store_operating_hours][saturday][close]", step3Response.satTo);
    if(step3Response.sunFrom) formData.append("business[store_operating_hours][sunday][open]", step3Response.sunFrom);
    if(step3Response.sunTo) formData.append("business[store_operating_hours][sunday][close]", step3Response.sunTo);
    formData.append("business[average_shipping_time]", step3.averageTime);
    formData.append("business[latitude]", "44.968046");
    formData.append("business[longitude]", "-94.420307");

      this.createStoreAPICallId = await this.apiCallFunc({
        method: configJSON.httpPostMethod,
        endPoint: configJSON.createStoreEndPoint,
        token: await this.getToken(),
        body: formData,
        isJsonStringify: false
      });
  }

  onAlertBoxClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      alertBox: {
        ...prevState.alertBox,
        isAlert: !prevState?.alertBox.isAlert,
      },
    }));
  };
  // Customizable Area End
}
// Customizable Area Start
export const step2Schema = Yup.object().shape({
  address: Yup.string().required(`${configJSON.addressReq}${configJSON.afterReqMessage}`).trim(),
  area: Yup.string().required(`${configJSON.areaReq}${configJSON.afterReqMessage}`).trim(),
  block: Yup.string().required(`${configJSON.blockReq}${configJSON.afterReqMessage}`).trim(),
  city: Yup.string().required(`${configJSON.cityReq}${configJSON.afterReqMessage}`)
  .matches(configJSON.alphaRegx, `*${configJSON.city} ${configJSON.alphaErr}`) // Ensures only alphabetic characters and spaces are allowed
  .trim(),
  unitNumber: Yup.string().matches(configJSON.numRegx, configJSON.unitNumErr).notRequired(),
  zipCode: Yup.number()
    .typeError(configJSON.zipCodeNumErr)
    .required(`${configJSON.zipCodeReq}${configJSON.afterReqMessage}`),
  paymentMode: Yup.array().of(Yup.string()).min(1, `${configJSON.paymentModeReq}${configJSON.afterReqMessage}`),
  instruction: Yup.string().required(`${configJSON.instructionReq}${configJSON.afterReqMessage}`),
  phoneNumber: Yup.string()
    .required(`${configJSON.phoneReq}${configJSON.afterReqMessage}`)
    .matches(configJSON.numRegx, configJSON.phoneNumErr) // Ensure it only contains digits
    .min(8, configJSON.phoneMinErr)
    .max(10, configJSON.phoneMaxErr),
});

export const paymentModes = [
  { label: "UPI", value: "UPI" },
  { label: "COD", value: "COD" },
  { label: "DEBIT CARD", value: "DEBIT CARD" },
  { label: "CREDIT CARD", value: "CREDIT CARD" },
];

export const averageDeliveryModes = [
  { label: "0 mins", value: "0 mins" },
  { label: "10 mins", value: "10 mins" },
  { label: "20 mins", value: "20 mins" },
  { label: "30 mins", value: "30 mins" },
  { label: "40 mins", value: "40 mins" },
  { label: "50 mins", value: "50 mins" },
  { label: "60 mins", value: "60 mins" },
];

export const step3Schema = Yup.object().shape({
  monFrom: Yup.date()
    .required("Mon From Time is required")
    .typeError("Invalid time format").nullable(),
  monTo: Yup.string()
    .required("Mon To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Mon To Time must be greater than Mon From Time",
      function (value) {
        const { monFrom } = this.parent;
        if (!monFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(monFrom));
      }
    ).nullable(),
  tueFrom: Yup.date()
    .required("Tue From Time is required")
    .typeError("Invalid time format").nullable(),
  tueTo: Yup.date()
    .required("Tue To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Tue To Time must be greater than Tue From Time",
      function (value) {
        const { tueFrom } = this.parent;
        if (!tueFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(tueFrom));
      }
    ).nullable(),
  wedFrom: Yup.date()
    .required("Wed From Time is required")
    .typeError("Invalid time format").nullable(),
  wedTo: Yup.date()
    .required("Wed To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Wed To Time must be greater than Wed From Time",
      function (value) {
        const { wedFrom } = this.parent;
        if (!wedFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(wedFrom));
      }
    ).nullable(),
  thuFrom: Yup.date()
    .required("Thu From Time is required")
    .typeError("Invalid time format").nullable(),
  thuTo: Yup.date()
    .required("Thu To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Thu To Time must be greater than Thu From Time",
      function (value) {
        const { thuFrom } = this.parent;
        if (!thuFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(thuFrom));
      }
    ).nullable(),
  friFrom: Yup.date()
    .required("Fri From Time is required")
    .typeError("Invalid time format").nullable(),
  friTo: Yup.date()
    .required("Fri To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Fri To Time must be greater than Fri From Time",
      function (value) {
        const { friFrom } = this.parent;
        if (!friFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(friFrom));
      }
    ).nullable(),
  satFrom: Yup.date()
    .required("Sat From Time is required")
    .typeError("Invalid time format").nullable(),
  satTo: Yup.date()
    .required("Sat To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Sat To Time must be greater than Sat From Time",
      function (value) {
        const { satFrom } = this.parent;
        if (!satFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(satFrom));
      }
    ).nullable(),
  sunFrom: Yup.date()
    .required("Sun From Time is required")
    .typeError("Invalid time format").nullable(),
  sunTo: Yup.date()
    .required("Sun To Time is required")
    .typeError("Invalid time format")
    .test(
      "is-after-availableTimeFrom",
      "Sun To Time must be greater than Sun From Time",
      function (value) {
        const { sunFrom } = this.parent;
        if (!sunFrom || !value) return true;
        return dayjs(value).isAfter(dayjs(sunFrom));
      }
    ).nullable(),
    averageTime: Yup.string().required("Average time to ship order is Required").trim()
});
// Customizable Area End
