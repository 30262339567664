import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { IStylistRequestResponse } from "./StylishClientsController";
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableBody: IStylistRequestResponse[];
  isSROpen: boolean;
  isAccepted: boolean;
  isRequestOpen: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylingRequestsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableBody: latestRequestTableBody,
      isSROpen: false,
      isAccepted: true,
      isRequestOpen: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  handleStylishRequestsRedirect = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  handleRequestClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isRequestOpen: !prevState.isRequestOpen,
    }));
  };

  handleReject = () => {
    this.setState(
      (prevState) => ({ ...prevState, isSROpen: true, isAccepted: false }),
      () => {
        setTimeout(() => this.handleClose(), 2000);
      }
    );
  };

  handleAccept = () => {
    this.setState(
      (prevState) => ({ ...prevState, isSROpen: true, isAccepted: true }),
      () => {
        setTimeout(() => this.handleClose(), 2000);
      }
    );
  };

  handleClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isSROpen: false,
      isRequestOpen: false,
    }));
  };
  // Customizable Area End
}
// Customizable Area Start
const latestRequestTableBody = [
  {
    id: 1,
    requestId: "#4564-4511",
    requestTime: "3:35 PM",
    requesterName: "Armonion",
    requesterGender: "Female",
    budget: "$50 - $150",
    requestStatus: "New Request",
  },
  {
    id: 2,
    requestId: "#4564-4512",
    requestTime: "3:36 PM",
    requesterName: "Armando",
    requesterGender: "Male",
    budget: "$30 - $90",
    requestStatus: "New Request",
  },
  {
    id: 3,
    requestId: "#4564-4513",
    requestTime: "3:49 PM",
    requesterName: "Emilia Clark",
    requesterGender: "Male",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
  {
    id: 4,
    requestId: "#4564-4514",
    requestTime: "3:50 PM",
    requesterName: "Armando",
    requesterGender: "Female",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
  {
    id: 5,
    requestId: "#4564-4515",
    requestTime: "3:55 PM",
    requesterName: "Armando",
    requesterGender: "Male",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
  {
    id: 6,
    requestId: "#4564-4516",
    requestTime: "4:45 PM",
    requesterName: "Armando",
    requesterGender: "Female",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
  {
    id: 7,
    requestId: "#4564-4517",
    requestTime: "5:25 PM",
    requesterName: "Armando",
    requesterGender: "Male",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
  {
    id: 8,
    requestId: "#4564-4518",
    requestTime: "5:22 PM",
    requesterName: "Armando",
    requesterGender: "Male",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
  {
    id: 9,
    requestId: "#4564-4519",
    requestTime: "5:22 PM",
    requesterName: "Armando",
    requesterGender: "Female",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
  {
    id: 10,
    requestId: "#4564-4520",
    requestTime: "6:45 PM",
    requesterName: "Armando",
    requesterGender: "Male",
    budget: "$80 - $120",
    requestStatus: "New Request",
  },
];
// Customizable Area End
