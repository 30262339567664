import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import storage from '../../../framework/src/StorageProvider';
import React, { RefObject, createRef ,ChangeEvent} from "react";

interface ResponseData {
  "errors": [{ token: string }];
  "data": [
    {
      "id": string;
      "type": string;
      "attributes": {
        "name": string;
        "description": string;
        "primary_image": string;
        "primary_price": string;
        "is_wishlist": false;
      }
    }]
  meta: {
    total_pages: number,
    next_page: number
  }
}

interface Category {
  id: string | undefined;
  type: string;
  subSubData: SubCategory[] | undefined
  attributes: {
    id: number | undefined;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
  subSubSubData?: SubCategory[]
}
type imageView = 'front' | 'back' | 'side'
interface SubCategory {
  id: string | number;
  type: string;
  subSubSubData?: SubsubsubCategory[]
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}
type AlertType = 'success' | 'error' | 'warning' | 'info';

interface SubsubsubCategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}
interface GridImage {
  name: string;
  imgUrlLink: string;
  active: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  likeWish: boolean
  showError: string
  showAlert: boolean
  catalogueDataLoading: boolean
  pageNum: number
  totalPage: number
  hasmore: boolean
  openModel: boolean
  openModelCsv: boolean
  fileName: string
  openMainForm: boolean
  genderSelect: boolean
  genderValue: string
  genValue:string
  productDescription: string
  productCare: string
  fit: string
  material: string
  brand: string
  brand1:string
  productName: string
  PrdName: string
  clothSelect: boolean
  clothValue: string
  categorySelect: boolean
  categoryValue: string
  categoryValue1: string
  listed: any
  linkStatus: number
  totalSteps: number
  personName: string[]
  selectedSizeIds: string[]
  sizevarient: string[]
  sizeMulSelect: boolean
  colorArry: string[]
  selectedColorIds: any
  colorSel: boolean
  category: Category[]
  category1: Category[]
  subsubCategory: Category[]
  varient_size: any
  varient_color: any
  create_vari: any
  selectId: number | undefined;
  sortedCategoryLoading: boolean,
  subCategoryLoading: boolean,
  filterStart: boolean,
  showScorl: boolean;
  categoryIdd:any;
  categoryValueId:any;
  subsubcategoryValueId:any;
  selectedCategory: Category | null;
  selectedCategory1: Category | null;
  subsubSelectId: number | undefined;
  variants: any;
  selectedView: imageView; 
  frontImage?: string | null;
  backImage?: string | null;
  sideImage?: string | null;
  blackSelectedImage: any
  whiteSelectedImage: any
  blueSelectedImage: any
  blackActiveGridImage: string
  whiteActiveGridImage: string
  blueActiveGridImage: string
  storeList: any;
  find_name:any;
  sku_change: any;
  catalogueDetail: any;
  selectedStores: any;
  selectAll: boolean;
  errors: { [key: string]: string };
  isVariantCreated: boolean,
  validationErrors: string[]
   variant_color: string;
   [key: string]: any;
   gridImages:any;
   isSuccess: boolean;
   isAlert: boolean;
   alertType: AlertType;
   alertMsg:string;
   selectedItem: number;
   arrowDisable: boolean;
   productId:any
   editProductDetail:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddProductCatalougeController extends BlockComponent<Props, S, SS> {
   // Customizable Area Start
   elementRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

   getCatalogueApiCallId: string
  getCategoryCallId: string = "";
  getSub_categoriesCallId: string = "";
  getSubSub_categoriesCallId: string = "";
  getVarientSizeCallId: string = "";
  getVarientColorCallId: string = "";
  getVarientCallId: string = "";
  getStoreCallId: string = "";
  getFindNameCallId: string = "";
  getskuChangeCallId: string = "";
  catalogueApiCallId: string = "";
  createCatalogueCallId: string = "";
  fileInputRef: any = React.createRef();
  createMainApiCallId: string ="";
  updateMainApiCallId: string ="";
  getDetailApiCallId: string ="";
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   // Customizable Area Start
   // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area Start
    this.state = {
      category: [],
      arrayHolder: [],
      token: "",
      likeWish: false,
      showError: '',
      showAlert: false,
      catalogueDataLoading: true,
      pageNum: 1,
      totalPage: 0,
      hasmore: true,
      openModel: false,
      openModelCsv: false,
      fileName: '',
      openMainForm: false,
      genderSelect: false,
      genderValue: 'male',
      genValue:'',
      productDescription: '',
      productCare: '',
      fit: '',
      material: '',
      brand: '',
      brand1:'',
      productName: '',
      PrdName:'',
      clothSelect: false,
      clothValue: "Womens Clothing",
      categorySelect: false,
      categoryValue: "fullSleeve",
      categoryValue1: "",
      listed: "listed",
      linkStatus: 1,
      totalSteps: 6,
      personName: [],
      selectedSizeIds: [],
      sizevarient: [],
      sizeMulSelect: false,
      colorArry: [],
      selectedColorIds: '',
      colorSel: false,
      selectId: 0,
      sortedCategoryLoading: false,
      subCategoryLoading: false,
      filterStart: false,
      showScorl: false,
      categoryIdd: null,
      categoryValueId: null,
      subsubcategoryValueId:null,
      selectedCategory: null,
      selectedCategory1: null,
      category1: [],
      subsubCategory: [],
      varient_size: [],
      varient_color: [],
      selectedStores: [],
      create_vari: [],
      storeList: [],
      find_name: '',
      sku_change: '',
      catalogueDetail:'',
      subsubSelectId: 0,
      variants: [],
      isAlert: false,
      selectedView: 'front', // default view
      frontImage: null,
      backImage: null,
      sideImage: null,
      selectAll: false,
      alertType: 'success' as AlertType,
      alertMsg:'',
      errors: {},
      isVariantCreated: false,
      selectedColors: [], 
      gridImages: [
      ],
   
      variant_color: '',
      blackActiveGridImage: "",
      whiteActiveGridImage: "",
      blueActiveGridImage: "",
      blackSelectedImage: "",
      whiteSelectedImage: "",
      blueSelectedImage: "",
      validationErrors: [],
      isSuccess: false,
      editProductDetail:false,
      arrowDisable: false,
      selectedItem: 0,
      productId:""
    };
    // Customizable Area End
   
    // Customizable Area Start
    this.getCatalogueApiCallId = ''
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
  // Customizable Area Start
     this.categoryTole()
    
     
    this.getCatalogueListRequest()
    this.varientSize()
    this.getCatalogueDetail(await storage.get('productId'))
   this.setState({productId:await storage.get('productId')})
   this.setState({editProductDetail: localStorage.getItem("editProduct")=="true"})
  
    this.varientColor()
    this.getStore()
    const selectedColors = JSON.parse(storage.get('selectedColors') || '[]');
    this.setState({ selectedColors });
  // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ token: from });
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        this.handleCatalogueAPICallId(apiRequestCallId, responseJson)
      } else {
        this.setState({ showError: 'Something went wrong.' })
      }

    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoryCallId !== null &&
      this.getCategoryCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ category: responseJsonCategory.data, sortedCategoryLoading: false })
    }

    this.getSub_categoriesCall(message);
    this.getSubSub_categories(message);
    this.manageVarientSizeResponce(message);
    this.manageVarientColorResponce(message);
    this.manageVarientCreateResponce(message);
    this.managestoreListResponce(message);
    this.manageFindNameResponce(message);
    this.manageSkuResponce(message);
    this.manageDetailResponce(message);

    // Customizable Area End
  }

  // Customizable Area Start
  getCatalogueListRequest = async () => {
    if (!this.state.pageNum || this.state.pageNum === null) {
      this.setState({ hasmore: false })
      return
    }

    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatalogueApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatalogueList + '&page=' + this.state.pageNum
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNavigationMessage = (productId:any) => {
    storage.set('productId',productId)
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ProductDetail' 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  getNavigationMessageCatalogue = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Catalogue' 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  getCatalogueDetail = async (productId:any) => {

    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCatalogueDetail}/${productId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };


  manageDetailResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDetailApiCallId !== null &&
      this.getDetailApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseDetail = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const selectedStoreIds = responseJsonseDetail.data.attributes.catalogue_variants_with_store.map((variant: any) => variant.store_info?.id);

      this.setState({
        selectedStores: selectedStoreIds,
        storeList: responseJsonseDetail.data.attributes.catalogue_variants_with_store.map((variant: any) => ({
          id: variant.store_info?.id,
          attributes: {
            store_name: variant.store_info.attributes?.store_name,
            image: variant.store_info.attributes?.image
          }
        })),
        selectAll: selectedStoreIds.length === responseJsonseDetail.data.attributes?.catalogue_variants_with_store?.length,
      });
   
      this.setState({ catalogueDetail: responseJsonseDetail.data })  
     this.state.editProductDetail&& this.setState({ catalogueDetail: responseJsonseDetail.data })    
      const gridImages = responseJsonseDetail.data.attributes.catalogue_variants.map((variant: any) => {
        const colorName = variant.attributes.catalogue_variant_color?.name;
        return {
          color: colorName,  
          images: [
            {
              name: 'Front View',
              imgUrlLink: variant.attributes.front_image || '',  
              active: !!variant.attributes.front_image,  
              file: null  
            },
            {
              name: 'Back View',
              imgUrlLink: variant.attributes.back_image || '',  
              active: !!variant.attributes.back_image,
              file: null
            },
            {
              name: 'Side View',
              imgUrlLink: variant.attributes.side_image || '',  
              active: !!variant.attributes.side_image,
              file: null
            }
          ],
          activeGridImage: variant.attributes.front_image ,  
          selectedImage: variant.attributes.front_image ,  
        };
      });
     this.state.editProductDetail&& 
        this.setState({productName:responseJsonseDetail.data.attributes.name})

       this.state.editProductDetail&&  this.setState({brand:responseJsonseDetail.data.attributes.brand_name})

       this.state.editProductDetail&& this.setState({material:responseJsonseDetail.data.attributes.material})

       this.state.editProductDetail&&  this.setState({fit:responseJsonseDetail.data.attributes.fit})
       this.state.editProductDetail&&  this.setState({productCare:responseJsonseDetail.data.attributes.prodcut_care})
       this.state.editProductDetail&& this.setState({productDescription:responseJsonseDetail.data.attributes.description})
       this.state.editProductDetail&& this.setState({listed:responseJsonseDetail.data.attributes.list_the_product})
       this.state.editProductDetail&& this.setState({genderValue:responseJsonseDetail.data.attributes.gender})
       this.state.editProductDetail&&  this.setState({categoryIdd:responseJsonseDetail?.data?.attributes?.category_id})
       this.state.editProductDetail&&  this.setState({categoryValueId:responseJsonseDetail?.data?.attributes?.sub_category_id})
       this.state.editProductDetail&& this.setState({subsubcategoryValueId:responseJsonseDetail?.data?.attributes?.sub_sub_category_id})
       this.state.editProductDetail&& this.setState({selectedColorIds: responseJsonseDetail.data.attributes?.catalogue_variants?.map((variant: any) => variant?.attributes?.catalogue_variant_color_id),})
       this.state.editProductDetail&& 
        this.setState({gridImages})
       this.state.editProductDetail&& this.setState({selectedSizeIds: responseJsonseDetail.data.attributes?.catalogue_variants?.map((variant: any) => variant?.attributes?.catalogue_variant_size_id),})
       this.state.editProductDetail&&  this.setState({create_vari: responseJsonseDetail.data.attributes.catalogue_variants.map((variant:any) => {
          const attributes = variant.attributes;
    
          return {
            id: attributes.id || null,
            catalogue_id: attributes.catalogue_id || null,
            catalogue_variant_color_id: attributes.catalogue_variant_color_id || null,
            catalogue_variant_size_id: attributes.catalogue_variant_size_id || null,
            price: attributes.price || null,
            stock_qty: attributes.stock_qty || null,
            on_sale: attributes.on_sale || null,
            sale_price: attributes.sale_price || null,
            discount_price: attributes.discount_price || null,
            length: attributes.length || null,
            breadth: attributes.breadth || null,
            height: attributes.height || null,
            created_at: attributes.created_at || null,
            updated_at: attributes.updated_at || null,
            block_qty: null, 
            sku: attributes.sku || null,
            low_stock_threshold: attributes.low_stock_threshold || null,
            is_listed: attributes.is_listed || null,
            deactivate: attributes.deactivate || null,
            discounted_percentage: null,
            discounted_price: attributes.discounted_price || null,
            remove_front_image: null, 
            remove_back_image: null,
            remove_side_image: null,
            variant_color: attributes.catalogue_variant_color ? attributes.catalogue_variant_color.name : null,
            variant_size: attributes.catalogue_variant_size ? attributes.catalogue_variant_size.name : null,
            front_image: attributes.front_image || null,
            side_image: attributes.side_image || null,
            back_image: attributes.back_image || null
          };
        })})
        
        
        this.state.editProductDetail&& 
        this.setState({personName:responseJsonseDetail.data.attributes.catalogue_variants.map((item:{
          attributes:{
            catalogue_variant_size:{
              name:string
            }
          }
        })=>{
          return item.attributes.catalogue_variant_size.name
        }) ,colorArry:responseJsonseDetail.data.attributes.catalogue_variants.map((item:{
          attributes:{
            catalogue_variant_color:{
              name:string
            }
          }
        })=>{
          return item.attributes.catalogue_variant_color.name
        })
      })
     

    }
    
  }

  handleHorizontalScroll = (element: RefObject<HTMLDivElement>, speed: number, distance: number, step: number) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (element.current) {
        element.current.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
          clearInterval(slideTimer);
        }
        this.setState({ arrowDisable: element.current.scrollLeft === 0 });
      }
    }, speed);
  };

  handleClick = () => {
    this.setState(prevState => ({ selectedItem: Math.max(prevState.selectedItem - 1, 0) }));
    this.handleHorizontalScroll(this.elementRef, 25, 100, -10);
  };

  handleNextClick = () => {
    this.setState(prevState => ({
      selectedItem: Math.min(prevState.selectedItem + 1, this.state.catalogueDetail?.attributes?.catalogue_variants[0]?.attributes?.side_image ? 2 : 1)
    }));
    this.handleHorizontalScroll(this.elementRef, 25, 100, 10);
  };
  handleCatalogueAPICallId = (apiRequestCallId: string, responseJson: ResponseData | {
    errors: [{ token: string }], data: [
      {
        "id": string;
        "type": string;
        "attributes": {
          "name": string;
          "description": string;
          "primary_image": string;
          "primary_price": string;
          "is_wishlist": false;
        }
      }]
    meta: {
      total_pages: number,
      next_page: number
    }
  }) => {
    if (apiRequestCallId === this.getCatalogueApiCallId) {
      if (responseJson?.data) {
        this.setState({ arrayHolder: this.state.arrayHolder.concat(responseJson?.data) })
        this.setState({ pageNum: responseJson?.meta?.next_page })
        this.setState({ totalPage: responseJson?.meta?.total_pages })
      }
      if (responseJson?.errors) {
        this.setState({ arrayHolder: [] })
        this.setState({ catalogueDataLoading: false })
      }
      if (responseJson?.errors[0]?.token) {
        this.setState({ showError: responseJson?.errors[0].token + ' Please login again' })
        this.setState({ showAlert: true })
      }
    }
  }
  closeModel = () => {
    this.setState({ showAlert: false })
  }

  getSub_categoriesCall = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSub_categoriesCallId !== null &&
      this.getSub_categoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSub_categories = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      this.setState({ category1: responseJsonSub_categories.data })
    }
  }


  formattedDate = () => {
    const newDate = this.state.catalogueDetail.attributes?.catalogue_variants_with_store[0].store_info.attributes?.expected_delivery_time || "";
    if (!newDate) return ""
    const date = new Date(newDate);
    let dateString = date.toDateString().split(' ')
    return `${dateString[0]}, ${dateString[2]} ${dateString[1]} - ${dateString[3]}`
  }
  categoryTole = async () => {
    this.setState({ sortedCategoryLoading: true })

    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoryCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categories
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  sub_category = async (categoryId: number | undefined) => {
    this.setState({ selectId: categoryId, subCategoryLoading: true, filterStart: true, showScorl: false })
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSub_categoriesCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sub_categories + categoryId
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  subsubCategorey = async (subsubCategoreyId: number | undefined) => {
    this.setState({ subsubSelectId: subsubCategoreyId, filterStart: true, showScorl: false })
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubSub_categoriesCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sub_sub_categories}=${subsubCategoreyId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  varientSize = async () => {

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVarientSizeCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.varient_size}`
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  getSubSub_categories = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSubSub_categoriesCallId !== null &&
      this.getSubSub_categoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSubSub_categories = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ subsubCategory: responseJsonSubSub_categories.data })

    }
  }

  manageVarientSizeResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVarientSizeCallId !== null &&
      this.getVarientSizeCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonSize = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ varient_size: responseJsonSize.data })

    }
  }

  varientColor = async () => {

    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVarientColorCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.varient_color}`
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  manageVarientColorResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVarientColorCallId !== null &&
      this.getVarientColorCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonColor = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ varient_color: responseJsonColor.data })

    }
  }
  manageVarientCreateResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVarientCallId !== null &&
      this.getVarientCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseCreate = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ create_vari: responseJsonseCreate })
    }
  }



  createVarient = async (variant_size_ids: any, variant_color_ids: any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVarientCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.create_varient}?variant_size_ids=[${variant_size_ids}]&variant_color_ids=[${variant_color_ids}]`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }


  
  handleDeleteImage = (color: string, viewName: string) => {
    this.setState((prevState) => ({
      gridImages: prevState.gridImages.map((grid: any) => {
        if (grid.color === color) {
          const updatedImages = grid.images.map((image: any) => {
            if (image.name === viewName) {
              return {
                ...image,
                imgUrlLink: "", 
                file: null,
                active: false,   
              };
            }
            return image; 
          });
  
          const isDeletingActiveImage = grid.activeGridImage === viewName;
  
          return {
            ...grid,
            images: updatedImages,
            selectedImage: isDeletingActiveImage ? '' : grid.selectedImage,
            activeGridImage: isDeletingActiveImage ? '' : grid.activeGridImage,
          };
        }
        return grid;
      })
    }));
  };
  
  

  handleGridImage = (color: string, viewName: string, event: ChangeEvent<HTMLInputElement>) => {
    const { gridImages } = this.state;
    const file = event.target.files ? event.target.files[0] : null;
  
    const updatedImages = gridImages.map((grid: any) => {
      if (grid.color === color) {
        const updatedImages = grid.images.map((image: any) => {
          if (image.name === viewName) {
            return {
              ...image,
              imgUrlLink: file ? URL.createObjectURL(file) : "",
              file,
              active: true,
            };
          }
          return image;
        });
  
        return {
          ...grid,
          images: updatedImages,
          selectedImage: file ? URL.createObjectURL(file) : "",
        };
      }
      return grid;
    });
  
    this.setState({ gridImages: updatedImages, selectedImg: file ? URL.createObjectURL(file) : '' });
  };
  
  handleActiveGrid = (color: string, viewName: string) => {
    const { gridImages } = this.state;
    
    const updatedImages = gridImages.map((grid:any) => {
      if (grid.color === color) {
        const selectedImage = grid.images.find((image:any) => image.name === viewName)?.imgUrlLink || "";
  
        const updatedImages = grid.images.map((image:any) => {
          return { 
            ...image, 
            active: image.name === viewName 
          };
        });
  
        return { 
          ...grid, 
          images: updatedImages, 
          activeGridImage: viewName,
          selectedImage,
        };
      }
      return grid;
    });
  
    this.setState({ gridImages: updatedImages, selectedImg: updatedImages.find((grid:any) => grid.color === color)?.selectedImage || "" });
  };
  
  
  getStore = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStoreCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.store_list}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  managestoreListResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStoreCallId !== null &&
      this.getStoreCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseStore = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ storeList: responseJsonseStore.data })
    }
  }




  validateForm = async () => {
    const errors: { [key: string]: string } = {};
    const textOnlyPattern = /^[a-zA-Z\s]*$/;

    await this.validateProductName(errors);
    this.validateRequiredFields(errors);
    this.validateTextFields(errors, textOnlyPattern);

    this.setState({ errors });

    return Object.keys(errors).length === 0;
};


validateProductName = async (errors: { [key: string]: string }) => {
  if (!this.state.productName || this.state.productName.trim() === '') {
      errors.productName = 'Please enter a valid product name.';
  } else {
      await this.findNameOnchange(this.state.productName);
      if (this.state.find_name?.exists) {
          errors.productName = 'Product name already exists';
      }
  }
};

validateRequiredFields = (errors: { [key: string]: string }) => {
    const { genderValue, brand, clothValue, category1, subsubCategory } = this.state;

    if (!genderValue) errors.genderValue = 'Please enter a valid gender';
    if (!brand) errors.brand = 'Please enter a valid brand';
    if (!clothValue) errors.clothValue = 'Please enter a valid category';
    if (!category1) errors.subCategory = 'Please enter a valid subcategory';
    if (!subsubCategory) errors.subSubCategory = 'Please enter a valid sub-subcategory';
};

validateTextFields = (errors: { [key: string]: string }, pattern: RegExp) => {
    const { material, fit, productCare, productDescription } = this.state;

    this.checkPattern('material', material, pattern, errors);
    this.checkPattern('fit', fit, pattern, errors);
    this.checkPattern('productCare', productCare, pattern, errors);

    if (!productDescription) errors.productDescription = 'Please enter a valid description';
};

checkPattern = (
    field: string, 
    value: string, 
    pattern: RegExp, 
    errors: { [key: string]: string }
) => {
    if (!value || !pattern.test(value)) {
        errors[field] = `Please enter a valid ${field}`;
    }
};

validateSkuPriceQuantity = async (): Promise<boolean> => {
  const { create_vari } = this.state;
  let validationErrors: string[] = [];

  const validateField = (fieldValue: any, errorMessage: string): string => {
    return !fieldValue ? errorMessage : '';
  };

  const validateNumericField = (fieldValue: any, errorMessage: string): string => {
    return isNaN(Number(fieldValue)) ? errorMessage : '';
  };

  for (let index = 0; index < create_vari.length; index++) {
    const variant = create_vari[index];

    create_vari[index].skuError = validateField(variant.sku, "Please fill SKU");

    create_vari[index].quantityError = validateField(variant.stock_qty, "Please fill Quantity") ||
                                       validateNumericField(variant.stock_qty, "Quantity must be a number");

    create_vari[index].priceError = validateField(variant.price, "Please fill Price") ||
                                    validateNumericField(variant.price, "Price must be a number");

    if (create_vari[index].skuError || create_vari[index].quantityError || create_vari[index].priceError) {
      validationErrors.push(`${create_vari[index].skuError} ${create_vari[index].quantityError} ${create_vari[index].priceError}`.trim());
    }
  }

  this.setState({ create_vari, validationErrors });

  return validationErrors.length === 0;
};




  handleNextStep = async () => {
    const isValid = await this.validateForm();
    if (isValid) {
      const skuValidation = await this.validateSkuPriceQuantity();
      if (skuValidation) {
        const { linkStatus, totalSteps } = this.state; 
       
  
        if (linkStatus === totalSteps - 1) {
          if (this.state.selectedStores.length === 0) {
            this.setState({isAlert:true,alertType:'error',alertMsg: 'Please select atleast one store'})
            return; 
          }
          await this.createMainApi();
          this.setState((prevState) => ({
            ...prevState,
            isSuccess: true,
        }), () => {
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        });
        } else {
          this.setState({ linkStatus: linkStatus + 1 });
        }
      }
    }
  };




  handleUpdateNextStep = async () => {
  
    
        const { linkStatus, totalSteps } = this.state; 
       
  
        if (linkStatus === totalSteps - 1) {
          if (this.state.selectedStores.length === 0) {
            this.setState({isAlert:true,alertType:'error',alertMsg: 'Please select atleast one store'})
            return; 
          }
          await this.updateMainApi();
          this.setState((prevState) => ({
            ...prevState,
            isSuccess: true,
        }), () => {
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        });
        } else {
          this.setState({ linkStatus: linkStatus + 1 });
        }
      
    
  };
  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  
  
  findNameOnchange = async(name:any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFindNameCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.find_name}?name=${name}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  manageFindNameResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFindNameCallId !== null &&
      this.getFindNameCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseFindName = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ find_name: responseJsonseFindName })
    }
  }






  skuOnchange = async(name:any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getskuChangeCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sku_change}?variant_skus=["${name}"]`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  manageSkuResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getskuChangeCallId !== null &&
      this.getskuChangeCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseSku = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ sku_change: responseJsonseSku })
    }
  }

  createMainApi=async ()=>{
    const token = await getStorageData("auth-token");
    const header = {
       token: token,
    };
      const formData = new FormData();
        formData.append("name", this.state.productName);
        formData.append("gender", this.state.genderValue);
        formData.append("brand_name", this.state.brand);
        formData.append("category_id",this.state.categoryIdd);
        formData.append("sub_category_id", this.state.categoryValueId);
        formData.append("sub_sub_category_id", this.state.subsubcategoryValueId);
        formData.append("material", this.state.material);
        formData.append("fit", this.state.fit);
        formData.append("prodcut_care", this.state.productCare);
        formData.append("description", this.state.productDescription);
        formData.append("list_the_product", this.state.listed);
        this.state.selectedColorIds.forEach((colorId:any, index:any) => {
          formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_color_id]`, colorId);
        });
        this.state.selectedSizeIds.forEach((sizeId, index) => {
          formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_size_id]`,sizeId)
        })
       
        this.state.create_vari.forEach((variant:any, index:any) => {
          formData.append(`catalogue_variants_attributes[${index}][stock_qty]`, variant.stock_qty);
          formData.append(`catalogue_variants_attributes[${index}][price]`, variant.price);
          formData.append(`catalogue_variants_attributes[${index}][sku]`, variant.sku);
        });

        this.state.gridImages.forEach((grid: any, index: number) => {
          const frontImage = grid.images.find((image: any) => image.name === 'Front View' && image.file);
          const backImage = grid.images.find((image: any) => image.name === 'Back View' && image.file);
          const sideImage = grid.images.find((image: any) => image.name === 'Side View' && image.file);
      
          if (frontImage) {
           
            formData.append(`catalogue_variants_attributes[${index}][front_image]`, frontImage.file);
          }
          if (backImage) {
            formData.append(`catalogue_variants_attributes[${index}][back_image]`, backImage.file);
          }
          if (sideImage) {
            formData.append(`catalogue_variants_attributes[${index}][side_image]`, sideImage.file);
          }
        });
       
      
        const storeIdsAsNumbers = this.state.selectedStores.map((storeId:any) => Number(storeId));
        formData.append("store_ids[]", JSON.stringify(storeIdsAsNumbers));

       
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createMainApiCallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_catalogue/catalogues`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `POST`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateMainApi=async ()=>{
      const productIdId=await storage.get('productId');
      const token = await getStorageData("auth-token");
      const header = {
         token: token,
      };
        const formData = new FormData();
        formData.append("fit", this.state.fit);
        formData.append("material", this.state.material);
        formData.append("sub_sub_category_id", this.state.subsubcategoryValueId);
        formData.append("sub_category_id", this.state.categoryValueId);
        formData.append("prodcut_care", this.state.productCare);
        formData.append("category_id",this.state.categoryIdd);
        formData.append("description", this.state.productDescription);
        formData.append("brand_name", this.state.brand);
        formData.append("list_the_product", this.state.listed);
        formData.append("gender", this.state.genderValue);
        formData.append("name", this.state.productName);
          this.state.selectedColorIds.forEach((colorId:any, index:any) => {
            formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_color_id]`, colorId);
          });
          this.state.selectedSizeIds.forEach((sizeId, index) => {
            formData.append(`catalogue_variants_attributes[${index}][catalogue_variant_size_id]`,sizeId)
          })
         
          this.state.create_vari.forEach((variant:any, index:any) => {
            formData.append(`catalogue_variants_attributes[${index}][sku]`, variant.sku);
            formData.append(`catalogue_variants_attributes[${index}][price]`, variant.price);
            formData.append(`catalogue_variants_attributes[${index}][stock_qty]`, variant.stock_qty);
          });
  
          this.state.gridImages.forEach((grid: any, index: number) => {
            const frontImage = grid.images.find((image: any) => image.name === 'Front View' && image.file);
            const backImage = grid.images.find((image: any) => image.name === 'Back View' && image.file);
            const sideImage = grid.images.find((image: any) => image.name === 'Side View' && image.file);
        
            if (frontImage) {
             
              formData.append(`catalogue_variants_attributes[${index}][front_image]`, frontImage.file);
            }
            if (backImage) {
              formData.append(`catalogue_variants_attributes[${index}][back_image]`, backImage.file);
            }
            if (sideImage) {
              formData.append(`catalogue_variants_attributes[${index}][side_image]`, sideImage.file);
            }
          });
         
        
          const storeIdsAsNumbers = this.state.selectedStores.map((storeId:any) => Number(storeId));
          formData.append("store_ids[]", JSON.stringify(storeIdsAsNumbers));
  
         
      
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.updateMainApiCallId = requestMessage.messageId;
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_catalogue/catalogues/${productIdId}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PUT`
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    oncloseAlert = () => {
      this.setState({ isAlert: false });
    };
    
  // Customizable Area End
}
