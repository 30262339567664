import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start

interface ErrorsMessage {
  nameErrorMsg?:string;
  emailErrorMsg?:string;
  subjectErrorMsg?:string;
  descriptionErrorMsg?:string;
  
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  getHelpData: {
    id: number;
    accordionHeading: string;
    accordionSummary: string;
  }[];
  expanded:string | number | boolean;
  name: string;
  email: string;
  errorsMessage: ErrorsMessage;
  subject: string;
  description: string;
  contactSupport: boolean;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info',
  alertMsg: string;
  usertype: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerHelpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contactUsPostApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      getHelpData: [{
        id:1, 
        accordionHeading: "Refund or payment complete", 
        accordionSummary: "If someone refunds your money, they return it to you, for example because you have too much or because you have returned goods to the shop."
      },{
        id: 2,
        accordionHeading: "Offers, Discounts, Coupons",
        accordionSummary: "A limited-time offer is any type of special deals such as free ship shipping, discount coupon, free gift, or reward which is available for a certain time period."
      }, {
        id: 3,
        accordionHeading: "Others",
        accordionSummary: "A return policy indicates rules and conditions about if, when, and for how long your customers can return a purchase and receive reimburesement."
      }],
      expanded: false,
      name: "",
      email:'',
      subject: "",
      errorsMessage: {
        nameErrorMsg:"",
        emailErrorMsg:"",
        subjectErrorMsg:"",
        descriptionErrorMsg:"",
      },
      description: "",
      contactSupport: false,
      isAlert: false,
      alertType: "success",
      alertMsg: "", usertype: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.contactUsPostApiCallID) {
      if(responseJson?.data){
        this.setState({
          contactSupport: false,
          isAlert: true,
          alertMsg: "Contact request submitted successfully."
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start 
  async componentDidMount () {
    const userRole = await getStorageData('userRole', true);
    this.setState({
      usertype: userRole?.userType
    })
  }
  handleChange = (panel: number | string) => (event: any, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel.toString() : false });
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
};

  handleNameChange = (event: string) => {
    const contactName = event;
    this.setState({
      name: contactName,
      errorsMessage: {
        nameErrorMsg: ""
      }
    });
  };

  handleSubjectChange = (event: string) => {
    const subjectChange = event;
    this.setState({
      subject: subjectChange,
      errorsMessage: {
        subjectErrorMsg: ""
      }
    });
  };

  handleContactClick = () => {
    this.setState({
      contactSupport: true
    });
  }

  handleContactSupport = () => {
    this.setState({
      contactSupport: false
    });
  }

  handleDescriptionChange = (event: string) => {
    const descriptionChange = event;
    this.setState({
      description: descriptionChange,
      errorsMessage: {
        descriptionErrorMsg: ""
      }
    });
  };

  validateForm = () => {
    let valid = true;
    let {errorsMessage} = this.state;
    const emailRegex = configJSON.emailRegex;
    const nameRegex = configJSON.nameRegex;

  
    if (this.state.name === "" || !nameRegex.test(this.state.name)) {
      errorsMessage.nameErrorMsg = '*Full Name is required';
      valid = false;
    }
  
    if (this.state.email === "" || !emailRegex.test(this.state.email)) {
      errorsMessage.emailErrorMsg = '*Email is required';
      valid = false;
    }
  
    if (this.state.subject.length < 1) {
      errorsMessage.subjectErrorMsg = '*Subject is required';
      valid = false;
    }
  
    if (this.state.description.length < 1) {
      errorsMessage.descriptionErrorMsg = '*Description is required';
      valid = false;
    }
  
    this.setState({ errorsMessage });
    return valid;
  }

  postContactUsApi = async () => {
    if (!this.validateForm()) {
      return false;
    }
    const { name, email, subject, description } = this.state;

    const formData = new FormData();
    const getToken = await getStorageData("auth-token")
    formData.append('data[query_type]', "Admin-Related Concerns");
    formData.append('data[name]', name);
    formData.append('data[email]', email);
    formData.append('data[subject]', subject);
    formData.append('data[description]', description);

    const headers = {
      redirect: "follow",
      "token": getToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.contactUsPostApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postContactusEndPoints}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleEmailChange = (event: string) => {
    const emailChange = event;
    this.setState({
      email: emailChange,
      errorsMessage: {
        emailErrorMsg: ""
      }
    });
  }

  generateClassName = (
    error: string | undefined,
    baseClassName: string,
    errorClassName: string
  ) => {
    return error ? errorClassName : baseClassName;
  };
  // Customizable Area End
}
