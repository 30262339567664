import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
type AlertType = 'success' | 'error' | 'warning' | 'info';
interface BankDetail {
  id: string;
  type: string;
  attributes: {
    bank_name: string;
    account_holder_name: string;
    bank_code: string;
    account_number: string;
    iban: string;
    is_default: boolean;
  };
}

interface StylistAccountAttributes {
  status: number;
  preferred_language: string;
  location_info: string;
  address: string;
  contact_details: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  pinterest: string;
  biography: string;
  year_of_experience: string;
  expertise: string;
  block: string;
  mall_name: string;
  floor: string;
  unit_number: number;
  city: string;
  zipcode: string;
  contact_number: string;
  google_pay: string | null;
  apple_pay: string | null;
  account_id: number;
  passport_status: string | null;
  moa_status: string | null;
  authorized_signature_status: string | null;
  commercial_license_status: string | null;
  business_bank_account_status: string | null;
  country_code: string | null;
  phone_number: string | null;
  full_phone_number: string | null;
  skip: boolean;
  bank_detail: {
    data: BankDetail[];
  };
  stylist_redirect_flag: string | null;
  passport: string | null;
  authorized_signature: string | null;
  commercial_license: string | null;
  moa: string | null;
  business_bank_account: string | null;
  profile_picture: string;
}

interface StylistAccount {
  id: string;
  type: string;
  attributes: StylistAccountAttributes;
}
export interface ActiveBread {
  activeBread: "1" | "2" | "3";
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}
interface FormErrors {
  profileBio?: string;
  areaofExpertise?: string;
  yearsofExperience?: string;
  company_contact_number?: string;
  preferredLanguage?: string;
  Address?: string;
  Area?: string;
  instagram?: string;
  facebook?: string;
  tiktok?: string;
  pinterest?: string;
  accountName?: string;
  IBANNumber?: string;
  accountNumber?: string;
}
interface FormFieldData {
  profileBio: string;
  areaofExpertise: string;
  yearsofExperience: string | number;
  Address: string;
  Instructions: string;
  zipCode: string | number;
  accountName: string;
  IBANNumber: string | number;
  accountNumber: string | number;
  City: string;
  Floor: string;
  unitNumber: string;
  mallName: string;
  Block: string;
  Area: string;
  company_contact_number: string | number;
  instagram: string;
  facebook: string;
  tiktok: string;
  pinterest: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  alertMsg: string;
  alertType: AlertType;
  visible: boolean,
  emailError: boolean;
  countrySuccess: Array<CountrySuccess>;
  selectedCountry: CountrySuccess | null;
  formData: FormFieldData;
  profileId: null;
  numberData: string;
  errors: FormErrors;
  errorsSelectLanguage: boolean;
  selectedFile: (File | null)[];
  combineImg: (string | null)[];
  presentImage: { selectedProFiles: (File | null)[], updateProImages: (string | null)[], description: string }[];
  dropdownOpen1: boolean;
  isCountryData1: string;
  isCountryData: string;
  presentImageCombineImg: { selectedFilecivilID: (File)[], combineImgcivilID: (string | null)[], newSelectedFilesType: string[] }[];
  commercialLicense: { selectedCommercialLicense: (File)[], combineImgCommercialLicense: (string | null)[], newSelectedCommercialLicense: string[] }[];
  authorizedSignatures: { selectedAuthorizedSignatures: (File)[], combineImgAuthorizedSignatures: (string | null)[], newSelectedAuthorizedSignatures: string[] }[];
  moaOrAoa: { selectedMoaOrAoa: (File)[], combineImgMoaOrAoa: (string | null)[], newSelectedFMoaOrAoa: string[] }[];
  businessBankAccount: { selectedBankAccount: (File)[], combineImgBankAccount: (string | null)[], newSelectedBankAccount: string[] }[];
  uplodeProfileEdite: StylistAccount | null
  isAlert: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistAccountActiveProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailLoginFormAPICallId: string = "";
  countryCodeAPIEditCallId: string = '';
  AccountActiveApiCallId: string = '';
  createProfileApiCallId: string = '';
  uplodeProfileApiCallId: string = '';
  customProfileGetCallId: string = '';
  createUplodeDocumentApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      alertMsg: "",
      alertType: 'success' as AlertType,
      isAlert: false,
      visible: false,
      uplodeProfileEdite: null,
      profileId: null,
      businessBankAccount: [],
      moaOrAoa: [],
      authorizedSignatures: [],
      presentImageCombineImg: [],
      presentImage: [],
      commercialLicense: [],
      isCountryData: "+965",
      isCountryData1: "",
      dropdownOpen1: false,
      countrySuccess: [{
        numeric_code: "",
        country_full_name: "",
        country_code: "",
        country_flag: ""
      }],
      emailError: false,
      selectedCountry: null,
      formData: {
        company_contact_number: "",
        instagram: "",
        facebook: "",
        tiktok: "",
        pinterest: "",
        profileBio: "",
        areaofExpertise: "",
        yearsofExperience: "",
        Address: "",
        Instructions: "",
        zipCode: "",
        accountName: "",
        IBANNumber: "",
        accountNumber: "",
        City: "",
        unitNumber: "",
        Floor: "",
        mallName: "",
        Block: "",
        Area: "",
      },
      numberData: "Select Language",
      errors: {},
      errorsSelectLanguage: false,
      selectedFile: [],
      combineImg: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeAPIEditCallId !== null &&
      this.countryCodeAPIEditCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ countrySuccess: responseJson as CountrySuccess[] })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createProfileApiCallId !== null &&
      this.createProfileApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if ('error' in responseJson.data) {
        this.setState({ isAlert: true, alertMsg: responseJson.error, alertType: 'error' });
      } else {
        const storageKeys = [
          'combineImg',
          'profileBio',
          'areaofExpertise',
          'yearsofExperience',
          'instagram',
          'facebook',
          'tiktok',
          'pinterest',
          'numberData',
          'Address',
          'Area',
          'Block',
          'mallName',
          'Floor',
          'unitNumber',
          'City',
          'zipCode',
          'Instructions',
          'accountName',
          'accountNumber',
          'IBANNumber',
          'selectedFile',
          'company_contact_number'
        ];
        storageKeys.forEach((keys) => removeStorageData(keys));
        this.setState({ isAlert: true, alertMsg: "Your protfolio has been updated", alertType: 'success' });
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistPortfolioAcitve');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uplodeProfileApiCallId !== null &&
      this.uplodeProfileApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ isAlert: true, alertMsg: "Your protfolio has been updated", alertType: 'success' }, () => {
          setTimeout(() => { this.uplodeDocuments() }, 1000);
        })
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.customProfileGetCallId !== null &&
      this.customProfileGetCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const mappedFormData = {
        company_contact_number: responseJson.data.attributes.contact_number,
        instagram: responseJson.data.attributes.instagram,
        facebook: responseJson.data.attributes.facebook,
        tiktok: responseJson.data.attributes.tiktok,
        pinterest: responseJson.data.attributes.pinterest,
        profileBio: responseJson.data.attributes.biography,
        areaofExpertise: responseJson.data.attributes.expertise,
        yearsofExperience: responseJson.data.attributes.year_of_experience,
        Address: responseJson.data.attributes.address,
        Instructions: responseJson.data.attributes.location_info,
        zipCode: responseJson.data.attributes.zipcode || "",
        accountName: responseJson.data.attributes.bank_detail.data[0].attributes.account_holder_name,
        IBANNumber: responseJson.data.attributes.bank_detail.data[0].attributes.iban,
        accountNumber: responseJson.data.attributes.bank_detail.data[0].attributes.account_number,
        City: responseJson.data.attributes.city,
        unitNumber: responseJson.data.attributes.unit_number,
        Floor: responseJson.data.attributes.floor,
        mallName: responseJson.data.attributes.mall_name,
        Block: responseJson.data.attributes.block,
        Area: responseJson.data.attributes.block,
      };
      this.setState({
        formData: mappedFormData, combineImg: [responseJson.data.attributes.profile_picture], numberData: responseJson.data.attributes.preferred_language, profileId: responseJson.data.id
      });
    }
    this.uplodeDoc(message)
    // Customizable Area End
  }

  // Customizable Area Start
  uplodeDoc = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createUplodeDocumentApiCallId !== null &&
      this.createUplodeDocumentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ visible: true })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleCountrySelect1 = (country: CountrySuccess, code: string) => {
    this.setState({
      selectedCountry: country,
      dropdownOpen1: false,
      isCountryData: code
    });
  };
  toggleDropdown1 = () => {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  };
  async componentDidMount() {
    this.toggleVariantsListOffer()
    this.profileDataGet()

    let profileBio = await storage.get('profileBio', this.state.formData.profileBio)
    let areaofExpertise = await storage.get('areaofExpertise', this.state.formData.areaofExpertise)
    let yearsofExperience = await storage.get('yearsofExperience', this.state.formData.yearsofExperience)
    let instagram = await storage.get('instagram', this.state.formData.instagram.replace(/"/g, ''))
    let facebook = await storage.get('facebook', this.state.formData.facebook.replace(/"/g, ''))
    let tiktok = await storage.get('tiktok', this.state.formData.tiktok.replace(/"/g, ''))
    let pinterest = await storage.get('pinterest', this.state.formData.pinterest.replace(/"/g, ''))
    let numberData = await storage.get('numberData', this.state.numberData)
    let Address = await storage.get('Address', this.state.formData.Address)
    let Area = await storage.get('Area', this.state.formData.Area)
    let Block = await storage.get('Block', this.state.formData.Block)
    let mallName = await storage.get('mallName', this.state.formData.mallName)
    let Floor = await storage.get('Floor', this.state.formData.Floor)
    let unitNumber = await storage.get('unitNumber', this.state.formData.unitNumber)
    let City = await storage.get('City', this.state.formData.City)
    let zipCode = await storage.get('zipCode', this.state.formData.zipCode)
    let Instructions = await storage.get('Instructions', this.state.formData.Instructions)
    let company_contact_number = await storage.get('company_contact_number', this.state.formData.company_contact_number)
    let IBANNumber = await storage.get('IBANNumber', this.state.formData.IBANNumber)
    let accountName = await storage.get('accountName', this.state.formData.accountName)
    let accountNumber = await storage.get('accountNumber', this.state.formData.accountNumber)
    let newCombin = await getStorageData('combineImg')
    let combineImg = JSON.parse(newCombin);
    this.setState({
      numberData: numberData, combineImg: combineImg === null ? [] : combineImg, formData: {
        ...this.state.formData, profileBio, areaofExpertise, yearsofExperience, instagram, facebook, tiktok, pinterest, Address, Area, Block, mallName, Floor, unitNumber, City, zipCode,
        Instructions, company_contact_number, IBANNumber, accountName, accountNumber
      },
    })

  }
  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberData: event.target.value as string });
  };
  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const isValid = this.firstPageValidateForm();
    if (this.state.numberData == "" || this.state.numberData == "Select Language") {
      this.setState({ errorsSelectLanguage: true })
    } else {
      this.setState({ errorsSelectLanguage: false })
      if (isValid) {
        this.getNavigationActiveAddress();
      }
    }

  };
  setStorage(key: string, value: string | number) {
    storage.set(key, value || '');
  }
  getNavigationActiveAddress = () => {
    this.setStorage('combineImg', JSON.stringify(this.state.combineImg))
    this.setStorage('profileBio', this.state.formData.profileBio)
    this.setStorage('areaofExpertise', this.state.formData.areaofExpertise)
    this.setStorage('yearsofExperience', this.state.formData.yearsofExperience)
    this.setStorage('instagram', `"${this.state.formData.instagram}"`)
    this.setStorage('facebook', `"${this.state.formData.facebook}"`)
    this.setStorage('tiktok', `"${this.state.formData.tiktok}"`)
    this.setStorage('pinterest', `"${this.state.formData.pinterest}"`)
    this.setStorage('numberData', this.state.numberData)

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistContactPayment');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { formData } = this.state;
    const { name, value } = e.target;
    let updatedValue: string | number = value;
    if (['yearsofExperience', 'zipCode', 'company_contact_number', 'unitNumber'].includes(name)) {
      const numberValue = parseFloat(value);
      updatedValue = !isNaN(numberValue) ? numberValue : '';
    }
    if (['areaofExpertise', 'City'].includes(name)) {
      updatedValue = value.replace(configJSON.addNumber, '');
    }
    this.setState(() => ({
      formData: {
        ...formData,
        [name]: updatedValue,
      }
    }));
  };
  openPictureUpload = (index: number) => {
    const inputAddPensionTwo = document.createElement('input');
    inputAddPensionTwo.type = 'file';
    inputAddPensionTwo.accept = 'image/png, image/jpeg, image/jpg';
    inputAddPensionTwo.multiple = false;
    inputAddPensionTwo.click();
    inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.openProfileUploadInSideMain(event, index);
    };
  }
  openProfileUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event, index: number) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    const base64 = URL.createObjectURL(file);
    this.fileReader(file)
    this.setState({
      combineImg: [base64],
      selectedFile: [file],
    });
  }
  fileReader = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setStorageData('selectedFile', JSON.stringify({ file: reader.result, fileName: file.name }));
    };
    reader.readAsDataURL(file)
  }
  convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleRemove = () => {
    this.setState({ selectedFile: [], combineImg: [] });
  };

  firstPageValidateForm = () => {
    const { formData } = this.state;
    const errors: { [key: string]: string } = {};
    const requiredFields = [
      { name: "profileBio", message: "*Please enter your profile bio" },
      { name: "areaofExpertise", message: "*Please enter Area of Expertise" },
      { name: "yearsofExperience", message: "*Please enter Your Years of Experience" },
    ];

    requiredFields.forEach(field => {
      const value = formData[field.name as keyof typeof formData];
      if (typeof value === "string" && value.trim() === "") {
        errors[field.name] = field.message;
      }
    });

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  secondPageValidateForm = () => {
    const { formData } = this.state;
    const errors: { [key: string]: string } = {};
    const requiredFieldsSecondPage = [
      { name: "Address", message: "*Please enter your Address" },
      { name: "accountName", message: "*Please enter Account holder" },
      { name: "IBANNumber", message: "*Please enter your IBAN Number" },
      { name: "accountNumber", message: "*Please enter Account Number" },
      { name: "company_contact_number", message: "*Please enter Your Contact Number" },
    ];
    requiredFieldsSecondPage.forEach(field => {
      const secondPagevalue = formData[field.name as keyof typeof formData];
      if (typeof secondPagevalue === "string" && secondPagevalue.trim() === "") {
        errors[field.name] = field.message;
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };
  secondPagehandleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const isValid = this.secondPageValidateForm();
    if (isValid) {
      this.getNavigationDocument();
    }
  };
  getFileFromLocalStorage = async (): Promise<File | null> => {
    const getNewFile: string | null = await getStorageData('selectedFile');
    if (!getNewFile) {
      return null;
    }
    const base64String = JSON.parse(getNewFile);

    if (base64String.file) {
      const byteString = atob(base64String.file.split(',')[1]);
      const mimeString = base64String.file.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      const file = new File([intArray], base64String.fileName, { type: mimeString });
      return file;
    }
    return null;
  };
  async getNavigationDocument() {
    this.setStorage('Address', this.state.formData.Address)
    this.setStorage('Area', this.state.formData.Area)
    this.setStorage('Block', this.state.formData.Block)
    this.setStorage('mallName', this.state.formData.mallName)
    this.setStorage('Floor', this.state.formData.Floor)
    this.setStorage('unitNumber', this.state.formData.unitNumber)
    this.setStorage('City', this.state.formData.City)
    this.setStorage('zipCode', this.state.formData.zipCode)
    this.setStorage('Instructions', this.state.formData.Instructions)
    this.setStorage('company_contact_number', this.state.formData.company_contact_number)
    this.setStorage('IBANNumber', this.state.formData.IBANNumber)
    this.setStorage('accountName', this.state.formData.accountName)
    this.setStorage('accountNumber', this.state.formData.accountNumber)

    let selectLg = await getStorageData("numberData")
    let yearsofExperience = await getStorageData("yearsofExperience")
    let profileBio = await getStorageData("profileBio")
    let areaofExpertise = await getStorageData("areaofExpertise")
    let instagram = await getStorageData("instagram")
    let facebook = await getStorageData("facebook")
    let tiktok = await getStorageData('tiktok')
    let pinterest = await getStorageData('pinterest')

    const token = await getStorageData("auth-token")
    const selectedFilenew: File | null = await this.getFileFromLocalStorage();
    const formData = new FormData()
    selectedFilenew && formData.append('profile_picture', selectedFilenew);
    formData.append('biography', profileBio);
    formData.append('expertise', areaofExpertise);
    formData.append('year_of_experience', yearsofExperience);
    formData.append('instagram', instagram?.replace(/"/g, ''));
    formData.append('facebook', facebook?.replace(/"/g, ''));
    formData.append('tiktok', tiktok?.replace(/"/g, ''));
    formData.append('pinterest', pinterest?.replace(/"/g, ''));
    formData.append('preferred_language', selectLg);
    formData.append('address', this.formDataProfilr('Address'));
    formData.append('location_info', this.formDataProfilr('Area'));
    formData.append('block', this.formDataProfilr('Block'));
    formData.append('mall_name', this.formDataProfilr('mallName'));
    formData.append('floor', this.formDataProfilr('Floor'));
    formData.append('unit_number', this.formDataProfilr('unitNumber'));
    formData.append('city', this.formDataProfilr('City'));
    formData.append('account_holder_name', this.formDataProfilr('accountName'))
    formData.append('contact_number', this.state.isCountryData.replace("+", "") + this.formDataProfilr('company_contact_number'))
    formData.append('zipcode', this.formDataProfilr('zipCode'));
    formData.append('bank_name', this.formDataProfilr('accountName'));
    formData.append('iban', this.formDataProfilr('IBANNumber'));
    formData.append('account_number', this.formDataProfilr('accountNumber'));
    const header = {
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createProfileApiCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.profileId == null ? configJSON.loginAPiMethod : configJSON.customProfilePutApi,
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.profileId == null ? configJSON.createProfileApi : `${configJSON.customProfilePut}/${this.state.profileId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  formDataProfilr = (dataKey: keyof FormFieldData): string => {
    let fromFiled = this.state.formData[dataKey]
    return fromFiled ? fromFiled.toString() : ''
  }

  getNavigationProfile = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistAccountActiveProfile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  toggleVariantsListOffer = async () => {
    const header = {
      "Content-Type": configJSON.countryApiContentType,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeAPIEditCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryCodeEndPoint
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  thodUploadImageFile = () => {
    const inputAddPensionTwo = document.createElement('input');
    inputAddPensionTwo.type = 'file';
    inputAddPensionTwo.accept = 'image/png, image/jpeg, image/jpg';
    inputAddPensionTwo.multiple = false;
    inputAddPensionTwo.click();
    inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.openProfileUploadInSideMainThod(event);
    };
  }
  openProfileUploadInSideMainThod = async (event: React.ChangeEvent<HTMLInputElement> | Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (!files || files.length === 0) {
      return;
    }
    const newUpdateProImages: string[] = [];
    const newSelectedProFiles: File[] = [];
    Array.from(files).forEach(file => {
      const base64 = URL.createObjectURL(file);
      newUpdateProImages.push(base64);
      newSelectedProFiles.push(file);
    });
    this.setState(prevState => ({
      presentImage: [...prevState.presentImage, { selectedProFiles: newSelectedProFiles, updateProImages: newUpdateProImages, description: '' }],
    }));
  }

  uplodeApi = async () => {
    const token = await getStorageData("auth-token")
    const formData = new FormData()
    this.state.presentImage.forEach((it: { selectedProFiles: (File | null)[], updateProImages: (string | null)[], description: string }, index: number) => {
      it.selectedProFiles.forEach((file) => {
        if (file) {
          formData.append("images[]", file);
        }
      });
      formData.append("image_descriptions[]", it.description);
    });

    const header = {
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uplodeProfileApiCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uplodeProfileApi
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  handleRemoveThad = (index: number) => {
    this.setState(prevState => { return { presentImage: prevState.presentImage.filter((_, numData) => { return index !== numData }) } });
  }
  changeDisc = (index: number, value: string) => {
    this.setState(prevState => { return { presentImage: prevState.presentImage.map((newValue, numData) => { return numData === index ? { ...newValue, description: value } : newValue }) } });
  }

  civilIDPassportopenPictureUpload = (nameUplodeFile: string) => {
    const inputAddPensionTwo = document.createElement('input');
    inputAddPensionTwo.type = 'file';
    inputAddPensionTwo.accept = 'image/png, image/jpeg, image/jpg';
    inputAddPensionTwo.multiple = false;
    inputAddPensionTwo.click();
    inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.civilIDPassportopenProfileUploadInSideMain(event, nameUplodeFile);
    };
  }
  civilIDPassportopenProfileUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event, nameUplodeFile: string) => {
    const targetPassport = event.target as HTMLInputElement;
    const files = targetPassport.files;
    if (!files || files.length === 0) {
      return;
    }
    if (nameUplodeFile === "civilIdPassport") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          presentImageCombineImg: [{ selectedFilecivilID: [file], combineImgcivilID: [base64], newSelectedFilesType: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "commercialLicense") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          commercialLicense: [{ selectedCommercialLicense: [file], combineImgCommercialLicense: [base64], newSelectedCommercialLicense: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "authorizedSignatures") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          authorizedSignatures: [{ selectedAuthorizedSignatures: [file], combineImgAuthorizedSignatures: [base64], newSelectedAuthorizedSignatures: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "moaOrAoa") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          moaOrAoa: [{ selectedMoaOrAoa: [file], combineImgMoaOrAoa: [base64], newSelectedFMoaOrAoa: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "businessBankAccount") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          businessBankAccount: [{ selectedBankAccount: [file], combineImgBankAccount: [base64], newSelectedBankAccount: [file.type] }],
        }));
      });
    }
  }

  civilIDPassportHandleRemoveThad = (index: number, nameUplode: string) => {
    if (nameUplode === "civilIdPassport") {
      this.setState(prevState => { return { presentImageCombineImg: prevState.presentImageCombineImg.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "commercialLicense") {
      this.setState(prevState => { return { commercialLicense: prevState.commercialLicense.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "authorizedSignatures") {
      this.setState(prevState => { return { authorizedSignatures: prevState.authorizedSignatures.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "moaOrAoa") {
      this.setState(prevState => { return { moaOrAoa: prevState.moaOrAoa.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "businessBankAccount") {
      this.setState(prevState => { return { businessBankAccount: prevState.businessBankAccount.filter((_, numData) => { return index !== numData }) } });
    }
  }

  uplodeDocuments = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistUploadDocumentsweb');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  profileDataGet = async () => {
    const token = await getStorageData("auth-token")
    const header = {
      "Content-Type": configJSON.countryApiContentType,
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.customProfileGetCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customProfileGet
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  createProfileUplodeDocument = async () => {
    const token = await getStorageData("auth-token")
    const formData = new FormData()

    this.state.presentImageCombineImg[0] && formData.append("passport", this.state.presentImageCombineImg[0].selectedFilecivilID[0])
    this.state.commercialLicense[0] && formData.append("commercial_license", this.state.commercialLicense[0].selectedCommercialLicense[0])
    this.state.authorizedSignatures[0] && formData.append("authorized_signature", this.state.authorizedSignatures[0].selectedAuthorizedSignatures[0])
    this.state.moaOrAoa[0] && formData.append("moa", this.state.moaOrAoa[0].selectedMoaOrAoa[0])
    this.state.businessBankAccount[0] && formData.append("business_bank_account", this.state.businessBankAccount[0].selectedBankAccount[0])
    const header = {
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createUplodeDocumentApiCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadDocumentsApi
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadDocumentsPost
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  handleCancel = () => {
    this.setState({ visible: false });
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylishDashboard');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  backButtonLgin = () => {
    this.setState({ visible: false });
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  // Customizable Area End
}