import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
interface DeliveryAddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zip_code: string;
  area: string;
  block: string;
  city: string;
  house_or_building_number: string;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

interface DeliveryAddress {
  id: string;
  type: string;
  attributes: DeliveryAddressAttributes;
}

interface PaymentDetail {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  charge_id: string;
  merchant_id: string | null;
  order_id: string;
  amount: number;
  currency: string;
  customer_id: string;
  reason: string;
  account_id: number;
  order_management_order_id: number;
  refund_id: string | null;
  refund_amount: string | null;
  refund_reason: string | null;
  seller_order_id: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
}

interface OrderManagementOrderAttributes {
  order_number: string;
  account: string;
  sub_total: string;
  total: string;
  status: string;
  placed_at: string;
  confirmed_at: string;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  returned_at: string | null;
  deliver_by: string | null;
  order_status_id: number;
  created_at: string;
  updated_at: string;
  order_deliver_date: string | null;
  order_deliver_time: string | null;
  delivery_addresses: DeliveryAddress;
  order_return_date: string | null;
  order_return_time: string | null;
  payment_detail: PaymentDetail;
}

interface OrderManagementOrder {
  id: string;
  type: string;
  attributes: OrderManagementOrderAttributes;
}

interface OrderItemAttributes {
  status: string;
  placed_at: string;
  confirmed_at: string;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  rejected_at: string | null;
  process_at: string;
  shipped_at: string | null;
  return_at: string | null;
  return_cancel_at: string | null;
  return_pick_at: string | null;
  quantity: number;
  unit_price: string;
  total_price: string;
  reason_of_rejection: string | null;
  catalogue_name: string;
  brand_name: string;
  catalogue_variant_color: string;
  catalogue_variant_sku: string;
  store_name: string;
  catalogue_variant_size: string;
  catalogue_variant_front_image: string;
  catalogue_variant_back_image: string;
  catalogue_variant_side_image: string;
  driver_name: string | null;
  driver_latitude: number | null;
  driver_longitude: number | null;
  driver_phone_number: string | null;
  otp: string | null;
}

interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}
interface SellerOrderAttributes {
  id: number;
  status: string;
  accept_order_upload_time: string | null;
  order_items: OrderItem[];
  order_management_order: OrderManagementOrder;
}

interface SellerOrder {
  id: string;
  type: string;
  attributes: SellerOrderAttributes;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  apiRecall: Function
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  page: number;
  tableAllOrder: SellerOrder[];
  rejectOpen: boolean;
  numberData: string;
  short: string[],
  visible: boolean,
  reloadTime: number,
  isLoading: boolean,
  isAlert: boolean,
  rejectId: number,
  confirmId: number,
  metaData: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  activeTab:string,
  timerFlag: boolean,
  timerAllOrders: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersAllOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMy_OrdersCallId: string = "";
  getAllOrderCallId: string = "";
  getRejectOrderCallId: string = "";
  getTimeOrderCallId: string = "";
  timerAllOrdersId: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      page: 1,
      tableAllOrder: [],
      rejectOpen: false,
      numberData: "",
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      visible: false,
      reloadTime: 45,
      isLoading: true,
      isAlert: false,
      rejectId: 0,
      confirmId: 0,
      metaData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      }
      ,
      activeTab: 'Return Request',
      timerFlag: true,
      timerAllOrders: 30,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllOrderCallId !== null &&
      this.getAllOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ metaData: responseJsonCategory?.meta, isAlert: responseJsonCategory.data === undefined ? true : false, tableAllOrder: responseJsonCategory?.data, isLoading: false })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectOrderCallId !== null &&
      this.getRejectOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true })
        this.props.apiRecall()
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeOrderCallId !== null &&
      this.getTimeOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true })
        this.props.apiRecall()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCancel = () => {
    this.setState({ visible: false });
  };

  incrementTime = () => {
    this.setState((prevState) => ({ timerAllOrders: prevState.timerAllOrders + 5 }));
  };

  decrementTime = () => {
    this.setState((prevState) => ({ timerAllOrders: prevState.timerAllOrders - 5 }));
  };
  handleOk = (confirmid: number) => {
    this.setState({ visible: true, confirmId: confirmid });
  };

  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberData: event.target.value as string });
  };
  handleOpen1 = (rejectid: number) => {
    this.setState({ rejectOpen: true, rejectId: rejectid })
  };

  handleClose1 = () => {
    this.setState({ rejectOpen: false })
  };
  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const date = new Date(created_at);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  }
  async componentDidMount() {
    this.allOrderData()
  }
  rejectData = () => {
    this.setState({ rejectOpen: false })
    this.rejectOrderData()
  }
  timeData = () => {
    this.setState({ visible: false })
    this.timeOrderData()
  }
  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAllOrder}&page=${this.state.page}&per_page=10`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.rejectId}&type=reject&reason_of_rejection=${this.state.numberData}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  formatTime = (seconds: number) => {
    const minutesAllOrders = Math.floor(seconds / 60);
    const secsAllOrders = seconds % 60;
    return `${minutesAllOrders.toString().padStart(2, '0')}:${secsAllOrders.toString().padStart(2, '0')}`;
  };
  componentDidUpdate() {
    if (this.state.timerFlag && !this.timerAllOrdersId) {
      this.timerAllOrdersId = setInterval(() => {
        this.setState(prevState => {
          if (prevState.timerAllOrders > 0) {
            return { timerAllOrders: prevState.timerAllOrders - 1 };
          } else {
            return { timerAllOrders: 0 };
          }
        });
      }, 1000);
    }
  }
  async componentWillUnmount() {
    if (this.timerAllOrdersId) {
      clearInterval(this.timerAllOrdersId);
    }
  }

  timeOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.confirmId}&type=accept&accept_order_upload_time=${this.state.timerAllOrders}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  // Customizable Area End
}
