import React from "react";
// Customizable Area Start
import {
    Box, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Button, Modal, Backdrop, Fade, withStyles, FormControl, Grid ,Snackbar
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import SellersNewOrderController, {
    Props,
} from "./SellersOrderDetailsController.web";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { button_close, Noimageavailablepng, button_arrow, view_Icon } from "../assets"
import SellerSideBar from "../../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../../components/src/Seller/SellerHeader";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

export default class SellersOrderDetailsWebPage extends SellersNewOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ page: newPage });
    };
    orderDetails = () => {
        const orderData = this.state.tableAllOrder.find(
            (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
                order.attributes.order_management_order.attributes.order_number === this.state.orderId
        );
        if (!orderData) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }

        const { order_items, order_management_order } = orderData.attributes;
        const { delivery_addresses } = order_management_order.attributes;

        return (
            <>
                <Box style={{ margin: '30px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Box style={{ display: "flex", alignItems: 'center' }}>
                                <img src={button_arrow} style={{ cursor: "pointer" }} data-testid="contioneShopping" onClick={this.contioneShopping} /> <Box style={webStyle.newOrderId} data-testid={`order_number=${order_management_order.attributes.order_number}`}>#{order_management_order.attributes.order_number}</Box> <Box style={{ ...webStyle.new_orderBox, textAlign: 'center' }}>New Order</Box>
                            </Box>
                            <Box style={{ color: "#9A9A9A", fontSize: "16px", fontFamily: "Lato", fontWeight: 500, marginTop: "6px" }}>
                                Total Products : {order_items.length}
                            </Box>
                        </Box>
                        <Box>
                            <Button style={{ ...webStyle.rejectButtonNewOrder, textTransform: "capitalize" }} data-testid={`handleOpen1=${orderData.attributes.id}`} onClick={() => this.handleOpen1(orderData.attributes.id)}>Reject</Button>
                            <Button style={{ ...webStyle.acceptOrderNewOrder, textTransform: "capitalize" }} data-testid={`timeOrderData=${orderData.attributes.id}`} onClick={() => this.timeOrderData(orderData.attributes.id)}>Accept</Button>
                        </Box>
                    </Box>
                    <Box>
                        <Box style={{ border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "20px" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={webStyle.tableHader}>Product</TableCell>
                                        <TableCell style={webStyle.tableHader}>Brand</TableCell>
                                        <TableCell style={webStyle.tableHader}>Size</TableCell>
                                        <TableCell style={webStyle.tableHader}>Color</TableCell>
                                        <TableCell style={webStyle.tableHader}>Product Code</TableCell>
                                        <TableCell style={webStyle.tableHader}>Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order_items?.map((order, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                                <Box style={{ display: "flex" }}>
                                                    <Box >
                                                        <img src={order.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : order.attributes.catalogue_variant_front_image} style={{ width: '44px', height: '44px', borderRadius: '2px', marginRight: "10px" }} />
                                                    </Box>
                                                    <Box style={{ ...webStyle.tableHaderrow }}>{order.attributes.catalogue_name}</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{order.attributes.brand_name}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_size}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box style={{ backgroundColor: order.attributes.catalogue_variant_color, width: '14px', height: '14px', borderRadius: '10px', marginRight: "8px" }} />{order.attributes.catalogue_variant_color}
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{order.attributes.catalogue_variant_sku}</TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>${order.attributes.total_price}</TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box style={webStyle.tableBox}>
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Box style={webStyle.orderDetailsName}>Store Name</Box>
                                    <Box style={{ ...webStyle.orderDetailsValue, textTransform: "capitalize" }}>{order_items[0].attributes.store_name}</Box>
                                </Grid>
                                <Grid item xs>
                                    <Box style={webStyle.orderDetailsName}>Customer Name</Box>
                                    <Box style={{ ...webStyle.orderDetailsValue, textTransform: "capitalize" }}>{order_management_order.attributes.account}</Box>
                                </Grid>
                                <Grid item xs>
                                    <Box style={webStyle.orderDetailsName}>Delivery Date</Box>
                                    <Box style={{ ...webStyle.orderDetailsValue, textTransform: "capitalize" }}>{this.dateFormetTransaction(order_management_order.attributes.order_deliver_date)}</Box>
                                </Grid>
                            </Grid>
                            <Box style={{ marginTop: '30px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <Box style={webStyle.orderDetailsName}>Shipping Address</Box>
                                        <Box style={{ ...webStyle.orderDetailsValue, textTransform: "capitalize" }}>{this.getAddress(delivery_addresses)}</Box>
                                    </Grid>
                                    <Grid item xs>
                                        <Box style={webStyle.orderDetailsName}>Coupon Applied</Box>
                                        <Box style={{ ...webStyle.orderDetailsValue, textTransform: "capitalize" }}>Fash10(-$5)</Box>
                                    </Grid>
                                    <Grid item xs>
                                        <Box style={webStyle.orderDetailsName}>Payment Method</Box>
                                        <Box style={{ ...webStyle.orderDetailsValue, textTransform: "capitalize" }}>Debit Card ending {delivery_addresses.attributes.house_or_building_number}</Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={{ display: 'flex' }}>
                    <SellerSideBar navigation={this.props.navigation} />
                    <Grid container>
                        <Grid item lg={12} md={12}>
                            <SellerHeader navigation={this.props.navigation} />
                            {this.orderDetails()}
                        </Grid>
                    </Grid>
                </Box>
                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    onClose={this.handleClose1}
                    closeAfterTransition
                    open={this.state.rejectOpen}
                    aria-labelledby="transition-modal-title"
                >
                    <Fade in={this.state.rejectOpen} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClose1} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectNameDetails, textAlign: "center" }}>Reject Order</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMasegDetails, textAlign: "center" }}>Are you sure you want to reject the order?</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>Reason of Rejection</Box>
                                <CustomSelectDetails variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelectDetailse
                                        value={this.state.numberData}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                        }}
                                        style={webStyle.reminderName}
                                        displayEmpty
                                        onChange={this.handleSingleChangeNumber}
                                        data-testid='sub-categories-selection'
                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                    >
                                        <MenuItem value="" disabled>
                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>Out of Stock</Box>
                                        </MenuItem>
                                        {this.state.short.map((select: string, index: number) => (
                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>
                                        ))}
                                    </MuiSelectSelectDetailse>
                                </CustomSelectDetails >
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelDetails style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>Close</StyledButtonModelDetails>
                                <StyledButton1ModelDetails style={{ fontSize: "14px" }} data-testid="rejectData" onClick={this.rejectData}>Reject</StyledButton1ModelDetails>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    open={this.state.visible}
                    aria-describedby="transition-modal-description"
                    closeAfterTransition
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleCancel}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    BackdropComponent={Backdrop}
                >
                    <Fade in={this.state.visible} style={webStyle.febBox}>
                        <div style={{ padding: "50px 60px 50px 60px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><img width="80px" height="80px" style={{ marginBottom: '30px' }} src={view_Icon} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectNameDetails, textAlign: "center", margin: '0px' }}>Order has been successfully Accepted</h2>
                        </div>
                    </Fade>
                </StyledDrawer>
                <Snackbar
                    open={this.state.isAlertOrder}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    style={{marginTop:'10px'}}
                    data-testid="oncloseAlert"
                    onClose={this.oncloseAlert}
                ><Alert variant="filled" severity="success">{this.state.already}</Alert></Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    orderDetailsValue: { color: "#94A3B8", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px", marginTop: "10px", textTransform: "capitalize" },
    orderDetailsName: { color: "#375280", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px" },
    tableBox: { border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "30px", padding: '20px' },
    newOrderId: { color: "#375280", fontFamily: 'Lato', fontWeight: 700, fontSize: "24px", margin: '0px 16px' },
    acceptOrderNewOrder: { backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700, padding: "10px 33px 10px 33px" },
    rejectButtonNewOrder: { color: "#375280", fontFamily: 'Lato', fontWeight: 500, fontSize: "16px", marginRight: "10px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px 33px 10px 33px" },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
    febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    confomMasegDetails: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
    new_orderBox: { backgroundColor: '#D1FAE5', color: '#059669', padding: '6px 10px 6px 10px', borderRadius: '2px', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    rejectNameDetails: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
}
const StyledButtonModelDetails = styled(Button)(() => ({
    color: "#375280",
    fontWeight: 500,
    textTransform: "none",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    height: "45px",
    width: "252px !important",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1ModelDetails = styled(Button)(() => ({
    color: "#fff",
    fontWeight: 500,
    width: "252px",
    backgroundColor: '#CCBEB1',
    height: "45px",
    textTransform: "none",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))

const StyledDrawer = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));

const MuiSelectSelectDetailse = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
const CustomSelectDetails = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
// Customizable Area End