import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../config");
interface DeliveryAddressAttributesRejected {
  id: string;
  type: string;
  attributes: {
    order_number: string;
    order_return_date: string | null,
    order_return_time: string | null,
    order_items: {
      id: string;
      type: string;
      attributes: {
        status: string;
        placed_at: string;
        confirmed_at: string;
        in_transit_at: string;
        delivered_at: string;
        cancelled_at: string | null;
        rejected_at: string | null;
        process_at: string;
        shipped_at: string | null;
        return_at: string | null;
        return_cancel_at: string | null;
        return_pick_at: string | null;
        return_placed_at: string;
        return_confirmed_at: string | null;
        return_reject_at: string | null;
        returned_assign_at: string | null;
        quantity: number;
        unit_price: string;
        total_price: string;
        reason_of_rejection: string | null;
        reason_of_return: string;
        refunded_cancel_at: string | null;
        reason_refunded_cancel: string | null;
        refunded_at: string | null;
        status_humanize: string;
        catalogue_name: string;
        brand_name: string;
        catalogue_variant_color: string;
        catalogue_variant_sku: string;
        catalogue_variant_size: string;
        catalogue_variant_front_image: string;
        catalogue_variant_back_image: string;
        catalogue_variant_side_image: string;
        driver_name: string | null;
        driver_latitude: string | null;
        driver_longitude: string | null;
        driver_phone_number: string | null;
        otp: string | null;
        store_name: string;
      }
    }[];
  };
};
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rejectOpen: boolean;
  page: number;
  short: string[],
  checkID: string[],
  fixID: string[],
  tableAllOrder: DeliveryAddressAttributesRejected;
  numberData: string;
  txtInputValue: string;
  isAlert: boolean,
  isLoading: boolean,
  visible: boolean,
  reloadTime: number,
  orderId: number,
  newOrderaOpen: boolean,
  confirmId: number,
  already: string,
  rejectId: number,
  rejectedOrder: string[],
  isAlertOrder: boolean,
  description: string,
  reasonOpen: boolean,
  profileName: string,
  submittedRequest: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersRejectedOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRejectedOrderDetailseCallId: string = "";
  getreturunOrderDetailCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profileName: "",
      orderId: 0,
      page: 1,
      visible: false,
      submittedRequest: false,
      checkID: [],
      fixID: [],
      tableAllOrder: {
        id: "",
        type: "",
        attributes: {
          order_number: "",
          order_return_time: null,
          order_return_date: null,
          order_items: [{
            id: "",
            type: "",
            attributes: {
              status: "",
              placed_at: "",
              confirmed_at: "",
              in_transit_at: "",
              delivered_at: "",
              cancelled_at: "",
              rejected_at: "",
              process_at: "",
              shipped_at: "",
              return_at: "",
              return_cancel_at: "",
              return_pick_at: "",
              return_placed_at: "",
              return_confirmed_at: "",
              return_reject_at: "",
              returned_assign_at: "",
              quantity: 0,
              unit_price: "",
              total_price: "",
              reason_of_rejection: "",
              reason_of_return: "",
              refunded_cancel_at: "",
              reason_refunded_cancel: "",
              refunded_at: "",
              status_humanize: "",
              catalogue_name: "",
              brand_name: "",
              catalogue_variant_color: "",
              catalogue_variant_sku: "",
              catalogue_variant_size: "",
              catalogue_variant_front_image: "",
              catalogue_variant_back_image: "",
              catalogue_variant_side_image: "",
              driver_name: "",
              driver_latitude: "",
              driver_longitude: "",
              driver_phone_number: "",
              otp: "",
              store_name: "",
            }
          }]
        },

      },
      rejectedOrder: ["Product", "Brand", "Size", "Color", "Product Code", "Price"],
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      numberData: "",
      rejectOpen: false,
      txtInputValue: "",
      reloadTime: 45,
      isLoading: true,
      newOrderaOpen: true,
      rejectId: 0,
      isAlert: false,
      isAlertOrder: false,
      already: "",
      confirmId: 0,
      description: "",
      reasonOpen: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectedOrderDetailseCallId !== null &&
      this.getRejectedOrderDetailseCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonRejectedDetails = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const checkId = responseJsonRejectedDetails.data?.attributes.order_items?.map((value: { id: string }) => {
        return value.id
      })
      this.setState({ tableAllOrder: responseJsonRejectedDetails.data, checkID: checkId, fixID: checkId, isLoading: false, isAlert: true })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getreturunOrderDetailCallId !== null &&
      this.getreturunOrderDetailCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonRejectedDetails = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonRejectedDetails.data) {
        this.setState({reasonOpen:false, submittedRequest: true })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>, checkOrderId: string): void => {
    event.preventDefault()
    this.setState((prevState) => {
      const { checkID } = prevState;
      if (this.state.checkID.includes(checkOrderId)) {
        return {
          checkID: checkID.filter((lang) => lang !== checkOrderId)
        };
      } else {
        return {
          checkID: [...checkID, checkOrderId]
        };
      }
    });
  };

  totalValue = () => {
    const sumValue = this.state.tableAllOrder.attributes.order_items.map(value => {
      return this.state.checkID.includes(value.id) ? parseFloat(value.attributes.total_price) : 0

    }).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return `$${sumValue}`
  }

  handleAllSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const isSelected = event.target.checked;
    this.setState({ checkID: isSelected ? this.state.fixID : [] })

  };

  async componentDidMount() {
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ orderId: idData })
    this.allOrderData()
    const profileHolder = await getStorageData("user_data")
    this.setState({ profileName: JSON.parse(profileHolder).first_name + " " + JSON.parse(profileHolder).last_name })
  }

  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListRejetedDeatils = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectedOrderDetailseCallId = requestMessageListRejetedDeatils.messageId;
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersManagement}/${this.state.orderId}`
    );
    requestMessageListRejetedDeatils.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListRejetedDeatils.id, requestMessageListRejetedDeatils);
  }
  contioneShopping = () => {
    const toNavigateReject = new Message(getName(MessageEnum.NavigationMessage));
    toNavigateReject.addData(getName(MessageEnum.NavigationTargetMessage), "OrderManagementPage");
    toNavigateReject.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigateReject);
  }
  changeDisc = (value: string) => {
    this.setState({ description: value })
  }
  reasonOpenHandleClose = () => {
    this.setState({ reasonOpen: false })
  }
  reasonOpenHandle = () => {
    this.setState({ reasonOpen: true })
  }

  cancelReturnOrder = async () => {
    const currentDateReturn = new Date();
    const dayReturn = String(currentDateReturn.getDate()).padStart(2, '0');
    const monthReturn = String(currentDateReturn.getMonth() + 1).padStart(2, '0');
    const yearReturn = currentDateReturn.getFullYear();
    const formattedDate = `${dayReturn}-${monthReturn}-${yearReturn}`;
    const dateReturn = new Date(); 
    let hoursReturn = dateReturn.getHours();
    let ampmReturn = hoursReturn >= 12 ? "PM" : "AM";
    hoursReturn = hoursReturn % 12 || 12;
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListReturnDeatils = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getreturunOrderDetailCallId = requestMessageListReturnDeatils.messageId;
    requestMessageListReturnDeatils.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cencelOrderMethod
    );
    requestMessageListReturnDeatils.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.returunOrder}?order_id=${this.state.orderId}&type=return_order&order_return_date=${formattedDate}&order_return_time=${hoursReturn}${ampmReturn}&order_item_ids=[${this.state.fixID}]&reason=${this.state.description}`
    );
    requestMessageListReturnDeatils.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListReturnDeatils.id, requestMessageListReturnDeatils);
  }
  // Customizable Area End
}