import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField
} from "@material-ui/core";
import { createTheme,styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {backgroundImage,callBlue,facebookBlue,appleBlue,googleBlue} from "../src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Visibility from "@material-ui/icons/Visibility";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { configJSON } from "./EmailAccountLoginController.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <>
        <Snackbar
                  open={this.state.login_page}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  data-test-id="alertTestId"
                >
                  <Alert severity={this.state.alertTyperoute}>{'Please sign in first'}</Alert>
                </Snackbar>
      <Header navigation={this.props.navigation}/>
      <MainGrid data-test-id="mainGridContainer" container>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
          <Box className="sideImageBox">
            <img src={backgroundImage} alt="" className="sideImage" />
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
          <Box className={"formBox"}>
            <form data-test-id="formSubmitted" onSubmit={this.handleSumitlogin} style={{width:"80%"}}>
             <Box width={"100%"}>
              <Typography className={"welcomeText"}>{configJSON.welcomeBack}</Typography>
              <Typography className={"welcomeSubText"}>
                {configJSON.loginStyle}
              </Typography>
              <Typography className={"inputHeading"}>{configJSON.emailAddress}</Typography>
                <TextField
                  data-test-id="emailTextInput"
                  fullWidth
                  variant="outlined"
                  className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                  placeholder={configJSON.emailPlace}
                  value={this.state.isEmail}
                  onChange={this.handleEmail}
                  error={this.state.errorsMessage.errorEmail}
                  helperText={this.state.errorsMessage.errorEmail ? this.state.errorsMessage.emailMessage : ""}
                />

              <Typography className={"inputHeading"}>{configJSON.placeHolderPassword}</Typography>
              <TextField
                data-test-id="passwordTextInput"
                type={this.state.showPasswordValue ? 'text' : 'password'}
                className={this.state.passError?"inputFeild2Error2":"inputFeild2"}
                value={this.state.passwords}
                onChange={this.handlePassword}
                placeholder={configJSON.passPlace}
                error={this.state.errorsMessage.errorPassword}
                helperText={this.state.errorsMessage.errorPassword ? this.state.errorsMessage.passwordMessage : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleTogglePassword}
                        edge="end"
                      >
                        {this.state.showPasswordValue ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                variant="outlined"
              />
              <Typography align="right"  data-test-id="forgotPassNavigation" onClick={this.opeForgotPasswordScreen}  className="forgotPassword">
                {configJSON.forgotPassword}
              </Typography>
              <Button
                variant="contained"
                className={"loginUserButton"}
                disableElevation
                type="submit"
              >
                {configJSON.labelTitle}
              </Button>
                <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestingId"
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
              </Box>
              <Typography className={"continueText"} align="center">
              {configJSON.continueWith}
            </Typography>
            </form>
            <Box className="socialLogin">
            {(this.state.userType == "1" || this.state.userType === "seller") ? (
                <Button
                variant="contained"
                className={"loginUserButton1"}
                disableElevation
                data-test-id="mobileNavigation" onClick={this.openMobileScreen}
              >
                <Box style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}} >
                <img src={callBlue} alt="call"  style={{width: "24px", height: "24px"}} />
               <Typography style={{color: "#375280", fontFamily: "Lato", fontSize: "16px", fontWeight: 600}}>Log In with Phone</Typography>
                </Box>
              </Button>
              ): (
                <>
              <img src={callBlue} alt="call" data-test-id="mobileNavigation" onClick={this.openMobileScreen} className="socialLoginImage" />
              <img src={facebookBlue} alt="Facebook" className="socialLoginImage" />
              <img src={appleBlue} alt="Apple" className="socialLoginImage" />
              <img src={googleBlue} alt="Google" className="socialLoginImage" />
                </>
              )}
            </Box>
            <Box className={"Register"}>
              <Typography className={"bottomText"} align="center">
                {configJSON.msgAlert}
              </Typography>
              <Typography  data-test-id="userNext" className={"registerButton"} onClick={this.openRegistrationScreen}>
                {configJSON.registerHere}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </MainGrid>
      <Footer navigation={this.props.navigation}/>
    </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& .sideImageBox": {
    width: "100%",
    height: "100%",
  },
  "& .sideImage": {
    width: "100%",
    height: "100%",
  },
  "& .imageGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px",
    alignSelf: "flex-start",
    margin:"10px 0px 0px 0px"
  },
  "& .inputFeild": {
    gap: "4px",
    border: "none",
    borderRadius: "0px"
  },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding:"7%"
  },
  "& .phoneInput": { display: "flex", width: "520px" },
  "& .formBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width:"100%",
    marginTop: "30px"
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start",
  },
  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    fontFamily: "Lato, sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px"
  },
  "& .loginUserButton": {
    textTransform:"capitalize",
    width: "100% !important",
    borderRadius: "0px !important",
    color: "white !important",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    height: "56px !important",
    "& .MuiButton-label":{
      fontWeight:600,
      fontSize:"16px"
  }
  },
  "& .loginUserButton1": {
    textTransform:"capitalize",
    width: "100% !important",
    borderRadius: "0px !important",
    color: "white !important",
    backgroundColor: "#FFFFFF !important",
    gap: "8px",
    border: "1px solid #CCBEB1",
    height: "56px !important",
    "& .MuiButton-label":{
      fontWeight:600,
      fontSize:"16px"
  }},
  "& .terms": {
    color: "#979797 !important",
    margin: "10px 10px 10px 10px !important",
    width: "520px !important",

    fontFamily: "Lato ,sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "& .condition": {
    color: "var(--Neutrals-Cool-gray-500, #375280)",
    cursor: "pointer",
  },
  "& .inputFeild2Error2":{
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
        border:"none"
    },
    "& .MuiFormHelperText-contained":{
        margin:"0 !important"
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
},
"& .inputFeild2": {
    "& .MuiTextField-root":{
        "&:hover":{
            border: "1px solid #F0F0F0 !important",
        },
    },
    "& .MuiOutlinedInput-root:hover" :{
        border: "none !important",
      },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      
  },
    "& .MuiFormHelperText-contained":{
        margin:"0 !important"
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
       "& .MuiOutlinedInput-notchedOutline":{
        borderColor: "#F0F0F0",
    }
},
  "& .Register": {
    padding: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .socialLogin": {
    padding: "20px",
    width: "80%",
    display: "flex",
    gap:"35px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .registerButton": {
    cursor: "pointer",
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    margin: "10px 10px 0 10px",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "24px !important",
  },
  "& .bottomText": {
    color: "#375280 !important",

    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
  },
  "& .continueText": {
    width:"100%",
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    display: "flex",
    marginTop:"15px",
    flexDirection: "row",
    "&:before, &:after":{
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
    },
    "&:before":{
        marginRight: "25px"
    },
    "&:after": {
        marginLeft: "25px"
    }
},
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px", flexWrap: "wrap" },
    "& .Register": {
      width: "350px !important",
    },
    "& .socialLogin": {
      width: "80% !important",
    },
    "& .terms": {
      width: "350px !important",
    },
    "& .loginUserButton": {
      width: "100% !important",
      "& .MuiButton-label":{
        fontWeight:600,
        fontSize:"16px"
    }
    },
    "& .phoneInput": { width: "370px" },
  "& .continueText": {
    width:"100%",
    color: "#375280 !important",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    display: "flex",
    marginTop:"15px",
    flexDirection: "row",
    "&:before, &:after":{
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto"
    },
    "&:before":{
        marginRight: "25px"
    },
    "&:after": {
        marginLeft: "25px"
    }
},
  },
});

// Customizable Area End
