import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Box,
  styled,
  Grid,
  Typography,
  Dialog,
  Button,
  LinearProgress,
} from "@material-ui/core";
import BulkActionController, { Props } from "./BulkActionController";
import {
  crossIcon,
  csvFileIcon,
  rightArrowIcon,
  storeSuccessIcon,
  uploadIcon,
} from "../../../assets";
import { FormHelperTextContainer } from "../SellerInventoryManagement.web";
import { DialogContainer } from "../../../CreateSellerStore.web";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class BulkAction extends BulkActionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <BulkActionContainer>
        <Grid container spacing={3}>
          {this.state.btnData.map((btn) => (
            <Grid key={btn.id} item xs={12} sm={6} md={6} lg={4} xl={4}>
              <Box
                data-test-id={`bulk-test-id-${btn.id}`}
                onClick={
                  btn.id === 5
                    ? () =>
                        this.props.handleRedirect("SellerStoreDeleteProducts")
                    : () => this.handleButtonClick(btn.name, btn.popUp)
                }
                className={`btn-container ${
                  this.state.selectedBtn === btn.name ? "selected" : ""
                }`}
              >
                <Typography
                  className={`btn-name-title ${
                    this.state.selectedBtn === btn.name ? "selected" : ""
                  }`}
                >
                  {btn.name}
                </Typography>
                <img src={rightArrowIcon} alt="right-icon" />
              </Box>
            </Grid>
          ))}
        </Grid>
        <DialogUploadContainer
          data-test-id="update-store-time-dialog"
          open={this.state.isUploadOpen}
        >
          <Box className="cross-icon-container">
            <img
              src={crossIcon}
              onClick={() => this.handleUploadClose()}
              alt="cross-icon"
              data-test-id="cross-icon-test"
            />
          </Box>
          <Box className="content-container">
            <Typography className="title-container">
              {this.state.selectedBtn}
            </Typography>
            {!this.state.previewUpload ? (
              <Button
                variant="contained"
                component="label"
                className="upload-btn"
              >
                <img src={uploadIcon} alt="upload" />
                <Typography className="upload-title">
                  Upload {this.state.popUpContain}
                </Typography>
                <Typography className="upload-csv">
                  Only CSV files are accepted
                </Typography>
                <input
                  type="file"
                  hidden
                  data-test-id="upload-id-test"
                  onChange={(event) => this.handleUploadChange(event, "upload")}
                  accept=".csv"
                />
              </Button>
            ) : (
              <>
                <ImagePreviewContainer>
                  <img
                    src={csvFileIcon}
                    alt="csv-icon"
                    className="img-preview"
                  />
                  <Typography className="progress-title">
                    {this.state.uploadProgress}% Completed
                  </Typography>{" "}
                  <LinearProgress
                    variant="determinate"
                    value={this.state.uploadProgress}
                    className="upload-file-progressbar"
                    style={{ backgroundColor: "#E2E8F0", color: "#375280" }}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    className="re-upload-btn"
                    data-test-id="re-upload-btn-test-id"
                  >
                    Reupload
                    <input
                      type="file"
                      data-test-id="re-upload-id-test"
                      hidden
                      onChange={(event) =>
                        this.handleUploadChange(event, "reUpload")
                      }
                      accept=".csv"
                    />
                  </Button>
                </ImagePreviewContainer>
                {this.state.uploadProgress === 100 && (
                  <Typography className="success-line" data-test-id="upload-success">
                    Your CSV file is uploaded completely
                  </Typography>
                )}
              </>
            )}
            {this.state.isError && (
              <FormHelperTextContainer data-test-id="upload-error">
                {this.state.errorMessage}
              </FormHelperTextContainer>
            )}
            <ButtonContainer>
              <Button
                variant="outlined"
                className={"cancel-btn"}
                disableElevation
                data-test-id="cancel-test-id"
                onClick={() => this.handleUploadClose()}
              >
                Back
              </Button>
              <Button
                variant="contained"
                className={"confirm-btn"}
                disableElevation
                onClick={() => this.handleUploadSubmit()}
                data-test-id="confirm-test-id"
              >
                Confirm
              </Button>
            </ButtonContainer>
          </Box>
        </DialogUploadContainer>
        <DialogContainer
          data-test-id="bulk-action-success-dialog"
          open={this.state.isSuccessOpen}
          onClose={() => this.handleSuccessClose()}
        >
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="success-icon" />
            <Typography className="text-container">
              {this.state.successMessage}
            </Typography>
          </Box>
        </DialogContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.alertMessage}
          onClose={this.onTokenAlertClose}
          severity="error"
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </BulkActionContainer>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const BulkActionContainer = styled(Box)({
  "& .btn-container": {
    border: "1px solid #E2E8F0",
    borderRadius: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "28px 20px",
    "& .btn-name-title": {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 20,
      fontWeight: 500,
    },
  },
  "& .btn-container.selected": {
    backgroundColor: "#F1F5F9",
    "& .btn-name-title.selected": {
      fontWeight: 700,
    },
  },
});

const DialogUploadContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: 620,
    "& .cross-icon-container": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .content-container": {
      padding: "0px 50px 50px 50px",
      "& .success-line": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 20,
        color: "#375280",
        textAlign: "center",
        marginTop: 10,
      },
      "& .title-container": {
        fontFamily: "Lato, sans-serif",
        justifyContent: "center",
        fontWeight: 500,
        fontSize: 30,
        alignItems: "center",
        color: "#375280",
        display: "flex",
        paddingBottom: 30,
      },
      "& .upload-btn": {
        boxShadow: "none",
        backgroundColor: "#F8F8F8 !important",
        width: "100%",
        height: 185,
        "& .MuiButton-label": {
          display: "flex",
          flexDirection: "column",
          gap: 16,
        },
        "& .upload-title": {
          fontFamily: "Lato, sans-serif",
          fontSize: 18,
          fontWeight: 700,
          color: "#375280",
          textTransform: "capitalize",
        },
        "& .upload-csv": {
          fontFamily: "Lato, sans-serif",
          fontSize: 18,
          fontWeight: 500,
          color: "#94A3B8",
          textTransform: "capitalize",
        },
      },
    },
  },
});

const ImagePreviewContainer = styled(Box)({
  height: 214,
  margin: "16px 0px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
  background: "#fff",
  "& .img-preview": {
    height: 85,
    width: 85,
  },
  "& .progress-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
    fontSize: 18,
    textAlign: "center",
    color: "#375280",
    marginBottom: 5,
  },
  "& .upload-file-progressbar": {
    height: 6,
    borderRadius: 10,
    width: "90%",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#375280",
      borderRadius: 10,
    },
  },
  "& .re-upload-btn": {
    textTransform: "capitalize",
    color: "#375280",
    backgroundColor: "#fff",
    fontFamily: "Lato, sans-serif",
    borderRadius: "2px",
    width: "max-content",
    boxShadow: "none",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 600,
    },
  },
});

export const ButtonContainer = styled(Box)({
  display: "flex",
  gap: 16,
  height: "54px",
  width: "100%",
  marginTop: 30,
  "& .cancel-btn": {
    textTransform: "capitalize",
    color: "#375280",
    borderColor: "#CCBEB1",
    width: "50%",
    height: "100%",
    fontFamily: "Lato, sans-serif",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 500,
    },
  },
  "& .confirm-btn": {
    textTransform: "capitalize",
    color: "white",
    fontFamily: "Lato, sans-serif",
    gap: "8px",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    width: "50%",
    height: "100%",
    borderRadius: "2px",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 600,
    },
  },
  "& .delete-btn": {
    textTransform: "capitalize",
    color: "white",
    fontFamily: "Lato, sans-serif",
    gap: "8px",
    backgroundColor: "#F87171",
    width: "50%",
    height: "100%",
    borderRadius: "2px",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 600,
    },
  },
});
// Customizable Area End
