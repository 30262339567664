import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Card
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { Vector, scroll, view } from "./assets";
import { Noimageavailable, image_Likepng } from "../../productdescription3/src/assets";
import { close } from "../../customisableuserprofiles2/src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import WishlistController, {
  Props,

} from "./WishlistController.web";

export default class Wishlist extends WishlistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleFavouriteClick = (product: any) => {
    const isFavourite = product.attributes.is_wishlist;
    this.handleFavouriteApi(product.id, isFavourite);
  };
  renderWishlistContent() {

    if (this.state.wishlistData.length === 0) {
      return (
        <div style={{ display: "grid", placeItems: "center" }}>
          <div style={webStyle.innerContainer6 as React.CSSProperties}>
            <div>
              <div style={webStyle.innerContainer5}>
                <img src={view} alt="View" />
              </div>
              <div style={{
                fontSize: '34px',
                fontWeight: 700, marginBottom: "14px", marginTop: "39px", color: "#375280", fontFamily: "Lato , sans-serif",
              }}>
                Your wishlist is empty.
              </div>
              <div style={webStyle.innerContainer7 as React.CSSProperties}>
                You don't have any products in the wishlist yet. You will find a lot <br />
                of interesting products on our Shop page.
              </div>
              <Button onClick={this.contioneShopping} style={webStyle.innerContainer8 as React.CSSProperties} data-test-id="continueShopping">
                Continue Shopping
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ marginLeft: "130px" }}>
          <div style={{ color: "#375280", fontSize: "28px", fontWeight: 700, fontFamily: "Lato , sans-serif", marginTop: "85px", marginBottom: "50px" }}>
            Wishlist
          </div>
          {this.state.wishlistData.map((item: { id: React.Key | undefined; attributes: { favouriteable_id: string; favouriteable: { data: { id: string; attributes: { primary_image: string | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; primary_colour: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; primary_price: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; }; }; }; }; }) => (
            <div key={item.id}>
              <div style={{ display: "flex", alignItems: "center", padding: "0px 20px 0px 24px", justifyContent: "space-between", }}>
                <div style={{ display: "flex", alignItems: "center", gap: "36px" }}>
                  <img
                    src={close}
                    data-test-id="Deletefavourites"
                    onClick={() => this.Deletefavourite(item.attributes.favouriteable_id? item.attributes.favouriteable_id : null)}
                    style={{ width: "22px", height: "22px", cursor: "pointer" }}
                    alt="Close"
                  />
                  <img src={item.attributes.favouriteable?.data?.attributes?.primary_image ? item.attributes.favouriteable?.data?.attributes?.primary_image : Noimageavailable} style={{ width: "110px", height: "110px", borderRadius: "2px" }} alt="Product" />
                  <div style={{ color: "#375280", fontSize: "22px", fontWeight: 700, fontFamily: "Lato , sans-serif" }}>
                    <p>{item.attributes.favouriteable.data?.attributes.name}</p>
                    <p style={{ marginTop: "-10px" }}>Color: <span style={{ color: "#94A3B8" }}>{item.attributes.favouriteable?.data?.attributes?.primary_colour?.name}</span></p>
                    <p
                      style={{ marginTop: "-10px" }}>Quantity: <span style={{ color: "#94A3B8" }}>1</span></p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "19px" }}>
                  <div style={{ color: "#375280", fontSize: "22px", fontWeight: 700, fontFamily: "Lato , sans-serif" }}>${item.attributes.favouriteable?.data?.attributes?.primary_price}</div>
                  <div onClick={() => this.navigateTo({ productId: item.attributes.favouriteable_id, props: this.props, screenName: "Productdescription3" })} style={{ fontSize: "18px", fontWeight: 700, color: "white", backgroundColor: "#CCBEB1", padding: "14px 28px", cursor: "pointer", fontFamily: "Lato" }}>
                    Add to cart
                  </div>
                </div>
              </div>
              <hr style={{ color: "#E2E8F0" }} />
            </div>
          ))}
        </div>
      );
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} />
        <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} container>
          <Grid item >
            <div
              style={webStyle.innerContainer}>
              <Typography style={webStyle.innerContainer1}>
                Home
              </Typography>
              <img src={Vector} />
              <Typography style={webStyle.innerContainer2}>
                Wishlist
              </Typography>
            </div>
            <div >
              <Typography style={webStyle.innerContainer3}>
                <img src={scroll} />
                {this.state.fullName}
              </Typography>
            </div>
            <Typography style={webStyle.innerContainer4}>
              Welcome to your Account
            </Typography>
            <Sidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item xs={12} sm={8} style={{ display: "grid" }}>
            {this.renderWishlistContent()}
          </Grid>
        </Grid>
        <div
          style={{
            fontSize: "28px", fontWeight: 700, color: "#375280", margin: "auto", padding: "30px", fontFamily: "Lato , sans-serif",
          }}>You May Also Like</div>
        <hr style={{ width: "7%", color: "#CCBEB1", marginTop: "15px", marginBottom: "45px" }} />
        <Box style={{ marginBottom: '78px', display: 'flex', flexWrap: 'nowrap' }}>
          <Grid container spacing={4} justifyContent="center">
            {this.state.products.map((product, index: number) => (<>
              <Grid item key={index}>
                <Box style={{ cursor: 'pointer' }} >
                  <Card style={webStyle.productBoxproductd}>
                    <Box style={{ position: 'relative' }}>
                      <img
                        style={{ position: 'relative' }}
                        width="100%" height="370px"
                        src={product.attributes.primary_image === null ? Noimageavailable : product.attributes.primary_image}
                        alt="Product"
                        data-test-id={`navigateTo=${product.id}`}
                        onClick={() => this.navigateTo({ productId: product.id, props: this.props, screenName: "Productdescription3" })}
                      />
                      <Box style={{ ...webStyle.productIconproductd, position: 'absolute' }}>
                        <Box style={webStyle.productDiscountproductd}>15% Off</Box>

                        <Box
                          style={webStyle.productFavorite}
                          data-test-id="handleLikeClick"
                          onClick={() => this.handleFavouriteClick(product)}
                        >
                          {product.attributes.is_wishlist ? (
                            <FavoriteIcon width="18px" height="18px" style={{ fill: 'blue' }} />
                          ) : (
                            <FavoriteBorderIcon width="18px" height="18px" style={{ fill: 'blue' }} />
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ padding: '24px', backgroundColor: '#F8F8F8' }} data-testid="alertTestId">
                      <Box style={{ ...webStyle.productDataproductd, whiteSpace: 'nowrap', flexDirection: 'row' }}>
                        <Box style={{ ...webStyle.productValueproductd, whiteSpace: 'nowrap' }}>
                          <Typography style={{ ...webStyle.productNameproductd, whiteSpace: 'nowrap' }} >{product.attributes.name}</Typography>
                          <Typography style={{ ...webStyle.productDescriptionproductd, whiteSpace: 'nowrap' }} >{product.attributes.description}</Typography>
                        </Box>
                        <Box style={{ ...webStyle.acountMrpproductd, textAlign: 'center' }}>$&nbsp;{`${product.attributes.primary_price}`}</Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </>))}
          </Grid>

        </Box>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}

          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>

        <Footer navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  innerContainer: {
    color: "#475569",
    fontWeight: 400,
    lineHeight: "22px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "40px",
    marginTop: "27px"
  },
  productBoxproductd: { boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "282px", marginBottom: "20px" },
  productIconproductd: { top: '20px', left: '18px', display: "flex", justifyContent: 'space-between', right: '0' },
  acountMrpproductd: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
  productDescriptionproductd: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productNameproductd: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productFavorite: { color: "#375280", marginRight: '30px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productValueproductd: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto' },
  productDataproductd: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' },
  productFavoriteproductd: { color: "#375280", marginRight: '20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productDiscountproductd: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 5px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
  innerContainer1: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
  },
  innerContainer2: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569"
  },
  innerContainer3: {
    fontFamily: "Lato , sans-serif",
    fontSize: "28px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 800,
    color: "#375280",
    marginLeft: "40px",
    marginTop: "31px",
    display: "flex",
    alignItems: "center",
    gap: "15px"
  },
  innerContainer4: {
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 400,
    color: "#807D7E",
    marginLeft: "40px",
    marginBottom: "37px"

  },
  innerContainer5: {
    borderRadius: "50%",
    backgroundColor: "#CCBEB121",
    width: "170px",
    height: "170px",
    alignItems: "center",

    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  innerContainer6: {
    boxShadow: "2px 2px 4px 0px #0000000D, -2px -2px 4px 0px #0000000D",
    width: "690px",
    height: "500px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF"
  },
  innerContainer7: {
    color: "#375280",
    fontSize: "14px",
    fontWeight: "400px",
    fontFamily: "Lato , sans-serif",
  },
  innerContainer8: {
    backgroundColor: "#CCBEB1",
    borderRadius: "2px",
    fontSize: "18px",
    padding: "10px 16px 10px 16px",
    marginTop: "48px",
    height: "64px",
    color: "white",
    textTransform: "none",
    fontWeight: 800,
    width: "365px",
    fontFamily: "Lato , sans-serif",
  },
  innerContainer9: {
    backgroundColor: "#CCBEB1",
    borderRadius: "2px",
    fontSize: "18px",
    padding: "14px 28px 14px 28px",
    height: "50px",
    color: "white",
    textTransform: "none",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
  },
  productIcon1: { top: '20px', left: '18px', display: "flex", justifyContent: 'space-between', right: '0' },
  productDiscount1: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 5px', borderRadius: '2px', height: '17px' },
  productFavorite1: { color: "#375280", marginRight: '20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productName1: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: "70%" },
  productDescription1: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, width: "70%" },
  acountMrp: { color: '#375280', fontSize: '14px', fontWeight: 700, backgroundColor: '#F8F8F8', padding: '10px 14px', borderRadius: '8px', width: '73px' },

};
// Customizable Area End
