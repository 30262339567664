import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { doc, jpg, pdf } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { analyticsSaleCount } from "./Analytics.web";
import moment from "moment";

type NullableDate = Date | string | null | undefined;
interface ProductDetails {
  id: number;
  order_management_order_id: number;
  quantity: number;
  unit_price: string;
  total_price: string;
  old_unit_price: string | null;
  status: string;
  created_at: string;
  updated_at: string;
  catalogue_id: number;
  catalogue_variant_id: number;
  order_status_id: number;
  placed_at: string;
  confirmed_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  manage_placed_status: boolean;
  manage_cancelled_status: boolean;
  seller_id: number;
  seller_order_id: number;
  reason_of_rejection: string;
  rejected_at: string;
  bussiness_information_id: number;
  accept_order_upload_time: string | null;
  item_return_date: string | null;
  item_return_time: string | null;
  process_at: string | null;
  shipped_at: string | null;
  return_at: string | null;
  return_cancel_at: string | null;
  return_pick_at: string | null;
  return_placed_at: string | null;
  return_confirmed_at: string | null;
  return_reject_at: string | null;
  returned_assign_at: string | null;
  reason_of_return: string | null;
  refunded_cancel_at: string | null;
  reason_refunded_cancel: string | null;
}

export interface Product {
  product_id: number;
  sold_units: number;
  returned_units: number;
  total_earnings: string;
  image_url: string;
  product: ProductDetails;
  name: string;
}
interface GraphData {
    impressions: {
        January: number,
        February: number,
        March: number,
        April: number,
        May: number,
        June: number,
        July: number,
        August: number,
        September: number,
        October: number,
        November: number,
        December: number
    };
    visitors_count: {
        January: number,
        February: number,
        March: number,
        April: number,
        May: number,
        June: number,
        July: number,
        August: number,
        September: number,
        October: number,
        November: number,
        December: number
    };
  }
  
  interface Data {
    filter_range: string;
    total_sales: number;
    wishlisted: number;
    impressions: number;
    total_orders: number;
    visitors_count: number;
    products: Product[];
    graph_data: GraphData;
  }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  isLoadingAnalytics: boolean;
  tabIndex: number;
  pdfOpen: boolean;
  accountPdf: string;
  downloadOptions: { type: string, src: string, label: string }[];
  salesCountValue: { label: string, value: number }[]
  allStoreAnalyticsReport: Data;
  allStoreProductReach: Data;
  anchorEl: HTMLElement | null,
  downloadOptionsActive: { type: string, label: string }[];
  accountFilter: string;
  endDate: NullableDate;
  startDate: NullableDate;
  isPickerOpen: boolean,
  tempFilter: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAnalyticsApiCallId: string = ""
  getProductReachApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      isLoadingAnalytics: true,
      tabIndex: 0,
      pdfOpen: false,
      accountPdf: "pdf",
      endDate: null,
      downloadOptions: [
        { type: 'pdf', src: pdf, label: 'PDF' },
        { type: 'doc', src: doc, label: 'Doc File' },
        { type: 'jpg', src: jpg, label: 'JPG' },
    ],
    salesCountValue: analyticsSaleCount,
    allStoreAnalyticsReport: {
      "filter_range": "this week",
      "total_sales": 92700,
      "wishlisted": 0,
      "impressions": 86,
      "total_orders": 4,
      "visitors_count": 9,
      "products": [{
        "product_id": 1259,
        "sold_units": 0,
        "returned_units": 0,
        "total_earnings": "100.0",
        "image_url": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdVFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a24a3d5f493e7f383d926f6b5e1beb33c4173838/20181202_170304.png",
        "product": {
            "id": 1259,
            "order_management_order_id": 923,
            "quantity": 1,
            "unit_price": "100.0",
            "total_price": "100.0",
            "old_unit_price": null,
            "status": "rejected",
            "created_at": "2024-07-13T04:18:48.552Z",
            "updated_at": "2024-07-13T04:20:38.227Z",
            "catalogue_id": 302,
            "catalogue_variant_id": 470,
            "order_status_id": 2,
            "placed_at": "2024-07-13T04:20:21.373Z",
            "confirmed_at": null,
            "in_transit_at": null,
            "delivered_at": null,
            "cancelled_at": null,
            "refunded_at": null,
            "manage_placed_status": false,
            "manage_cancelled_status": false,
            "seller_id": 501,
            "seller_order_id": 661,
            "reason_of_rejection": "Order Processing Error",
            "rejected_at": "2024-07-13T04:20:38.217Z",
            "bussiness_information_id": 246,
            "accept_order_upload_time": null,
            "item_return_date": null,
            "item_return_time": null,
            "process_at": null,
            "shipped_at": null,
            "return_at": null,
            "return_cancel_at": null,
            "return_pick_at": null,
            "return_placed_at": null,
            "return_confirmed_at": null,
            "return_reject_at": null,
            "returned_assign_at": null,
            "reason_of_return": null,
            "refunded_cancel_at": null,
            "reason_refunded_cancel": null
        },
        "name": "Brand 246"
    }],
      "graph_data": {
          "impressions": {
              "January": 0,
              "February": 0,
              "March": 0,
              "April": 0,
              "May": 0,
              "June": 0,
              "July": 82,
              "August": 0,
              "September": 0,
              "October": 0,
              "November": 0,
              "December": 0
          },
          "visitors_count": {
              "January": 0,
              "February": 0,
              "March": 0,
              "April": 0,
              "May": 0,
              "June": 0,
              "July": 9,
              "August": 0,
              "September": 0,
              "October": 0,
              "November": 0,
              "December": 0
          }
      }
  },
  allStoreProductReach: {
    "impressions": 86,
    "wishlisted": 0,
    "total_sales": 92700,
    "filter_range": "this week",
    "visitors_count": 9,
    "products": [{
      "product_id": 1259,
      "sold_units": 0,
      "image_url": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdVFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a24a3d5f493e7f383d926f6b5e1beb33c4173838/20181202_170304.png",
      "returned_units": 0,
      "total_earnings": "100.0",
      "product": {
        "order_management_order_id": 923,
          "id": 1259,
          "unit_price": "100.0",
          "quantity": 1,
          "old_unit_price": null,
          "total_price": "100.0",
          "created_at": "2024-07-13T04:18:48.552Z",
          "updated_at": "2024-07-13T04:20:38.227Z",
          "status": "rejected",
          "catalogue_variant_id": 470,
          "catalogue_id": 302,
          "placed_at": "2024-07-13T04:20:21.373Z",
          "return_cancel_at": null,
          "return_at": null,
          "return_placed_at": null,
          "return_pick_at": null,
          "return_confirmed_at": null,
          "returned_assign_at": null,
          "return_reject_at": null,
          "refunded_cancel_at": null,
          "reason_refunded_cancel": null,
          "reason_of_return": null,
          "order_status_id": 2,
          "confirmed_at": null,
          "in_transit_at": null,
          "cancelled_at": null,
          "delivered_at": null,
          "manage_placed_status": false,
          "manage_cancelled_status": false,
          "refunded_at": null,
          "seller_order_id": 661,
          "seller_id": 501,
          "rejected_at": "2024-07-13T04:20:38.217Z",
          "reason_of_rejection": "Order Processing Error",
          "bussiness_information_id": 246,
          "item_return_date": null,
          "accept_order_upload_time": null,
          "item_return_time": null,
          "process_at": null,
          "shipped_at": null,
      }, 
      "name": "Brand 246" 
  }], 
    "total_orders": 4,  
    "graph_data": {  
        "visitors_count": {
            "January": 0,
            "March": 0,
            "February": 0,
            "April": 0,
            "December": 0,
            "May": 0,
            "June": 0,
            "July": 9,
            "September": 0,
            "August": 0,
            "October": 0,
            "November": 0,
        },
        "impressions": {
          "March": 0,
            "January": 0,
            "June": 0,
            "August": 0,
            "October": 0,
            "September": 0,
            "December": 0,
            "November": 0,
            "February": 0,
            "May": 0,
            "April": 0,
            "July": 82,
        },
    }
},
  anchorEl: null,
  downloadOptionsActive: [
    { type: 'this week', label: 'This Week' },
    { type: 'this month', label: 'This Month' },
    { type: 'this year', label: 'This Year' },
    { type: 'manually', label: 'Select Date Range' },
],
accountFilter: "this week",
isPickerOpen: false,
tempFilter: "this week",
startDate: null,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getAnalyticsApiCallId){
      this.setState({ allStoreAnalyticsReport: responseJson.data, isLoadingAnalytics: false, salesCountValue: [
        { label: "Total Sales", value: `$ ${responseJson.data.total_sales}` },
        { label: "Impression", value: responseJson.data.impressions },
        { label: "Total Orders", value: responseJson.data.total_orders },
        { label: "Visitors", value: responseJson.data.visitors_count }
      ]})
    }
    if(apiRequestCallId === this.getProductReachApiCallId){
      this.setState({ allStoreProductReach: responseJson.data, isLoadingAnalytics: false, salesCountValue: [
        { label: "Total Sales", value: `$ ${responseJson.data.total_sales}` },
        { label: "Impression", value: responseJson.data.impressions },
        { label: "Total Orders", value: responseJson.data.total_orders },
        { label: "Visitors", value: responseJson.data.visitors_count }
      ]})
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getSellerOrdersProcessedApi();
    this.getSellerProductReachApi();
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabIndex: newValue });
  };

  handleOpenPdf = () => {
    this.setState({ pdfOpen: true });
};
handleClosePdf = () => {
    this.setState({ pdfOpen: false });
}

handlePopoverClose = () => {
  this.setState({ anchorEl: null });
};

handlefilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  this.setState({
      accountFilter: value,
  });
}

handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
  this.setState({ anchorEl: event?.currentTarget });
};

handleEndDateChange = (date: Date | null) => {
  this.setState({ endDate: date, isPickerOpen: false });
};

handlesatrtDateChange = (date: Date | null) => {
  this.setState({ startDate: date, isPickerOpen: false });
};

filterSellerEarnings = () => {
  this.setState({ tempFilter: this.state.accountFilter, anchorEl: null }, this.state.tabIndex === 0 ? () => this.getSellerOrdersProcessedApi() : () => this.getSellerProductReachApi());
};

handlePdfChange = (event: React.ChangeEvent<{}>) => {
    const value = (event.target as HTMLInputElement).value;
    this.setState({ accountPdf: value });
}

navigateToProductOverview = ({productOverviewId,bussinessInformationId,props,screenName}: {productOverviewId: number | undefined,bussinessInformationId: number | undefined,props: unknown,screenName: string}) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
  productOverviewId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productOverviewId);
  runEngine.sendMessage(message.id, message);
  setStorageData("bussinessInformationId", bussinessInformationId)
  setStorageData("productOverviewId", productOverviewId)
}

getSellerProductReachApi = async () => {  
  const startDateProductReach = moment(this.state.startDate).format('DD-MM-YYYY');
  const endDateProductReach = moment(this.state.endDate).format('DD-MM-YYYY');
  const token = await getStorageData("auth-token");
  
  const headers = {
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getProductReachApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/accounts/bussiness/all_stores_products_analytics_report?filter_range=${this.state.accountFilter}&start_date=${startDateProductReach}&end_date=${endDateProductReach}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

getSellerOrdersProcessedApi = async () => {  
  const startDateNew = moment(this.state.startDate).format('DD-MM-YYYY');
  const startDateNewEnd = moment(this.state.endDate).format('DD-MM-YYYY');
  const token = await getStorageData("auth-token");
  
  const headers = {
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAnalyticsApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/accounts/bussiness/all_store_analytics_report?filter_range=${this.state.accountFilter}&start_date=${startDateNew}&end_date=${startDateNewEnd}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}
  // Customizable Area End
}
