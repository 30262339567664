import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { forgotpassword } from "../src/assets";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import ReactCountryFlag from 'react-country-flag'
// Customizable Area End

import ForgotPasswordController, {
  CountrySuccess,
  Props,
  configJSON,
} from "./ForgotPasswordController.web";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  render() {
    const phoneClassName=this.state.forgotError?"inputFeild2Error2":"inputFeild2";
    const phoneErrorMessage=this.state.errorMsg.forgotNumberError ? this.state.errorMsg.errorMsgPhone : "";
    return (
      <>
      <Header navigation={this.props.navigation}/>
      <MainGridContainer container>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="sideGrid">
          <Box className="sideImageBox">
            <img src={forgotpassword} alt="" className="sideImage" />
          </Box>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
            <Box className={"formBox"}>
              <form data-test-id="formSubmitId"  onSubmit={this.handleSubmit}>
                <Box>
                  <Typography className={"mainCotainer"}>{configJSON.forgotPassword}</Typography>
                  <Typography className={"inputHeading"}>{configJSON.emailTextAddress}</Typography>
                  <TextField
                  data-test-id="emailTextFiled"
                  fullWidth
                  variant="outlined"
                  className={this.state.emailError ? "inputFeild2Error2" : "inputFeild2"}
                  placeholder={configJSON.emailPlaceholder}
                  value={this.state.isEmail}
                  onChange={this.handleEmail}
                  error={this.state.errorMsg.errorEmail}
                  helperText={this.state.errorMsg.errorEmail ? this.state.errorMsg.emailMessage : ""}
                />
                  <Box className={"TextHeading"}>
                    <Typography className={"inputHeading2"}>{configJSON.or}</Typography>
                  </Box>

                  <Typography data-test-id="countryId" className={"inputHeading"}>{configJSON.phoneNumber}</Typography>
                  <Box className={"phoneInput"}>
                    <CustomDropDownBox>
                      <DropDownHeaderMenu data-test-id="toggleCountryId" onClick={this.toggleDropdown} className={this.state.errorMsg.forgotNumberError ? "isActive" : ""}>
                        {this.state.selectedCountry ? (
                          <SelectedCountryList>
                            <Typography data-test-id='testdata'>
                              <ReactCountryFlag data-test-id='flagData' countryCode={this.state.selectedCountry.country_code} svg />
                              {this.state.selectedCountry.numeric_code}
                            </Typography>
                          </SelectedCountryList>
                        ) : (
                          <Typography data-test-id='Flagdata' className={"countryFlag"}>
                            <ReactCountryFlag countryCode="KW" svg />
                            {this.state.isCountryData}
                          </Typography>
                        )}
                        <span>{this.state.dropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                      </DropDownHeaderMenu>
                      {this.state.dropdownOpen && (
                        <DropDownOptionsList data-test-id='Dropdata' >
                          {this.state.countrySuccess.map((country: CountrySuccess) => (
                            <OptionsValueList
                              data-test-id="countrySelectId"
                              key={country.country_code}
                              onClick={() => this.handleCountrySelect(country, country.numeric_code)}
                            >
                              <Typography data-test-id='listData' className={"listData"}>
                                <ReactCountryFlag countryCode={country.country_code} svg />
                                {country.country_full_name} ({country.numeric_code})
                              </Typography>
                            </OptionsValueList>
                          ))}
                        </DropDownOptionsList>
                      )}
                    </CustomDropDownBox>
                    <TextField
                      data-test-id="phoneNumberTextFiled"
                      variant="outlined"
                      className={phoneClassName}
                      value={this.state.forgotMobileNumber}
                      onChange={this.handlePhoneNumberChange}
                      placeholder={configJSON.placeName}
                      error={this.state.errorMsg.forgotNumberError}
                      helperText={phoneErrorMessage}
                      inputProps={{ maxLength: 12 }}
                      InputProps={{
                        style: {
                          gap: "4px",
                          border: "0px",
                          borderRadius: "0px",
                          width: "434px"
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  className={"loginUserButton"}
                  disableElevation
                  type="submit"
                >
                  {configJSON.resetPassword}
                </Button>
              </form>
            </Box>
        </Grid>
      </MainGridContainer>
      <Footer navigation={this.props.navigation}/>
      </>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const MainGridContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& .sideGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .sideImageBox": {
    width: "100%",
    height: "100%",
  },
  
  "& .sideImage": {
    width: "100%",
    height: "100%",
  },
  "& .mainCotainer": {
    marginBottom: "40px",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Lato",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "26px",
    alignSelf: "flex-start",
  },
  "& .TextHeading": {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "40px",
  },
  "& .inputHeading": {
    color: "rgba(55, 82, 128, 1)",
    fontFamily: "Lato",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "10px"
  },
  "& .inputHeading2": {
    color: "rgba(55, 82, 128, 1)",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "10px"
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important",
  },
  "& .phoneInput": { display: "flex",gap:"15px",height:"60px", width: "100%px" },
  "& .formGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .formBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width:"80%",
    paddingTop: "50px",
    padding: "5px",
  },
  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px",
  },
  "& .loginUserButton": {
    textTransform: "capitalize",
    margin: "25px 0px 20px 0px !important",
    width: "100% !important",
    borderRadius: "0px !important",
    color: "rgba(255, 255, 255, 1) !important",
    backgroundColor: "rgba(204, 190, 177, 1)",
    gap: "8px",
    height: "56px !important",
    fontSize: "18px",
    fontWeight:700,
    fontFamily:'Lato'
  },
  "& .inputFeild2Error2":{
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
        border:"none"
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
    "& .MuiFormHelperText-contained":{
        margin:"0 !important"
    },
    
},
"& .listData":{
  color:'#375280',
  display: "flex",
  alignItems: "center",
  gap: "3px"
},
"& .inputFeild2": {
  "& .MuiTextField-root":{
      "&:hover":{
          border: "1px solid #F0F0F0 !important",
      },
  },
  "& .MuiOutlinedInput-root:hover" :{
      border: "1px solid #F0F0F0 !important",
    },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
  },
  "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
  },
  '& .MuiInputBase-input': {
      color: "#375280"
  },
  "& .MuiFormHelperText-contained":{
      margin:"0 !important"
  },
     "& .MuiOutlinedInput-notchedOutline":{
      borderColor: "#F0F0F0",
  }
},
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .formBox": { width: "400px !important", flexWrap: "wrap" },
    "& .phoneInput": { width: "100%" ,height: "60px" },
    "& .inputFeild2": {
      "& .MuiTextField-root":{
          "&:hover":{
              border: "1px solid #F0F0F0 !important",
          },
      },
      "& .MuiOutlinedInput-root:hover" :{
          border: "1px solid #F0F0F0 !important",
        },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
      },
      "& .MuiOutlinedInput-root": {
          backgroundColor: "#F0F0F0",
          borderRadius: "2px",
          
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F0F0F0",
      },
      '& .MuiInputBase-input': {
          color: "#375280"
      },
      "& .MuiFormHelperText-contained":{
          margin:"0 !important"
      },
         "& .MuiOutlinedInput-notchedOutline":{
          borderColor: "#F0F0F0",
      },
      width:"300px"
    },
    "& .continueText": {
      width: "87%",
      color: "#375280 !important",
      fontFamily: "Lato, sans-serif",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      fontWeight: 400,
      lineHeight: "24px !important",
      display: "flex",
      flexDirection: "row",
      "&:before, &:after": {
        content: "''",
        flex: "1 1",
        borderBottom: "1px solid",
        margin: "auto",
      },
      "&:before": {
        marginRight: "25px",
      },
      "&:after": {
        marginLeft: "25px",
      },
    },
  },
});
const DropDownOptionsList = styled(Box)({
  left: 0,
  width: "100%",
  border: "1px solid #ccc",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  backgroundColor: "#fff",
  position: "absolute",
  zIndex: 1,
  height: "60vh",
  overflowX: "hidden"
});
const CustomDropDownBox = styled(Box)({
  position: "relative",
  cursor: "pointer",
  width: "125px"
});

const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const OptionsValueList = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
      backgroundColor: "#F0F0F0"
  }
});
const DropDownHeaderMenu = styled(Box)({
  padding: "10px",
  gap: "15px",
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  background: "#F0F0F0",
  height: "36px",
  color: "#375280",
  fontSize: "18px",
  "&.isActive":{
  border: "1px solid red",
  },
  "&:hover": {
      backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
