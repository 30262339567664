import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

export interface OrderOfItemSeller {
  type: "order_item_seller";
  id: string;
  attributes: {
    placed_at: string;
    status: string;
    in_transit_at: string | null;
    delivered_at: string | null;
    confirmed_at: string;
    cancelled_at: string | null;
    rejected_at: string | null;
    shipped_at: string | null;
    process_at: string;
    return_cancel_at: string | null;
    return_at: string | null;
    return_pick_at: string | null;
    quantity: number;
    unit_price: string;
    store_name: string;
    catalogue_variant_size: string;
    catalogue_variant_front_image: string;
    catalogue_variant_back_image: string;
    catalogue_variant_side_image: string;
    driver_name: string | null;
    driver_latitude: number | null;
    driver_longitude: number | null;
    total_price: string;
    reason_of_rejection: string | null;
    catalogue_name: string;
    brand_name: string;
    catalogue_variant_color: string;
    catalogue_variant_sku: string;
    otp: string | null;
    driver_phone_number: string | null;
  };
}

interface DeliveryAddress {
  type: "delivery_address";
  id: string;
  attributes: {
    block: string;
    city: string;
    house_or_building_number: string;
    address_name: string;
    is_default: boolean;
    latitude: number;
    longitude: number;
    name: string;
    country_code: string;
    phone_number: string;
    contact_number: string;
    street: string;
    zip_code: string;
    area: string;
  };
}

interface PaymentDetail {
  id: number;
  status: string;
  currency: string;
  customer_id: string;
  reason: string;
  account_id: number;
  order_management_order_id: number;
  refund_id: string | null;
  refund_reason: string | null;
  refund_amount: string | null;
  seller_order_id: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
  created_at: string;
  updated_at: string;
  charge_id: string;
  merchant_id: string | null;
  order_id: string;
  amount: number;
}

interface OrderSellerSide {
  type: "seller_order_seller";
  id: string;
  attributes: {
    order_status_id: number;
    created_at: string;
    updated_at: string;
    order_deliver_date: string | null;
    order_deliver_time: string | null;
    delivery_addresses: DeliveryAddress;
    order_return_date: string | null;
    order_return_time: string | null;
    payment_detail: PaymentDetail;
    order_number: string;
    account: string;
    sub_total: string;
    total: string;
    status: string;
    placed_at: string;
    confirmed_at: string;
    in_transit_at: string | null;
    delivered_at: string | null;
    cancelled_at: string | null;
    refunded_at: string | null;
    returned_at: string | null;
    deliver_by: string | null;
  };
}

export interface OrderOfOrderSeller {
  type: "seller_order_seller";
  id: string;
  attributes: {
    status: string;
    id: number;
    order_items: OrderOfItemSeller[];
    order_management_order: OrderSellerSide;
    accept_order_upload_time: string | null;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoadingReady: boolean
  readyForPage: number
  isAlertReadyForCollection: boolean
  metaReadyForData: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  SellerOrdersProcessedView: OrderOfOrderSeller[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OutOfDeliveryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOutForDeliveryApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoadingReady: true,
      SellerOrdersProcessedView: [],
      metaReadyForData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      readyForPage: 1,
      isAlertReadyForCollection: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getOutForDeliveryApiCallId){
      this.setState({ metaReadyForData: responseJson.meta, SellerOrdersProcessedView: responseJson.data, isLoadingReady: false, isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false })
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    this.getOutForDeliveryApi()
    // Customizable Area End
  }

  navigateToOutForDelivery = ({productDetailId,props,screenName}: {productDetailId: string | undefined,props: unknown,screenName: string}) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productDetailId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productDetailId);
    runEngine.sendMessage(message.id, message);
  }

  getOutForDeliveryApi = async () => {
    const token = await getStorageData("auth-token");
    
    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOutForDeliveryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerOrdersProcessed}?type=shipped&page=${this.state.readyForPage}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
