import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RefObject, createRef } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Product {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    primary_image: string | undefined;
    primary_price: string;
  };
}
interface VariantSwithStore {
  id: string,
  type: string,
  attributes: {
    id: number,
    catalogue_variant_color_id: number,
    catalogue_id: number,
    catalogue_variant_color: {
      name: string,
      id: number,
      updated_at: string
      created_at: string,
    },
    catalogue_variant_size_id: number,
    catalogue_variant_size: {
      name: string,
      id: number,
      updated_at: string
      created_at: string,
    },
    front_image: string,
    pair_it_with: [
      {
        type: string,
        id: string,
        attributes: {
          catalogue_id: number;
          stock_qty: number;
          product_description: string;
          product_name: string;
          id: number;
          low_stock_threshold: number;
          sku: string;
          is_listed: boolean;
          price: string;
          size: string;
          colour: string;
          gender: string;
          brand_name: string;
          front_image: string;
        }
      }
    ]
  },
  store_info: {
    type: string,
    id: string,
    attributes: {
      area: string,
      description: string,
      store_name: string,
      address: string,
      block: string,
      mall_name?: string,
      unit_number: number,
      zipcode: number | string,
      floor: string,
      expected_delivery_time: string
      city: string,
      average_shipping_time: string
      driver_instruction: string,
    },
  }
}
interface ProductData {
  type: string;
  id: string;
  attributes: {
    catalogue_variants_with_store: VariantSwithStore[]
    name: string;
    description: string;
    primary_price: string;
    fit: string;
    primary_image: string | undefined;
    id: string;
    prodcut_care: string;
    material: string;
    expected_delivery_time: string;
  };
}
interface INavigateTo {
  productId: string | undefined | number;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedItem: number;
  arrowDisable: boolean;
  products: Product[],
  productsData: ProductData | null,
  categoryData: string,
  sub_categoryData: string,
  colors: string[],
  lodingProduct: boolean,
  isAlert: boolean,
  already: string,
  setProps: React.ReactNode
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PairitwithWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  elementRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  getcategoriesCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      setProps: "",
      isAlert: false,
      colors: ['red', 'blue', 'green', 'yellow', 'purple'],
      sub_categoryData: "",
      categoryData: "",
      arrowDisable: false,
      selectedItem: 0,
      productsData: null,
      products: [],
      lodingProduct: true,
      already: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getcategoriesCallId !== null &&
      this.getcategoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonColor = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const categoryName = responseJsonColor.data.attributes.category.attributes.name;
      const match = categoryName.match(/^[^\s']+/);
      const firstWord = match ? match[0] : '';
      this.setState({ productsData: responseJsonColor.data, lodingProduct: false, categoryData: firstWord, sub_categoryData: responseJsonColor.data.attributes?.sub_category.attributes.name })
    }
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  handleSelect = (index: number) => {
    this.setState({ selectedItem: index });
  };
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  handleNextClick = () => {
    this.setState(prevState => ({ selectedItem: prevState.selectedItem + 1 }));
    this.handleHorizantalScroll(this.elementRef, 25, 100, 10);
  };
  clothingPageNavigation = (props: React.ReactNode) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CategoriessubcategoriesWebPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    this.send(message);
  }
  handleClick = () => {
    this.setState(prevState => ({ selectedItem: prevState.selectedItem - 1 }));
    this.handleHorizantalScroll(this.elementRef, 25, 100, -10);
  };
  handleHorizantalScroll = (element: RefObject<HTMLDivElement>, speed: number, stepPairit: number, distance: number) => {
    let scrollAmountPairit = 0;
    const slideTimerPairit = setInterval(() => {
      if (element.current) {
        element.current.scrollLeft += stepPairit;
        scrollAmountPairit += Math.abs(stepPairit);
        if (scrollAmountPairit >= distance) {
          clearInterval(slideTimerPairit);
        }
        if (element.current.scrollLeft === 0) {
          this.setState({ arrowDisable: true });
        } else {
          this.setState({ arrowDisable: false });
        }
      }
    }, speed);
  };
  homePageNavigation = (props: React.ReactNode) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    this.send(message);
  }
  // Customizable Area End
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.categorey()
    // Customizable Area End
  }

  navigateTo = ({
    productId,
    props,
    screenName,
  }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    runEngine.sendMessage(message.id, message);
    this.categorey()
  }
  categorey = async () => {
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageListParirit = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcategoriesCallId = requestMessageListParirit.messageId;
    requestMessageListParirit.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageListParirit.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cataloge}/${idData}`
    );
    requestMessageListParirit.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListParirit.id, requestMessageListParirit);
  }
  // Customizable Area End
}