import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Grid, Typography, styled, Box } from "@material-ui/core";
import StylishMyClientsController, {
  Props,
} from "./StylishMyClientsController";
import { ThemeProvider } from "react-native-elements";
import { backBtnIcon, rightArrowIcon } from "../../assets";
import StylishSidebar from "../../../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../../../components/src/Stylish/StylishHeader";
import {
  StylishClientContainer,
  StylishClientTitleContainer,
} from "./StylingClientWishlist.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylishMyClients extends StylishMyClientsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid
            item
            xl={2}
            md={2}
            id="stylish-my-clients-sidebar-catalogue"
            lg={2}
            sm={2}
            xs={2}
          >
            <StylishSidebar navigation={this.props.navigation} />
          </Grid>
          <Grid
            item
            xl={10}
            md={10}
            id="stylish-my-clients-header-catalogue"
            lg={10}
            sm={10}
            xs={10}
          >
            <StylishHeader navigation={this.props.navigation} />
            <StylishClientContainer>
              <StylishClientTitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  onClick={() =>
                    this.handleStylishMyClientsRedirect("StylishClients")
                  }
                />
                <Typography className="main-title">My Clients</Typography>
              </StylishClientTitleContainer>
              <StylishClientRespDataContainer>
                <Grid container spacing={4}>
                  {this.state.respData.map((resp) => (
                    <Grid
                      key={resp.id}
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                    >
                      <ClientDetailContainer data-test-id={`response-test-id-${resp.id}`} onClick={()=>this.handleStylishMyClientsRedirect("StylishClientDetails")}>
                        <Box className="client-detail">
                          <Typography className="client-name">
                            {resp.clientName}
                          </Typography>
                          <Box
                            className={`client-status client-status-${resp.status}`}
                          >
                            {resp.status}
                          </Box>
                        </Box>
                        <img src={rightArrowIcon} alt="right-icon" />
                      </ClientDetailContainer>
                    </Grid>
                  ))}
                </Grid>
              </StylishClientRespDataContainer>
            </StylishClientContainer>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StylishClientRespDataContainer = styled(Box)({
  marginTop: 40,
  minHeight: "calc(100vh - 205px)",
  overflowY: "auto",
  overflowX: "hidden",
});

const ClientDetailContainer = styled(Box)({
  display: "flex",
  gap: 16,
  justifyContent: "space-between",
  alignItems: "center",
  padding: "4px 0px 20px 0px",
  borderBottom: "1px solid #CBD5E1",
  "& .client-detail": {
    display: "flex",
    gap: 12,
    alignItems: "center",
    "& .client-name": {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontWeight: 500,
      fontSize: 18,
    },
    "& .client-status": {
      borderRadius: 5,
      padding: "6px 10px 6px 10px",
      fontFamily: "Lato, sans-serif",
      fontWeight: 500,
      fontSize: 12,
    },
    "& .client-status-Completed": {
      color: "#059669",
      backgroundColor: "#D1FAE5",
    },
    "& .client-status-Current": {
      color: "#BE5B00",
      backgroundColor: "#FFE7D0",
    },
  },
});
// Customizable Area End
