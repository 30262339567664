import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Button,
    Backdrop,
    Fade,
    Modal
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import Footer from "../../../components/src/Footer";
import StylistAccountActiveProfileController, {
    Props
} from "./StylistAccountActiveProfileController.web";
import { view1, document, buttonDelete, documentBack, view_Icon } from "./assets";
import SellerHeaderActive from "../../../components/src/Seller/SellerHeaderActive";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export default class StylistUploadDocumentsweb extends StylistAccountActiveProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <>
                <SellerHeaderActive navigation={this.props.navigation} />
                <MainGrid data-test-id="mainGridContainer" container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
                        <Box className="sideImageBox">
                            <img src={documentBack} alt="" className="sideImage" />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
                        <Box className={"formBox"} data-testid="portfolioActive">
                            <Box width={"100%"}>
                                <Box style={{ display: "flex", alignItems: "center", gap: "25px", marginBottom: "30px" }}>
                                    <img src={view1} alt="" className="view" data-testid="getNavigationProfile" onClick={() => this.getNavigationActiveAddress()} />
                                    <Typography className={"welcomeText"} data-testid="CreateProfile">Create Profile</Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6" style={webStyle.docTitle} data-testid="CivilIDPassport">Civil ID/Passport</Typography>
                                    <Box style={webStyle.documentBox as React.CSSProperties}>
                                        <Box>
                                            <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <img className="fieldImg" alt='civilIdPassport' src={document} data-testid="civilIdPassport" onClick={() => { this.civilIDPassportopenPictureUpload("civilIdPassport") }} />
                                            </Box>
                                            <Box style={{ textAlign: 'center' }}>
                                                <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allLableText}>Upload png, jpg, jpeg or pdf</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allDiscripcen}>Passport is accepted for only "Kuwaitis". Required for all shareholders with 25% shares or more plus all authorized signatories as well</Typography>
                                </Box>
                                <Box style={{ display: 'flex' }}>
                                    {this.state.presentImageCombineImg.map((value, index) => (
                                        <Box style={{ position: 'relative', ...webStyle.addimgPosisen }}>
                                            <Box style={{ ...webStyle.uplodeImgIcon, textAlign: 'center' }}>
                                                <img src={value.combineImgcivilID[0] || undefined} width="40px" height="40px" style={{ marginTop: '10px' }} />
                                                <Typography style={{ ...webStyle.selectNameUplodeFile, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{value.selectedFilecivilID[0] && value.selectedFilecivilID[0].name.substring(0, value.selectedFilecivilID[0].name.lastIndexOf('.'))}</Typography>
                                            </Box>
                                            <Box style={{ position: 'absolute', ...webStyle.removedImg }}><img src={buttonDelete} width="24px" height="24px" data-testid="handleRemoveThad" onClick={() => { this.civilIDPassportHandleRemoveThad(index, "civilIdPassport") }} /></Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box>
                                    <Typography variant="h6" style={webStyle.docTitle}>Commercial License</Typography>
                                    <Box style={webStyle.documentBox as React.CSSProperties}>
                                        <Box>
                                            <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <img className="fieldImg" alt='commercialLicense' src={document} data-testid="commercialLicense" onClick={() => { this.civilIDPassportopenPictureUpload("commercialLicense") }} />
                                            </Box>
                                            <Box style={{ textAlign: 'center' }}>
                                                <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allLableText}>Upload png, jpg, jpeg</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ display: 'flex' }}>
                                    {this.state.commercialLicense.map((value, index) => (
                                        <Box style={{ position: 'relative', ...webStyle.addimgPosisen }}>
                                            <Box style={{ ...webStyle.uplodeImgIcon, textAlign: 'center' }}>
                                                <img src={value.combineImgCommercialLicense[0] || undefined} width="40px" height="40px" style={{ marginTop: '10px' }} />
                                                <Typography style={{ ...webStyle.selectNameUplodeFile, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{value.selectedCommercialLicense[0] && value.selectedCommercialLicense[0].name.substring(0, value.selectedCommercialLicense[0].name.lastIndexOf('.'))}</Typography>
                                            </Box>
                                            <Box style={{ position: 'absolute', ...webStyle.removedImg }}><img src={buttonDelete} width="24px" height="24px" data-testid="handleRemoveCommercialLicense" onClick={() => { this.civilIDPassportHandleRemoveThad(index, "commercialLicense") }} /></Box>
                                        </Box>
                                    ))}
                                </Box>

                                <Box>
                                    <Typography variant="h6" style={webStyle.docTitle}>Authorized Signatures</Typography>
                                    <Box style={webStyle.documentBox as React.CSSProperties}>
                                        <Box>
                                            <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <img className="fieldImg" alt='add image' src={document} data-testid="authorizedSignatures" onClick={() => { this.civilIDPassportopenPictureUpload("authorizedSignatures") }} />
                                            </Box>
                                            <Box style={{ textAlign: 'center' }}>
                                                <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allLableText}>Upload png, jpg, jpeg</Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box style={{ display: 'flex' }}>
                                        {this.state.authorizedSignatures.map((value, index) => (
                                            <Box style={{ position: 'relative', ...webStyle.addimgPosisen }}>
                                                <Box style={{ ...webStyle.uplodeImgIcon, textAlign: 'center' }}>
                                                    <img src={value.combineImgAuthorizedSignatures[0] || undefined} width="40px" height="40px" style={{ marginTop: '10px' }} />
                                                    <Typography style={{ ...webStyle.selectNameUplodeFile, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{value.selectedAuthorizedSignatures[0] && value.selectedAuthorizedSignatures[0].name.substring(0, value.selectedAuthorizedSignatures[0].name.lastIndexOf('.'))}</Typography>
                                                </Box>
                                                <Box style={{ position: 'absolute', ...webStyle.removedImg }}><img src={buttonDelete} width="24px" height="24px" data-testid="authorizedSignaturesRemoved" onClick={() => { this.civilIDPassportHandleRemoveThad(index, "authorizedSignatures") }} /></Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography variant="h6" style={webStyle.docTitle}>MOA or AOA</Typography>
                                    <Box style={webStyle.documentBox as React.CSSProperties}>
                                        <Box>
                                            <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <img className="fieldImg" alt='add image' src={document} data-testid="moaOrAoa" onClick={() => { this.civilIDPassportopenPictureUpload("moaOrAoa") }} />
                                            </Box>
                                            <Box style={{ textAlign: 'center' }}>
                                                <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allLableText}>Upload pdf</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allDiscripcen}>Copy of the Memorandum of Association or Articles of association of shareholding entities with 25% shares or more is required.</Typography>

                                    <Box style={{ display: 'flex' }}>
                                        {this.state.moaOrAoa.map((value, index) => (
                                            <Box style={{ position: 'relative', ...webStyle.addimgPosisen }}>
                                                <Box style={{ ...webStyle.uplodeImgIcon, textAlign: 'center' }}>
                                                    <img src={value.combineImgMoaOrAoa[0] || undefined} width="40px" height="40px" style={{ marginTop: '10px' }} />
                                                    <Typography style={{ ...webStyle.selectNameUplodeFile, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{value.selectedMoaOrAoa[0] && value.selectedMoaOrAoa[0].name.substring(0, value.selectedMoaOrAoa[0].name.lastIndexOf('.'))}</Typography>
                                                </Box>
                                                <Box style={{ position: 'absolute', ...webStyle.removedImg }}><img src={buttonDelete} width="24px" height="24px" data-testid="moaOrAoaRemoveThad" onClick={() => { this.civilIDPassportHandleRemoveThad(index, "moaOrAoa") }} /></Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography variant="h6" style={webStyle.docTitle}>Business Bank Account</Typography>
                                    <Box style={webStyle.documentBox as React.CSSProperties}>
                                        <Box>
                                            <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                <img className="fieldImg" alt='add image' src={document} data-testid="businessBankAccount" onClick={() => { this.civilIDPassportopenPictureUpload("businessBankAccount") }} />
                                            </Box>
                                            <Box style={{ textAlign: 'center' }}>
                                                <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allLableText}>Upload png, jpg, jpeg</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allDiscripcen}>The above info must be provided with a Stamped official Bank Certificate. It cannot be a personal bank account</Typography>
                                    <Box style={{ display: 'flex' }}>
                                        {this.state.businessBankAccount.map((value, index) => (
                                            <Box style={{ position: 'relative', ...webStyle.addimgPosisen }}>
                                                <Box style={{ ...webStyle.uplodeImgIcon, textAlign: 'center' }}>
                                                    <img src={value.combineImgBankAccount[0] || undefined} width="40px" height="40px" style={{ marginTop: '10px' }} />
                                                    <Typography style={{ ...webStyle.selectNameUplodeFile, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{value.selectedBankAccount[0] && value.selectedBankAccount[0].name.substring(0, value.selectedBankAccount[0].name.lastIndexOf('.'))}</Typography>
                                                </Box>
                                                <Box style={{ position: 'absolute', ...webStyle.removedImg }}><img src={buttonDelete} width="24px" height="24px" data-testid="businessBankAccountRemoveThad" onClick={() => { this.civilIDPassportHandleRemoveThad(index, "businessBankAccount") }} /></Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                            <Button style={{ ...webStyle.createButton, textTransform: 'capitalize', textAlign: 'center' }} data-testid="createProfileUplodeDocument" onClick={async () => { await this.createProfileUplodeDocument() }}>Create & Verify</Button>
                        </Box>
                    </Grid>
                </MainGrid>
                <StyledDrawerOrder
                    closeAfterTransition
                    aria-describedby="transition-modal-description"
                    onClose={this.handleCancel}
                    aria-labelledby="transition-modal-title"
                    open={this.state.visible}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    BackdropComponent={Backdrop}
                >
                    <Fade in={this.state.visible} style={webStyle.febBox}>
                        <div style={{ padding: "48px" }} data-testid="handleCancel" onClick={this.handleCancel}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><img src={view_Icon} /></Box>
                            <h2 style={{ ...webStyle.rejectNameNewOrder, textAlign: "center" }}>Great!</h2>
                            <h2 style={{ textAlign: "center", ...webStyle.confomMasegNewOrder }}>Your Request to register as a Stylist has been submitted  successfully. You will get a confirmation in your email inbox soon. </h2>
                        </div>
                    </Fade>
                </StyledDrawerOrder>
                <Footer navigation={this.props.navigation} />
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle = {
    confomMasegNewOrder: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato" },
    febBox: { borderRadius: '10px', width: "676px", backgroundColor: "white" },
    rejectNameNewOrder: { fontSize: '30px', color: "#375280", fontWeight: 400, fontFamily: "Lato", margin: '30px 0px' },
    removedImg: { top: '10px', right: '10px' },
    addimgPosisen: { color: 'white', width: 'max-content', marginRight: '13px' },
    selectNameUplodeFile: { color: '#375280', fontSize: '12px', fontFamily: 'Lato', fontWeight: 400 },
    uplodeImgIcon: { border: '1px solid #E2E8F0', borderRadius: '1px', padding: '4px', width: '80px', height: '80px', marginTop: '8px' },
    allDiscripcen: { color: "#94A3B8", fontFamily: 'Lato', fontSize: '14px', fontWeight: 400 },
    allLableText: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 700, marginTop: '16px' },
    docTitle: {
        color: "#375280",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: 'Lato , sans-serif',
        marginBottom: "30px",
        marginTop: "24px"
    },
    createButton: { backgroundColor: '#CCBEB1', color: '#FFFFFF', height: '54px', marginTop: '48px', fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', padding: '10px 16px' },
    documentBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '14.5rem',
        width: '100%',
        backgroundColor: '#F0F0F0',
        position: "relative",
    },
}

const MainGrid = styled(Grid)({
    justifyContent: "center",
    display: "flex",
    "& .sideImageBox": {
        height: "100%",
        width: "100%",
    },
    "& .sideImage": {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    "& .imageGrid": {
        alignItems: "flex-start",
        justifyContent: "center",
        display: "flex",
    },
    "& .welcomeSubText": {
        textOverflow: "ellipsis",
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        alignSelf: "flex-start",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        lineHeight: "26px",
        margin: "10px 0px 0px 0px",
        fontStyle: "normal",
        fontWeight: 200,
        fontFamily: "Avenir, sans-serif",
        fontSize: "16px",
    },
    "& .inputFeild": {
        gap: "4px",
        borderRadius: "0px",
        border: "none"
    },
    "& .formGrid": {
        alignItems: "flex-start",
        display: "flex",
        padding: "7%",
        justifyContent: "center",
    },
    "& .formBox": {
        width: "100%",
        marginTop: "30px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    },
    "& .welcomeText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontStyle: "normal",
        fontFamily: 'Lato , sans-serif',
        fontWeight: 700,
        lineHeight: "26px",
        fontSize: "24px",
        alignSelf: "flex-start",
    },
    "@media screen and (min-width: 0px) and (max-width: 1100px)": {
        "& .formBox": { width: "400px", flexWrap: "wrap" },
    },
});
const StyledDrawerOrder = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));
// Customizable Area End
