import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  withStyles,
  Snackbar,
  SnackbarContent
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import ContactusController, { Props } from "./ContactusController";
import { Vector } from "../../addressmanagement/src/assets";
import Footer from "../../../components/src/Footer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const StyledSnackbarContent = withStyles({
  root: {
    backgroundColor: '#CCBEB1',
    color: '#ffffff',
  }
})(SnackbarContent);
 // Customizable Area End
export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
 // Customizable Area Start
 // Customizable Area End
  render() {
    // Customizable Area Start
    const { snackbarOpen, snackbarMessage, contactUsDetail } = this.state;

    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Header navigation={this.props.navigation} />
        <Grid
          style={webStyle.contactUsContainer}
          container
        >
          <Grid item>
            <div style={webStyle.contactInnerContainer}>
              <Typography data-test-id="proceedToLandingPage" onClick={this.proceedToLandingPage} style={webStyle.contactInnerContainer13}>Home</Typography>
              <img src={Vector} />
              <Typography style={webStyle.contactInnerContainer23}>
                Contact Us
              </Typography>
            </div>
            <div>
              <Typography style={webStyle.contactInnerContainer33}>
                Contact Us
              </Typography>
              <div style={webStyle.AdminButtonInnerContainer}>Admin-Related Concerns</div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={webStyle.contactUsContainerMargin}>
            <div
              style={webStyle.contactusFormTitle}
            >
              Admin-Related Concerns
            </div>
            <div style={webStyle.contactUsContainerBg}>
              <ContactContainer
                style={webStyle.addContactContainer as React.CSSProperties}
              >
                <Grid
                  container
                  spacing={2}
                  style={webStyle.contactusFormGrid}
                >
                  <Grid item xs={12} sm={6}>
                    <p style={webStyle.formInputTitle}>
                      Full Name
                    </p>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={contactUsDetail.fullName}
                      data-test-id="fullname"
                      name="fullName"
                      onChange={this.handleChange}
                      className={this.state.errors.fullName ? "" : "textInputFeild"}
                      error={!!this.state.errors.fullName}
                      helperText={this.state.errors.fullName ? this.state.errors.fullName : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p style={webStyle.formInputTitle}>
                      Email Address
                    </p>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      data-test-id="email"
                      value={contactUsDetail.email}
                      onChange={this.handleChange}
                      className={this.state.errors.email ? "" : "textInputFeild"}
                      error={!!this.state.errors.email}
                      helperText={this.state.errors.email ? this.state.errors.email : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <p style={webStyle.formInputTitle}>
                      Subject
                    </p>
                    <TextField
                      fullWidth
                      variant="outlined"
                      data-test-id="subject"
                      name="subject"
                      value={contactUsDetail.subject}
                      onChange={this.handleChange}
                      className={this.state.errors.subject ? "" : "textInputFeild"}
                      error={!!this.state.errors.subject}
                      helperText={this.state.errors.subject ? this.state.errors.subject : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <p style={webStyle.formInputTitle}>
                      Description
                    </p>
                    <TextField multiline minRows={5}  data-test-id="description" fullWidth variant="outlined" className={this.state.errors.description ? "" : "textInputFeild outlineNone"} error={!!this.state.errors.description} helperText={this.state.errors.description ? this.state.errors.description : ""} name="description" value={contactUsDetail.description} onChange={this.handleChange} />
                  </Grid>
                </Grid>
                <div style={webStyle.submitBtnContainer}>
                  <div
                    style={{ ...webStyle.button12 as React.CSSProperties, ...webStyle.saveButton }}
                    data-test-id="postContactUsApi"
                    onClick={this.postContactUsApi}
                  >
                    Submit
                  </div>
                </div>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  data-test-id="handleSnackbarClose"
                  onClose={this.handleSnackbarClose}
                >
                  <StyledSnackbarContent
                    message={snackbarMessage}
                  />
                </Snackbar>
              </ContactContainer>
            </div>
          </Grid>
        </Grid>
        <Footer navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const ContactContainer = styled(Box)({
  padding: "16px",
  "& .textAreaField": {
    height: "90px !important",
    overFlow: "hidden",
    width: "100%",
    background: "#f0f0f0",
    border: "none",
    outline: "none",
    padding: "10px",
    resize: "none",
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px !important"
  },
  "& .textInputFeild": {
    "& .MuiTextField-root":{
        "&:hover":{
            border: "1px solid #F0F0F0 !important",
        },
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
    "& .MuiFormHelperText-contained":{
        margin:"0 !important"
    },
       "& .MuiOutlinedInput-notchedOutline":{
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root:hover" :{
        border: "none !important",
      },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
        
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    }
},
});
const UploadButton = styled(Button)({
  width: '100%',
  backgroundColor: "#f0f0f0 !important",
  boxShadow: "none !important",
  "& .MuiButton-root": {
    boxShadow: "none !important",
  },
  "& .MuiButton-contained:hover": {
    boxShadow: "none !important"
  },
  "& .MuiButtonBase-root": {
    width: "100% !important",
    background: "none !important"
  },
  "& .MuiIconButton-label": {
    display: "grid",
    padding: "12px"
  },
  "& .UploadIconImg": {
    margin: "0 auto"
  },
  "& .outlineNone": {
    "& .MuiOutlinedInput-multiline": {
      outline: "none"
    }
  },
  "& .UploadText": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "26px",
    color: "#375280",
    textTransform: "initial"
  }
});
const webStyle = {
  addContactContainer: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    marginBottom: "24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
    lineHeight: "26px"
  },
  contactUsContainer: {
    backgroundColor: "#F8F8F8", 
    marginBottom: "58px"
  },
  contactUsContainerMargin: {
    margin: "auto"
  },
  contactusFormTitle: {
    fontSize: "20px",
    fontWeight: 800,
    lineHeight: "33.5px",
    marginBottom: "29px",
    color: "#375280",
    display: "flex",
    alignItems: "center",
    gap: 8,
    marginTop: "93px",
    fontFamily: 'Lato , sans-serif',
  },
  contactUsContainerBg: {
    backgroundColor: "#F8F8F8", 
  },
  contactusFormGrid: {
    width: "100%", 
    margin: 0
  },
  formInputTitle: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#375280",
    marginBottom: "5px",
    fontFamily: "Lato , sans-serif",
  },
  button12: {
    padding: "10px 16px 10px 16px",
    width: "50%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px"
  },
  submitBtnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px"
  },
  saveButton: {
    color: "white",
    backgroundColor: "#CCBEB1",
    cursor: "pointer"
  },
  contactInnerContainer: {
    alignItems: "center",
    color: "#475569",
    display: "flex",
    fontWeight: 400,
    marginLeft: "40px",
    marginTop: "27px",
    lineHeight: "22px",
    gap: "10px"
  },
  contactInnerContainer13: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px"
  },
  contactInnerContainer23: {
    fontStyle: "normal",
    padding: "4px 6px 4px 6px",
    color: "#475569",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
  },
  contactInnerContainer33: {
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "33.5px",
    fontFamily: "Lato , sans-serif",
    color: "#375280",
    marginLeft: "40px",
    gap: "15px",
    marginTop: "31px",
    display: "flex",
    fontSize: "28px"
  },
  AdminButtonInnerContainer: {
    width: "100%",
    padding: "20px",
    boxShadow: "0px 2px 8px 0px #00000014",
    border: "1px solid #CCBEB1",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "33.5px",
    fontFamily: "Lato , sans-serif",
    color: "#375280",
    marginLeft: "40px",
    gap: "15px",
    marginTop: "31px",
    display: "flex",
    fontSize: "20px"
  },
};

// Customizable Area End
