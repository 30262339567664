
import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Box, Divider,
    TableContainer,
    Table, TableBody, TableRow, TableCell, TableHead,
    Button
} from "@material-ui/core";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import Pagination from '@material-ui/lab/Pagination';
import { Noimageavailablepng, dcubesvg, shoppingBag, timerPause, timerPause1 } from "./assets";
// Customizable Area End

import StylishDashboardController, {
    Props
} from "./StylishDashboardController";


export default class StylistDashboard extends StylishDashboardController {
    // Customizable Area Start

    orderLabelAndStyle = (value: string) => {
        let styles = {}
        let label = ''
        switch (value) {
            case 'shipped':
                styles = webStyle.shippedBox
                label = 'Out for Delivery'
                break;
            case 'delivered':
                styles = webStyle.deliveredBox
                label = 'Delivered'
                break;
            case 'new_order':
                styles = webStyle.new_orderBox
                label = 'New Order'
                break;

        }
        return (<Box style={styles}>{label}</Box>)
    }
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    

    render() {
        // Customizable Area Start
        return (
            // Required for all blocks
            <>
               <Grid container> 
                     <Grid  item  xl={2} md={2} lg= {2}  sm={2 } xs= {2} >
                         <StylishSidebar  navigation={this.props.navigation} /> 
                     </Grid> 
                       <Grid  item  xl= {10} md={10}  lg ={10} sm={10}  xs={10}  >
                        <StylishHeader  navigation={ this.props.navigation}  /> 
                       <Grid container style={webStyle.containerStyle}>
                                        <Grid item lg={3} md={6} sm={6} xs={6} style={webStyle.boxStyle}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={dcubesvg} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>Total</Box>
                                                    <Box style={webStyle.numberBox}>{"2,000"}</Box>
                                                </Box>
                                            </Box>
                                        </Grid >
                                        <Grid item lg={3} md={6} sm={6} xs={6} style={webStyle.boxStyle}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={timerPause1} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>Pending</Box>
                                                    <Box style={webStyle.numberBox}>{"10"}</Box>
                                                </Box>
                                            </Box>
                                        </Grid >
                                        <Grid item lg={3} md={6} sm={6} xs={6} style={webStyle.boxStyle}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={shoppingBag} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>In Progress</Box>
                                                    <Box style={webStyle.numberBox}>{"70"}</Box>
                                                </Box>
                                            </Box>
                                        </Grid >
                                        <Grid item  lg={3} md={6} sm={6} xs={6} style={webStyle.boxStyle}>
                                            <Box style={{ display: 'flex' }}>
                                                <img src={timerPause} style={webStyle.imgBakColor} />
                                                <Box>
                                                    <Box style={webStyle.lableText}>Accepted</Box>
                                                    <Box style={webStyle.numberBox}>{"120"}</Box>
                                                </Box>
                                            </Box>
                                        </Grid >
                        </Grid>
                        <Box sx={webStyle.secondaryContainer}>
                            <Typography style={webStyle.newOrdersText}>New Styling Requests</Typography>
                            <Box style={webStyle.secondBox} data-test-id="viewAllClickSecond" onClick={()=> this.headerNavigation("SellersAllOrderDetailsWebPage")}>
                            <Typography style={webStyle.viewAllText}>View All</Typography>
                            <Divider style={webStyle.dividerLine}/>
                            </Box>
                        </Box>

                        <CustomStyledTable style={{padding: "25px"}}>
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Request ID</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Request Time</Typography></TableCell>
                                <TableCell style={{...webStyle.tableHader, width: "30%"}}><Typography variant="h6">Name & Gender</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Budget</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Status</Typography></TableCell>
                                <TableCell style={{...webStyle.tableHader, width: "241px" }}><Typography variant="h6">Action</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                           
                             <TableRow  className="hover" >
                                        <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#4564-4512</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>3:30 PM</TableCell>
                                        <TableCell>
                                         <Box style={{ display: 'flex'}} >
                                                    <Box><img src={Noimageavailablepng} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px", marginRight: "10px" }} /></Box>
                                         <Box style={{ display: 'flex', flexDirection: "column"}} >
                                                    <Typography style={{ ...webStyle.tableHaderName }}>Emilia Clark </Typography>
                                                    <Typography style={{ ...webStyle.tableHaderrow }}>Female</Typography>
                                         </Box>
                                        </Box>
                                        </TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>$50 - $150</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{this.orderLabelAndStyle("new_order")}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                            <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize", width: "100%" }} 
                                             data-testid={`handleOkAcceptOrder-${0}`}>View Request</Button>
                                        </TableCell>
                                    </TableRow>
                        </TableBody>
                    </Table>
                        </CustomStyledTable>

                        <Box sx={webStyle.secondaryContainer}>
                            <Typography style={webStyle.newOrdersText}>New Orders</Typography>
                            <Box style={webStyle.secondBox} data-test-id="viewAllClicked"
                             onClick={()=> this.headerNavigation("SellersAllOrderDetailsWebPage")}>
                            <Typography style={webStyle.viewAllText}>View All</Typography>
                            <Divider style={webStyle.dividerLine}/>
                            </Box>
                        </Box>

                <CustomStyledTable style={{padding: "25px"}}>
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order ID</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order Time</Typography></TableCell>
                                <TableCell style= {{...webStyle.tableHader, width: "30%"}}><Typography variant="h6">Products
                                </Typography>
                                </TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Total Price</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Status</Typography></TableCell>
                                <TableCell style={{...webStyle.tableHader, width: "241px"}}><Typography variant="h6">Action</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                    <TableRow  className="hover" >
                                        <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#4564-4512</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>3:30 PM</TableCell>
                                        <TableCell>
                                                <Box style={{ display: 'flex' }}  data-testid={`navigateTo=${0}`}>
                                                    <Box><img src={ Noimageavailablepng } style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px", marginRight: "10px" }} /></Box>
                                                    <Box style={{ ...webStyle.tableHaderrow }}>{"Shawl Collar Blazer with Belt(Woman)"} x {2}</Box>
                                                </Box>
                                        </TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>$120</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{this.orderLabelAndStyle("new_order")}</TableCell>
                                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                            <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize", width: "35%" }} data-testid={`handleOpen1Reject=${0}`}
                                            //  onClick={() => this.handleRejectOpen(orderData.attributes.id)}
                                             >Reject</Button>
                                            <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize", width: "60%" }} 
                                            // onClick={() => this.handleAcceptOrder(orderData.attributes.id)}
                                             data-testid={`handleOkAcceptOrder-${7}`}>Accept Order (0:29)</Button>
                                        </TableCell>
                                    </TableRow>
                        </TableBody>
                    </Table>
                </CustomStyledTable>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px 0px" , padding: "0px 25px 0px 25px"}}>
                                    <Box style={{ color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: 'Lato' }}>
                                        {1} - {10} of {50} results
                                    </Box>
                                    <CustomStyledPagination
                                        data-test-id="paginationCustom"
                                        page={2}
                                        count={10}
                                        shape="rounded"
                                        color="primary"
                                    />
                                </Box>
                     </Grid> 
                </Grid> 

            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

const CustomStyledPagination = styled(Pagination)({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
});

const CustomStyledTable = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px",
    },
    
    "& .MuiTable-root":{
        width: "96%"
    },

    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 20px"
    }
});

const webStyle = {
    containerStyle: {
        padding: '25px',
    },
    rejectButton: { color: "#375280",marginBottom: "4px", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", marginRight: "8px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },

    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    tableHaderName: {
        color: "#375280", fontSize: '14px', fontFamily: "Lato", fontWeight: 700
    },
    secondaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px 10px 24px',
  },
  tableHader: {
    color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px", width: "12%", maxWidth: "122px"
},
acceptOrder: { backgroundColor: "#CCBEB1", marginBottom: "4px",borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
  secondBox:{
    cursor: "pointer",
    marginRight: "10px"
},
dividerLine:{backgroundColor: "#375280", width: "65px", height: "2px"},
    imgBakColor: { backgroundColor: "#CCBEB1", padding: '20px', borderRadius: '2px', marginRight: '16px', width: "36px", height: "36px" },
    viewAllText: {
        fontSize: "14px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280",
        textTransform: "uppercase"
    } as React.CSSProperties,
    newOrdersText:{
        fontSize: "24px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280"
    },
    new_orderBox:{ backgroundColor: '#D1FAE5', color: '#059669', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' } as React.CSSProperties,
    in_processBox:{backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px'},
    processedBox:{backgroundColor: '#fff3c9', color: '#d97601', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px'},
    deliveredBox: { backgroundColor: '#E2E8F0', color: '#375280', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    shippedBox: { backgroundColor: '#F0E5FF', color: '#6200EA', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    lableText: { fontSize: "16px", fontFamily: "Lato", fontWeight: 400, color: "#334155", marginBottom: '30px' },
    numberBox: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280" },
    boxStyle: {
        width: 'calc(33.33% - 10px)',
        height: '92px',
        backgroundColor: '#F8F8F8',
        display: 'flex', alignItems: 'center', 
    },
}
// Customizable Area End
