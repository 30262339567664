import React from "react";
import {
  ArcElement as ChartJSArcElement,
  Color as ChartJSColor,
  Chart as ChartJS,
  ChartData as ChartJSData,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ChartJSArcElement);

// Define Round and CustomElement types
interface Round {
  x: number;
  y: number;
  radius: number;
  arcColor: ChartJSColor;
}

interface IPieChartProps {
  labels: ChartJSColor[];
  dataSetsVal: number[];
  backgroundColor: string[];
}

const GrowthPieChart: React.FC<IPieChartProps> = ({
  labels,
  dataSetsVal,
  backgroundColor,
}) => {
  // Define data and plugins
  const data: ChartJSData<"doughnut"> = {
    labels: labels,
    datasets: [
      {
        data: dataSetsVal,
        backgroundColor: backgroundColor,
        borderWidth: 0,
      },
    ],
  };
  return (

        <Doughnut
          width={300}
          height={300}
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            cutout: "80%",
            rotation: -25,
            plugins: {
              tooltip: {
                enabled: true,
                callbacks: {
                  title: (context) => {
                    const index = context[0].dataIndex;
                    return index === 0 ? '         ' : '';
                  },
                  label: function(tooltipItem) {
                    const dataIndex = tooltipItem.dataIndex;
                    const dataset = data.datasets[0];
                    const value = dataset.data[dataIndex];
                    const label = data.labels ? data.labels[dataIndex] : "";
                    return `${label}: ${value}`;
                  },
                },
              },
              legend: { display: false },
            },
            elements: {
              arc: { borderWidth: 0 },
            },
          }}
        />
  );
};

export default GrowthPieChart;
