import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  styled,
  Drawer,
  FormHelperText,
} from "@material-ui/core";
import Select from "react-select";
import InventoryPairTheProductsController, {
  OptionType,
  Props,
} from "./InventoryPairTheProductsController";
import {
  CustomStyledPagination,
  CustomStyledTableUpdateInventory,
  PaginationContainer,
  TableHeaderCell,
} from "../UpdateInventory/UpdateInventory.web";
import { crossIcon, editFillIcon, selectEndIcon } from "../../../assets";
import Loader from "../../../../../../components/src/LoaderContainer";
import { NoRecordTypoContainer } from "../AssignStore/InventoryAssignStore.web";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
import {
  CustomInput,
  Dropdown,
  DropdownIndicator,
  selectStyles,
} from "../../../../../../components/src/Seller/ReactSelect";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class InventoryPairTheProducts extends InventoryPairTheProductsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  selectIconRender = () => (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        paddingRight: 10,
        position: "absolute",
        right: 0,
        zIndex: 1,
        pointerEvents: "none",
      }}
      data-test-id="select-drop-down-img"
    />
  );

  renderPairProductPagination = () => (
    <>
      {this.state.pairProductInventoryMeta !== undefined ? (
        <PaginationContainer>
          <Box className="pagination-data-container">
            {`${
              (this.state.pairProductInventoryMeta?.current_page - 1) * 10 + 1
            } - 
              ${Math.min(
                this.state.pairProductInventoryMeta?.current_page * 10,
                this.state.pairProductInventoryMeta?.total_record
              )}
            of ${this.state.pairProductInventoryMeta?.total_record} results`}
          </Box>
          <CustomStyledPagination
            data-test-id="as-pagination-test-id"
            page={this.state.page}
            count={this.state.pairProductInventoryMeta?.total_pages}
            shape="rounded"
            color="primary"
            onChange={this.handlePairProductPageChange}
          />
        </PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  renderPairProductStoreBody = () => (
    <>
      {this.state.tableBodyData.length === 0 ? (
        <TableRow>
          <TableCell className="pp-no-data" colSpan={3}>
            <NoRecordTypoContainer>No Records Found</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {this.state.tableBodyData?.map((assignStoreInventoryData) => (
            <TableRow key={assignStoreInventoryData.id} className="hover">
              <TableCell data-test-id="product-name">
                <Box className="product-name-container">
                  <img
                    src={assignStoreInventoryData.front_image}
                    className="img-container"
                    alt={assignStoreInventoryData.product_name}
                  />
                  <Typography className="product-title">
                    {assignStoreInventoryData.product_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography className="product-sku">
                  {assignStoreInventoryData.sku}
                </Typography>
              </TableCell>
              <TableCell>
                {assignStoreInventoryData.pair_it_with.length === 0 ? (
                  <PairItWithButton
                    data-test-id={`btn-pair-product-${assignStoreInventoryData.id}`}
                    onClick={() =>
                      this.openDrawer("add", assignStoreInventoryData.id, {
                        name: assignStoreInventoryData.product_name,
                        image: assignStoreInventoryData.front_image,
                      })
                    }
                  >
                    Pair It with
                  </PairItWithButton>
                ) : (
                  <EditBoxContainer>
                    <Typography className="sku-pair-title">
                      {assignStoreInventoryData.pair_it_with.join(", ")}
                    </Typography>
                    <img
                      src={editFillIcon}
                      alt="edt-ic"
                      onClick={() =>
                        this.openDrawer("edit", assignStoreInventoryData.id, {
                          name: assignStoreInventoryData.product_name,
                          image: assignStoreInventoryData.front_image,
                        })
                      }
                      data-test-id={`btn-pair-product-${assignStoreInventoryData.id}`}
                    />
                  </EditBoxContainer>
                )}
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <CustomStyledTableUpdateInventory>
          <Table stickyHeader size="small" className="custom-table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "#f0f0f0" }}
                className="as-table-head-row"
              >
                {this.state.tableHeadName.map(
                  (header: string, index: number) => (
                    <TableHeaderCell key={index} className="as-table-head-cell">
                      <Typography className="table-head-title">{header}</Typography>
                    </TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Loader
                      id="step-4-loader"
                      dataTestId="step-4-loader-test"
                    />
                  </TableCell>
                </TableRow>
              ) : (
                this.renderPairProductStoreBody()
              )}
            </TableBody>
          </Table>
        </CustomStyledTableUpdateInventory>
        {this.state.pairProductInventoryMeta.total_record > 10 &&
          this.renderPairProductPagination()}
        <DrawerContainer
          anchor="right"
          open={this.state.isDrawerOpen}
          data-test-id="drawer-test-id"
        >
          <Box className="drawer-header">
            <Typography className="drawer-header-title">
              Pair it With
            </Typography>
            <img
              className="cross-img"
              src={crossIcon}
              onClick={() => this.toggleDrawer()}
              alt="cross-ic"
              data-test-id="cross-test-id"
            />
          </Box>
          <Box className="product-img-container">
            <img
              className="product-img"
              src={this.state.product.image}
              alt=""
            />
          </Box>
          <Typography className="product-name">
            {this.state.product.name}
          </Typography>
          <Typography className="description">
            Enter the SKU if the product you want to suggest for pair to the
            buyer. (This allows the seller to pair clothing together by
            referencing the SKU number of the product they want to pair with.)
          </Typography>
          <Typography className="sku-description">
            Add SKU’s (Up to 5)
          </Typography>
          <Dropdown
            isOpen={this.state.pairSelect.sku1.isOpen}
            onClose={this.toggle1Open}
            target={
              <SKUSelect onClick={this.toggle1Open}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku1.value
                    ? `${this.state.pairSelect.sku1.value.label}`
                    : ""}
                </Typography>
                {this.selectIconRender()}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={this.onSelect1Change}
              data-test-id="select-sku-1"
              options={this.getFilteredOptions("sku1")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku1.value}
            />
          </Dropdown>
          <Dropdown
            isOpen={this.state.pairSelect.sku2.isOpen}
            onClose={this.toggle2Open}
            target={
              <SKUSelect onClick={this.toggle2Open}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku2.value
                    ? `${this.state.pairSelect.sku2.value.label}`
                    : ""}
                </Typography>
                {this.selectIconRender()}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              data-test-id="select-sku-2"
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={this.onSelect2Change}
              options={this.getFilteredOptions("sku2")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku2.value}
            />
          </Dropdown>
          <Dropdown
            isOpen={this.state.pairSelect.sku3.isOpen}
            onClose={this.toggle3Open}
            target={
              <SKUSelect onClick={this.toggle3Open}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku3.value
                    ? `${this.state.pairSelect.sku3.value.label}`
                    : ""}
                </Typography>
                {this.selectIconRender()}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              data-test-id="select-sku-3"
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={this.onSelect3Change}
              options={this.getFilteredOptions("sku3")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku3.value}
            />
          </Dropdown>
          <Dropdown
            isOpen={this.state.pairSelect.sku4.isOpen}
            onClose={this.toggle4Open}
            target={
              <SKUSelect onClick={this.toggle4Open}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku4.value
                    ? `${this.state.pairSelect.sku4.value.label}`
                    : ""}
                </Typography>
                {this.selectIconRender()}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              data-test-id="select-sku-4"
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={this.onSelect4Change}
              options={this.getFilteredOptions("sku4")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku4.value}
            />
          </Dropdown>
          <Dropdown
            isOpen={this.state.pairSelect.sku5.isOpen}
            onClose={this.toggle5Open}
            target={
              <SKUSelect onClick={this.toggle5Open}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku5.value
                    ? `${this.state.pairSelect.sku5.value.label}`
                    : ""}
                </Typography>
                {this.selectIconRender()}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              data-test-id="select-sku-5"
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={this.onSelect5Change}
              options={this.getFilteredOptions("sku5")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku5.value}
            />
          </Dropdown>
          {this.state.error && (
            <FormHelperTextContainer data-test-id="error-test-id">
              {this.state.errorMessage}
            </FormHelperTextContainer>
          )}
          <SaveBtnContainer
            data-test-id="save-btn-test-id"
            onClick={() => this.handlePairProductSave()}
          >
            Save
          </SaveBtnContainer>
        </DrawerContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertPPSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const SKUSelect = styled(Box)({
  marginTop: 16,
  width: "100%",
  paddingLeft: 18,
  height: 56,
  display: "flex",
  alignItems: "center",
  borderRadius: 2,
  backgroundColor: "#F8F8F8",
  "& .selected-value": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 14,
    color: "#375280",
  },
});

const FormHelperTextContainer = styled(FormHelperText)({
  paddingTop: 15,
  color: "f44336",
});

const SaveBtnContainer = styled(Box)({
  marginBottom: 70,
  backgroundColor: "#CCBEB1",
  fontFamily: "Lato, sans-serif",
  fontWeight: 800,
  fontSize: 20,
  display: "flex",
  color: "#FFFFFF",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 2,
  padding: "10px 16px 10px 16px",
  height: 56,
});

const PairItWithButton = styled(Box)({
  width: 114,
  height: 34,
  borderRadius: 1,
  backgroundColor: "#CCBEB1",
  color: "#fff",
  fontFamily: "Lato, sans-serif",
  fontWeight: 700,
  fontSize: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const EditBoxContainer = styled(Box)({
  height: 24,
  display: "flex",
  alignItems: "center",
  gap: 10,
  "& .sku-pair-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: "#375280",
  },
});

const DrawerContainer = styled(Drawer)({
  "& .MuiPaper-root.MuiDrawer-paper": {
    padding: 30,
    width: 440,
    "& .drawer-header": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .drawer-header-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 24,
        color: "#375280",
      },
      "& .cross-img": {
        transform: "rotate(90deg)",
      },
    },
    "& .product-img-container": {
      padding: "30px 0px",
      display: "flex",
      justifyContent: "center",
      "& .product-img": {
        width: 245,
        height: 285,
      },
    },
    "& .product-name": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 24,
      color: "#375280",
      width: "100%",
      textAlign: "center",
    },
    "& .description": {
      width: "100%",
      fontFamily: "Lato, sans-serif",
      fontWeight: 400,
      fontSize: 16,
      color: "#375280",
      padding: "16px 0px 20px 0px",
    },
    "& .sku-description": {
      paddingBottom: 16,
      width: "100%",
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 16,
      color: "#375280",
    },
  },
});
// Customizable Area End
