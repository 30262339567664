import React from "react";

// Customizable Area Start
import {
  Box,
  Tabs,
  Tab,
  capitalize,
  styled,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { setting, tshirt } from "./assets";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Grid container>
      <Grid item xl={2} md={2} lg={2} sm={2} xs={2}>
        {
          this.state.userType === "seller" ? (
            <SellerSidebar navigation={this.props.navigation} />
          ) : (
            <StylishSidebar navigation={this.props.navigation} />
          )
        }
      </Grid>
      <Grid item xl={10} md={10} lg={10} sm={10} xs={10}>
      {
          this.state.userType === "seller" ? (
            <SellerHeader navigation={this.props.navigation} />
          ) : (
            <StylishHeader navigation={this.props.navigation} />
          )
        }
          <div style={webStyle.mainDiv}>
          <div style={webStyle.headerDiv}>
            <h1 style={webStyle.titleNotification}>{configJSON.titleMessage}</h1>
            <img src={setting} alt="setting" data-test-id="settingBtn" style={webStyle.settingicon} onClick={()=>this.handleNavigation()}/>
          </div>
          <Box sx={{ width: '100%' }}>
      <Box style={{borderBottom:'1px solid', color:"rgb(226, 232, 240)",width:'60%'}}>
          {
            this.state.userType === "stylist" ? (
              <Tabs value={this.state.value} data-test-id="StylistvalueTab" TabIndicatorProps={{style: {backgroundColor: "#375280"}}}onChange={(event,newValue)=>{
                this.setState({value:newValue})
              }} aria-label="basic tabs example">
              <CustomTab style={webStyle.tabsStyle}  label={capitalize("Buyer")} {...a11yProps(0)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Seller")} {...a11yProps(1)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Stylist")} {...a11yProps(2)} />
        </Tabs>
            ): (
              <Tabs value={this.state.value} data-test-id="valueTab" TabIndicatorProps={{style: {backgroundColor: "#375280"}}}onChange={(event,newValue)=>{
                this.setState({value:newValue})
              }} aria-label="basic tabs example">
              <CustomTab style={webStyle.tabsStyle}  label={capitalize("All")} {...a11yProps(0)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Orders")} {...a11yProps(1)} />
              <CustomTab style={webStyle.tabsStyle} label={capitalize("Inventory")} {...a11yProps(2)} />
              </Tabs>
            )
          }
      </Box>
     
      <div
      role="tabpanel"
      hidden={this.state.value !== 0}
      id={`simple-tabpanel-${0}`}
      aria-labelledby={`simple-tab-${0}`}
      data-test-id="custome1"
    >{this.state.value === 0&&<Box sx={{ p: 3 }}>
        <div  style={webStyle.mainDivContent}>
        {getContent([1,2,3,4,5,6])}
        </div></Box>}
        </div>
      
      <div
      role="tabpanel"
      hidden={this.state.value !== 1}
      id={`simple-tabpanel-${1}`}
      aria-labelledby={`simple-tab-${1}`}
      data-test-id="custome1"
    >{this.state.value === 1&&<Box sx={{ p: 3 }}>
        <div  style={webStyle.mainDivContent}>
        {getContent([1,2,3,4,5,6])}
        </div></Box>}
        </div>
        <div
      role="tabpanel"
      hidden={this.state.value !== 2}
      id={`simple-tabpanel-${2}`}
      aria-labelledby={`simple-tab-${2}`}
      data-test-id="custome1"
    >{this.state.value === 2&&<Box sx={{ p: 3 }}>
        <div  style={webStyle.mainDivContent}>
        {getContent([1,2,3,4,5,6])}
        </div></Box>}
        </div>
    </Box>
          </div>
      </Grid>
  </Grid></ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const getContent = (data:number[]) =>{
  return (
    data?.map((item)=>{
      return(
        <><div style={webStyle.subDivCont} key={item}>
        <img src={tshirt} alt="" />
        <div style={webStyle.contentDiv}>
          <h1 style={webStyle.contentDivH1}>Your order of White Oversized T-shirt-Order #1456879 is accepted by your delivery partner</h1>
          <h3 style={webStyle.contentDivH2}>Today | 12:00 PM</h3>
        </div>
        </div>
        <hr style={webStyle.hrDiv}/></>

      )
    })
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const theme = createTheme({
  palette: {
      primary: {
          main: "#0000ff",
          contrastText: "#fff",
      },
  },
});

const webStyle = {
  headerDiv:{
   display:'flex'
  },
  titleNotification:{
    color: '#375280',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  settingicon:{
    marginLeft:'auto'
  },
  mainDiv:{
  margin:'30px',
  marginTop:'20px'
  },
  tabsStyle:{
    color: '#375280',
    fontSize: '14px',
    lineHeight:'18px',
    fontWeight:600,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  mainDivContent:{
    padding:'20px',
    border:'2px solid #E2E8F0',
    marginLeft:'-24px'
  },
  subDivCont:{
   display:'flex',
   marginBottom:'22px'
  },
  contentDiv:{
   marginLeft:'20px'
  },
  contentDivH1:{
   fontSize:'18px',
   lineHeight:'24px',
   fontWeight:400,
   color:'#375280',
    margin:'0px',
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  contentDivH2:{
    fontSize:'16px',
    lineHeight:'22px',
    fontWeight:400,
    color:'#94A3B8',
    margin:'0px',
    marginTop:'10px',
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  hrDiv:{
   marginBottom:'22px',
   color:'#fffcfc'
  }
};
const CustomTab = styled(Tab)({
 textTransform:'capitalize'
});
// Customizable Area End
