import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../config");
interface DeliveryAddressAttributesInProcessDetails {
  name: string;
  contact_number: string;
  country_code: string;
  area: string;
  street: string;
  phone_number: string;
  longitude: number;
  zip_code: string;
  city: string;
  block: string;
  address_name: string;
  latitude: number;
  house_or_building_number: string;
  is_default: boolean;
}

interface DeliveryAddressInProcessDetails {
  type: string;
  id: string;
  attributes: DeliveryAddressAttributesInProcessDetails;
}

interface PaymentDetailInProcessDetails {
  status: string;
  id: number;
  charge_id: string;
  updated_at: string;
  created_at: string;
  currency: string;
  amount: number;
  refund_id: string | null;
  customer_id: string;
  account_id: number;
  reason: string;
  refund_reason: string | null;
  last_four_card_digit: string;
  deleted: boolean;
  order_management_order_id: number;
  refund_amount: string | null;
  merchant_id: string | null;
  seller_order_id: string | null;
  order_id: string;
  payment_type: string;
}

interface OrderManagementOrderAttributesInProcessDetails {
  account: string;
  delivery_addresses: DeliveryAddressInProcessDetails;
  total: string;
  placed_at: string;
  in_transit_at: string | null;
  order_number: string;
  status: string;
  refunded_at: string | null;
  confirmed_at: string;
  sub_total: string;
  deliver_by: string | null;
  cancelled_at: string | null;
  order_return_time: string | null;
  delivered_at: string | null;
  order_deliver_time: string | null;
  order_status_id: number;
  returned_at: string | null;
  updated_at: string;
  order_deliver_date: string | null;
  payment_detail: PaymentDetailInProcessDetails;
  created_at: string;
  order_return_date: string | null;
}

interface OrderManagementOrderInProcessDetails {
  attributes: OrderManagementOrderAttributesInProcessDetails;
  id: string;
  type: string;
}

interface OrderItemAttributesInProcessDetails {
  status: string;
  in_transit_at: string | null;
  placed_at: string;
  cancelled_at: string | null;
  delivered_at: string | null;
  confirmed_at: string;
  rejected_at: string | null;
  return_at: string | null;
  return_cancel_at: string | null;
  process_at: string;
  return_pick_at: string | null;
  quantity: number;
  shipped_at: string | null;
  driver_latitude: number | null;
  catalogue_name: string;
  unit_price: string;
  brand_name: string;
  store_name: string;
  reason_of_rejection: string | null;
  catalogue_variant_color: string;
  driver_name: string | null;
  catalogue_variant_sku: string;
  total_price: string;
  otp: string | null;
  catalogue_variant_back_image: string;
  catalogue_variant_front_image: string;
  driver_phone_number: string | null;
  catalogue_variant_size: string;
  driver_longitude: number | null;
  catalogue_variant_side_image: string;
}

interface OrderItemInProcessDetails {
  type: string;
  id: string;
  attributes: OrderItemAttributesInProcessDetails;
}
interface SellerOrderAttributesInProcessDetails {
  id: number;
  order_management_order: OrderManagementOrderInProcessDetails;
  order_items: OrderItemInProcessDetails[];
  status: string;
  accept_order_upload_time: string | null;
}

interface SellerOrderInProcessDetails {
  id: string;
  attributes: SellerOrderAttributesInProcessDetails;
  type: string;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rejectOpen: boolean;
  tableAllOrder: SellerOrderInProcessDetails[];
  visible: boolean,
  numberData: string;
  isAlert: boolean,
  orderId: number,
  isLoading: boolean,
  confirmId: number,
  rejectId: number,
  isAlertOrder: boolean,
  tableLable: string[]
  already: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersInProcessOrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMy_OrdersCallId: string = "";
  getTimeInProcessDetailsCallId: string = "";
  getRejectOrderDetailCallId: string = "";
  getInProcessDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tableLable: ["Product", "Brand", "Size", "Color", "Product Code", "Price"],
      tableAllOrder: [],
      orderId: 0,
      visible: false,
      numberData: "",
      rejectOpen: false,
      already: "",
      rejectId: 0,
      isAlert: false,
      isLoading: true,
      isAlertOrder: false,
      confirmId: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getInProcessDetailsCallId !== null &&
      this.getInProcessDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonInProcessDetails = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ tableAllOrder: responseJsonInProcessDetails?.orders.data, isLoading: false, isAlert: true })
    }

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTimeInProcessDetailsCallId !== null &&
      this.getTimeInProcessDetailsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategoryInProcessDetails = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategoryInProcessDetails.data) {
        this.setState({ isLoading: true, isAlertOrder: true, already: "Order Accepted" })
        setTimeout(() => {
          this.contioneShoppingInProcessDetails()
        }, 3000)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start



  dateFormetTransactionInProcessDetails = (created_at: string | null) => {
    if (!created_at) return "";
    const [day, month, year] = created_at.split('-').map(Number);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedMonth = months[month - 1];
    return `${day} ${formattedMonth} ${year}`;
  };

  contioneShoppingInProcessDetails = () => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "SellersAllOrderDetailsWebPage");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { tabIndex: 2 });
    toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(toNavigate);
  }


  oncloseAlert = () => {
    this.setState({ isAlertOrder: false });
  };
  handleOpenInProcessDetails = (rejectid: number) => {
    this.setState({ visible: true, rejectId: rejectid })
  };
  handleCancelInProcessDetails = () => {
    this.setState({ visible: false });
  };
  async componentDidMount() {
    const idDataInProcessDetails = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({ orderId: idDataInProcessDetails })
    this.allOrderDataInProcessDetails()
  }
  timeDataInProcessDetails = () => {
    this.timeOrderDataInProcessDetails()
    this.setState({ visible: false, isLoading: true })
    this.allOrderDataInProcessDetails()
  }

  allOrderDataInProcessDetails = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListInProcessDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInProcessDetailsCallId = requestMessageListInProcessDetails.messageId;
    requestMessageListInProcessDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageListInProcessDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInProcessAllOrder
    );
    requestMessageListInProcessDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListInProcessDetails.id, requestMessageListInProcessDetails);
  }

  timeOrderDataInProcessDetails = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageListTimeInProcessDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeInProcessDetailsCallId = requestMessageListTimeInProcessDetails.messageId;
    requestMessageListTimeInProcessDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageListTimeInProcessDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.rejectId}&type=ready_to_ship`
    );
    requestMessageListTimeInProcessDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageListTimeInProcessDetails.id, requestMessageListTimeInProcessDetails);
  }

  getAddressInProcessDetails = (delivery_addresses: {
    id: string;
    type: string;
    attributes: {
      country_code: string;
      name: string;
      contact_number: string;
      street: string;
      phone_number: string;
      area: string;
      block: string;
      zip_code: string;
      house_or_building_number: string;
      address_name: string;
      city: string;
      latitude: number;
      longitude: number;
      is_default: boolean;
    };
  }) => {
    if (delivery_addresses) {
      const { house_or_building_number, block, area, street, city, zip_code } = delivery_addresses.attributes
      return `${house_or_building_number}, ${street}, ${block} ${area}, ${city} - ${zip_code}`
    } else {
      return ""
    }
  }
  // Customizable Area End
}
