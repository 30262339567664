import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";

const navigation = require("react-navigation");

interface APIPayloadType {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: object;
    token?: string;
    type?: string;
}

interface ErrorResponseArray {
    errors: [
        {
            otp: string;
        }
    ]
}

interface EmailVarificationResponse 
    {
        messages: [
            {
                otp: string;
            }
        ]
    }


interface ResentdOTP {
    data: {
        id: string;
        type: string;
        attributes: {
            activated: boolean;
            country_code: string;
            email: string;
            first_name: string | null;
            full_phone_number: string;
            last_name: string | null;
            full_name: string;
            phone_number: string;
            type: string | null;
        }
    };

    meta: {
        token: string;
    };
}

interface OTPBody {
    data:{
        token: string,
        otp_code: string
    }
}

interface ResendSMSOTP{
    data: {
        type: string;
        attributes: {
            full_phone_number:string;
        }
    }
}

interface ResendEmailOTP{
    data: {
        type: string;
        attributes: {
            email: string;
        }
    }
}

interface ForgotDetails {
    forgotType: string,
    isEmail: string;
    isToken: string;
}
type AlertType = 'success' | 'error' | 'warning' | 'info';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    otpInput: string;
    isEmail:string;
    isFullPhoneNumber:string;
    
    emailResendOTP:boolean;
    isMobile:boolean;
    dataParam:ForgotDetails;
    errorMassgage?:{
        errorMsg?:string;
        otpError?:boolean;
    };
    email:boolean;
    emailToken:string;
    isAlert: boolean;
    alertMsg: string;
    alertType: AlertType;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class ResetOTPPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    resetOTPAPICall:string="";
    reSentOTPAPICallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            otpInput: "",
            isEmail:"",
            isFullPhoneNumber:"",
            emailResendOTP:false,
            isMobile:false,
            errorMassgage:{
                errorMsg:"",
                otpError:true
            },
            dataParam:{ 
                forgotType: "", 
                isEmail: "", 
                isToken:""
            },
            email:true,
            emailToken:"",
            isAlert: false,
            alertMsg: '',
            alertType: 'success' as AlertType,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount(){
        // Customizable Area Start
        await storage.get("user-email")
        await storage.get("user-mobiler-number")
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const navigationParams = message.getData(
              getName(MessageEnum.NavigationPayLoadMessage)
              );
              this.setState({dataParam:navigationParams})
          }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiSucessCall(apiRequestCallId, responseJson);
           } else if (responseJson && responseJson.errors) {
             this.apiFailureCall(apiRequestCallId,responseJson)
           }
          }
        // Customizable Area End
    }
    // Customizable Area Start
    handleOTPChangeUser = (userOTP: string) => {
        this.setState({ 
            otpInput: userOTP,
            errorMassgage:{
                errorMsg:"",
                otpError:false
            }
         });
    }

    apiCallResetPassService = async (userData: APIPayloadType) => {
        const { contentType, method, endPoint, body } = userData;
        const header = {
            "Content-Type": contentType,
        };
        const requestResetOTPMessageSerice = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestResetOTPMessageSerice.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestResetOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestResetOTPMessageSerice.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestResetOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestResetOTPMessageSerice.id, requestResetOTPMessageSerice);
        return requestResetOTPMessageSerice.messageId;
    };

    apiSucessCall = async (apiRequestCallId: string, responseJson: EmailVarificationResponse & ResentdOTP) => {
        if (apiRequestCallId === this.resetOTPAPICall) {
            this.setState({ isAlert: true, alertMsg: responseJson.messages[0].otp, alertType: 'success', });
            this.setState({ email: false,otpInput:"" },
            ()=>this.openResetPasswordScreen());
        }
        if (apiRequestCallId === this.reSentOTPAPICallId) {
            alert("Resent OTP")
            this.setState({ email: false,otpInput:"" })
        }
    };

    apiFailureCall = async (apiRequestCallId: string, responseJson: ErrorResponseArray) => {
        if (apiRequestCallId === this.resetOTPAPICall) {
            this.setState({ isAlert: true, alertMsg: responseJson.errors[0].otp, alertType: 'error' });
        }
    };
    
    oncloseMessageAlert = () => {
        this.setState({ isAlert: false });
    };

    handleSubmit = async (submitEvent: React.FormEvent) => {
        submitEvent.preventDefault();
        const { otpInput, email } = this.state;
        const errorMsg = otpInput === '' || otpInput.length !== 4 ? configJSON.enterOTP : '';
        this.setState({ errorMassgage: { errorMsg, otpError: errorMsg !== '' } });
        if (errorMsg === '') {
            const userbody = {
                data:{
                    token: this.state.dataParam.isToken,
                    otp_code: otpInput
                }
            };
            this.setState({ isMobile: !email });
            this.handleEmailOTPSubmitForm(userbody);
        }
    }

    handleEmailOTPSubmitForm = async (body:OTPBody) => {
        this.resetOTPAPICall = await this.apiCallResetPassService({
            contentType: configJSON.postForgotPasswordContentType,
            method: configJSON.postApimethod,
            endPoint: configJSON.postResetOTPEndPoint,
            body: body
        });
    };  

    handlereSendSMSOTP = async (body:ResendSMSOTP | ResendEmailOTP) => {
        this.reSentOTPAPICallId = await this.apiCallResetPassService({
            contentType: configJSON.postResendOTPContentType,
            method: configJSON.postApimethod,
            endPoint: configJSON.postResendOTPEndPoint,
            body: body
        })
    }

    handleEmailData=()=>{
        let mail = this.state.dataParam.isEmail;
        let atIndex = mail.indexOf('@'); 
        let maskedEmail = mail.substring(atIndex - 2);
        let starMail= `********${maskedEmail}`
        return starMail
    }

    handlePhoneData=()=>{
        let phoneNumber = this.state.dataParam.isEmail;  
        let length = phoneNumber.length;  
        let maskedPart1 = phoneNumber.substring(length - 2); 
        let starPhone = `*******${maskedPart1}`
        return starPhone 
    }

    handeResentOTPButton = async()=>{
        if(this.state.dataParam.isEmail ==="email"){
            const body = {
                data: {
                    type: "email_account",
                    attributes: {
                        email: `${this.state.isEmail}${this.state.dataParam.isEmail}`
                    }
                }
            }
            this.handlereSendSMSOTP(body)
        }else{
            const body = {
                data: {
                    type: "sms_account",
                    attributes: {
                        full_phone_number:`${this.state.isFullPhoneNumber}${this.state.dataParam.isEmail}`
                    }
                }
            }
            this.handlereSendSMSOTP(body)
        }
    };

    openResetPasswordScreen = () => {
        const toOTP = new Message(getName(MessageEnum.NavigationMessage));
        toOTP.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        toOTP.addData(getName(MessageEnum.NavigationTargetMessage), "ResetPassword");
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { isToken: this.state.dataParam.isToken });
        toOTP.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        runEngine.sendMessage(toOTP.id, toOTP);
    };
    // Customizable Area End
}
