import React from "react";
// Customizable Area Start
import {
    Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Typography, Button, Modal, Backdrop, Fade, withStyles, FormControl,CircularProgress
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { styled } from "@material-ui/core/styles";
import ReturnRequestController, {
    Props,
    SellerOrderSeller,
    OrderItemSeller
} from "./ReturnRequestController.web";
import Select from '@material-ui/core/Select';
import { Noimageavailablepng, button_close } from "./assets";
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';



// Customizable Area End

export default class ReturnRequest extends ReturnRequestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handlePageChangeReadyFor = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ readyForPage: newPage });
    };
    renderContent = (startIndex: number, endIndex: number, ROWS_PER_PAGE: number) => {
        const { activeTab } = this.state;
        if (this.state.SellerOrdersProcessedView === undefined && this.state.isAlertReadyForCollection) {
            return (
                <Box style={{ ...webStyle.tabeLableReadyForCollection as React.CSSProperties }}>
                    There are no orders
                </Box>
            );
        }
        if (this.state.isLoadingReady) {
            return (
              <Box style={{ ...webStyle.tabsBoxReady }}>
              <CircularProgress style={webStyle.CircularProgressColor} />
            </Box>
            );
        }
        return (
            <>
                <TableHead>
                    <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Return Request' ? '#375280' : 'inherit',
                            color: activeTab === 'Return Request' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography variant="h6" data-test-id='setActiveTab' onClick={() => this.setActiveTab('Return Request')}>Return Request</Typography></TableCell>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Return in Process' ? '#375280' : 'inherit',
                            color: activeTab === 'Return in Process' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography variant="h6" data-test-id='setActiveTab1' onClick={() => this.setActiveTab('Return in Process')}>Return in Process</Typography></TableCell>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Refund under Process' ? '#375280' : 'inherit',
                            color: activeTab === 'Refund under Process' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography variant="h6" data-test-id='setActiveTab2' onClick={() => this.setActiveTab('Refund under Process')}>Refund under Process</Typography></TableCell>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Refunded' ? '#375280' : 'inherit',
                            color: activeTab === 'Refunded' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography variant="h6" data-test-id='setActiveTab3' onClick={() => this.setActiveTab('Refunded')}>Refunded</Typography></TableCell>
                    </TableRow>


                </TableHead>
                <CustomStyledTable className="container">

                    <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order ID</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Order Time</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Products</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Shop</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Total Price</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography variant="h6">Status</Typography></TableCell>
                                {activeTab !== 'Refunded' && (
                                    <TableCell style={webStyle.tableHader}><Typography variant="h6">Action</Typography></TableCell>
                                )}
                            </TableRow>
                        </TableHead>

                        <Table>

                        </Table>
                        {this.renderTableBody()}

                    </Table>
                </CustomStyledTable>
                <Box style={webStyle.paginationMainBox}>
                    <Box style={webStyle.paginationSecBox}>
                        {startIndex} - {endIndex} of {this.state.metaData?.total_record} results
                    </Box>
                    <CustomStyledPagination
                        page={this.state.readyForPage}
                        count={this.state.metaData?.total_pages}
                        onChange={this.handlePageChangeReadyFor}
                        shape="rounded"
                        color="primary"
                    />
                </Box>
            </>
        );
    }

    setActiveTab = (tab: any) => {
        this.setState({ activeTab: tab });
    };
    renderTableBody = () => {
        const { activeTab } = this.state;

        switch (activeTab) {
            case 'Return Request':
                return (
                    <TableBody>
                        {this.state.SellerOrdersProcessedView?.map((sellerData: SellerOrderSeller, index: number) => (
                            <TableRow className="hover">
                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#{sellerData.attributes.order_management_order.attributes.order_number}</TableCell>
                                <TableCell style={{ verticalAlign: 'top', ...webStyle.tableHaderrow }}>{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</TableCell>


                                <TableCell data-test-id="navigateToReturnDetail1" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
                                >
                                    {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                                        <Box style={{ display: "flex" }}>
                                            <Box><img src={items.attributes.catalogue_variant.attributes.front_image} style={webStyle.productIMg} /></Box>
                                            <Box style={{ ...webStyle.tableHaderrowOut1 }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                                        </Box>
                                    ))}
                                </TableCell>

                                <TableCell style={{ ...webStyle.tableHaderrowOut1 }}>{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrowOut1 }}>${sellerData.attributes.order_items[0].attributes.total_price}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center' }}>Return Request</Box></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                    <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} data-test-id='handleOpen1' onClick={() => this.handleOpen1(sellerData.attributes.id)} >Reject</Button>
                                    <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} data-test-id='handleOk' onClick={() => this.handleOk(sellerData.attributes.id)}>Accept</Button>
                                </TableCell>
                                <StyledDrawer
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={this.state.visible}
                                    onClose={this.handleCancel}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={this.state.visible} style={webStyle.febBox}>
                                        <div style={{ padding: "10px 30px 30px 30px" }}>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleCancel} /></Box>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>Accept Order</h2>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>Are you sure you want to accept the order?</h2>

                                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleCancel" onClick={this.handleCancel}>Close</StyledButtonModel>
                                                <StyledButton1Model style={{ fontSize: "14px" }} data-test-id="timeData1" onClick={() => this.AcceptRequest(sellerData.attributes.id)}>Confirm</StyledButton1Model>
                                            </div>
                                        </div>
                                    </Fade>
                                </StyledDrawer>

                                <StyledDrawer
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={this.state.rejectOpen}
                                    onClose={this.handleClose1}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={this.state.rejectOpen} style={webStyle.febBox}>
                                        <div style={{ padding: "10px 30px 30px 30px" }}>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClose1} /></Box>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>Reject Order</h2>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>Are you sure you want to reject the order?</h2>
                                            <Box style={{ marginBottom: '30px' }}>
                                                <Box style={webStyle.modelText}>Reason of Rejection</Box>
                                                <CustomSelect variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                                    <MuiSelectSelect
                                                        style={webStyle.reminderName}
                                                        value={this.state.numberData}
                                                        onChange={this.handleSingleChangeNumber}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                                        data-testid='sub-categories-selection'
                                                        MenuProps={{
                                                            getContentAnchorEl: null,
                                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                                        }}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>Out of Stock</Box>
                                                        </MenuItem>
                                                        {this.state.short.map((select: string, index: number) => (
                                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>
                                                        ))}
                                                    </MuiSelectSelect>
                                                </CustomSelect >
                                            </Box>
                                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>Close</StyledButtonModel>
                                                <StyledButton1Model style={{ fontSize: "14px" }} data-test-id="rejectData" onClick={this.rejectData}>Reject</StyledButton1Model>
                                            </div>
                                        </div>
                                    </Fade>
                                </StyledDrawer>

                            </TableRow>
                        ))}

                    </TableBody>
                );
            case 'Return in Process':
                return (
                    <TableBody>
                        {this.state.SellerOrdersProcessedViewProcess?.map((sellerData: SellerOrderSeller, index: number) => (
                            <TableRow className="hover">
                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#{sellerData.attributes.order_management_order.attributes.order_number}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</TableCell>


                                <TableCell data-test-id="navigateToReturnDetail1" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
                                >
                                    {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                                        <Box style={webStyle.productDflex}>
                                            <Box><img src={items.attributes.catalogue_variant.attributes.front_image} style={webStyle.productIMg} /></Box>
                                            <Box style={{ ...webStyle.tableHaderrowOut2 }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                                        </Box>
                                    ))}
                                </TableCell>

                                <TableCell style={{ ...webStyle.tableHaderrowOut2 }}>{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrowOut2 }}>${sellerData.attributes.order_items[0].attributes.total_price}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center', color: "#B55B52", backgroundColor: "#B55B521A" }}>Return In Process</Box></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                    <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} >Order Status</Button>
                                </TableCell>

                            </TableRow>
                        ))}


                    </TableBody>
                );
            case 'Refund under Process':
                return (
                    <TableBody>
                        {this.state.SellerUnderProcess?.map((sellerData: SellerOrderSeller, index: number) => (
                            <TableRow className="hover">
                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#{sellerData.attributes.order_management_order.attributes.order_number}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</TableCell>


                                <TableCell data-test-id="navigateToReturnDetail" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
                                >
                                    {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                                        <Box style={webStyle.productDflex}>
                                            <Box><img src={items.attributes.catalogue_variant.attributes.front_image ? items.attributes.catalogue_variant.attributes.front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                                            <Box style={{ ...webStyle.tableHaderrowOut }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                                        </Box>
                                    ))}
                                </TableCell>

                                <TableCell style={{ ...webStyle.tableHaderrowOut }}>{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrowOut }}>${sellerData.attributes.order_items[0].attributes.total_price}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center', color: "#F545C4", backgroundColor: "#F545C41A" }}>Refund In Process</Box></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                    <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} >Reject</Button>
                                    <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} >Initiate Refund</Button>
                                </TableCell>

                            </TableRow>
                        ))}

                    </TableBody>
                );
            case 'Refunded':
                return (

                    <TableBody>
                        {this.state.SellerRefunded?.map((sellerData: SellerOrderSeller, index: number) => (
                            <TableRow className="hover">
                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>#{sellerData.attributes.order_management_order.attributes.order_number}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</TableCell>


                                <TableCell data-test-id="navigateToReturnDetail" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
                                >
                                    {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                                        <Box style={webStyle.productDflex}>
                                            <Box><img src={items.attributes.catalogue_variant.attributes.front_image ? items.attributes.catalogue_variant.attributes.front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                                            <Box style={{ ...webStyle.tableHaderrowOut3 }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                                        </Box>
                                    ))}
                                </TableCell>

                                <TableCell style={{ ...webStyle.tableHaderrowOut3 }}>{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrowOut3 }}>${sellerData.attributes.order_items[0].attributes.total_price}</TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center', backgroundColor: "#E1EFE1", color: "#039000" }}>Refunded</Box></TableCell>


                            </TableRow>
                        ))}

                    </TableBody>

                );
            default:
                return null;
        }
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 4;
        const endIndex = Math.min(this.state.metaData?.current_page * ROWS_PER_PAGE, this.state.metaData?.total_record);
        const startIndex = (this.state.metaData?.current_page - 1) * ROWS_PER_PAGE + 1;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box data-testid="allOrder" style={{ marginTop: '20px' }}>
                    {this.renderContent(startIndex, endIndex, ROWS_PER_PAGE)}
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    new_orderBox: { backgroundColor: '#0A84FF1A', color: '#0A84FF', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    return_requestBox: { backgroundColor: '#e8f1ff', color: '#1a8fea', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    in_processBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    processedBox: { backgroundColor: '#fff3c9', color: '#d97601', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    deliveredBox: { backgroundColor: '#E2E8F0', color: '#375280', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    shippedBox: { backgroundColor: '#F0E5FF', color: '#6200EA', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    timeBox: { display: 'flex', alignItems: 'center', color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400 },
    acceptOrder: { backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    rejectButton: { color: "#375280", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", marginRight: "8px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
    tabeLable: { color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '90%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    confomMaseg: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    rejectName: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    productDflex: {
        display: "flex"
    },
    tabeLableReadyForCollection: {
        color: "rgb(55, 82, 128)",
        fontSize: '20px',
        position: "absolute",
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: "50%",
        left: "50%",
        transform: "translate(20%, 20%)",
        fontWeight: 800,
        fontFamily: 'Lato',
    },
    productIMg: {
        width: '34px',
        borderRadius: '2px',
        height: '34px',
        marginRight: "10px",
        marginBottom: "12px",
    },
    tableHaderrowOut: {
        color: "#375280",
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: "Lato",
        verticalAlign: 'top',
    },
    tableHaderrowOut1: {
        verticalAlign: 'top',
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 400,
        color: "#375280",
    },
    tableHaderrowOut2: {
        fontWeight: 400,
        color: "#375280",
        fontSize: '16px',
        verticalAlign: 'top',
        fontFamily: "Lato",
    },
    paginationMainBox: {
        justifyContent: 'space-between',
        display: 'flex',
        margin: "23px 0px",
        alignItems: 'center',
    },
    CircularProgressColor: {
        color: "#375280"
    },
    tabsBoxReady: {
        paddingBottom: '20px',
        width: '100%',
        alignItems: 'center',
        height: '75%',
        justifyContent: 'center',
        display: 'flex',
      },
    paginationSecBox: {
        fontSize: "14px",
        color: "#475569",
        fontFamily: 'Lato',
        fontWeight: 500,
    },

    tableHaderrowOut3: {
        fontSize: '16px',
        verticalAlign: 'top',
        fontFamily: "Lato",
        fontWeight: 400,
        color: "#375280",
    },
    tableHaderCase: {
        fontSize: '14px', fontFamily: "Lato", fontWeight: 500, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px", cursor: 'pointer'
    },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
}
const CustomStyledPagination = styled(Pagination)({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
})
const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "252px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "252px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomStyledTable = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px"
    },

    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    }
})
const StyledDrawer = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
// Customizable Area End