// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  styled,
  Snackbar,
  Tooltip,
  Fade,
  Backdrop,
} from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  setStorageData,
  removeStorageData,
  getStorageData,
} from "../../../framework/src/Utilities";
import Alert from "@material-ui/lab/Alert";
import {
  logout,
  sidebarimg,
} from "../../../blocks/customisableuserprofiles2/src/assets";
import {
  supportHelp,
  analytics,
  catalogue,
  sale,
  earnings,
  boardDashboard,
  order,
  userActivity,
} from "./assets";
import { faceLogo } from "../../../blocks/email-account-registration/src/assets";
import { store } from "../../../blocks/customform/src/assets";
import {
  StyledButton,
  StyledButton1,
  StyledButtonSigein,
  StyledDrawer,
} from "../Header";

interface S {
  openMenu: boolean;
  selectedItem: string | null;
  isAlert: boolean;
  anchorEl: HTMLElement | null;
  open1: boolean;
  auth: string;
  basePathe:string;
}
export interface Props {
  navigation?: any;
  id?: string;
  className?: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class SellerSidebar extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      openMenu: false,
      selectedItem: "",
      isAlert: false,
      anchorEl: null,
      open1: false,
      auth: "",
      basePathe:""
    };
    // Customizable Area End
  }
  handleItemClick = (item: string) => {
    localStorage.setItem("selectedItem", item);
    this.setState({
      selectedItem: item,
    });
  };

  headerNavigation = async (pageLink: string, name: string) => {
    await setStorageData("selectedItem", name);
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    this.setState({
      selectedItem: name,
    });
  };

  notNavigation = async (name: string) => {
    await setStorageData("selectedItem", name);
    this.setState({ selectedItem: name, isAlert: true });
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  headerRedirect = (block: string) => {
    this.props.navigation.navigate(block);
  };

  handleOpen1 = () => {
    this.setState({ open1: true, anchorEl: null });
  };
  hendelUrl = async () => {
    const baseUrl = new URL(document.URL).href.split('/').pop();
    await setStorageData("selectedItem", baseUrl)
    const selectedItemUrl = await getStorageData("selectedItem")
    this.setState({ basePathe: selectedItemUrl })
  }
  async componentDidMount() {
    this.hendelUrl()
  }
  handleClose1 = () => {
    this.setState({ open1: false });
  };
  
  logout = async () => {
    ["orderNumber", "auth-token", "sms-token","userRole"].map(async (value) => {
      await removeStorageData(value);
    });
    this.setState({ open1: false });
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  render() {
    // Customizable Area Start
    const { selectedItem } = this.state;
    const isItemSelected = selectedItem !== "";
    return (
      <>
        <StyledWrapper>
          <Box className="sidebar-container">
            <Box>
              <Box
                className="logo-container"
                onClick={() => {
                  this.headerRedirect("LandingPage");
                }}
              >
                <img src={faceLogo} alt="fashLogo" className="logo" />
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('SellerDashboard', "SellerDashboard")}
                style={{
                  backgroundColor:
                    this.state.basePathe === "SellerDashboard"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "SellerDashboard"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Dashboard">
                    <img src={boardDashboard} />
                  </Tooltip>
                  <span className="link-text">Dashboard</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("Catalogue", "Catalogue")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "Catalogue"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "Catalogue"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Catalogue">
                    <img src={catalogue} />
                  </Tooltip>
                  <span className="link-text">Catalogue</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("SellersAllOrderDetailsWebPage", "SellersAllOrderDetailsPage")}
                style={{
                  backgroundColor:
                  this.state?.basePathe === "SellersAllOrderDetailsPage"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state?.basePathe === "SellersAllOrderDetailsPage"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Orders">
                    <img src={order} />
                  </Tooltip>
                  <span className="link-text">Orders</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("SellerStore", "SellerStore")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "SellerStore"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "SellerStore"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Stores">
                    <img src={store} />
                  </Tooltip>
                  <span className="link-text">Stores</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("StylistAnalytics", "StylistAnalytics")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "StylistAnalytics"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe ===
                      "StylistAnalytics"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Analytics and Insights">
                    <img src={analytics} />
                  </Tooltip>
                  <span className="link-text">Analytics and Insights</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("EarningsAnalyticsWebPage", "EarningsAnalyticsWebPage")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "EarningsAnalyticsWebPage"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "EarningsAnalyticsWebPage"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Earnings">
                    <img src={earnings} />
                  </Tooltip>
                  <span className="link-text">Earnings</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("MyActivity","userActivity")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "userActivity"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "userActivity"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Your Activity">
                    <img src={userActivity} />
                  </Tooltip>
                  <span className="link-text">Your Activity</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("SellerHelp","SellerHelp")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "SellerHelp"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "SellerHelp"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Get Help">
                    <img src={supportHelp} />
                  </Tooltip>
                  <span className="link-text">Get Help</span>
                </Typography>
              </Box>
            </Box>
            <Box
              className="link"
              onClick={() => this.handleOpen1()}
              style={{
                backgroundColor:
                this.state.basePathe === "logout"
                    ? "#F1F5F9"
                    : "transparent",
                textDecoration: "none",
                position: "relative",
              }}
            >
              <div
                className="wishlist-icon"
                style={{
                  backgroundImage:
                  this.state.basePathe === "logout"
                      ? `url(${sidebarimg})`
                      : "none",
                }}
              />
              <Typography className="link1">
                <Tooltip title="Logout">
                  <img src={logout} />
                </Tooltip>
                <span className="link-text">Logout</span>
              </Typography>
            </Box>
          </Box>
          <StyledDrawer
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open1}
            onClose={this.handleClose1}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade
              in={this.state.open1}
              style={{
                borderRadius: "10px",
                maxWidth: "320px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}
              >
                <Box
                  id="transition-modal-title"
                  style={{
                    fontFamily: "Lato",
                    width: "300px",
                    fontSize: "18px",
                    color: "#375280",
                    fontWeight: 900,
                    marginLeft: "10px",
                  }}
                >
                  {this.state.auth === null ? "" : "Log Out"}
                </Box>
                <h2
                  id="transition-modal-title"
                  style={{
                    fontFamily: "Lato",
                    width: "300px",
                    fontSize: "18px",
                    color: "#375280",
                    fontWeight: 600,
                    marginLeft: "10px",
                  }}
                >
                  {this.state.auth === null
                    ? "You need to sign in to access Profile"
                    : "Are you sure you want to log out your account?"}
                </h2>
                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.auth === null ? (
                    <StyledButton1
                      style={{ fontSize: "18px" }}
                      onClick={this.handleClose1}
                    >
                      Cancel
                    </StyledButton1>
                  ) : (
                    <StyledButton
                      style={{ fontSize: "18px" }}
                      onClick={() => this.logout()}
                    >
                      Logout
                    </StyledButton>
                  )}
                  {this.state.auth === null ? (
                    <StyledButtonSigein
                      style={{ fontSize: "18px" }}
                      onClick={() =>
                        this.headerRedirect("EmailAccountLoginBlock")
                      }
                    >
                      Sign In
                    </StyledButtonSigein>
                  ) : (
                    <StyledButton1
                      style={{ fontSize: "18px" }}
                      onClick={this.handleClose1}
                    >
                      Cancel
                    </StyledButton1>
                  )}
                </div>
              </div>
            </Fade>
          </StyledDrawer>
          <Snackbar
            open={this.state.isAlert}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={this.oncloseAlert}
            data-test-id="alertTestId"
          >
            <Alert
              variant="filled"
              severity="info"
              style={{ width: "300px", marginTop: "10px" }}
            >
              Coming Soon !!!
            </Alert>
          </Snackbar>
        </StyledWrapper>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const StyledWrapper = styled(Box)({
  "& .sidebar-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRight: "1px solid #E2E8F0",
    height: "100%",
    padding: "40px 25px",
  },
  "& .logo-container": {
    paddingBottom: 30,
  },
  "& .link": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#94A3B8",
    fontFamily: "Lato , sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "21.6px !important",
    padding: "8px 20px 8px 7px",
    gap: "10px",
    "& .wishlist-icon": {
      width: "2px",
      height: "40px",
      marginLeft: "-7px",
      position: "absolute",
    },
    "& .link-text": {
      padding: 0,
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
  },
  "& .link1": {
    color: "#DC2626",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Lato , sans-serif",
    fontSize: "18px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "21.6px !important",
    padding: "8px 20px 8px 7px",
    gap: "10px",
  },
});
// Customizable Area End
