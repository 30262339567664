import React from "react";
// Customizable Area Start
import MyActivityController, { Props, configJSON, step3Schema } from "./MyActivityController";
import {
    Grid, styled, Box, Typography, RadioGroup,
    FormControlLabel, Button
} from "@material-ui/core";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { filterIcon } from "../../customform/src/assets";
import CloseIcon from '@material-ui/icons/Close';
import RadioButton from "../../../components/src/RadioButton";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Formik } from "formik";
import { FormError, FormErrorTouched } from "../../customform/src/CreateSellerStoreController";
import { calendar } from "./assets";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End


export default class MyActivity extends MyActivityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    filterOptions = [
        { value: "0", label: "This Week", selectedValue: "week" },
        { value: "1", label: "This Month", selectedValue: "month" },
        { value: "2", label: "This Year", selectedValue: "year" },
        {value: "3", label: (
                <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 700 }}>Select Date Range</Typography>
            ), selectedValue: "date"
        },
    ];
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2} >
                    {
                        this.state.userRole !== "stylist" ? (
                            <SellerSidebar navigation={this.props.navigation} />
                        ): (
                            <StylishSidebar navigation={this.props.navigation} />
                        )
                    }
                    </Grid>
                    <Grid item xl={10} md={10} lg={10} sm={10} xs={10}  >
                    {
                        this.state.userRole !== "stylist" ? (
                            <SellerHeader navigation={this.props.navigation} />
                        ): (
                            <StylishHeader navigation={this.props.navigation} />
                        )
                    }
                        <MyActivityPage>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box className="sales-report-title">
                                    <Typography className="title">
                                        My Activity
                                    </Typography>
                                    <Typography className="span-title">
                                        [Past 1 Month]
                                    </Typography>
                                </Box>
                                <Box className="btn-container">
                                    <img src={filterIcon} alt="filter-icon" data-test-id="filterIcon" onClick={this.handleFilterClick} />
                                    {this.state.isFilter && (
                                        <Box className="filterContainer">
                                            <Box className="headingFilter">
                                                <Typography style={{ color: "#375280",  fontWeight: 800, fontSize: "20px" }}>Filters</Typography>
                                                <Box data-test-id="filterClose" onClick={()=> {
                                                    this.setState({
                                                        isFilter: false
                                                    })
                                                }}>
                                                <CloseIcon style={{color: "#375280"}}/>
                                                </Box>
                                            </Box>
                                            <hr style={{ border: "1px solid #F1F5F9" }} />
                                            <Box style={{ display: "flex", flexDirection: "column", gap: 10, padding: "20px 20px 6px" }}>
                                                <RadioGroup
                                                    aria-labelledby="language-selection"
                                                    name="language-selection"
                                                    style={{ justifyContent: "space-between", marginBottom: '15px', color: "#375280", fontFamily: "Lato", fontSize: "16px", fontWeight: 400 }}
                                                    value={this.state.filter}
                                                    data-test-id="group-language"
                                                onChange={(event, value) => this.handleChange(value)}
                                                >
                                                    {this.filterOptions.map(lang => (
                                                        <FormControlLabel
                                                            key={lang.value}
                                                            value={lang.selectedValue}
                                                            control={<RadioButton variant="outlined" />}
                                                            label={lang.label}
                                                            data-test-id={`radio-${lang.value}`}
                                                            className={`radioButton ${lang.label === this.state.filter
                                                                    ? "activeRadioButton"
                                                                    : ""
                                                                }`}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </Box>
                                            <Box style={{padding: "0px 20px 6px"}}>
                                                <Formik
                                                    initialValues={this.state.step3}
                                                    validationSchema={step3Schema}
                                                    enableReinitialize
                                                    onSubmit={this.handleSubmit3}
                                                    data-test-id="stepper-3-form-data"
                                                >
                                                    {({
                                                        errors,
                                                        touched,
                                                        handleSubmit,
                                                        getFieldProps,
                                                        setFieldValue,
                                                        values,
                                                    }) => {

                                                        const handleDateChange = (value: Date | null, field: string) => {
                                                            setFieldValue(field, value ? value.toISOString() : "");
                                                        };
                                                        return (
                                                            <form data-test-id ="formSubmit" onSubmit={handleSubmit}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <Box style={{display: "flex", gap: 20}}>
                                                                            <Box style={{width: "184px", height: "56px"}}>
                                                                                <TimePickerContainer>
                                                                                    <TimePicker
                                                                                        {...getFieldProps("monFrom")}
                                                                                        margin="normal"
                                                                                        variant="dialog"
                                                                                        placeholder={configJSON.placeholderFrom}
                                                                                        value={this.valueStep3(values.monFrom)}
                                                                                        error={
                                                                                            this.getErrorAndHelperText(
                                                                                                "monFrom",
                                                                                                errors as FormError,
                                                                                                touched as FormErrorTouched
                                                                                            ).isError
                                                                                        }
                                                                                        onChange={(time) => { handleDateChange(time, "monFrom") }}
                                                                                        helperText={
                                                                                            this.getErrorAndHelperText(
                                                                                                "monFrom",
                                                                                                errors as FormError,
                                                                                                touched as FormErrorTouched
                                                                                            ).helperText
                                                                                        }
                                                                                        data-test-id="mon-from-date-test"
                                                                                    />
                                                                                    <Box style={{position: "absolute", top: "31%", right: "8px"}}>
                                                                                        <img src={calendar} alt="calendar"/>
                                                                                    </Box>

                                                                                </TimePickerContainer>
                                                                            </Box>

                                                                            <Box style={{width: "184px", height: "56px"}}>
                                                                                <TimePickerContainer>
                                                                                    <TimePicker
                                                                                        {...getFieldProps("monTo")}
                                                                                        margin="normal"
                                                                                        placeholder={configJSON.placeholderTo}
                                                                                        variant="dialog"
                                                                                        value={this.valueStep3(values.monTo)}
                                                                                        onChange={(time) => { handleDateChange(time, "monTo") }}
                                                                                        error={
                                                                                            this.getErrorAndHelperText(
                                                                                                "monTo",
                                                                                                errors as FormError,
                                                                                                touched as FormErrorTouched
                                                                                            ).isError
                                                                                        }
                                                                                        helperText={
                                                                                            this.getErrorAndHelperText(
                                                                                                "monTo",
                                                                                                errors as FormError,
                                                                                                touched as FormErrorTouched
                                                                                            ).helperText
                                                                                        }
                                                                                        data-test-id="mon-to-date-test"
                                                                                    />
                                                                                     <Box style={{position: "absolute", top: "31%", right: "8px"}}>
                                                                                        <img src={calendar} alt="calendar"/>
                                                                                    </Box>
                                                                                </TimePickerContainer>
                                                                            </Box>
                                                                    </Box>

                                                                    <Box className="btn-container2">
                                                                    <Button
                                                                      variant="outlined"
                                                                      className={"back-btn"}
                                                                      disableElevation
                                                                      data-test-id="back-icon-test-id"
                                                                    >
                                                                      {configJSON.clear}
                                                                    </Button>
                                                                    <Button
                                                                      variant="contained"
                                                                      className={"next-btn"}
                                                                      data-test-id="applyBtn"
                                                                      disableElevation
                                                                      type="submit"
                                                                    >
                                                                      {configJSON.apply}
                                                                    </Button>
                                                                    </Box>
                                                                </MuiPickersUtilsProvider>
                                                            </form>
                                                        );
                                                    }}
                                                </Formik>
                                            </Box>

                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box style={{ width: "100%", maxHeight: "818px", border: "1px solid #E2E8F0", marginTop: "20px", padding: "12px 18px", overflowY: "auto", overflowX: "hidden" }}>
                                {this.state.myActivityData.map((activity, index) => (
                                    <>
                                        <Box style={{ minHeight: "56px", display: "flex", justifyContent: "space-between" }} key={index}>
                                            <Box>
                                                <Typography style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 700, color: "#375280" }}>{activity.mainHeading} <span style={{ fontWeight: 400 }}>{activity.spanHeading}</span></Typography>
                                                <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 400, color: "#94A3B8" }}>{activity.name}</Typography>
                                            </Box>

                                            <Box>
                                                <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 400, color: "#94A3B8" }}>{activity.date}</Typography>
                                            </Box>

                                        </Box>
                                        <hr style={{ color: "#E2E8F0", width: "100%" }} />
                                    </>
                                ))}

                            </Box>
                        </MyActivityPage>
                    </Grid>
                </Grid>

            </ThemeProvider>
        );
    }
    // Customizable Area End



}

// Customizable Area Start

export const TimePickerContainer = styled(Box)({
    width: "100%",
    backgroundColor: "#F0F0F0",
    position: "relative",
    "& .MuiFormControl-root.MuiTextField-root": {
        width: "100%",
        height: "85%",
        margin: 0,
        borderRadius: 8,
        "& .MuiInputBase-root.Mui-error": {
            border: "1px solid #f44336",
            "& .MuiInputBase-input": {
                color: "#f44336",
            },
        },
        "& .MuiInputBase-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            height: 56,
            "& .MuiInputBase-input": {
                color: "#94A3B8",
                height: "100%",
                padding: "15px 16px",
                fontWeight: 400,
                fontSize: 18,
                fontFamily: "Lato, sans-serif",
            },
        },
        "& .MuiInput-underline:before": {
            border: "none !important",
        },
        "& .MuiInput-underline:after": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important",
        },
    },
});
const MyActivityPage = styled(Box)({
    minHeight: "calc(100vh - 85px)",
    padding: "30px 60px",
    "& .sales-report-title": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 12,
        "& .title": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 700,
            fontSize: 24,
            color: "#375280",
        },
        "& .span-title": {
            fontFamily: "Lato, sans-serif",
            fontWeight: 400,
            fontSize: 16,
            marginTop: 3,
            color: "#375280",
        },
    },
    "& .btn-container": {
        display: "flex",
        gap: 24,
        cursor: "pointer",
        position: "relative"
    },
    "& .filterContainer": { maxWidth: "370px",  position: "absolute", top: "35px", right: "3px", boxShadow: "5px 7px 1px 2px rgba(0, 0, 0, 0.06)", backgroundColor: "#FFFFFF" },
      "& .headingFilter" : { display: "flex", justifyContent: "space-between", padding: '20px 20px 6px ' },
    "@media(max-width:600px)": {
        height: "max-content",
        "& .sales-report-title": {
            "& .title": {
                fontWeight: 600,
                fontSize: "18px !important",
            },
        },
        "& .filterContainer": {
            width: "310px"
        },
        "& .btn-container": {
            flexDirection: "column",
            gap: 4,
            height: "max-content",
        },
    },
    "& .btn-container2": {
        display: "flex",
        gap: 16,
        height: "54px",
        width: "100%",
        marginTop: "36px",
        "& .back-btn": {
          textTransform: "capitalize",
          color: "#375280",
          borderColor: "#CCBEB1",
          width: "185px",
          height: "100%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        "& .next-btn": {
          textTransform: "capitalize",
          color: "white",
          gap: "8px",
          backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
          width: "185px",
          height: "100%",
          borderRadius: "2px",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
      },
      "@media(max-width: 400px)":{
        "& .filterContainer": {
            width: "260px"
        },
      }
      
});
const webStyles = {}
// Customizable Area End
