import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { location } from "../../addressmanagement/src/assets"
import CheckoutSteps from "./CheckoutSteps.web";
import { createTheme, styled, withStyles } from "@material-ui/core/styles";
import ReactCountryFlag from "react-country-flag";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Switch from "@material-ui/core/Switch";
import {Noimageavailable} from "../../productdescription3/src/assets"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Shippingaddressvalidation2Controller, {
  Props,
  CountrySuccess
} from "./Shippingaddressvalidation2Controller";

export default class AddShippingaddressvalidation2 extends Shippingaddressvalidation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleToggle = this.handleToggle.bind(this);
    this.handleAddInputChange = this.handleAddInputChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  renderOrderSummary() {
    const { orderSummary } = this.state;
    if (!orderSummary) return null;

    const { order_items, sub_total, total } = orderSummary.data.attributes;

    return (
      <div>
        {order_items && order_items.map((item) => (
          <div>
          <div key={item.id} style={{ ...webStyle.shippingOrder, display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
            <img src={item.attributes.catalogue_variant_front_image === "" ? Noimageavailable : item.attributes.catalogue_variant_front_image} 
            style={{ width: '63px', borderRadius: '4px', height: '63px', }} />
            <div style={{ ...webStyle.orderDetail, flex: '1', marginLeft: '10px' }}>
              <p>{item.attributes.catalogue_name} <span style={{ color: '#94A3B8' }}>x {item.attributes.quantity}</span></p>
              <p style={{ marginTop: '-18px' }}>Color : <span style={{ color: '#94A3B8' }}>{item.attributes.catalogue_variant_color}</span></p>
            </div>
            <p style={{ fontSize: '14px',color: '#94A3B8' , marginLeft: '10px', }}>${item.attributes.total_price}</p>
          </div>
          <hr style={{ color: 'rgb(242, 240, 240)' }} />
          </div>
        ))}
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1',fontSize: '18px', marginLeft: '10px',  }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>Subtotal <span style={{ color: '#94A3B8' }}>({order_items.length} items)</span></p>
              <p style={{ fontSize: '18px' }}>${parseFloat(sub_total).toFixed(2)}</p>
            </div>
            <div style={{ display: 'flex', fontSize: '18px', justifyContent: 'space-between', }}>
              <p style={{ marginTop: '-5px' }}>Savings</p>
              <p style={{ marginTop: '-5px' }}>-$0.00</p>
            </div>
          </div>
        </div>
        <hr style={{ color: 'rgb(242, 240, 240)' }} />
        <div style={{ ...webStyle.shippingOrder }}>
          <div style={{ ...webStyle.orderDetail, flex: '1', marginLeft: '10px', fontSize: '18px' }}>
            <div style={{justifyContent: 'space-between', display: 'flex',  fontSize: '18px' }}>
              <p>Total</p>
              <p>${parseFloat(total).toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div style={{ ...webStyle.placeOrderbutton, ...webStyle.saveButton }} onClick={() => this.placeOrderNavigation()}>Place Order</div>
      </div>
    );
  }

  // Customizable Area End

  render() {
    const { formData,errors } = this.state;

    const GreenSwitch = withStyles({
      switchBase: {
        color: "#fff",
        "&$checked": {
          transform: "translateX(22px)",
          color: "#fff",
          "& + $track": {
            backgroundColor: "#CCBEB1",
            opacity: 1,
            border: "none",
            height: 17,
          }
        },
        "&$focusVisible $thumb": {
          color: "#4cd964",
          border: "6px solid #fff",
        }
      },
      thumb: {
        width: 19,
        height: 21
      },
      track: {
        borderRadius: 16 / 2,
        opacity: 1,
        border: "1px solid #E5E5EA",
        backgroundColor: "#E5E5EA",
        transition: "background-color 0.3s"
      },
      checked: {},
      focusVisible: {}
    })(Switch);
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} />
        <Box style={{ backgroundColor: "rgb(248, 248, 248)", color: "#375280", fontFamily: "Lato" }}>
          <CheckoutSteps activeBread="address" />

        </Box>
        <div style={{ display: "flex", padding: "40px",backgroundColor: "#F8F8F8" }}>
          <Grid
            style={{  marginBottom: "58px" }}
            container
          >

            <Grid item xs={12} md={8} style={{ paddingInline: 20 }}>

              <div style={{ backgroundColor: "#F8F8F8" }}>
                <div>
                  <div style={webStyle.addAddressContainer as React.CSSProperties}>

                    <div
                      className="address_child"
                      style={{
                        fontSize: "20px",
                        color: "#375280",
                        marginBottom: "22px",
                        marginLeft: "10px",
                        fontFamily: 'Lato , sans-serif',
                      }}
                    >
                      Add Address
                    </div>
                    <div
                      className="address_child"
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#FFFFFF",
                        backgroundColor: "#CCBEB1",
                        padding: "10px 16px 10px 16px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        width: "254px",
                        height: "64px",
                        marginBottom: "22px",
                        borderRadius: "2px",
                        fontFamily: 'Lato , sans-serif',
                        marginLeft: "10px"
                      }}
                    >
                      <img src={location} />
                      Use my current location
                    </div>
                    <Grid
                      container
                      spacing={2}
                      style={{ width: "100%", margin: 0 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",

                          }}

                        >
                          Full Name
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          name="name"
                          variant="outlined"
                          value={formData.name}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.name && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.name}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MainGridContainer container>
                          <Grid item style={{ width: "100%" }}>
                            <Box>
                              <Typography
                                data-test-id="countryId"
                                className={"inputHeading"}
                              >
                                Phone Number
                                <span style={{ color: '#375280' }}>*</span>
                              </Typography>
                              <Box
                                className={"phoneInput"}
                                style={{ width: "100%" }}
                              >
                                <CustomDropDownBox>
                                  <DropDownHeaderMenu
                                    data-test-id="toggleDropdown"
                                    onClick={this.toggleDropdown}
                                    style={{ borderRadius: "8px" }}
                                  >
                                    {this.state.selectedCountry ? (
                                      <SelectedCountryList>
                                        <Typography data-test-id="testdata">
                                          <ReactCountryFlag
                                            data-test-id="flagData"
                                            countryCode={
                                              this.state.selectedCountry
                                                .country_code
                                            }
                                            svg
                                          />
                                          {
                                            this.state.selectedCountry
                                              .numeric_code
                                          }
                                        </Typography>
                                      </SelectedCountryList>
                                    ) : (
                                      <Typography
                                        data-test-id="Flagdata"
                                        className={"countryFlag"}
                                        style={{
                                          width: "max-content",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 5
                                        }}
                                      >
                                        <ReactCountryFlag
                                          countryCode="KW"
                                          svg
                                        />
                                        {this.state.isCountryData}
                                      </Typography>
                                    )}
                                    <span>
                                      {this.state.dropdownOpen ? (
                                        <ExpandLessIcon />
                                      ) : (
                                        <ExpandMoreIcon />
                                      )}
                                    </span>
                                  </DropDownHeaderMenu>
                                  {this.state.dropdownOpen && (
                                    <DropDownOptionsList data-test-id="Dropdata">
                                      {this.state.countrySuccess.map(
                                        (country: CountrySuccess) => (
                                          <OptionsValueList
                                            data-test-id="countrySelectId"
                                            key={country.country_code}
                                            onClick={() =>
                                              this.handleCountrySelect(
                                                country,
                                                country.numeric_code
                                              )
                                            }
                                          >
                                            <Typography
                                              data-test-id="listData"
                                              className={"listData"}
                                            >
                                              <ReactCountryFlag
                                                countryCode={
                                                  country.country_code
                                                }
                                                svg
                                              />
                                              {country.country_full_name} (
                                              {country.numeric_code})
                                            </Typography>
                                          </OptionsValueList>
                                        )
                                      )}
                                    </DropDownOptionsList>
                                  )}
                                </CustomDropDownBox>
                                <TextField
                                  data-test-id="phoneNumberTextFiled"
                                  variant="outlined"
                                  className="inputFeild2"
                                  style={{ width: "100%" }}
                                  placeholder="Enter Contact Number"
                                  inputProps={{ maxLength: 12 }}
                                  name="phone_number"
                                  value={formData.phone_number}
                                  onChange={this.handleAddInputChange}
                                  InputProps={{
                                    style: {
                                      gap: "4px",
                                      border: "0px",
                                      borderRadius: "8px",
                                      width: "100%"
                                    }
                                  }}
                                />

                              </Box>
                              {errors.phone_number && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.phone_number}</span>}
                            </Box>
                          </Grid>
                        </MainGridContainer>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          Street Name
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="street"
                          value={formData.street}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.street && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.street}</span>}

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          House / Building Number
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="house_or_building_number"
                          value={formData.house_or_building_number}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.house_or_building_number && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.house_or_building_number}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          Block
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="block"
                          value={formData.block}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.block && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.block}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          Area
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="area"
                          value={formData.area}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.area && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.area}</span>}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          City
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="cityTestId"
                          fullWidth
                          variant="outlined"
                          name="city"
                          value={formData.city}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.city && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.city}</span>}

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          Zip Code
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="zipcode"
                          value={formData.zipcode}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.zipcode && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.zipcode}</span>}

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#375280",
                            fontFamily: "Lato , sans-serif",
                          }}
                        >
                          Address Name
                          <span style={{ color: '#375280' }}>*</span>
                        </p>
                        <InputField
                          data-test-id="memberId"
                          fullWidth
                          variant="outlined"
                          name="address_name"
                          value={formData.address_name}
                          onChange={this.handleAddInputChange}
                        />
                        {errors.address_name && <span style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{errors.address_name}</span>}

                      </Grid>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#375280",
                        fontSize: "16px",
                        fontWeight: 500
                      }}
                    >
                      <GreenSwitch
                        checked={this.state.isCheccked}
                        onChange={this.handleAddToggle}
                        inputProps={{ "aria-label": "toggle switch" }}
                      />
                      {/* <p>Toggle is {this.state.isChecked ? 'ON' : 'OFF'}</p> */}
                      <p>Make it default delivery address</p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 22,
                        paddingInline: 10
                      }}
                    >
                      <div style={webStyle.button12} onClick={this.getNavigationAddAddress}>Cancel</div>
                      <div
                        style={{ ...webStyle.button12, ...webStyle.saveButton }}
                        onClick={this.handleSubmit}
                        data-test-id="handleSubmit"
                      >
                        Save
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4} style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }}>

              <div style={{ margin: 'auto', paddingInline: 20 }}>
                <div>

                  <div style={webStyle.addAddressContainer1 as React.CSSProperties} >
                    <div style={{ marginBottom: "14px", cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" }}>
                      Order Summary
                    </div>
                    <hr style={{ color: 'rgb(242, 240, 240)' }} />

                    {this.renderOrderSummary()}

                  </div>

                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer navigation={this.props.navigation} />
        <Snackbar
          data-test-id="alertTestId"
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  addAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  placeOrderbutton: {
    padding: "10px 16px 10px 16px",
    height: "64px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: '5px'
  },
  orderDetail: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Lato , sans-serif',
  },
  shippingOrder: { marginBottom: "14px", cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" },
  addAddressContainer1: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '24px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '28.8px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },
  dropdown: {
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 0px #00000014',
    backgroundColor: 'white',
    zIndex: 1,
    left: 0,
    width: '120px',
    top: '100%',
    position: 'absolute',
  },
  dropdownP: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingBottom: '10px',
    borderBottom: '3px solid #D5D5D5',
  },
  dropdownP1: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  button12: {
    padding: "10px 16px 10px 16px",
    width: "50%",
    height: "64px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: '5px'
  },
  button123: {
    padding: "10px 16px 10px 16px",
    height: "64px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 700,
    color: "#375280",
    border: "1px solid #CCBEB1",
    borderRadius: "2px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: '5px'
  },
  saveButton: {
    color: "white",
    backgroundColor: "#CCBEB1"
  },

};
const InputField = styled(TextField)({
  "& .MuiInputBase-input": {
    color: "#375280 !important"
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
      borderWidth: "1px",
    }
  },
  "& .MuiInputBase-root": {
    fontSize: "16px",
    borderRadius: "8px",
    margin: "auto",
    height: "56px",
    backgroundColor: "#F0F0F0",
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-input": {
    color: "#1E293B",
    padding: "10px 16px 10px 16px",
    "&::placeholder": {
      fontSize: "16px",
      color: "#94A3B8",
      "@media only screen and (max-width: 414px)": {
        fontSize: "14px"
      }
    },
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    }
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  }
});
const MainGridContainer = styled(Grid)({
  justifyContent: "center",
  display: "flex",

  "& .mainCotainer": {
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "24px",
    lineHeight: "26px",
    fontFamily: "Avenir,sans-serif",
    alignSelf: "flex-start",
    textOverflow: "ellipsis",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    marginBottom: "40px",
  },

  "& .inputHeading": {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
    marginTop: "20px",
    fontStyle: "normal",
    marginBottom: "10px",
    color: "#375280",
    fontFamily: "Lato, sans-serif",
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    width: "100%px",
    height: "56px",
    gap: "15px",
    display: "flex",
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    fontWeight: 200,
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    fontFamily: "Lato, sans-serif",
    cursor: "pointer",
    margin: "10px",
    textOverflow: "ellipsis",
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      border: "none",
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
   
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important"
      }
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #F0F0F0 !important"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
      backgroundColor: "#F0F0F0",
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
   
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
   
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiTextField-root": {
        "&:hover": {
          border: "1px solid #F0F0F0 !important"
        }
      },
      "& .MuiOutlinedInput-root:hover": {
        border: "1px solid #F0F0F0 !important"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
     
      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"
    }
  }
});
const CustomDropDownBox = styled(Box)({
  width: "125px",
  cursor: "pointer",
  position: "relative",
});
const DropDownHeaderMenu = styled(Box)({
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  gap: "15px",
  background: "#F0F0F0",
  fontSize: "18px",
  color: "#375280",
  height: "36px",
  padding: "10px",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const DropDownOptionsList = styled(Box)({
  backgroundColor: "#fff",
  color: "#375280",
  overflowX: "hidden",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  height: "60vh",
  zIndex: 1,
  position: "absolute",
  border: "1px solid #ccc",
  left: 0,
  width: "100%",
});
const OptionsValueList = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
